import React, { useEffect, useState } from 'react';
import '../../CSS/products.css';
import SellSheetCube from './SellSheetCube';
import { useNavigate } from 'react-router-dom';
import { Cloudinary } from '@cloudinary/url-gen';
import { AdvancedImage } from '@cloudinary/react';
import VideoPlayer from './VideoPlayer';
import '../../CSS/QuickShipButton.css';
import { FaCircle } from 'react-icons/fa';
import GetColorInfo from './GetColorInfo';
import useAuth from '../../hooks/useAuth';
import { useDispatch, useSelector } from 'react-redux';
import { updateProductInfo } from '../../ReduxSlices/productState';
import { useCart } from '../../context/CartContext';

const assemblyIcon =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653569/Website%20Assets/SVGs/Assembly_arbocg.svg';
const marketing =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653586/Website%20Assets/SVGs/MK_Video_jvp7d4.svg';
const demo =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653575/Website%20Assets/SVGs/Demo_Video_cky8wz.svg';
const sellsheet =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653559/Website%20Assets/SVGs/Sell_Sheet_xuwftb.svg';

const DetailsDisplay = ({}) => {
  const { product, quickShipProducts, skuDisplayed, customerPrice, skuCompleted, loadingPrice } =
    useSelector((state) => state.productState.value);
  const screenType = useSelector((state) => state.isMobile.value);

  // const premLam = fullResponse?.seriesData?.premLam;
  // const premEdge = fullResponse.premEdge;
  const [showMore, setShowMore] = useState(false);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);
  const [videoSrc, setVideoSrc] = useState();
  const [detailTitles, setDetailTitles] = useState([
    'Description',
    'Media',
    'Dimensions',
    'Shipping Info',
  ]);
  const [selectedDetails, setSelectedDetails] = useState(detailTitles[0]);
  // const navigate = useNavigate();
  const { auth } = useAuth();
  const { notificationData } = useCart();
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'da3rom333',
    },
  });

  const otherLitIcon =
    'https://res.cloudinary.com/da3rom333/image/upload/v1692968626/Website%20Assets/SVGs/General_Icon_without_bg_yhrwep.svg';
  const Accesories = [
    {
      title: 'Titan Table',
      image: 'v1674225538/Demo Sample Images/Categories/MSC03W_ct5ihu.jpg',
      link: 'Titan%20Table%2030%22H',
    },
  ];

  useEffect(() => {
    if (!detailTitles?.includes(selectedDetails)) {
      setSelectedDetails(detailTitles[0]);
    }
  }, [detailTitles]);

  useEffect(() => {
    if (
      product &&
      (Object.keys(product?.configOptions).length <= 0 || skuCompleted) &&
      (auth?.accounts
        ?.find((account) => account.last_login === 1)
        ?.AccountRoles?.some((role) => [1100, 1200, 1300].includes(role)) ||
        auth?.roles?.includes(3500)) &&
      !notificationData?.some((i) => i.Path === 'pricing')
    ) {
      if (!detailTitles?.includes('Pricing')) {
        // console.log(140);
        setDetailTitles([...detailTitles, 'Pricing']);
      }
    } else {
      setDetailTitles(detailTitles.filter((i) => i !== 'Pricing'));
    }
  }, [auth]);

  useEffect(() => {
    let holderArray = [...detailTitles];
    if (product?.literature?.filter((i) => i.type === 'Assembly Instructions')?.length > 0) {
      if (!holderArray?.includes('Installation')) {
        holderArray.push('Installation');
      }
    } else {
      holderArray = holderArray.filter((i) => i !== 'Installation');
    }
    if (
      product &&
      (Object.keys(product?.configOptions).length <= 0 || skuCompleted) &&
      (auth?.accounts
        ?.find((account) => account.last_login === 1)
        ?.AccountRoles?.some((role) => [1100, 1200, 1300].includes(role)) ||
        auth?.roles?.includes(3500)) &&
      !notificationData?.some((i) => i.Path === 'pricing')
    ) {
      if (!holderArray?.includes('Pricing')) {
        // console.log(140);
        holderArray.push('Pricing');
      }
    } else {
      holderArray = holderArray.filter((i) => i !== 'Pricing');
    }

    setDetailTitles(holderArray);
  }, [product, skuDisplayed]);

  // function handleAccesoryClick(item) {
  //   navigate({
  //     pathname: `/products/${item.link}`,
  //     // pathname: "/products/LuvraFlex Series",
  //     // search: `?${createSearchParams(optionsSelected)}`,
  //   });
  // }
  function videoClickHandler(src) {
    setShowVideoPlayer(true);
    setVideoSrc(src);
  }
  function literatureHandler(literature) {
    // window.open(product?.productInfo?.SellSheet, "_blank");
    window.open(`${literature?.full_link}`, '_blank');
  }
  function sellSheetHandler() {
    // window.open(product?.productInfo?.SellSheet, "_blank");
    window.open(
      `https://res.cloudinary.com/da3rom333/image/upload/${product?.productInfo?.SellSheet}`,
      '_blank'
    );
  }
  function assemblyHandler() {
    // window.open(product?.productInfo?.SellSheet, "_blank");
    window.open(
      `https://res.cloudinary.com/da3rom333/image/upload/${product?.assemblyInstructions}`,
      '_blank'
    );
  }

  const shippingInfo = product?.shippingInfo;
  function switchDetails() {
    switch (selectedDetails) {
      case 'Description':
        return (
          <div className='description-display'>
            <div
              style={{
                textAlign: 'justify',
                paddingBottom: '1.5rem',
                marginBottom: '1.5rem',
                borderBottom: '1px solid #E1E1E1',
                whiteSpace: 'pre-wrap',
                font: 'normal normal normal 16px/26px Poppins',
                color: '#4B4B4B',
              }}
            >
              {product?.productInfo?.LongDescription}
            </div>

            <div
              className='desc-display-more'
              style={{ display: 'flex' }}
            >
              {(!screenType.isMobile || showMore) && (
                <div className='bullets'>
                  <h2>Specs</h2>
                  <div className='underline'></div>
                  {product?.bullets?.map((i) => {
                    return (
                      <div
                        key={i.Index}
                        style={{ margin: '0' }}
                        className='bullet'
                      >
                        <FaCircle
                          style={{
                            height: '7px',
                            width: '7px',
                            position: 'absolute',
                          }}
                        />{' '}
                        <div style={{ marginLeft: '25px' }}>
                          {i.Title && <span style={{ fontWeight: 'bold' }}>{i.Title}: </span>}
                          {i.Text}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
              {screenType.isMobile && (
                <a
                  className='read-more'
                  onClick={() => setShowMore(!showMore)}
                >
                  {showMore ? 'Read Less' : 'Read More'}
                </a>
              )}
              <div className='certifications'>
                {product?.certifications?.map((i, index) => {
                  return (
                    <AdvancedImage
                      style={{ cursor: i.fileLink ? 'pointer' : '' }}
                      onClick={() => {
                        if (i.fileLink) {
                          window.open(i.fileLink, '_blank');
                        }
                      }}
                      key={index}
                      cldImg={cld.image(i.LogoImage)}
                      // .resize(scale().height(100))}
                      // plugins={[lazyload()]}
                    />
                  );
                })}
                {quickShipProducts?.some((i) => {
                  if (
                    product?.configOptions?.Seating?.selectionName?.toLowerCase() === 'stool table'
                  ) {
                    const manipulatedSku = skuDisplayed.slice(0, -2);

                    return i.title.toLowerCase() === manipulatedSku.toLowerCase();
                  } else {
                    return i.title.toLowerCase() === skuDisplayed.toLowerCase();
                  }
                }) ? (
                  <img
                    className='lead-time-img'
                    src={
                      'https://res.cloudinary.com/da3rom333/image/upload/v1692214095/Icons/Certifications/7_Day_rt9jle.svg'
                    }
                  />
                ) : product?.productInfo?.LeadTimeImage ? (
                  <img
                    className='lead-time-img'
                    src={product.productInfo.LeadTimeImage}
                  />
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        );
      case 'Media':
        return (
          <div style={{ display: 'flex' }}>
            {showVideoPlayer ? (
              <VideoPlayer
                videoSrc={videoSrc}
                setShow={setShowVideoPlayer}
              />
            ) : (
              ''
            )}

            {product?.videos?.map((i, index) => (
              <SellSheetCube
                key={index}
                clickHandler={() => {
                  videoClickHandler(i.video_link);
                }}
                text={
                  i.type.toLowerCase() === 'demo'
                    ? 'WATCH THE DEMO VIDEO'
                    : 'WATCH THE MARKETING VIDEO'
                }
                img={i.type.toLowerCase() === 'demo' ? demo : marketing}
              />
            ))}
            {product?.literature?.map((i, index) => (
              <SellSheetCube
                key={index}
                clickHandler={() => literatureHandler(i)}
                text={
                  i.text
                    ? i.text
                    : i.type.toLowerCase() === 'sell sheet'
                    ? `DOWNLOAD ${i.type}`
                    : i.type.toLowerCase() === 'other'
                    ? 'DOWNLOAD LITERATURE'
                    : i.type
                }
                img={
                  i.type.toLowerCase() === 'sell sheet'
                    ? sellsheet
                    : i.type.toLowerCase() === 'assembly instructions'
                    ? assemblyIcon
                    : i.type.toLowerCase() === 'other'
                    ? otherLitIcon
                    : ''
                }
              />
            ))}
          </div>
        );

      case 'Installation':
        return (
          <div style={{ display: 'flex' }}>
            {product?.literature
              ?.filter((i) => i.type === 'Assembly Instructions')
              .map((i,index) => (
                <SellSheetCube
                  key={index}
                  clickHandler={() => literatureHandler(i)}
                  text={
                    i.text
                      ? i.text
                      : i.type.toLowerCase() === 'sell sheet'
                      ? `DOWNLOAD ${i.type}`
                      : i.type.toLowerCase() === 'other'
                      ? 'DOWNLOAD LITERATURE'
                      : i.type
                  }
                  img={
                    i.type.toLowerCase() === 'sell sheet'
                      ? sellsheet
                      : i.type.toLowerCase() === 'assembly instructions'
                      ? assemblyIcon
                      : i.type.toLowerCase() === 'other'
                      ? otherLitIcon
                      : ''
                  }
                />
              ))}
          </div>
        );
      case 'Dimensions':
        return (
          <>
            {product?.dims.map((i, index) => {
              return (
                <div key={index}>
                  {i.Type}: {i.Dims}
                </div>
              );
            })}
          </>
        );
      // case "Accesories/Use With":
      //   return (
      //     <>
      //       {Accesories
      //         // product?.productInfo?.Accesories?
      //         .map((i) => {
      //           return (
      //             <TemplateTile item={i} clickHandler={handleAccesoryClick} />
      //           );
      //         })}
      //     </>
      //   );
      case 'Shipping Info':
        return (
          <>
            {/* All shipping info */}
            {shippingInfo
              ? Object.keys(shippingInfo)?.map((i, index) => {
                  if (
                    shippingInfo[i] &&
                    i.toLowerCase() !== 'list price' &&
                    i.toLowerCase() !== 'number of stools/benches'
                  ) {
                    return <div key={index}>{`${i}: ${shippingInfo[i]}`}</div>;
                  }
                })
              : ''}
            {/* <div>Ships from: {shippingInfo["Ships From"]}</div> */}
          </>
        );
      case 'Pricing':
        // const shippingInfo = product?.shippingInfo;
        return customerPrice ? (
          <div style={{ display: 'flex' }}>
            {' '}
            <div style={{ marginRight: '15px' }}>
              {/* All shipping info */}
              <h4 style={{ marginTop: '0px', marginBottom: '5px' }}>List Price:</h4>
              <div
                style={{
                  height: '20px',
                  borderRadius: '0px',
                }}
                className={
                  !customerPrice?.basePrice?.listPrice || loadingPrice
                    ? 'loading-price'
                    : 'modelNum'
                }
              >
                Item Price: $
                {parseFloat(customerPrice?.basePrice?.listPrice).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </div>
              <div>
                {!loadingPrice &&
                customerPrice?.basePrice?.listPrice &&
                customerPrice?.laminatesUpcharge
                  ? `Premium Laminate Upcharge: $${parseFloat(
                      customerPrice?.laminatesUpcharge?.listPrice
                    ).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  `
                  : ''}
              </div>
              <div>
                {!loadingPrice &&
                customerPrice?.basePrice?.listPrice &&
                customerPrice?.premEdgeUpcharge?.listPrice
                  ? `Premium Edge Upcharge: $${parseFloat(
                      customerPrice?.premEdgeUpcharge?.listPrice
                    ).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                 `
                  : ''}
              </div>

              <div>
                {!loadingPrice &&
                customerPrice?.basePrice?.listPrice &&
                (customerPrice?.laminatesUpcharge || customerPrice?.premEdgeUpcharge)
                  ? `Total: $${parseFloat(
                      (+customerPrice?.laminatesUpcharge?.listPrice ||
                        +customerPrice?.laminatesUpcharge) +
                        (+customerPrice?.premEdgeUpcharge?.listPrice ||
                          +customerPrice?.premEdgeUpcharge) +
                        +customerPrice?.basePrice?.listPrice
                    ).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                 `
                  : ''}
              </div>
            </div>
            <div>
              <h4 style={{ marginTop: '0px', marginBottom: '5px' }}>Dealer Price:</h4>
              <div
                style={{
                  height: '20px',
                  borderRadius: '0px',
                }}
                className={
                  !customerPrice?.basePrice?.listPrice || loadingPrice
                    ? 'loading-price'
                    : 'modelNum'
                }
              >
                Item Price: $
                {parseFloat(customerPrice?.basePrice?.unitPrice).toLocaleString('en-US', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </div>
              <div>
                {!loadingPrice &&
                customerPrice?.basePrice?.unitPrice &&
                customerPrice?.laminatesUpcharge
                  ? `Premium Laminate Upcharge: $${parseFloat(
                      customerPrice?.laminatesUpcharge?.unitPrice
                    ).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  `
                  : ''}
              </div>
              <div>
                {!loadingPrice &&
                customerPrice?.basePrice?.unitPrice &&
                customerPrice?.premEdgeUpcharge?.unitPrice
                  ? `Premium Edge Upcharge:
                  $${parseFloat(customerPrice?.premEdgeUpcharge?.unitPrice).toLocaleString(
                    'en-US',
                    {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    }
                  )}
                  `
                  : ''}
              </div>

              <div>
                {!loadingPrice &&
                customerPrice?.basePrice?.unitPrice &&
                (customerPrice?.laminatesUpcharge || customerPrice?.premEdgeUpcharge)
                  ? `Total: $${parseFloat(
                      (+customerPrice?.laminatesUpcharge?.unitPrice ||
                        +customerPrice?.laminatesUpcharge) +
                        (+customerPrice?.premEdgeUpcharge?.unitPrice ||
                          +customerPrice?.premEdgeUpcharge) +
                        +customerPrice?.basePrice?.unitPrice
                    ).toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                 `
                  : ''}
              </div>
            </div>
          </div>
        ) : (
          <>
            <div>Custom Pricing - please reach out to a sales rep for your price.</div>
          </>
        );
      case 'Certifications':
        return (
          <>{product.productInfo.Certifications ? `${product.productInfo.Certifications}` : ''}</>
        );
      default:
        return '';
    }
  }

  return (
    <>
      <div className='detail-titles-scroll'>
        <div className='detail-titles'>
          {detailTitles.map((d) => {
            return (
              <div
                key={d}
                className={
                  selectedDetails === d ? 'detail-title detail-title-selected' : `detail-title`
                }
                onClick={() => {
                  setSelectedDetails(d);
                }}
              >
                {d}{' '}
              </div>
            );
          })}
        </div>
      </div>
      <div className='details-content'>
        <div className='switch-details'>{switchDetails()}</div>
      </div>
    </>
  );
};

export default DetailsDisplay;
