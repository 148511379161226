import { CgSearch } from 'react-icons/cg';
import '../../CSS/NPSportal/reusableComponents.css';
import { useState } from 'react';

const BasicSearchBar = ({ filters, setFilters }) => {
  return (
    <div className={`${filters ? 'search-has-text' : ''} basic-search-bar`}>
      <CgSearch style={{ transform: 'scaleX(-1)' }} />
      <input
        onChange={(e) => setFilters(e.target.value)}
        placeholder='Search'
        value={filters || ''}
      ></input>
    </div>
  );
};

export default BasicSearchBar;
