import React, { useEffect, useState } from 'react';
import EditTemplate from '../../Components/EditorPortal/EditTemplate';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import useAuth from '../../hooks/useAuth';
import { FaCheckCircle, FaEnvelope } from 'react-icons/fa';
import { RiErrorWarningFill } from 'react-icons/ri';
import { IoReturnDownBack } from 'react-icons/io5';
import { useLocation } from 'react-router-dom';
const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const AccountManagerPortal = () => {
  const axiosJWT = useAxiosJWT();
  const { auth } = useAuth();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  // Convert searchParams to string
  const queryString = searchParams.toString();
  let decoded;
  if (queryString) {
    decoded = decodeURIComponent(queryString)?.substring(queryString?.indexOf('=') + 1);
  }

  const [accountRoles, setAccountsRoles] = useState([]);
  const [showRoles, setShowRoles] = useState(false);
  const [showPermissions, setShowPermissions] = useState(false);
  const [checkedRoles, setCheckedRoles] = useState([]);
  const [showAddAccount, setShowAddAccount] = useState(false);
  const [email, setEmail] = useState('');
  const [validEmail, setValidEmail] = useState(false);
  const [emailFocus, setEmailFocus] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const [notification, setNotification] = useState();
  const [requestInvite, setRequestIvite] = useState(false);
  useEffect(() => {
    axiosJWT
      .post(`register/getAccountRoles`)
      .then((response) => {
        // console.log(Object.values(response.data), 219);
        // console.log(response.data, 18);
        setAccountsRoles(response.data);
      })
      .catch((err) => {
        console.error(err.sqlMessage);
      });
  }, []);
  useEffect(() => {
    // console.log(auth, 123);
  }, []);

  useEffect(() => {
    setValidEmail(EMAIL_REGEX.test(email));
  }, [email]);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification();
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [notification]);

  const handleCheckedRoleChange = (role, checked) => {
    if (checked) {
      setCheckedRoles([...checkedRoles, role]);
      // console.log(checkedRoles);
    } else {
      setCheckedRoles(checkedRoles.filter((i) => i !== role));
    }
  };
  const sendInvite = async () => {
    const v1 = EMAIL_REGEX.test(email);
    if (!auth?.rep) {
      setErrMsg('NOT REGISTERED USER');
      return;
    }
    if (!v1) {
      setErrMsg('Invalid Entry');
      setEmailFocus(true);
      return;
    }

    // console.log(auth);
    const userID = auth?.accounts[0]?.user_ID;

    const account = auth?.accounts?.find((i) => i.last_login === 1)
      ? auth.accounts.find((i) => i.last_login === 1)
      : auth.accounts[0];
    const accountNum = account.account_num;

    // console.log(accountNum, 46);
    try {
      await axiosJWT
        .post(`account/invite`, {
          userID,
          email,
          roles: checkedRoles.join(', '),
          accountNum,
        })

        .then((response) => {
          setShowAddAccount(false);
          setEmail('');
          setEmailFocus(false);
          setErrMsg('');
          setCheckedRoles([]);
          setShowRoles(false);
          setShowPermissions(false);
          setNotification(response.data.message);
          // console.log(Object.values(response.data), 219);
          // console.log(response.data, 50);
        });
    } catch (err) {
      console.error(err);
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        if (err.response.data.error.message === 'Username Taken') {
          setRequestIvite(true);
        } else {
          setErrMsg(err.response.data.error.message);
        }
      } else {
        setErrMsg('Registration Failed');
      }
    }
  };

  const persistRequest = async () => {
    const userID = auth?.accounts[0]?.user_ID;

    const account = auth?.accounts?.find((i) => i.last_login === 1)
      ? auth.accounts.find((i) => i.last_login === 1)
      : auth.accounts[0];
    const accountNum = account.account_num;

    // console.log(accountNum, 46);
    try {
      await axiosJWT
        .post(`account/persistRequest`, {
          userID,
          email,
          accountNum,
        })

        .then((response) => {
          setShowAddAccount(false);
          setEmail('');
          setEmailFocus(false);
          setErrMsg('');
          setCheckedRoles([]);
          setShowRoles(false);
          setShowPermissions(false);
          setNotification(response.data.message);
          setRequestIvite(false);
          // console.log(Object.values(response.data), 219);
          // console.log(response.data, 50);
        });
    } catch (err) {
      console.error(err);
      if (!err?.response) {
        setErrMsg('No Server Response');
      } else if (err.response?.status === 400) {
        setErrMsg(err.response.data.error.message);
      } else {
        setErrMsg('Registration Failed');
      }
    }
  };

  const closeAdd = () => {
    setShowAddAccount(false);
    setEmail('');
    setEmailFocus(false);
    setErrMsg(false);
    setCheckedRoles([]);
    setShowRoles(false);
    setShowPermissions(false);
    setRequestIvite(false);
  };

  useEffect(() => {}, [showAddAccount]);

  const columns = [
    {
      type: 'text',
      name: 'ID',
      searchAllowed: true,
      editable: false,
      display: false,
      table: 'users',
      fieldName: 'ID',
    },
    {
      type: 'text',
      name: 'Name',
      searchAllowed: true,
      editable: true,
      display: true,
      table: 'users',
      fieldName: 'rep',
    },
    {
      type: 'text',
      name: 'Company',
      searchAllowed: true,
      editable: true,
      display: true,
      table: 'users',
      fieldName: 'dealer',
    },
    {
      type: 'text',
      name: 'Phone',
      searchAllowed: true,
      editable: true,
      display: true,
      table: 'users',
      fieldName: 'phone',
    },
    {
      type: 'text',
      name: 'Username',
      searchAllowed: true,
      editable: false,
      display: true,
      table: 'users',
      fieldName: 'username',
    },
    {
      type: 'text',
      name: 'Password',
      searchAllowed: true,
      editable: false,
      display: false,
      table: 'users',
      fieldName: 'password',
    },
    {
      type: 'text',
      name: 'Created On',
      searchAllowed: true,
      editable: false,
      display: true,
      table: 'users',
      fieldName: 'createdOn',
    },
    {
      type: 'roles',
      name: 'AccountRoles',
      searchAllowed: true,
      editable: true,
      display: true,
      table: 'account_roles',
      fieldName: 'AccountRoles',
    },
    {
      type: 'roles',
      name: 'Permissions',
      searchAllowed: true,
      editable: true,
      display: true,
      table: 'account_roles',
      fieldName: 'AccountRoles',
    },
  ];

  return (
    <>
      {/* <button
        style={{
          position: "absolute",
          marginTop: "6.77rem",
          marginLeft: "53rem",
          width: "205px",
        }}
        onClick={() => setShowAddAccount(true)}
        className="editableFormButton"
      >
        ADD USER
      </button> */}
      <EditTemplate
        columns={columns}
        getEndPoint={'getUsersForAccount'}
        title={'User Management'}
        table={'users'}
        exportAvailable={false}
        //   saveHandlerIncoming={saveHandlerUsers}
        importBatch={false}
        // exportTemplate={false}
        // importUpdate={true}
        accountRoles={accountRoles}
        setShowAddAccount={setShowAddAccount}
        searchInput={decoded}
      />

      {showAddAccount && (
        <div>
          <div className='ma popup'>
            <div className='overlay'></div>
            <div style={{ minHeight: '376px', top: '33%' }} className='add-user-popup'>
              <AiOutlineCloseCircle
                className='close-button'
                onClick={closeAdd}
                style={{ top: '1rem', right: '1rem' }}
              />
              <h2>Add User</h2>

              {!requestInvite ? (
                <>
                  <div
                    style={{
                      display: 'flex',
                      width: '590px',
                      height: '128px',
                      marginTop: 'auto',
                      padding: '40px 16px',
                      flexDirection: 'column',
                    }}
                    className='search-sku-div'
                  >
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-around',
                      }}
                    >
                      <div
                        className={`login-input-holder ${
                          emailFocus && !validEmail
                            ? 'error-input'
                            : validEmail
                              ? 'valid-input'
                              : ''
                        }`}
                        style={{
                          background: 'white',
                          borderRadius: '10px',
                          height: '40px',
                          width: '300px',
                        }}
                      >
                        <div className={`dealer-icon ${validEmail ? 'valid-icon' : ''}`}>
                          <FaEnvelope />
                        </div>
                        <input
                          style={{
                            width: '205px',
                            height: '36px',
                            background: 'none',
                          }}
                          placeholder='Email Address'
                          type='text'
                          name='email'
                          id='email'
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          onFocus={() => {
                            setEmailFocus(true);
                          }}
                        ></input>
                        <div>
                          {validEmail ? (
                            <div className='dealer-msg valid'>
                              <FaCheckCircle />
                            </div>
                          ) : emailFocus ? (
                            <div className='dealer-msg error'>
                              <RiErrorWarningFill />
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>

                      <>
                        <div
                          onClick={() => {
                            showRoles ? setShowRoles(false) : setShowRoles(true);
                          }}
                          className={`login-input-holder selection `}
                          style={{
                            background: 'white',
                            justifyContent: 'space-between',
                            padding: '0px 10px',
                            display: 'flex',
                            width: '140px',
                            cursor: 'pointer',
                            fontWeight: 'initial',
                            marginLeft: '2px',
                            borderRadius: '10px',
                            height: '40px',
                            fontSize: '14px',
                          }}
                        >
                          Roles
                          {showRoles ? (
                            <BiChevronUp
                              onClick={() => {
                                setShowRoles(false);
                              }}
                              className='laminate-select-down laminates-svg'
                            />
                          ) : (
                            <BiChevronDown
                              onClick={() => {
                                setShowRoles(true);
                              }}
                              className='laminate-select-down laminates-svg'
                            />
                          )}{' '}
                        </div>
                        {showRoles && (
                          <div
                            style={{
                              marginLeft: '9.5rem',

                              fontSize: '14px',
                              marginTop: '2.6rem',
                              width: '134px',
                              padding: '10px 5px',
                            }}
                            className='userroles-dropdown'
                          >
                            {accountRoles
                              ?.filter((i) => !i.permission)
                              ?.map((role, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: 'flex',
                                    cursor: 'default',
                                  }}
                                >
                                  <input
                                    type='checkbox'
                                    checked={checkedRoles.includes(role.role)}
                                    onChange={(event) =>
                                      handleCheckedRoleChange(role.role, event.target.checked)
                                    }
                                    style={{ cursor: 'pointer' }}
                                  />
                                  <div>{role.role}</div>
                                </div>
                              ))}
                          </div>
                        )}
                      </>
                      <>
                        <div
                          onClick={() => {
                            showPermissions ? setShowPermissions(false) : setShowPermissions(true);
                          }}
                          className={`login-input-holder selection `}
                          style={{
                            background: 'white',
                            justifyContent: 'space-between',
                            padding: '0px 10px',
                            display: 'flex',
                            width: '140px',
                            cursor: 'pointer',
                            fontWeight: 'initial',
                            marginLeft: '2px',
                            borderRadius: '10px',
                            height: '40px',
                            fontSize: '14px',
                          }}
                        >
                          Permissions
                          {showPermissions ? (
                            <BiChevronUp
                              onClick={() => {
                                setShowPermissions(false);
                              }}
                              className='laminate-select-down laminates-svg'
                            />
                          ) : (
                            <BiChevronDown
                              onClick={() => {
                                setShowPermissions(true);
                              }}
                              className='laminate-select-down laminates-svg'
                            />
                          )}{' '}
                        </div>
                        {showPermissions && (
                          <div
                            style={{
                              marginLeft: '26.5rem',

                              fontSize: '14px',
                              marginTop: '2.6rem',
                              width: '134px',
                              padding: '10px 5px',
                            }}
                            className='userroles-dropdown'
                          >
                            {accountRoles
                              ?.filter((i) => i.permission)
                              ?.map((role, index) => (
                                <div
                                  key={index}
                                  style={{
                                    display: 'flex',
                                    cursor: 'default',
                                  }}
                                >
                                  <input
                                    type='checkbox'
                                    checked={checkedRoles.includes(role.role)}
                                    onChange={(event) =>
                                      handleCheckedRoleChange(role.role, event.target.checked)
                                    }
                                    style={{ cursor: 'pointer' }}
                                  />
                                  <div>{role.role}</div>
                                </div>
                              ))}
                          </div>
                        )}
                      </>
                    </div>
                    {emailFocus && !validEmail ? (
                      <div className='error-msg-text'>Required field is missing.</div>
                    ) : (
                      ''
                    )}
                  </div>

                  <button className='editableFormButton' onClick={sendInvite}>
                    SEND INVITE
                  </button>
                  {errMsg ? (
                    <div
                      className='error-msg-text'
                      style={{
                        textAlign: 'center',
                        marginBottom: '0',
                        marginTop: '-7px',
                      }}
                    >
                      {errMsg}
                    </div>
                  ) : (
                    ''
                  )}
                </>
              ) : requestInvite ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'space-around',
                    height: '220px',
                  }}
                >
                  <h4>{email}</h4>
                  <h3 style={{ textAlign: 'center' }}>
                    Username already exists and may be linked to other accounts. Would you like to
                    request this user to be linked to this account as well?
                  </h3>
                  <button onClick={persistRequest} className='editableFormButton'>
                    Request
                  </button>

                  <IoReturnDownBack
                    onClick={() => setRequestIvite(false)}
                    style={{
                      height: '2rem',
                      width: '2rem',
                      cursor: 'pointer',
                      color: 'var(--lightgreen)',
                    }}
                  />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      )}

      {notification ? <div className='notice'>{notification}</div> : ''}
    </>
  );
};

export default AccountManagerPortal;
