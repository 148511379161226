import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../CSS/NotFound.css';

// const banner =
// 	"https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688650800/Website%20Assets/Banners/notFound_ga0ecc.jpg";

const NotFound = () => {
  useEffect(() => {
    document.title = `Not Found - National Public Seating`;
    window.scrollTo(0, 0);
  });
  const navigate = useNavigate();
  return (
    <div>
      <div className='banner not-found'>
        {/* <img
					src={banner}
					style={{ marginTop: "0" }}
				></img> */}
        <div className='go-home-button-div'>
          <button
            className='go-home-button'
            onClick={() => {
              navigate('/');
            }}
          >
            Go Home
          </button>
        </div>
      </div>
      <div style={{ height: '54rem' }}></div>
    </div>
  );
};

export default NotFound;
