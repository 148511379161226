import React, { useState } from 'react';
import useAxiosJWT from '../../hooks/useAxiosJWT';

function GenerateCalendar({ setEditedCalendar }) {
  const axiosJWT = useAxiosJWT();
  const [showWarning, setShowWarning] = useState(false);

  async function handleGenerateCalendar() {
    await axiosJWT
      .post(`employeePortal/generateCalendar`)
      .then((response) => {
        console.error('regenerated', response.data);
        setEditedCalendar(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
    setShowWarning(false);
  }

  return (
    <div>
      {showWarning && (
        <div className='calendar-popup'>
          <h4>WARNING!</h4>
          <div>
            Regenerating this calendar will schedule a new cycle of events, beginning today. All
            events from the previous cycle that are still active will be rescheduled except for
            events that were added manually.
          </div>
          <div>Are you sure you would like to generate a new calendar?</div>
          <div className='sub-cal-buttons'>
            <button className='edit-cal-button' onClick={() => setShowWarning(false)}>
              NO
            </button>
            <button className='edit-cal-button warning' onClick={handleGenerateCalendar}>
              YES
            </button>
          </div>
        </div>
      )}
      <button className='edit-cal-button generate' onClick={() => setShowWarning(true)}>
        Generate New Calendar
      </button>
    </div>
  );
}

export default GenerateCalendar;
