import '../../CSS/NPSportal/reusableComponents.css';
import { useState } from 'react';

const BasicButton = ({
  text,
  type,
  onClick = false,
  disabled = false,
  icon = false,
  buttonStyle = false,
}) => {
  const [clicked, setClicked] = useState(false);

  return (
    <div
      onClick={() => {
        setClicked(!clicked);
        if (onClick && !disabled) {
          onClick();
        }
      }}
      className={` ${disabled ? 'disabled-basic-button' : type} basic-button-design `}
      style={buttonStyle || {}}
    >
      {icon ? (
        <img
          className='button-icon'
          src={`${icon}`}
        ></img>
      ) : (
        ''
      )}
      <div>{text}</div>
    </div>
  );
};

export default BasicButton;
