import { useEffect, useState } from 'react';
import { useStageInfo } from '../../context/StageProvider';

function GenerateCustomStage() {
  const { setStageBuild, setConfigure, uniqueIdTracker, setUniqueIdTracker } = useStageInfo();
  const [formData, setFormData] = useState([
    {
      id: 0,
      name: 'width in feet',
      type: 'number',
      value: 0,
    },
    {
      id: 1,
      name: 'depth in feet',
      type: 'number',
      value: 0,
    },
    {
      id: 2,
      name: 'levels',
      type: 'select',
      options: [
        { id: 0, name: '8 inches', img: '' },
        { id: 1, name: '16 inches', img: '' },
        { id: 2, name: '24 inches', img: '' },
        { id: 3, name: '32 inches', img: '' },
      ],
      allow: 'multiple',
      value: [],
    },
    {
      id: 3,
      name: 'capacity',
      type: 'number',
      value: 0,
    },
    {
      id: 4,
      name: 'type',
      type: 'select',
      options: [
        { id: 0, name: 'Fixed Height Stages', img: '' },
        { id: 1, name: 'Risers', img: '' },
      ],
      value: [],
    },
    {
      id: 5,
      name: 'shape',
      type: 'select',
      options: [
        { id: 0, name: 'Rectangle', img: '' },
        { id: 1, name: 'Rectangular Arch', img: '' },
        { id: 2, name: 'Rounded Arch', img: '' },
        { id: 3, name: 'Bow', img: '' },
        { id: 4, name: 'Ring', img: '' },
        { id: 5, name: 'Oval', img: '' },
      ],
      value: [],
    },
  ]);

  function handleSubmit(e) {
    e.preventDefault();
    window.alert(
      "A stage package will be generated based on the user's input...\n We just need the data set up first..."
    );
    setConfigure();
  }

  useEffect(() => {
    console.log('formData', formData);
  }, [formData]);

  return (
    <form className='custom-stage-form'>
      <img
        src='https://res.cloudinary.com/da3rom333/image/upload/v1701706617/Website%20Assets/All%20Icons/Coming_Soon_1_nfk7sf.png'
        alt='generate custom coming soon'
        width='300px'
        style={{ margin: 'auto', marginTop: '1rem' }}
      />
      {/* {formData?.map((field) => (
				<span key={field.id}>
					{field.type === 'select' ? (
						<span>
							{field.name.toUpperCase()}
							{field.options.map((optn) => (
								<span key={optn.id}>
									<input
										type='checkbox'
										className='checkbox'
										checked={field.value?.includes(optn.id)}
										onChange={(e) =>
											setFormData((prevData) => {
												return prevData.map((f) => {
													if (f.id === field.id) {
														return {
															...f,
															value: e.target.checked
																? field.allow === 'multiple'
																	? [...f.value, optn.id]
																	: [optn.id]
																: f.value.filter((o) => o !== optn.id),
														};
													}
													return f;
												});
											})
										}
									></input>
									{optn.name}
								</span>
							))}
						</span>
					) : (
						<span>
							{field.name.toUpperCase()}
							<input
								type={field.type}
								value={field.value}
								min={0}
								onChange={(e) =>
									setFormData((prevData) => {
										return prevData.map((f) => {
											if (f.id === field.id) {
												return { ...f, value: e.target.value };
											}
											return f;
										});
									})
								}
							></input>
						</span>
					)}
				</span>
			))}
			<input
				type='submit'
				className='submit'
				value='Generate'
				onClick={(e) => handleSubmit(e)}
			></input> */}
    </form>
  );
}

export default GenerateCustomStage;
