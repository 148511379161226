import { useEffect, useState } from 'react';
import LoadingIcon from '../Shared/LoadingIcon';
import { useStageInfo } from '../../context/StageProvider';

function PrebuiltPackages({
  editPackageData = false,
  selectedPackage = undefined,
  setSelectedPackage,
}) {
  const {
    getPackagesData,
    setStageBuild,
    setConfigure,
    uniqueIdTracker,
    setUniqueIdTracker,
    handleSelectAll,
    packagesData,
    riserData,
    stageData,
    packageShapes,
  } = useStageInfo();
  const [packages, setPackages] = useState([]);

  function handleAddPackage(stagePackage) {
    let uidTracker = 0;
    handleSelectAll();
    setStageBuild([
      ...stagePackage.items.map((item) => {
        return {
          ...item,
          uid: uidTracker++,
        };
      }),
    ]);
    setUniqueIdTracker(uniqueIdTracker + stagePackage.items.length);
    setConfigure();
  }

  useEffect(() => {
    getPackagesData();
  }, []);

  useEffect(() => {
    if (packagesData) {
      const formattedPackagesData = [...packagesData];
      formattedPackagesData.forEach((p) => {
        p.items.map((i) => {
          i.productList =
            i.modelNum.includes('RS') || i.modelNum.includes('RT')
              ? riserData?.filter((r) => r.modelNum.includes(i.modelNum))
              : stageData?.filter((s) => s.modelNum.includes(i.modelNum));
        });
      });
      setPackages(formattedPackagesData);
    }
  }, [riserData, stageData]);

  return (
    <div className='prebuilt-packages-wrapper'>
      {!packages.length || !packageShapes.length ? (
        <div className='loading-wrapper'>
          <LoadingIcon />
        </div>
      ) : (
        packageShapes.map((shape, index) => {
          return (
            <div key={index}>
              <h4>
                {shape.shape} - {shape.capacityType}
              </h4>
              <div className='prebuild-options-div'>
                {packages
                  .filter((p) => p.shape === shape.shape && p.capacityType === shape.capacityType)
                  .sort((a, b) => {
                    if (a.letterCode.length === 1 && b.letterCode.length > 1) return -1;
                    if (a.letterCode.length > 1 && b.letterCode.length === 1) return 1;
                    return a.letterCode.toLowerCase().localeCompare(b.letterCode.toLowerCase());
                  })
                  .map((p) => (
                    <div
                      className={`img-wrapper ${selectedPackage?.id === p.id ? 'selected' : ''}`}
                      key={p.id}
                      onClick={() => {
                        if (editPackageData) {
                          setSelectedPackage(p);
                        } else {
                          handleAddPackage(p);
                        }
                      }}
                    >
                      <span>
                        <span>{p.letterCode}</span> ({Math.floor(p.width / 12)}'
                        {p.width % 12 ? ` ${p.width % 12}"` : ''} x {Math.floor(p.length / 12)}'
                        {p.length % 12 ? ` ${p.length % 12}"` : ''})
                      </span>
                      <img
                        src={p.iconLink}
                        alt='package setup'
                      ></img>
                      <span className='package-capacity'>Capacity: {p.capacity}</span>
                    </div>
                  ))}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
}

export default PrebuiltPackages;
