import { useEffect, useState } from 'react';
import SelectStageItems from './SelectStageItems';
import { findExposedSides } from './StageFunctions';
import { useStageInfo } from '../../context/StageProvider';

function AddSteps() {
  const { getStageData, stageBuild, stepsData, setStepsData, steps, setSteps } = useStageInfo();
  const [availableHeights, setAvailableHeights] = useState();

  function handleSelect(selectedSides) {
    setSteps(
      selectedSides.map((side) => {
        const item = stageBuild.find((i) => i.uid === side.itemUid);
        return {
          ...side,
          product: stepsData?.skuList.find(
            (sku) =>
              sku.configOptions.Height.value === item?.productList[0].configOptions.Height.value
          ),
        };
      })
    );
  }

  useEffect(() => {
    getStageData('Stage Steps', stepsData, setStepsData);
  }, []);

  useEffect(() => {
    if (stepsData) {
      setAvailableHeights(stepsData.skuList.map((sku) => sku.configOptions.Height.value));
    }
  }, [stepsData]);

  return (
    <SelectStageItems
      type='steps'
      stageBuild={stageBuild}
      selected={steps}
      setSelected={handleSelect}
      disable={findExposedSides(stageBuild).filter(
        (side) =>
          !availableHeights?.includes(
            stageBuild.find((i) => i.uid === side.itemUid)?.productList[0].configOptions.Height
              .value
          ) ||
          stageBuild.find((i) => i.uid === side.itemUid)?.productList[0].productInfo.Series ===
            'Risers' ||
          side.itemUid.toString().includes('_')
      )}
    />
  );
}

export default AddSteps;
