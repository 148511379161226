import React, { useState } from 'react';
import useExportToExcel from '../Shared/useExportToExcel';
import useAxiosJWT from '../../hooks/useAxiosJWT';
// eslint-disable-next-line no-undef
const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;

const SalesPortal = () => {
  const ExportToExcel = useExportToExcel();
  const axiosJWT = useAxiosJWT();

  const [loading, setLoading] = useState(false);
  function exportHandler() {
    setLoading(true);
    axiosJWT.get(`${apiAddress}/products/allInclusiveDownload`).then((response) => {
      ExportToExcel(
        {
          data: response.data,
        },
        'AllInclusiveItemList'
      );
      setLoading(false);
    });
  }

  return (
    <div className='usersContainer'>
      <h2>Sales Portal</h2>
      {!loading ? (
        <button className='editableFormButton' onClick={exportHandler}>
          Download All Inclusive Item List
        </button>
      ) : (
        <img src='https://res.cloudinary.com/da3rom333/image/upload/v1712177351/Website%20Assets/GIFs/icons8-circle_qf2kuy.gif'></img>
      )}
    </div>
  );
};

export default SalesPortal;
