import React, { useEffect, useState } from 'react';
import useExportToExcel from '../Shared/useExportToExcel';
import { useParams } from 'react-router-dom';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import { HiOutlineDocumentDownload } from 'react-icons/hi';
import ItemsTable from './ItemsTable';
import FullPageLoading from '../Shared/FullPageLoading';
import { MdFormatListBulletedAdd } from 'react-icons/md';
import TableAddNew from './TableAddNew';
const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;

const TableView = () => {
  const { tableName } = useParams();
  const completeTableName = decodeURIComponent(tableName)?.replace(/ /g, '');
  const ExportToExcel = useExportToExcel();
  const axiosJWT = useAxiosJWT();
  const [data, setData] = useState();
  const [loading, setLoading] = useState();
  const [columns, setColumns] = useState();
  const [openAdd, setOpenAdd] = useState();
  const [notification, setNotification] = useState();
  const [allowAdd, setAllowAdd] = useState();

  function getColumns() {
    axiosJWT
      .post(`${apiAddress}/itemTable/getViewTableColumns`, {
        tableName: decodeURIComponent(tableName),
      })
      .then((response) => {
        setColumns(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function getData() {
    setLoading(true);
    axiosJWT
      .get(`${apiAddress}/itemTable/get${completeTableName}`)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
      });
  }

  function handleExport() {
    axiosJWT
      .get(`${apiAddress}/itemTable/get${completeTableName}`)
      .then((response) => {
        ExportToExcel(
          {
            data: response.data,
          },
          tableName
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function checkTableForAdd() {
    if (columns[0]?.table_title?.toLowerCase()?.replace(' ', '_') === columns[0]?.db_table_name) {
      setAllowAdd(true);
    } else {
      setAllowAdd(false);
    }
  }

  useEffect(() => {
    if (tableName) {
      getColumns();
      getData();
    }
  }, []);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [notification]);

  useEffect(() => {
    if (columns) {
      checkTableForAdd();
    }
  }, [columns]);

  return (
    <>
      {!loading && data && columns ? (
        <div>
          <>
            <div className={`in-stock-table-header inner-padding`}>
              <div
                style={{ alignItems: 'end' }}
                className='basic-flex'
              >
                <h2>{tableName}</h2>
              </div>
              <div>
                {allowAdd ? (
                  <MdFormatListBulletedAdd
                    style={{
                      cursor: 'pointer',
                      marginRight: '10px',
                    }}
                    onClick={() => setOpenAdd(true)}
                  />
                ) : (
                  ''
                )}
                <HiOutlineDocumentDownload
                  style={{
                    cursor: 'pointer',
                  }}
                  onClick={() => handleExport()}
                />
              </div>
            </div>
          </>
          <ItemsTable
            getData={getData}
            tableName={tableName}
            tableColumns={columns}
            tableRows={data}
            allowAdd={allowAdd}
            setNotification={setNotification}
          />
        </div>
      ) : loading ? (
        <FullPageLoading />
      ) : (
        ''
      )}
      {openAdd && columns[0] ? (
        <TableAddNew
          columns={columns}
          getData={getData}
          setOpenAdd={setOpenAdd}
          setNotification={setNotification}
        />
      ) : (
        ''
      )}
      {notification ? (
        <div
          style={{
            fontSize: '16px',
            top: '47.7rem',
            marginLeft: '4.8rem',
            width: '520px',
          }}
          className='notice'
        >
          {notification}
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default TableView;
