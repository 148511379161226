import "../../CSS/Search.css";
import "../../CSS/MobileApp/M_Search.css";
// import { HiSearchCircle } from "react-icons/hi";
// import searchImg from "../../assets/SVG/Search Blue.svg";
import SearchIcon from "../../assets/SVG/ReactSvg/searchIcon";
import { useNavigate } from "react-router-dom";

const Search = ({ searchText, setSearchText }) => {
  const navigate = useNavigate();
  function clicked() {
    navigate({
      pathname: `/search`,
      search: `?search=${searchText}`,
    });
    setSearchText("");
  }
  //
  return (
    <li className="search-bar">
      <input
        id={"searchBar"}
        placeholder={"Search Here"}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            clicked();
          }
        }}
      ></input>
      <div className="search-icon" onClick={clicked}>
        <SearchIcon />
      </div>
    </li>
  );
};

export default Search;
