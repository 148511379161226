import '../../CSS/Popup.css';
import { AiOutlineCloseCircle } from 'react-icons/ai';
const EnvPopup = ({ element, setShow }) => {
  return (
    <div className='popup'>
      <div className='overlay'></div>
      <div className='popup-element'>
        <AiOutlineCloseCircle
          className='close-button'
          onClick={() => {
            setShow('');
          }}
        />
        {element}
      </div>
    </div>
  );
};

export default EnvPopup;
