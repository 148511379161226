import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import '../../CSS/AboutMenu.css';
import { useNavigate } from 'react-router-dom';

// eslint-disable-next-line no-undef
const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;

function AboutMenu() {
  const navigate = useNavigate();
  const [aboutMenu, setAboutMenu] = useState();

  function getData() {
    Axios.post(`${apiAddress}/content/getDropDownTabs`, { tabName: 'About' }).then((response) => {
      setAboutMenu(response.data.dropdown);
    });
  }
  useEffect(() => {
    document.title = 'About Us - National Public Seating';
    getData();
  }, []);
  return (
    <div className='about-menu'>
      <h2>ABOUT US</h2>
      <div className='underline' />
      <div className='template-tile-div inner-padding'>
        {aboutMenu?.map((item, index) => (
          <div key={index} className='template-tile' onClick={() => navigate(`${item.link}`)}>
            <div className='template-img'>
              <img src={item.image} alt={`${item.title} icon`} />
            </div>
            <h2>{item.title}</h2>
          </div>
        ))}
      </div>
    </div>
  );
}

export default AboutMenu;
