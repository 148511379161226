import React, { useEffect } from 'react';
import LaminateColors from './LaminateColors';
import SwatchSample from './SwatchSample';
import GetColorInfo from './GetColorInfo';
const SwatchSamples = ({
  filterExceptionList,
  optionsSelected,
  finishTypes,
  product,
}) => {
  const laminateColors = LaminateColors();

  function getOption(color) {
    for (let i = 0; i < Object.keys(optionsSelected).length; i++) {
      if (optionsSelected[Object.keys(optionsSelected)[i]] === color) {
        return Object.keys(optionsSelected)[i];
      }
    }
  }
  return filterExceptionList?.some((i) => optionsSelected[i]) ? (
    <div className='swatch-samples'>
      <div className='your-selections-title'>Your Selections:</div>
      <div className='color-swatch-cont'>
        {filterExceptionList.map((i) => {
          if (optionsSelected[i]) {
            const color = GetColorInfo(
              optionsSelected[i],
              finishTypes,
              i,
              product
            );
            const swatch = color?.Swatch;
            const code = color?.value;
            const option = getOption(color?.selectionName);
            return (
              <SwatchSample
                swatch={swatch}
                code={code}
                name={color?.selectionName}
                option={option}
                key={`${color}-${i}`}
              />
            );
          }
        })}
      </div>
    </div>
  ) : (
    ' '
  );
};

export default SwatchSamples;
