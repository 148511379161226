import React, { useState } from "react";

const Value = ({ data }) => {
  const [open, setOpen] = useState(false);
  return (
    <div className={`value ${open ? "value-open" : ""}`}>
      <div className="image-div">
        <img src={data.image}></img>
      </div>
      <h1>{data.title}</h1>
      <div className="underline"></div>
      <p>{open ? data.fullData : data.data}</p>
      <h3
        onClick={() => {
          setOpen(!open);
        }}
      >
        {open ? "Read Less" : "Read More"}
      </h3>
    </div>
  );
};

export default Value;
