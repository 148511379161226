import React, { useEffect, useState } from 'react';
import ImageWithHover from '../EditorPortal/ImageWithHover';
import useAxiosJWT from '../../hooks/useAxiosJWT';
const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;

function ItemTableData({ setRows, identifyingColumn, identifyingValue, row, column }) {
  const [topUse, setTopUse] = useState();
  const [rightUse, setRightUse] = useState();
  const [imageHover, setImageHover] = useState();
  const axiosJWT = useAxiosJWT();

  function changeCheckbox(value, row, column) {
    axiosJWT
      .post(`${apiAddress}/itemTable/setItemData`, {
        value,
        table: column.db_table_name,
        column: column.value,
        identifyingColumn: identifyingColumn.value,
        identifyingValue: identifyingValue,
      })
      .then(() => {
        setRows((prevRows) =>
          prevRows.map((r) => (r === row ? { ...r, [column.value]: value } : r))
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    if (imageHover) {
      setTopUse(document?.getElementById(imageHover)?.getBoundingClientRect().top - 260);
      setRightUse(
        document?.getElementById(imageHover)?.clientWidth > 100
          ? document?.getElementById(imageHover)?.getBoundingClientRect().left - 55
          : document?.getElementById(imageHover)?.getBoundingClientRect().left - 95
      );
    } else {
      setTopUse();
      setRightUse();
    }
  }, [imageHover]);

  function getCellData() {
    switch (column.type) {
      case 'image':
        return typeof row[column.value] !== 'object' || row[column.value].swatch ? (
          <div
            onMouseEnter={() =>
              setImageHover(
                row.ID + (row[column.value]?.swatch + column.value || row[column.value])
              )
            }
            onMouseLeave={() => setImageHover()}
            id={row.ID + (row[column.value]?.swatch + column.value || row[column.value])}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: '100%',
              flexDirection: 'column',
              fontSize: '11px',
            }}
          >
            <ImageWithHover
              topUse={topUse}
              rightUse={rightUse}
              src={
                typeof row[column.value] === 'object'
                  ? row[column.value]?.swatch || ''
                  : row[column.value]
              }
              swatch={row[column.value]?.swatch ? row[column.value] : ''}
            />
            {row[column.value]?.swatch ? <div>{`${row[column.value]?.name}`}</div> : ''}
          </div>
        ) : (
          ''
        );
      case 'boolean':
        return (
          <input
            onChange={(e) => {
              changeCheckbox(row[column.value] === 1 ? 0 : 1, row, column);
            }}
            type='checkbox'
            className='checkbox'
            checked={row[column.value] === 1}
          />
        );
      default:
        return (
          <span
            onClick={() => {
              if (column.link) window.open(`${row[column.link]}`);
            }}
            className={column.link ? 'link' : ''}
          >
            {row[column.value]}
          </span>
        );
    }
  }
  return getCellData() || '';
}

export default ItemTableData;
