import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import '../../CSS/MediaDownloads.css';
import MediaTiles from '../../Components/Media/MediaTiles';
import CategoryBar from '../../Components/Media/CategoryBar';
import MediaButtons from '../../Components/Media/MediaButtons';
import { useSearchParams } from 'react-router-dom';
import { createSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { SlArrowRight } from 'react-icons/sl';
import MediaNavButtons from '../../Components/Media/MediaNavButtons';

// eslint-disable-next-line no-undef
const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;
const mediaDownloads =
  'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688650797/Website%20Assets/Banners/G_Media_Downloads_wdgzrq.jpg';
const MediaDownloads = ({}) => {
  useEffect(() => {
    document.title = 'Media Downloads - National Public Seating';
  }, []);
  const [categories, setCategories] = useState();

  const [selectedSkus, setSelectedSkus] = useState(); //list of skus to display
  const [mediaList, setMediaList] = useState(); //media list by category
  const [mediaListbySkus, setMediaListbySkus] = useState(); // media list that filters from media list based on skus selected
  const [fullMediaList, setFullMediaList] = useState();
  const [selectAll, setSelectAll] = useState(false);
  const [selectedList, setSelectedList] = useState(); //list of images selected for download
  const [skuList, setSkuList] = useState(); //list the skus in the selected category
  const [catLevels, setCatLevels] = useState([]);
  const [clearList, setClearList] = useState(false);
  const [listItems, setListItems] = useState(false); //false for image tiles, true for list items
  const [selectedImageTypes, setSelectedImageTypes] = useState([]);
  const [selectedType, setSelectedType] = useState('Images'); //for images, literature
  const [imageEffects, setImageEffects] = useState({});
  const [applyEffects, setApplyEffects] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchParamsUse = Object.fromEntries([...searchParams]);
  function addToSelected(imageLink) {
    setSelectedList([...(selectedList ? selectedList : []), imageLink]);
  }
  function removeFromSelected(imageLink) {
    setSelectedList(selectedList?.filter((i) => i !== imageLink));
  }
  function addRemoveSku(sku, addAll = false, removeAll = false) {
    if (addAll) {
      setSelectedSkus(skuList);
    } else if (removeAll) {
      setSelectedSkus();
    } else if (Array.isArray(sku)) {
      setSelectedSkus(selectedSkus ? [...selectedSkus, ...sku] : [...sku]);
    } else {
      setSelectedSkus(
        selectedSkus
          ? selectedSkus.includes(sku)
            ? selectedSkus.filter((i) => i !== sku)
            : [...selectedSkus, sku]
          : [sku]
      );
    }
  }

  function getCategories() {
    Axios.get(`${apiAddress}/products/getCategories`).then((response) => {
      setCategories(response.data.productList);
    });
  }
  //get the list of skus by category
  function getSkuList() {
    var holderArray = [];
    if (fullMediaList) {
      if (catLevels.length) {
        Object.keys(fullMediaList).map((i) => {
          if (fullMediaList[i]?.tags?.includes(catLevels[catLevels?.length - 1])) {
            holderArray.push(i);
          }
        });
      } else {
        Object.keys(fullMediaList).map((i) => {
          holderArray.push(i);
        });
      }
      setSkuList(holderArray);
    }
  }

  useEffect(() => {
    for (let i = 0; i < selectedList?.length; i++) {
      var remove = true;
      for (let j = 0; j < selectedSkus?.length; j++) {
        if (mediaListbySkus[selectedSkus[j]]?.imageLinks?.includes(selectedList[i])) {
          var remove = false;
        }
      }
      if (remove) {
        removeFromSelected(selectedList[i]);
      }
    }
  }, [mediaListbySkus]);

  //reset list if all skus are unselected
  useEffect(() => {
    if (!selectedSkus?.length) {
      addRemoveSku(skuList, false, true);
    }
  }, [selectedSkus]);

  function getFullMediaList() {
    Axios.get(`${apiAddress}/products/getImageLinks`).then((response) => {
      setFullMediaList(response.data.productImageMap);
      setMediaList(response.data.productImageMap);
      setMediaListbySkus(response.data.productImageMap);
    });
  }
  function filterMediaList() {
    if (catLevels?.length) {
      var holder = {};
      if (selectedSkus) {
        selectedSkus.forEach((i) => {
          holder[i] = fullMediaList[i];
          setMediaListbySkus(holder);
        });
      } else {
        if (skuList.length) {
          skuList.forEach((i) => {
            holder[i] = fullMediaList[i];
            setMediaList(holder);
          });
        } else {
          setMediaList([]);
        }
      }
    } else {
      setMediaList(fullMediaList);
    }
  }

  useEffect(() => {
    getCategories();
    getFullMediaList();
    if (searchParamsUse.Category) {
      setCatLevels([decodeURIComponent(searchParamsUse.Category)]);
    } else {
      setCatLevels([]);
    }

    setSelectedType(searchParamsUse.type || 'Images');

    if (searchParamsUse.types) {
      if (JSON.parse(searchParamsUse.types).length) {
        setSelectedImageTypes(JSON.parse(searchParamsUse.types));
      }
    }
  }, []);

  useEffect(() => {
    if (searchParamsUse.Category) {
      setCatLevels([decodeURIComponent(searchParamsUse.Category)]);
    } else {
      setCatLevels([]);
    }
  }, [searchParamsUse.Category]);

  useEffect(() => {
    getSkuList();
  }, [fullMediaList, catLevels]);
  //filter image list when selection changes
  useEffect(() => {
    filterMediaList();

    // console.log(selectedSkus);
  }, [skuList, selectedSkus]);

  //reset MediaListbySkus when Media List changes (for each category)
  useEffect(() => {
    setMediaListbySkus(mediaList);
  }, [mediaList]);
  //filter list based on skus selected
  useEffect(() => {
    // console.log(selectedSkus);
    if (selectedSkus) {
      var holder = {};
      selectedSkus.forEach((i) => {
        holder[i] = mediaList[i];
      });
      setMediaListbySkus(holder);
    } else {
      setMediaListbySkus(mediaList);
    }
  }, [selectedSkus]);

  useEffect(() => {
    setSelectAll(false);
    setSelectedList();
  }, [skuList, selectedSkus]);

  //clear sku selected list when the selected categories change
  useEffect(() => {
    // console.log(catLevels);
    setSelectedSkus();
  }, [catLevels]);

  useEffect(() => {
    // console.log(selectedImageTypes, 223);
  }, [selectedImageTypes]);

  function navigateOnChange(i) {
    const queryParams = {
      type: selectedType,
      types: JSON.stringify(selectedImageTypes || []),
    };

    if (catLevels[0] && catLevels[0] === i) {
      // console.log(catLevels[0], i, "243");
      queryParams.Category = '';
    } else if (i) {
      // console.log(catLevels[0], i, "246");

      queryParams.Category = encodeURIComponent(i);
    }

    if (i) {
      // console.log(queryParams, "251", catLevels);
      const searchParams = createSearchParams(queryParams);
      const path = `/media${searchParams ? `?${searchParams}` : ''}`;

      navigate(path, { replace: true });
    }
  }

  useEffect(() => {
    navigateOnChange();
  }, [selectedImageTypes]);
  useEffect(() => {
    setSelectedList([]);

    navigateOnChange();
  }, [selectedType]);

  return (
    <div className='mediaDownloads'>
      <div className='banner'>
        <img src={mediaDownloads} style={{ width: '100%' }}></img>
      </div>
      <div className='navigateBar inner-padding'>
        <a href='/media-center'>Media Center</a>
        <SlArrowRight />
        <div className='current'>Media Downloads</div>
      </div>
      <MediaNavButtons current={'Media Downloads'} />
      <div className='mediaDownloads inner-padding' style={{ display: 'flex' }}>
        <CategoryBar
          productList={categories}
          skuList={skuList}
          addRemoveSku={addRemoveSku}
          selectedSkus={selectedSkus}
          catLevels={catLevels}
          setCatLevels={setCatLevels}
          selectedMediaTypes={selectedImageTypes}
          setSelectedMediaTypes={setSelectedImageTypes}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          navigateOnChange={navigateOnChange}
          setImageEffects={setImageEffects}
          imageEffects={imageEffects}
          setApplyEffects={setApplyEffects}
        />
        <div className='flexbox2'>
          <MediaButtons
            selectAll={selectAll}
            setSelectAll={setSelectAll}
            selectedList={selectedList}
            setSelectedList={setSelectedList}
            mediaList={mediaListbySkus}
            setClearList={setClearList}
            setListItems={setListItems}
            listItems={listItems}
            fullMediaList={fullMediaList}
            imageEffects={imageEffects}
            applyEffects={applyEffects}
            setApplyEffects={setApplyEffects}
            selectedType={selectedType}
          />
          <MediaTiles
            list={mediaListbySkus}
            selectAll={selectAll}
            addToSelected={addToSelected}
            removeFromSelected={removeFromSelected}
            clearList={clearList}
            selectedList={selectedList}
            listItems={listItems}
            selectedMediaTypes={selectedImageTypes}
            setSelectedMediaTypes={setSelectedImageTypes}
            selectedType={selectedType}
            imageEffects={imageEffects}
            applyEffects={applyEffects}
          />
        </div>
      </div>
    </div>
  );
};

export default MediaDownloads;
