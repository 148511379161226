import React from "react";

const NJSDA = () => {
  return (
    <div style={{ margin: "auto", textAlign: "center" }}>
      <h1>
        GP-0728-F12<br></br>Furniture, Fixtures & Equipment
      </h1>

      <div
        style={{
          position: "relative",
          //   paddingTop: "max(60%,326px)",
          height: "0",
          width: "100%",
        }}
      >
        <iframe
          allow="clipboard-write"
          sandbox="allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms"
          //   allowFullScreen={true}
          style={{
            // position: "absolute",
            border: "none",
            width: "90rem",
            height: "45rem",
            // left: "0",
            // right: "0",
            // top: "0",
            // bottom: "0",
            margin: "auto",
          }}
          src="https://e.issuu.com/embed.html?d=njsda_catalog&hideIssuuLogo=true&u=npscorp"
        ></iframe>
      </div>
    </div>
  );
};

export default NJSDA;
