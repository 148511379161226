import React, { useEffect, useState } from 'react';
import '../../CSS/About.css';
import Value from '../../Components/About/Value';
import ImageSlider from '../../Components/Shared/ImageSlider';
import Symbol from '../../Components/About/Symbol';

const industrial =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653567/Website%20Assets/SVGs/About_Us_Industrial_Grade_x3rptk.svg';
const bifma =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653566/Website%20Assets/SVGs/About_Us_BIFMA_ytnuzw.svg';
const body =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653566/Website%20Assets/SVGs/About_Us_Body_Builder_hadpob.svg';
const sitting =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653569/Website%20Assets/SVGs/About_Us_SittingGreen_rbpgit.svg';
const passion =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653569/Website%20Assets/SVGs/About_Us_Passion_jlc44p.svg';
const mas =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653568/Website%20Assets/SVGs/About_Us_Mas_Green_dncooy.svg';
const usa =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653571/Website%20Assets/SVGs/About_Us_USA_k2azeg.svg';
const exceptional =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653567/Website%20Assets/SVGs/About_Us_Exceptional_ibjh9s.svg';

const barry =
  'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688653479/Website%20Assets/JPGs/barry_kjptmw.jpg';
const banner =
  'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688650793/Website%20Assets/Banners/aboutBanner_ddikok.png';
const About = () => {
  useEffect(() => {
    document.title = `About Us - National Public Seating`;

    window.scrollTo(0, 0);
  });
  const values = [
    {
      image: industrial,
      title: 'Industrial Grade',
      data: 'All our products are industrial grade, and that means they’re crafted for heavy-duty usage day in, day out. We don’t cut corners and we don’t mess around.',
      fullData:
        'All our products are industrial grade, and that means they’re crafted for heavy-duty usage day in, day out. We don’t cut corners and we don’t mess around. Our team of engineers and designers develop the furniture in our New Jersey plant, supported by 90+ factory employees. The furniture is then put through rigorous quality control testing to ensure it meets and surpasses every industry standard. We are there at every step in the manufacturing process.',
    },
    {
      image: passion,
      title: 'A Passion for Education',
      data: 'Furnishing schools is our passion. Of course, we love providing every industry with the tools it needs to succeed, but sustaining the learning and development of',
      fullData:
        'Furnishing schools is our passion. Of course, we love providing every industry with the tools it needs to succeed, but sustaining the learning and development of America’s future leaders is where we shine brightest. Students sit most of the day. If they aren’t comfortable they’ll fidget and won’t be happy to learn. It’s that basic. That’s why making strong, feel-good furniture for schools is our focal point. We proudly equip art rooms, music rooms, science labs, auditoriums, and cafeterias with sturdy tables, supportive chairs, and hardwood lecterns designed to truly last. ',
    },
    {
      image: exceptional,
      title: 'Exceptional Service',
      data: 'Our sales team doesn’t rest on its laurels. It is constantly finding new ways to better streamline the supply process. The shipping team gets orders on the road in hours,',
      fullData:
        'Our sales team doesn’t rest on its laurels. It is constantly finding new ways to better streamline the supply process. The shipping team gets orders on the road in hours, and with warehouses in California, Tennessee and New Jersey, shipments often arrive the next day. Believe it or not, our customer service representatives look forward to solving any issue that should crop up. Because that’s what they’re there for. That’s not all, though. Thanks to our custom design services, reliable web support, no-questions-asked returns and repairs, and ten year warranty, our dealers have come to know and deeply trust our service for almost 40 years. Here’s to many more. ',
    },
  ];

  const symbols = [
    {
      image: body,
      hoverData:
        'While the entire NPS Folding Chair line meets ANSI/BIFMA standards, including a 300lb. seat drop test, most have recently passed more stringent testing for 600lbs. of static weight and 480lbs. of moving weight on the seating area.',
    },
    {
      image: sitting,
      hoverData:
        "SittingGreen® is NPS®'s environmentally responsible, eco-friendly, folding chair line which adheres to ANSI/BIFMA #X7.1-2007 VOC emissions standards (criteria #1- TVOC, Formaldehyde, All Aldehydes & 4-Phenylcyclohexene), provides LEED points (option 4.5 for Furniture) for Green buildings, and provides points in the BIFMA “Level” program, criteria #1.",
    },
    {
      image: mas,
      hoverData:
        'Our entire product line is MAS Certified Green®. For more information, visit mascertifiedgreen.com',
    },
    {
      image: bifma,
      hoverData: 'Products meet or exceed applicable ANSI/BIFMA standards',
    },
    {
      image: usa,
      hoverData:
        'Designed, Engineered, and Assembled in the USA for exceptional quality, performance, and pride.',
    },
  ];
  const [symbolElements, setSymbolElements] = useState();
  useEffect(() => {
    var holderArray = [];
    symbols.map((i, index) => {
      holderArray.push(<Symbol data={i} key={index} />);
    });
    setSymbolElements(holderArray);
  }, []);
  return (
    <div className='about-us-page'>
      <div className='about-banner'>
        <img src={banner}></img>
      </div>
      {/* <div style={{ height: "84rem" }}></div> */}
      <div className='ourValues'>
        <h1>Our Values</h1>
        <div className='underline'></div>
        <div
          style={{
            width: '1410px',
            display: 'flex',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            margin: 'auto',
          }}
        >
          {values.map((i, index) => {
            return <Value key={index} data={i} />;
          })}
        </div>
      </div>
      <div className='inner-padding symbols'>
        <h2>LOOK FOR THESE SYMBOLS THROUGHOUT THE WEBSITE</h2>
        <div className='underline'></div>
        <div className='symbol-slider'>
          {symbolElements ? <ImageSlider images={symbolElements} numOfDisplay={5} /> : ''}
        </div>
      </div>
      <div className='barry'>
        <img src={barry}></img>
        <div className='line'></div>
        <div className='details'>
          <h1>A Tribute to Our Founder</h1>
          <h2>
            <span className='bold'>Barry Stauber</span> built up National Public Seating with his
            bare hands and his whole heart.
          </h2>
          <div className='underline'></div>
          <p>
            He saw his customers as real people and felt personally responsible for providing them a
            seamless experience. To Barry, bumps in the road were golden opportunities to make
            things right, and he never let an opportunity pass him by. But it wasn't just consumers
            he cared for. Even as the team grew, he embraced every member as family. He invested in
            his employees the same way he invested in the business, teaching them to go above and
            beyond in their work with kindness, compassion and deep respect. <br></br>
            <br></br>On August 17th 2018, we were heartbroken to learn Barry had passed. Two years
            prior when preparing to retire, he had transitioned the staff and trained in a new
            president. In true Barry fashion, he had ensured every aspect of the operation could run
            according to plan without him. Now, every day at National Public Seating is a tribute to
            his legacy. The company he left behind is rooted in integrity, commitment and growth,
            and through it he lives on.
          </p>
          <button
            onClick={() => {
              window.open(
                'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1689274322/Website%20Assets/About%20Page/Tribute_to_Barry_Stauber_1_sedjmf.pdf'
              );
            }}
          >
            Barry's Tribute
          </button>
        </div>
        <div className='line'></div>
      </div>
      <div style={{ height: '80rem' }}></div>
    </div>
  );
};

export default About;
