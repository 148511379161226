import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AboutUsSection from '../../Components/HomePage/AboutUsSection';
import HomeCarousel from '../../Components/HomePage/Carousel';
import DiscoverProducts from '../../Components/HomePage/DiscoverProducts';
import CalculateShipping from '../../Components/HomePage/CalculateShipping';
import EnvironmentsSlider from '../../Components/HomePage/EnvironmentsSlider';
import { useState } from 'react';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import '../../CSS/Home.css';
import '../../CSS/MobileApp/M_Home.css';
import { useSelector } from 'react-redux';

const Home = () => {
  useEffect(() => {
    document.title = 'National Public Seating';
  }, []);
  const navigate = useNavigate();
  const mobileScreen = useSelector((state) => state.isMobile.value);
  const [images, setImages] = useState();
  const [catalogImage, setCatalogImage] = useState();
  const axiosJWT = useAxiosJWT();

  useEffect(() => {
    axiosJWT.post(`content/getCarouselBanners`, { page: 'home' }).then((response) => {
      let filteredData;
      if (mobileScreen.isMobile) {
        filteredData = response.data.filter((i) => i.screen_type?.toLowerCase() === 'mobile');
      } else {
        filteredData = response.data.filter((i) => i.screen_type?.toLowerCase() === 'desktop');
      }
      setImages(filteredData?.filter((i) => i.carousel_type?.toLowerCase() === 'main'));

      setCatalogImage(
        response?.data?.filter((i) => {
          return i.carousel_type.toLowerCase() === 'catalog';
        })[0].image
      );
    });
  }, []);
  
  return (
    <div>
      <div className='inner-padding'>
        <HomeCarousel images={images} />

        <AboutUsSection />
        <DiscoverProducts />
        <CalculateShipping />
        <EnvironmentsSlider />
        <div
          className='catalog-bar'
          onClick={() => {
            // console.log(catalogImage);
            navigate(`/Catalog`);
          }}
        >
          <img src={catalogImage}></img>
        </div>
      </div>
    </div>
  );
};

export default Home;
