import { useState, useEffect } from 'react';
import { RxMinusCircled } from 'react-icons/rx';
import OptionsBar from './OptionsBar';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { HiCheckCircle } from 'react-icons/hi';
import { useSelector, useDispatch } from 'react-redux';
import { updateProductInfo } from '../../ReduxSlices/productState';

const ConfigCube = ({ title, option, flatArray, setInfoHover }) => {
  const {
    configTypes,
    configSelected,
    optionsSelected,
    product,
    openAddOns,
    skuCompleted,
    stageBuilder,
  } = useSelector((state) => state.productState.value);
  const dispatch = useDispatch();
  const [available, setAvailable] = useState(true);

  useEffect(() => {
    if (!skuCompleted) {
      if (configSelected === option) {
        if (
          Object.keys(optionsSelected).every((i) => Object.keys(product.configOptions).includes(i))
        ) {
          if (
            Object.keys(option)
              .filter(
                (i) =>
                  Object.keys(product.configOptions).includes(i) &&
                  configSelected[i]?.values?.length > 1
              )
              .every((opt) => Object.keys(optionsSelected).includes(opt))
          ) {
            let newConfig;
            for (let i = 0; i < flatArray.length; i++) {
              if (flatArray[i] === option) {
                if (i === flatArray.length - 1) {
                  dispatch(updateProductInfo({ openAddOns: true }));
                } else if (
                  Object.keys(product?.configOptions).some((value) =>
                    Object.keys(option).includes(value)
                  )
                ) {
                  newConfig = flatArray[i + 1];
                  break;
                }
              }
            }
            dispatch(updateProductInfo({ configSelected: newConfig }));
          }
        }
      }
    }
  }, [optionsSelected, product, option]);

  useEffect(() => {
    if (configSelected) {
      if (
        !Object.keys(product?.configOptions).some((value) =>
          Object.keys(configSelected).includes(value)
        )
      ) {
        dispatch(
          updateProductInfo({
            configSelected: flatArray[flatArray.indexOf(configSelected) + 1],
          })
        );
      }
    }
  }, [configSelected]);

  useEffect(() => {
    if (product?.config) {
      if (option.name !== 'Add Ons') {
        setAvailable(Object?.keys(product?.config).includes(option?.name));
      } else {
        setAvailable(true);
      }
    }
  }, [Object.keys(optionsSelected)]);
  function clicked(event) {
    if (openAddOns) {
      dispatch(updateProductInfo({ openAddOns: false }));
    }
    if (option === configSelected) {
      dispatch(updateProductInfo({ configSelected: false }));
    } else {
      dispatch(updateProductInfo({ configSelected: option }));
      if (!stageBuilder) {
        event.preventDefault();
        event.stopPropagation();
        setTimeout(() => {
          document.getElementById(title).scrollIntoView({
            block: 'center',
            inline: 'nearest',
            behavior: 'smooth',
          });
        }, 100);
      }
      // var parentContainer = document.getElementById("scrollbar");
      // var outerContainer = document.getElementById(title);
      // console.log(outerContainer);
      // // Scroll the outer component into view
      // var offsetTop = outerContainer.offsetTop;
      // parentContainer.scrollTop = offsetTop;
      // myImage.
    }
  }

  function renderOptions() {
    if (Object.keys(option).length > 1) {
      return Object.keys(option)
        .filter((i) => option[i]?.values?.length > 1)
        .filter((i) => Object.keys(product.configOptions).includes(i))
        .every((opt) => Object.keys(optionsSelected).includes(opt)) ? (
        <h3 className='config-name-selected'>
          {Object.keys(option)
            .filter((i) => option[i]?.values?.length > 1)
            .filter((i) => optionsSelected[i] !== undefined)
            .map((key) => `${key}: ${optionsSelected[key]}`)
            .join('; ')}
        </h3>
      ) : (
        <h3 className='config-name-list'>
          {Object.keys(option)
            .filter((i) => option[i]?.values?.length > 1)
            .map((key) =>
              optionsSelected[key]
                ? `${key}: ${optionsSelected[key]}`
                : Object.keys(product.configOptions).includes(key)
                ? `Select ${key}`
                : null
            )
            .filter((item) => item !== null)
            .join(Object.keys(option).length > 1 ? '; ' : '')}
        </h3>
      );
    } else if (Object.keys(option).length === 1) {
      return (
        <h3
          className={
            optionsSelected[Object.keys(option)[0]] ? 'config-name-selected' : 'config-name-list'
          }
        >
          {Object.keys(option).map((key) =>
            optionsSelected[key] ? `${optionsSelected[key]}` : 'Please Select'
          )}
        </h3>
      );
    }
  }

  return (
    <div className='product-outer-div'>
      <div
        className={`config-cube-new`}
        onClick={(event) => (available ? clicked(event) : null)}
      >
        <div className='option-bar-container'>
          <div className='option-bar-container1'>
            {Object.keys(option)
              .filter((i) => option[i]?.values?.length > 1)
              .filter((i) => Object.keys(product.configOptions).includes(i))
              .every((opt) => Object.keys(optionsSelected).includes(opt)) ? (
              <HiCheckCircle className='check-circle' />
            ) : (
              <RxMinusCircled className='minus-circle' />
            )}
            <h3 className='config-parent-name'>{title}</h3>
          </div>
          <div className='option-bar-container2'>
            {renderOptions()}
            {configSelected === option ? (
              <BsChevronUp className='down-arrow' />
            ) : (
              <BsChevronDown className='down-arrow' />
            )}
          </div>{' '}
        </div>
      </div>
      {configSelected === option
        ? Object.keys(configSelected)
            .filter((j) => configSelected[j]?.values?.length > 1)
            .map(
              (i) =>
                Object.keys(product.configOptions).includes(i) && (
                  <div
                    key={i}
                    className={`whole-config ${configSelected === option ? 'open' : ''}`}
                  >
                    <OptionsBar
                      key={option}
                      option={option}
                      configSelectedList={configSelected}
                      configTypes={configTypes}
                      configSelected={configSelected[i]}
                      setInfoHover={setInfoHover}
                    />
                  </div>
                )
            )
        : ''}
    </div>
  );
};

export default ConfigCube;
