import React from "react";

const InstantFreightLoading = () => {
	return (
		<>
			<div
				className="form"
				style={{
					backgroundImage:
						"url(https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1689625054/Website%20Assets/GIFs/truck-animation_miwg1h.gif)",
				}}
			></div>
			<h4 className="load-f-qoute">Generating Freight Quote</h4>
		</>
	);
};

export default InstantFreightLoading;
