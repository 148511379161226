import React, { useEffect, useState } from 'react';
import TemplateTile from './TemplateTile';
import TemplateTileElement from './TemplateTileElement';

import '../../CSS/TemplateTile.css';
import '../../CSS/MobileApp/M_TemplateTile.css';
import { SlArrowRight } from 'react-icons/sl';
import NavigateBar from '../Products/navigateBar';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import BasicButton from '../NPSportal/basicButton';
import { useNavigate } from 'react-router-dom';
const TemplateTilePage = ({
  banner,
  list,
  clickHandler,
  elements,
  across = 4,
  onlyStrings = false,
  navigateBar = false,
  navigateBarOriginal = false,
  qsNavigate = false,
  middleElement = false,
  bannerClass = false,
  statusIcons = false,
  year = false,
  currentYear = false,
}) => {
  const { isMobile } = useSelector((state) => state.isMobile.value);
  const [fitAcross, setFitAcross] = useState(across);
  const [remainder, setRemainder] = useState();
  const [openYear, setOpenYear] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    if (isMobile) {
      setFitAcross(2);
    }
  }, [isMobile]);

  useEffect(() => {
    // console.log(list, "in template tile page");
    if (list) {
      if (list?.length % fitAcross === 0) {
        setRemainder(0);
      } else {
        setRemainder(fitAcross - (list?.length % fitAcross));
      }
    } else {
      if (elements?.length % fitAcross === 0) {
        setRemainder(0);
      } else {
        setRemainder(fitAcross - (elements?.length % fitAcross));
      }
    }
  }, [list, elements, fitAcross]);
  return (
    <div>
      <div className={bannerClass ? bannerClass : 'banner'}>
        {banner ? (
          banner
        ) : year ? (
          <div
            className={year === currentYear ? 'current-year-banner' : 'year-banner'}
            onClick={() => {
              if (year !== currentYear) {
                setOpenYear(!openYear);
              }
            }}
          >
            {year}
            {year !== currentYear ? (
              openYear ? (
                <BsChevronUp className='down-arrow expand-year' />
              ) : (
                <BsChevronDown className='down-arrow expand-year' />
              )
            ) : (
              <div className='underline' />
            )}
          </div>
        ) : (
          'BANNER GOES HERE'
        )}
      </div>
      {!navigateBar &&
        !navigateBarOriginal &&
        !qsNavigate &&
        banner?.props?.src?.includes('QuickShip_Banner') && (
          <div
            style={{ justifyContent: 'end' }}
            className='inner-padding basic-flex'
          >
            <div className='basic-blue-text'>For available overstock items, click here:</div>
            <BasicButton
              text={'Overstock Products'}
              type={'white-bordered-button'}
              onClick={() => navigate('/overstock-products')}
              icon={
                'https://res.cloudinary.com/da3rom333/image/upload/v1721762685/Website%20Assets/SVGs/Icon_Quick_Ship_Laminates_B_bxo5qc.svg'
              }
              buttonStyle={{ marginRight: '0px' }}
            />
          </div>
        )}
      {navigateBar ? (
        <div className='mediaDownloads'>
          <div className='navigateBar inner-padding'>
            <a href='/media-center'>Media Center</a>
            <SlArrowRight />
            <div className='current'>{navigateBar}</div>
          </div>
        </div>
      ) : navigateBarOriginal ? (
        <NavigateBar series={navigateBarOriginal} />
      ) : qsNavigate ? (
        <div className='mediaDownloads'>
          <div className='navigateBar inner-padding'>
            <a href='/quickship'>Quickship Products</a>
            <SlArrowRight />

            <div className='current'>{qsNavigate}</div>
          </div>
        </div>
      ) : (
        ''
      )}

      {middleElement ? middleElement : ''}
      <div
        className={` template-tile-div inner-padding ${onlyStrings ? 'columns4' : ''} ${
          year && !openYear && year !== currentYear ? 'hide' : ''
        }`}
      >
        {/* {console.log(list)} */}
        {(!year || openYear || year === currentYear) &&
          list?.map((i, index) => (
            <TemplateTile
              key={index}
              item={i} //it is putting the whole list in as the item, not each one
              statusIcons={statusIcons}
              clickHandler={clickHandler}
              onlyStrings={onlyStrings}
            />
          ))}
        {elements?.map((e, i) => {
          {
            // console.log(e);
          }
          return (
            <TemplateTileElement
              key={i}
              element={e}
            />
          );
        })}
        {/* {console.log(list.length % fitAcross)} */}
        {list && remainder
          ? Array.from(Array(remainder), (e, i) => {
              return (
                <TemplateTile
                  key={i}
                  empty={true}
                />
              );
            })
          : ''}
        {elements && remainder
          ? Array.from(Array(remainder), (e, i) => {
              return (
                <TemplateTileElement
                  key={i}
                  empty={true}
                />
              );
            })
          : ''}
        <div
          className='pages'
          style={{ display: 'flex' }}
        >
          {/* {flatList ? (
            <Paginator
              currentPage={page}
              totalPages={lastPage}
              setCurrentPage={setPage}
              setShow={setShow}
              show={show}
            />
          ) : (
            ""
          )} */}
        </div>
      </div>
    </div>
  );
};

export default TemplateTilePage;
