import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../CSS/MediaCenterDrop.css';
import '../../CSS/AboutDrop.css';

const AboutDrop = ({ data }) => {
  const logo =
    'https://res.cloudinary.com/da3rom333/image/upload/v1693922895/Website%20Assets/All%20Icons/Logo_About_Nav_hzcykm_byzlqj.svg';
  const navigate = useNavigate();
  useEffect(() => {
    // console.log(data);
  }, []);
  // const mediaObj = [
  //   {
  //     title: "Overview",
  //     link: "",
  //   },
  //   {
  //     title: "Testimonials",
  //     link: "testimonials",
  //   },
  //   {
  //     title: "Patents",
  //     link: "patents",
  //   },
  //   {
  //     title: "Government Contracts",
  //     link: "government",
  //   },
  //   {
  //     title: "Design Services",
  //     link: "design-services",
  //   },
  //   {
  //     title: "Environmental Statements",
  //     link: "environmental",
  //   },
  //   {
  //     title: "Manufacturer's Warranty",
  //     link: "warranty",
  //   },

  //   {
  //     title: "Returns & Cancellations",
  //     link: "returns",
  //   },
  // ];
  // eslint-disable-next-line no-undef
  // const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;
  // function getData() {
  //   Axios.post(`${apiAddress}/content/getDropDownTabs`, {
  //     tabName: "Dealer",
  //   }).then((response) => {
  //     // console.log(response.data.dropdown);
  //   });
  // }

  // useEffect(() => {
  //   getData();
  // }, []);
  return (
    <div
      style={{ marginLeft: '-44rem' }}
      className='media-div about-drop-div'
    >
      <div className='media-title-div'>
        <h2>About Us</h2>
        {/* <a href="/about">VIEW ALL</a> */}
      </div>
      <div className='grid-container'>
        <div className='about-div'>
          {data?.map((e) => (
            <div
              key={e.title}
              className='about-tile'
              onClick={() => {
                navigate(`/about/${e.link}`);
              }}
            >
              <h3>{e.title}</h3>
            </div>
          ))}
        </div>
        <div className='about-drop-text'>
          <h1>YOUR ONE-STOP SOURCE FOR QUICK SHIP, INSTITUTIONAL GRADE FURNITURE</h1>
          <h2>
            The National Public Seating® logo represents the Superior Strength, Quality, and Value
            our customers have come to expect of the NPS product line.
          </h2>
        </div>
        <img
          src={logo}
          alt='logo'
        ></img>
      </div>
    </div>
  );
};

export default AboutDrop;
