import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../CSS/Footer.css';
import '../../CSS/MobileApp/M_Footer.css';
import { BsTelephoneFill } from 'react-icons/bs';
import { MdEmail, MdLocationPin } from 'react-icons/md';
import { useSelector } from 'react-redux';

// import markerSDK from '@marker.io/browser';
const Footer = () => {
  const mobileScreen = useSelector((state) => state.isMobile.value);
  function startEmail() {
    window.open(`mailto:info@nationalpublicseating.com?subject=Contact Us`);
  }
  const navigate = useNavigate();
  return (
    <footer>
      <div className='icons-bar'>
        <img src='https://res.cloudinary.com/da3rom333/image/upload/e_trim:10/v1688653587/Website%20Assets/SVGs/new_footer_vvmzae.svg'></img>
      </div>
      <div className='footer-background'>
        <div className='footer-div'>
          <div
            className='footer-logo'
            onClick={() => {
              navigate('/');
            }}
            style={{ cursor: 'pointer' }}
          ></div>

          <div className='footer-links'>
            <ul>
              <li
                onClick={() => {
                  navigate(`/`);
                }}
              >
                Home
              </li>
              <li
                onClick={() => {
                  navigate(`/about`);
                }}
              >
                About
              </li>
              <li
                onClick={() => {
                  navigate(`/media-center`);
                }}
              >
                Catalogs &amp; Brochures
              </li>
              <li
                onClick={() => {
                  navigate(`/videos`);
                }}
              >
                Videos
              </li>
              <li
                onClick={() => {
                  navigate(`/contact`);
                }}
              >
                Service Portal
              </li>
              <li
                onClick={() => {
                  navigate(`/dealer-locator`);
                }}
              >
                Dealer Locator
              </li>
              <li
                onClick={() => {
                  navigate(`/about/terms`);
                }}
              >
                Terms &amp; Conditions{' '}
              </li>
            </ul>
          </div>
          <div className='footer-quick-links'>
            <h2>Quick Links</h2>
            <li
              onClick={() => {
                navigate(`/Dealer-Portal`);
              }}
            >
              Dealer Portal
            </li>
            <li
              onClick={() => {
                navigate(`/Media-Center`);
              }}
            >
              Media Center
            </li>
            <li
              onClick={() => {
                navigate(`/cart`);
              }}
            >
              Freight Quote
            </li>
            <li
              onClick={() => {
                navigate(`/contact`);
              }}
            >
              Service Portal
            </li>
            <li
              onClick={() => {
                navigate(`/whats-new`);
              }}
            >
              News
            </li>
          </div>
          <div className='footer-contact-us'>
            <h2 onClick={startEmail}>CONTACT US!</h2>
            <div className='contact-div contact-phone'>
              <BsTelephoneFill className='phoneSvg' /> <a href={`tel:800-261-4112`}>800.261.4112</a>
            </div>
            <div className='contact-div contact-location'>
              <MdEmail className='locationSvg' />
              <p
                onClick={startEmail}
                className='NPSLocation'
              >
                contact@nationalpublicseating.com
              </p>
            </div>
            <div className='contact-div contact-location'>
              <MdLocationPin className='locationSvg' />
              <p
                onClick={() => {
                  window.open('https://maps.google.com?q=149 Entin Road Clifton, NJ 07014');
                }}
                className='NPSLocation'
              >
                149 Entin Road
                {mobileScreen.isMobile ? <span>|</span> : <br></br>}
                Clifton, NJ 07014
              </p>
            </div>

            {/* <button
              className="submitFeedback"
              onClick={() => {
                navigate("/contact?category=general-feedback");
              }}
            >
              Submit Feedback
            </button> */}
          </div>
        </div>

        <div className='footer-copyright'>
          <div className='footer-underline'></div>© Copyright 2023 National Public Seating{' '}
          {mobileScreen.isMobile ? <br></br> : <span> | </span>} Designed by The National Public
          Seating Development Team
        </div>
      </div>
    </footer>
  );
};

export default Footer;
