import React from 'react';
import InfoPopup from '../Products/InfoPopup';
import UploadFiles from './UploadFiles';

const InputField = ({ data, onInputchange, formValues, files, setFiles }) => {
  switch (data['Input Type']) {
    case 'address': //fall through to "text"
    case 'text':
      return (
        <>
          <div style={{ display: 'flex' }}>
            <label>
              {data['Placeholder Text']}
              {data['Required'] === 'TRUE' ? <span style={{ color: 'red' }}>*</span> : ''}
            </label>
            {data['Notice Text'] ? <InfoPopup message={data['Notice Text']} /> : ''}
          </div>
          <input
            type='text'
            name={data['Input Name']}
            value={formValues[data['Input Name']]}
            onChange={onInputchange}
            placeholder={`Enter ${data['Placeholder Text']}`}
            required={data['Required'] === 'TRUE'}
            maxLength={data['Input Type'] === 'text' ? 45 : 95}
          ></input>
        </>
      );
    case 'textarea':
      return (
        <>
          <label>
            {data['Placeholder Text']}
            {data['Required'] === 'TRUE' ? <span style={{ color: 'red' }}>*</span> : ''}
          </label>
          <textarea
            type='textarea'
            name={data['Input Name']}
            value={formValues[data['Input Name']]}
            onChange={onInputchange}
            placeholder={`${data['Placeholder Text']}...`}
            required={data['Required'] === 'TRUE'}
            maxLength={1000}
          ></textarea>
        </>
      );
    case 'date':
      return (
        <>
          <label>
            {data['Placeholder Text']}
            {data['Required'] === 'TRUE' ? <span style={{ color: 'red' }}>*</span> : ''}
          </label>
          <input
            type='date'
            name={data['Input Name']}
            value={formValues[data['Input Name']]}
            onChange={onInputchange}
            placeholder='MM/DD/YYYY'
            required={data['Required'] === 'TRUE'}
          ></input>
        </>
      );
    case 'email':
      return (
        <>
          {' '}
          <label>
            Email Address
            {data['Required'] === 'TRUE' ? <span style={{ color: 'red' }}>*</span> : ''}
          </label>
          <input
            type='email'
            name='email'
            value={formValues.email}
            onChange={onInputchange}
            placeholder='Enter Email'
            required={data['Required'] === 'TRUE'}
            maxLength={75}
          ></input>
        </>
      );
    case 'files':
      return <UploadFiles files={files} setFiles={setFiles} />;
    default:
      break;
  }
  return <div></div>;
};

export default InputField;
