// import React from "react";

const decodeCloudinary = () => {
  function decodeCloudinaryURL(url) {
    if (url) {
      const uploadIndex = url.indexOf("/upload/");
      if (uploadIndex !== -1) {
        const trimmedURL = url.substring(uploadIndex + "/upload/".length);
        const decodedURL = decodeURIComponent(trimmedURL);
        // console.log(decodedURL);
        return decodedURL;
      }
      return url;
    }
  }

  return decodeCloudinaryURL;
};

export default decodeCloudinary;
