import React from 'react';
import { IoCloseCircleOutline } from 'react-icons/io5';
import '../../CSS/VideoPlayer.css';
import { useDispatch } from 'react-redux';
import { updateProductInfo } from '../../ReduxSlices/productState';

const Popup = ({ element, setShow, popupParent = undefined }) => {
  const dispatch = useDispatch();
  return (
    <div className='video-popup'>
      <div className='video-player'>
        <IoCloseCircleOutline
          className='closeButton'
          onClick={() => {
            if (setShow) {
              setShow(false);
            } else if (popupParent?.productInfo) {
              dispatch(updateProductInfo({ unauthorized: false }));
            }
          }}
        />
        <div className='element-div'> {element}</div>
      </div>
      <div className='modal'></div>
    </div>
  );
};

export default Popup;
