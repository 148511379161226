import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { updateProductInfo } from '../../ReduxSlices/productState';
import { MdDeleteForever } from 'react-icons/md';
import CartQuantity from '../FreightCart/CartQuantity';
import CartPricing from './CartPricing';
import useAuth from '../../hooks/useAuth';
import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';

function Accessories() {
  const { auth } = useAuth();
  const dispatch = useDispatch();
  const { product, accessoriesSelected, customerPrice, quantity, loadingPrice, accessoryPrices } =
    useSelector((state) => state.productState.value);
  const [deleteHover, setDeleteHover] = useState();
  const [total, setTotal] = useState();
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'da3rom333',
    },
  });

  function handleDelete(accessory) {
    dispatch(
      updateProductInfo({
        accessoriesSelected: accessoriesSelected.filter((a) => a.name !== accessory.name),
      })
    );
  }

  function handleOpenDetails(accessory) {
    const modelNum = accessory.accessorySkus?.find((i) =>
      product.modelNum.includes(i.accessoryFor)
    ).modelNum;
    const options = {};
    Object.keys(
      accessory.product.skuList.find((sku) => sku.modelNum === modelNum).configOptions
    ).forEach(
      (key) =>
        (options[key] = accessory.product.skuList.find(
          (sku) => sku.modelNum === modelNum
        ).configOptions[key].selectionName)
    );
    window.open(
      `/products/${accessory.series}?${Object.keys(options)
        .map((key) => `${key}=${options[key]}&`)
        .join('')}`
    );
  }

  useEffect(() => {
    if (customerPrice?.basePrice && accessoryPrices?.length)
      setTotal(
        (+customerPrice?.basePrice?.unitPrice +
          (+customerPrice?.laminatesUpcharge?.unitPrice || +customerPrice?.laminatesUpcharge) +
          (+customerPrice?.premEdgeUpcharge?.unitPrice || +customerPrice?.premEdgeUpcharge)) *
          quantity +
          accessoriesSelected
            .map((a) => {
              return (
                accessoryPrices?.find(
                  (p) =>
                    p.modelNum ===
                    a.accessorySkus?.find((i) => product.modelNum.includes(i.accessoryFor)).modelNum
                )?.price.basePrice.unitPrice * a.qty
              );
            })
            .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
      );
  }, [customerPrice, accessoriesSelected, accessoryPrices]);

  return (
    <>
      {accessoriesSelected?.map((accessory, i) => (
        <div
          className='add-accessories-to-cart'
          key={i}
        >
          <div className='img-div'>
            <AdvancedImage
              cldImg={cld.image(
                accessory.accessorySkus?.find((i) => product.modelNum.includes(i.accessoryFor))
                  .imageLink
              )}
              style={{
                borderRadius: '6px',
              }}
            />
          </div>
          <div className='accessory-details'>
            <span className='skuTitle'>
              ACCESSORY SKU:
              <span className='modelNum'>
                {
                  accessory.accessorySkus?.find((i) => product.modelNum.includes(i.accessoryFor))
                    .modelNum
                }
              </span>
            </span>
            <span>
              {(auth?.accounts
                ?.find((account) => account.last_login === 1)
                ?.AccountRoles?.some((role) => [1100, 1200, 1300].includes(role)) ||
                auth?.roles?.includes(3500)) && (
                <CartPricing
                  customerPrice={
                    accessoryPrices.find(
                      (p) =>
                        p.modelNum ===
                        accessory.accessorySkus?.find((i) =>
                          product.modelNum.includes(i.accessoryFor)
                        ).modelNum
                    )?.price || {}
                  }
                  loadingPrice={
                    loadingPrice ||
                    accessoryPrices.find(
                      (p) =>
                        p.modelNum ===
                        accessory.accessorySkus?.find((i) =>
                          product.modelNum.includes(i.accessoryFor)
                        )
                    )
                  }
                />
              )}
            </span>
            <div className='accessory-qty-wrapper'>
              <div className='product-addToCart'>
                <CartQuantity
                  quantity={accessory.qty || 1}
                  updateQty={(newQty) => {
                    dispatch(
                      updateProductInfo({
                        accessoriesSelected: accessoriesSelected.map((a) =>
                          a.name === accessory.name ? { ...a, qty: newQty } : a
                        ),
                      })
                    );
                  }}
                />
              </div>
              <div
                className={`remove-accessory ${deleteHover === i ? 'hover' : ''}`}
                onClick={() => handleDelete(accessory)}
                onMouseOver={() => setDeleteHover(i)}
                onMouseOut={() => setDeleteHover()}
              >
                <MdDeleteForever />
              </div>
            </div>
            <div
              className='accessory-see-details'
              onClick={() => handleOpenDetails(accessory)}
            >
              See Details
            </div>
          </div>
        </div>
      ))}
      {(auth?.accounts
        ?.find((account) => account.last_login === 1)
        ?.AccountRoles?.some((role) => [1100, 1200, 1300].includes(role)) ||
        auth?.roles?.includes(3500)) &&
        customerPrice && (
          <div className='product-price-totals-div'>
            Dealer Total:
            <span
              className={loadingPrice || isNaN(total) ? 'loading-price' : 'modelNum'}
              style={{ display: 'inline-block', borderRadius: '15px', width: '112px' }}
            >
              {total?.toLocaleString(undefined, {
                style: 'currency',
                currency: 'USD',
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        )}
    </>
  );
}

export default Accessories;
