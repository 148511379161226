import React, { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { CgSoftwareDownload } from 'react-icons/cg';
import { AdvancedImage } from '@cloudinary/react';
import { AiOutlineLink } from 'react-icons/ai';
import clipboardCopy from 'clipboard-copy';
import { getPage } from '@cloudinary/url-gen/actions/extract';
import FormatImageLink from '../Shared/FormatImageLink';
const PdfTile = ({
  image,
  // selectAll,
  addToSelected,
  removeFromSelected,
  clearList,
  selectedList,
  listItems,
}) => {
  const [selected, setSelected] = useState(false);
  const [copied, setCopied] = useState(false);

  // const imgData = image.imageLink.split("/");
  // const seriesName = imgData[imgData.length - 2];
  // const skuName = imgData[imgData.length - 1].substring(
  //   0,
  //   imgData[imgData.length - 1].indexOf("_")
  // );

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 400);
  }, [copied]);

  useEffect(() => {
    setSelected(false);
    // console.log(clearList);
  }, [clearList]);

  useEffect(() => {
    // console.log(selectedList);
    if (
      selectedList?.some(
        (item) => item.imageLink === image.imageLink && item.modelNum === image.modelNum
      )
    ) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectedList]);

  function handleClick() {
    // setSelected(!selected);
    // console.log(image);
    // console.log(image);
    if (!selected) {
      addToSelected(image);
    } else {
      removeFromSelected(image);
    }
  }

  const decodeAndFormat = FormatImageLink();
  return (
    <>
      {listItems ? (
        <div
          className='mediaListItem'
          style={{
            // borderColor: selected ? "var(--lightgreen)" : "#BBBBBA",
            border: selected ? '2px solid var(--lightgreen)' : '1px solid #BBBBBA',
            cursor: 'pointer',
          }}
          onClick={handleClick}
        >
          <div
            onClick={handleClick}
            className={'selection-box'}
            style={{
              borderColor: selected ? '#004D44' : '#939393',
              background: selected ? '#004D44' : 'white',
            }}
          >
            {selected ? (
              <FaCheck style={{ color: 'white' }} />
            ) : (
              <div style={{ height: '16px', width: '16px' }}></div>
            )}
          </div>
          <div className='tile-img' onClick={handleClick}>
            <AdvancedImage
              cldImg={decodeAndFormat(image.imageLink)
                // .resize(scale().width(300))
                .format('png')
                .extract(getPage().byNumber(1))}
              // plugins={[lazyload()]}
            />
          </div>
          <div
            // onClick={() => {
            //   console.log(imgData, skuName);
            // }}
            className='skuTitle'
          >
            {image.modelNum}
          </div>
          <div className='mediaTile-buttons'>
            <div
              className='download-img'
              onClick={() => {
                window.open(`${image.imageLink}`, '_blank');
              }}
            >
              <CgSoftwareDownload />
            </div>
            <div
              className='download-img'
              onClick={() => {
                // console.log("copied", navigator.clipboard);
                setCopied(true);
                // Navigator.clipboard.writeText(
                //   `https://res.cloudinary.com/da3rom333/image/upload/${image}`
                // );
                clipboardCopy(`${image.imageLink}`);
              }}
            >
              <AiOutlineLink />
              {copied ? (
                <div className='notice' style={{ left: '50%' }}>
                  Link Copied
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className='mediaTile'
          style={{
            // borderColor: selected ? "var(--lightgreen)" : "#BBBBBA",
            border: selected ? '2px solid var(--lightgreen)' : '1px solid #BBBBBA',
            cursor: 'pointer',
          }}
          // onClick={handleClick}
        >
          <div
            // onClick={() => {
            //   console.log(imgData, skuName);
            // }}
            className='skuTitle'
          >
            {image.modelNum}
          </div>
          <div className='tile-img' onClick={handleClick}>
            <AdvancedImage
              cldImg={decodeAndFormat(image.imageLink)
                // .resize(scale().width(300))
                .format('png')
                .extract(getPage().byNumber(1))}
              // plugins={[lazyload()]}
            />
          </div>
          {/* {index} */}
          <div className='mediaTile-buttons'>
            <div
              onClick={handleClick}
              className={'selection-box'}
              style={{
                borderColor: selected ? '#004D44' : '#939393',
                background: selected ? '#004D44' : 'white',
              }}
            >
              {selected ? <FaCheck style={{ color: 'white' }} /> : ''}
            </div>
            <div
              className='download-img'
              onClick={() => {
                window.open(`${image.imageLink}`, '_blank');
              }}
            >
              <CgSoftwareDownload />
            </div>
            <div
              className='download-img'
              onClick={() => {
                setCopied(true);
                // console.log("copied", navigator.clipboard);
                clipboardCopy(`${image.imageLink}`);
              }}
            >
              <AiOutlineLink />
              {copied ? (
                <div className='notice' style={{ left: '50%' }}>
                  Link Copied
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PdfTile;
