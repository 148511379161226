import React, { useEffect, useState } from 'react';
import '../CSS/Terms.css';
import { useNavigate } from 'react-router-dom';
import Axios from 'axios';

// eslint-disable-next-line no-undef
const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;

const termsIcon =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653564/Website%20Assets/SVGs/termsIcon_c8wfhc.svg';
const banner =
  'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688650800/Website%20Assets/Banners/terms_edepax.jpg';

const Terms = () => {
  const navigate = useNavigate();
  const [terms, setTerms] = useState();

  useEffect(() => {
    document.title = 'Terms - National Public Seating';
  }, []);

  function getTerms() {
    Axios.get(`${apiAddress}/content/getTerms`)
      .then((response) => {
        setTerms(response.data);
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    getTerms();
  }, []);

  return (
    <div>
      <div className='banner'>
        <img src={banner}></img>
      </div>
      {/* <div style={{ height: "17rem" }}></div> */}

      <div className='terms inner-padding'>
        {terms?.map((i, index) => {
          return (
            <div className='term' key={index}>
              <h1>{i.title}</h1>
              <h3>{i.details}</h3>
            </div>
          );
        })}
      </div>
      <div className='portalNotice'>
        <img src={termsIcon}></img>
        <h1>Need Help? Contact us through our Service Portal</h1>
        <button
          onClick={() => {
            navigate('/contact');
          }}
        >
          Go to Portal
        </button>
      </div>
      <div style={{ height: '23rem' }}></div>
    </div>
  );
};

export default Terms;
