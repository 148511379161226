import React, { useEffect, useState } from 'react';
import { BiLeftArrow } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';
import InputField from '../Components/Service Portal/InputField';
import useAxiosJWT from '../hooks/useAxiosJWT';
import useAuth from '../hooks/useAuth';

const check =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653577/Website%20Assets/SVGs/green_check_d3olgm.svg';
const EmployeePortalCustomForm = () => {
  const inputFields = [
    {
      'Input Name': 'customerNum',
      'Input Type': 'text',
      'Placeholder Text': 'Customer Account Number',
      Required: 'FALSE',
    },
    {
      'Input Name': 'itemNum',
      'Input Type': 'text',
      'Placeholder Text': 'Item Number',
      Required: 'FALSE',
    },
    {
      'Input Name': 'files',
      'Input Type': 'files',
      Required: 'FALSE',
    },
    {
      'Input Name': 'additionalInfo',
      'Input Type': 'textarea',
      'Placeholder Text': 'Additional Information',
      Required: 'TRUE',
    },
  ];
  const axiosJWT = useAxiosJWT();
  const navigate = useNavigate();
  const [formValues, setFormValues] = useState({ files: [] });
  const [submitComplete, setSubmitComplete] = useState(false);
  const [continueValid, setContinueValid] = useState(false);
  const [invalidMessage, setInvalidMessage] = useState();
  const [notification, setNotification] = useState();
  const auth = useAuth();
  const claimFormStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    left: '23rem',
    top: '17rem',
    cursor: 'pointer',
  };

  function onInputchange(event) {
    setFormValues({ ...formValues, [event.target.name]: event.target.value });
  }

  function setFiles(files) {
    setFormValues({ ...formValues, files: files });
  }

  function checkRequiredFields() {
    const requiredFields = inputFields.filter((field) => field.Required === 'TRUE');
    if (requiredFields) {
      for (const field of requiredFields) {
        if (!formValues[field['Input Name']]) {
          return false;
        }
      }
    }
    return true;
  }

  function saveForm() {
    const formData = new FormData();
    Object.values(formValues?.files).forEach((file) => {
      formData.append('arrayOfFiles', file);
    });
    formData.append('user', auth?.auth?.rep);
    formData.append('formValues', JSON.stringify(formValues));
    axiosJWT
      .post(`forms/customreq`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then(() => {
        setSubmitComplete(true);
      })
      .catch((err) => {
        setNotification(`${err}`);
      });
  }

  function submitHandler(event) {
    event.preventDefault();
    if (continueValid) {
      saveForm();
    } else {
      setInvalidMessage(true);
    }
  }

  useEffect(() => {
    const checkFields = checkRequiredFields();
    if (checkFields) {
      setContinueValid(true);
      setInvalidMessage(false);
    } else {
      setContinueValid(false);
    }
  }, [formValues]);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification();
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [notification]);

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', marginTop: '70px' }}
      className='claimForm'
    >
      <div
        style={claimFormStyle}
        onClick={() => {
          navigate('/employee-portal');
        }}
      >
        <BiLeftArrow style={{ marginRight: '.5rem' }} /> BACK
      </div>
      {!submitComplete ? (
        <form>
          <h2>Custom Requirement Form</h2>
          <div className='underline'></div>
          {inputFields?.map((i, index) => {
            return (
              <InputField
                data={i}
                key={index}
                onInputchange={onInputchange}
                formValues={formValues}
                files={formValues.files}
                setFiles={setFiles}
              />
            );
          })}
          <button
            onClick={(e) => {
              submitHandler(e);
            }}
            className={'valid-continue'}
          >
            Submit
          </button>
          <div className='error-msg-text'>{invalidMessage ? 'Required field is missing.' : ''}</div>
        </form>
      ) : (
        <>
          <form className='success-form'>
            <img src={check}></img>
            <h2>Thank you for submitting your request!</h2>
            <div className='underline'></div>
          </form>
          {notification ? <div className='notice'>{notification}</div> : ''}
        </>
      )}
    </div>
  );
};

export default EmployeePortalCustomForm;
