import '../../CSS/NPSportal/reusableComponents.css';
import { IoChatbubble, IoChatbubbleOutline } from 'react-icons/io5';

const ChatIcon = ({ texts }) => {
  return (
    <div style={{ position: 'relative' }} className='basic-flex'>
      <div>
        {texts?.text?.length ? <div className='chat-number'>{texts?.text?.length}</div> : ''}
        {texts.active ? (
          <IoChatbubbleOutline className='chat-icon active' />
        ) : (
          <IoChatbubble className='chat-icon inactive' />
        )}
      </div>
    </div>
  );
};

export default ChatIcon;
