import React, { useEffect, useState } from 'react';
import TemplateTilePage from '../../Components/Shared/TemplateTilePage';
import BrochureCube from '../../Components/Media/BrochureCube';
import MediaNavButtons from '../../Components/Media/MediaNavButtons';
import useAxiosJWT from '../../hooks/useAxiosJWT';
const brochureBanner =
  'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688650797/Website%20Assets/Banners/G_Brochures_xomevm.jpg';
const Brochures = () => {
  useEffect(() => {
    document.title = 'Brochures - National Public Seating';
  }, []);
  const [elementsMapped, setElementsMapped] = useState([]);

  const axiosJWT = useAxiosJWT();
  useEffect(() => {
    axiosJWT
      .get(`/content/getBrochures`)
      .then((res) => {
        setElementsMapped(
          res.data.brochures.map((i, index) => {
            return <BrochureCube key={index} frameData={i} />;
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return (
    <TemplateTilePage
      // list={[]}
      banner={<img src={brochureBanner}></img>}
      elements={elementsMapped}
      navigateBar={'Brochures'}
      middleElement={
        <div>
          <MediaNavButtons current={'Brochures'} />{' '}
        </div>
      }
    />
  );
};
export default Brochures;
