import React from "react";
import { useNavigate } from "react-router-dom";
const inspire =
  "https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1689475803/Website%20Assets/PNGs/Inspire_ub3gce.jpg";
const inspire1 =
  "https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1689475805/Website%20Assets/PNGs/Inspire_1_t3xegp.png";
const inspire2 =
  "https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1689475803/Website%20Assets/PNGs/Inspire_2_terxff.jpg";
const inspire3 =
  "https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1689475803/Website%20Assets/PNGs/Inspire_3_u2wlec.png";
const inspire4 =
  "https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1689475805/Website%20Assets/PNGs/Inspire_4_ieo3ic.png";

const Inspire = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="inspires">
        <div className="inner-inspires">
          <h1>Get Inspired!</h1>
          <h3>See our portfolio of environments to inspire yourself</h3>
          <div className="images">
            <div className="first-column">
              <img src={inspire4}></img>
              <img src={inspire3}></img>
            </div>
            <div className="second-column">
              <img src={inspire2}></img>
            </div>
            <div className="third-column">
              <img src={inspire1}></img>
              <img src={inspire}></img>
            </div>
          </div>
          <button
            onClick={() => {
              navigate("/environments");
            }}
          >
            View More
          </button>
        </div>
      </div>
      <div style={{ height: "967px" }}></div>
    </>
  );
};

export default Inspire;
