import { useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import useAxiosJWT from '../hooks/useAxiosJWT';
// eslint-disable-next-line no-undef
const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;

const UpdateImageTable = ({ setWarning, table }) => {
  const [response, setResponse] = useState(false);
  const [loading, setLoading] = useState(false);
  const [responseText, setResponseText] = useState('');
  const axiosJWT = useAxiosJWT();
  function updateCloudinaryImages() {
    axiosJWT

      .post(`${apiAddress}/cloudinary/updateCloudinaryImages`)
      .then((response) => {
        setLoading(false);
        setResponse(true);
        setResponseText(response.data);
      })
      .catch((error) => {
        setLoading(false);
        setResponse(true);
        setResponseText('Network Error');

        console.error('Error:', error);
      });
  }

  function updateEnvCloudinaryImages() {
    axiosJWT

      .post(`${apiAddress}/cloudinary/updateEnvCloudinaryImages`)
      .then((response) => {
        setLoading(false);
        setResponse(true);
        setResponseText(response.data);
      })
      .catch((error) => {
        setLoading(false);
        setResponse(true);
        setResponseText('Network Error');

        console.error('Error:', error);
      });
  }

  function updateQuickshipCloudinaryImages() {
    axiosJWT

      .post(`${apiAddress}/cloudinary/updateQuickshipCloudinaryImages`)
      .then((response) => {
        setLoading(false);
        setResponse(true);
        setResponseText(response.data);
      })
      .catch((error) => {
        setLoading(false);
        setResponse(true);
        setResponseText('Network Error');

        console.error('Error:', error);
      });
  }
  return (
    <div className='popup'>
      <div className='overlay'></div>

      <div
        className='popup-element'
        style={{
          width: '50rem',
          left: '30%',
          borderColor: 'white',
          borderRadius: '2rem',
          boxShadow: '1px 5px 5px grey',
          position: 'fixed',
          height: '265px',
          marginTop: '15rem',
          padding: '0px 30px',
        }}
      >
        <AiOutlineCloseCircle
          className='close-button'
          onClick={() => {
            setWarning(false);
          }}
          style={{ top: '1rem', right: '1rem' }}
        />
        <h2 style={{ color: 'red', textDecorationColor: 'black' }}>WARNING</h2>
        {!response && !loading ? (
          <>
            <p>
              By clicking update, you will be erasing the entire {table} image table and updating it
              with new data from cloudinary. Are you sure you want to continue?
            </p>
            <button
              className='update-images-button'
              style={{ marginTop: '2rem', cursor: 'pointer' }}
              onClick={() => {
                table === 'product'
                  ? updateCloudinaryImages()
                  : table === 'environment'
                    ? updateEnvCloudinaryImages()
                    : updateQuickshipCloudinaryImages();
                setLoading(true);
              }}
            >
              Continue
            </button>
            <button
              className='update-images-button'
              style={{ marginTop: '2rem', cursor: 'pointer' }}
              onClick={() => {
                setWarning(false);
              }}
            >
              Cancel
            </button>
          </>
        ) : loading ? (
          <p>Updating...</p>
        ) : (
          <>
            {' '}
            <p>{responseText}</p>
            <button
              className='update-images-button'
              style={{ marginTop: '2rem', cursor: 'pointer' }}
              onClick={() => {
                setWarning(false);
              }}
            >
              Close
            </button>{' '}
          </>
        )}
      </div>
    </div>
  );
};

export default UpdateImageTable;
