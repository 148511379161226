import React, { useEffect } from "react";
import { FaPhoneAlt } from "react-icons/fa";
import { MdMail } from "react-icons/md";
const DealerInfoReturn = ({ data }) => {
  useEffect(() => {
    // console.log(data);
  }, [data]);
  return (
    <div className="dealer-info-div">
      <h3>
        {data.State} ({data.StateAbbr})
      </h3>
      <div className="underline"></div>

      <h4>
        Account Manager: {data.managers.find((i) => i.type === "account").name}
      </h4>

      <h5>
        <FaPhoneAlt />{" "}
        {data.managers.find((i) => i.type === "account").phone_number}
      </h5>
      <h5>
        <MdMail />
        {data.managers.find((i) => i.type === "account").email}
      </h5>

      <h4>
        Relationship Manager:{" "}
        {data.managers.find((i) => i.type === "relationship").name}
      </h4>

      <h5>
        <FaPhoneAlt />{" "}
        {data.managers.find((i) => i.type === "relationship").phone_number}
      </h5>

      <h5>
        {" "}
        <MdMail /> {data.managers.find((i) => i.type === "relationship").email}
      </h5>
    </div>
  );
};

export default DealerInfoReturn;
