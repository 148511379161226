// import React from "react";

const LaminateColors = () => {
  return {
    popular: [
      {
        name: "Grey Nebula",
        vendorCode: "4622-60",
        npsCode: "GY",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/4/6/4622_greynebula.jpg",
        vendor:
          "https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png",
        link: "https://www.wilsonart.com/grey-nebula-4622",
      },
      {
        name: "Bannister Oak",
        vendorCode: "7806-60",
        npsCode: "OK",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/7/8/7806_8.jpg",
        vendor:
          "https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png",
        link: "https://www.wilsonart.com/bannister-oak-7806",
      },
      {
        name: "Fusion Maple",
        vendorCode: "7909-60",
        npsCode: "FM",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/7/9/7909-Full_Sheet_2400_cn.jpg",
        vendor:
          "https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png",
        link: "https://www.wilsonart.com/fusion-maple-7909",
      },
      {
        name: "Montana Walnut",
        vendorCode: "7110K-78",
        npsCode: "WT",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/7/1/7110_8.jpg",
        vendor:
          "https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png",
        link: "https://www.wilsonart.com/montana-walnut-7110",
      },
      {
        name: "Persian Blue",
        vendorCode: "D26K-18",
        npsCode: "BL",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/D/2/D26_5.jpg",
        vendor:
          "https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png",
        link: "https://www.wilsonart.com/persian-blue-d26",
      },
      {
        name: "Wild Cherry",
        vendorCode: "7054-60",
        npsCode: "CH",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/7/0/7054-Full_Sheet_2400_cn.jpg",
        vendor:
          "https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png",
        link: "https://www.wilsonart.com/wild-cherry-7054",
      },
      {
        name: "Hollyberry",
        vendorCode: "D307-60",
        npsCode: "RE",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/d/3/d307_6-full.jpg",
        vendor:
          "https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png",
        link: "https://www.wilsonart.com/hollyberry-d307",
      },
      {
        name: "Portico Teak",
        vendorCode: "8210K-28",
        npsCode: "PT",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/8/2/8210k-28_portico_teak_webfullsheet.jpg",
        vendor:
          "https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png",
        link: "https://www.wilsonart.com/portico-teak-8210",
      },
      {
        name: "Black",
        vendorCode: "1595-60",
        npsCode: "BK",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/1/5/1595-Full_Sheet_2400_cn.jpg",
        vendor:
          "https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png",
        link: "https://www.wilsonart.com/black-1595",
      },
      {
        name: "Graphite Nebula",
        vendorCode: "4623-60",
        npsCode: "GN",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/4/6/4623_graphitenebula.jpg",
        vendor:
          "https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png",
        link: "https://www.wilsonart.com/graphite-nebula-4623-391d4dc379b16cdd71ccae0de95e0472",
      },

      {
        name: "Marigold",
        vendorCode: "D341-60",
        npsCode: "YW",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/D/3/D341_2.jpg",
        vendor:
          "https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png",
        link: "https://www.wilsonart.com/marigold-d341",
      },
      {
        name: "Figured Mahogany",
        vendorCode: "7040K-78",
        npsCode: "MY",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/7/0/7040_8.jpg",
        vendor:
          "https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png",
        link: "https://www.wilsonart.com/figured-mahogany-7040",
      },
    ],
    standard: [
      {
        name: "Admiral",
        vendorCode: "Y0839-60",
        npsCode: "AD",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/y/0/y0839_admiral_full_sheet.jpg",
        vendor:
          "https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png",
        link: "https://www.wilsonart.com/admiral-y0839",
      },
      {
        name: "Asian Night",
        vendorCode: "7949-60",
        npsCode: "AN",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/7/9/7949-Full_Sheet_2400_cn.jpg",
        vendor:
          "https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png",
        link: "https://www.wilsonart.com/asian-night-7949",
      },
      {
        name: "Brittany Blue",
        vendorCode: "D321-60",
        npsCode: "BB",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/d/3/d321_brittany_blue_3.jpg",
        vendor:
          "https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png",
        link: "https://www.wilsonart.com/brittany-blue-d321",
      },
      {
        name: "Catalina",
        vendorCode: "13092-60",
        npsCode: "CI",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/1/3/13092_catalina_full_sheet_view.jpg",
        vendor:
          "https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png",
        link: "https://www.wilsonart.com/catalina-13092",
      },
      {
        name: "Hollyberry",
        vendorCode: "D307-60",
        npsCode: "RE",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/d/3/d307_6-full.jpg",
        vendor:
          "https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png",
        link: "https://www.wilsonart.com/hollyberry-d307",
      },
      {
        name: "Grey Nebula",
        vendorCode: "4622-60",
        npsCode: "GY",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/4/6/4622_greynebula.jpg",
        vendor:
          "https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png",
        link: "https://www.wilsonart.com/grey-nebula-4622",
      },
      {
        name: "Hunter Green",
        vendorCode: "D79-60",
        npsCode: "GR",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/d/7/d79-hunter-green-full_2.jpg",
        vendor:
          "https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png",
        link: "https://www.wilsonart.com/hunter-green-d79",
      },
      {
        name: "Marigold",
        vendorCode: "D341-60",
        npsCode: "YW",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/D/3/D341_2.jpg",
        vendor:
          "https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png",
        link: "https://www.wilsonart.com/marigold-d341",
      },
      {
        name: "Orange Grove",
        vendorCode: "D501-60",
        npsCode: "OG",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/D/5/D501_8.jpg",
        vendor:
          "https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png",
        link: "https://www.wilsonart.com/orange-grove-d501-ef563a42f074c509019e8580013b0643",
      },
    ],
    premium: [
      {
        name: "Portico Teak",
        vendorCode: "8210-28",
        npsCode: "PT",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/8/2/8210k-28_portico_teak_webfullsheet.jpg",
        vendor:
          "https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png",
        link: "https://www.wilsonart.com/portico-teak-8210",
      },
      {
        name: "Biltmore Cherry",
        vendorCode: "7924K-07",
        npsCode: "BC",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/7/9/7924_8.jpg",
        vendor:
          "https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png",
        link: "https://www.wilsonart.com/biltmore-cherry-7924",
      },
      {
        name: "Sandy Topaz",
        vendorCode: "4862K-07",
        npsCode: "ST",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/4/8/4862_sandytopaz_1.jpg",
        vendor:
          "https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png",
        link: "https://www.wilsonart.com/sandy-topaz-4862",
      },
    ],
    stools: [
      {
        name: "Grey",
        npsCode: "02",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/4/8/4862_sandytopaz_1.jpg",
      },
      {
        name: "Blue",
        npsCode: "04",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/4/8/4862_sandytopaz_1.jpg",
      },
      {
        name: "Green",
        npsCode: "06",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/4/8/4862_sandytopaz_1.jpg",
      },
      {
        name: "Burgundy",
        npsCode: "08",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/4/8/4862_sandytopaz_1.jpg",
      },
      {
        name: "Black",
        npsCode: "10",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/4/8/4862_sandytopaz_1.jpg",
      },

      {
        name: "Yellow",
        npsCode: "25",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/4/8/4862_sandytopaz_1.jpg",
      },
      {
        name: "Purple",
        npsCode: "28",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/4/8/4862_sandytopaz_1.jpg",
      },
      {
        name: "Red",
        npsCode: "40",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/4/8/4862_sandytopaz_1.jpg",
      },
      {
        name: "Pacific Blue",
        npsCode: "14",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/4/8/4862_sandytopaz_1.jpg",
      },
      {
        name: "Pea Pod",
        npsCode: "16",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/4/8/4862_sandytopaz_1.jpg",
      },
      {
        name: "Tangerine",
        npsCode: "17",
        swatch:
          "https://images.wilsonart.com/media/catalog/product/cache/b66ec2c0d143bbfe0cd01d94af869e2e/4/8/4862_sandytopaz_1.jpg",
      },
    ],
  };
};

export default LaminateColors;
