import React, { useEffect, useRef, useState } from 'react';

const EditableField = ({ data, saveData }) => {
  // console.log(data);
  const [edit, setEdit] = useState(false);
  const [value, setValue] = useState(data[Object.keys(data)[0]]);

  function toggleEdit() {
    if (edit) {
      setEdit(false);
      saveData(Object.keys(data)[0], value);
    } else {
      setEdit(true);
    }
  }
  const textareaRef = useRef(null);

  const handleTextareaChange = () => {
    const textarea = textareaRef.current;
    textarea.style.height = 'auto'; // Reset the height to auto
    textarea.style.height = `${textarea?.scrollHeight}px`; // Set the height to fit the content
  };

  useEffect(() => {
    if (edit) {
      handleTextareaChange();
    }
  }, [edit]);
  return (
    <tr key={Object.keys(data)[0]}>
      <td style={{ width: '15rem' }}>{Object.keys(data)[0]}</td>
      <td
        style={{ width: '35rem', whiteSpace: 'pre-wrap' }}
        className={`editable-row ${edit ? 'edit-mode' : ''}`}
      >
        {edit ? (
          <textarea
            ref={textareaRef}
            value={value}
            onChange={(e) => {
              setValue(e.target.value);
              handleTextareaChange();
            }}
            style={{
              width: '100%',
              resize: 'none',
            }}
          ></textarea>
        ) : (
          value
        )}
      </td>
      <td
        style={{
          width: '10rem',
        }}
      >
        {edit ? (
          <div>
            <button style={{ marign: 'auto', cursor: 'pointer' }} onClick={toggleEdit}>
              Save
            </button>
            <button
              style={{ marginLeft: '.5rem', cursor: 'pointer' }}
              onClick={() => {
                setEdit(false);
              }}
            >
              Cancel
            </button>
          </div>
        ) : (
          <button style={{ marign: 'auto', cursor: 'pointer' }} onClick={toggleEdit}>
            Edit
          </button>
        )}
      </td>
    </tr>
  );
};

export default EditableField;
