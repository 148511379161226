import { Link } from "react-router-dom";
import { useState } from "react";


const HeaderItem = ({
  itemText,
  dropdownObject,
  openDefault,
  categories,
  link,
}) => {
  const [open, setOpen] = useState(openDefault);

  function mouseEnter() {
    setOpen(true);
  }
  function mouseExit() {
    if (!openDefault) {
      setOpen(false);
    }
  }

  return (
    <div>
      <li
        className="nav-item"
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseExit}
        onClick={mouseExit}
      >
        <Link
          to={"/" + link}
          className="nav-item-a"
          state={
            itemText === "PRODUCTS"
              ? { selectedCat: categories?.productList }
              : ""
          }
        // onClick={(e) => {
        //   if (itemText === "DEALER PORTAL") {
        //     e.preventDefault();
        //   }
        // }}
        >
          {itemText}
        </Link>
        {open ? dropdownObject : ""}
      </li>
    </div>
  );
};

export default HeaderItem;
