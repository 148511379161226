import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
const UserContext = createContext({});

export const UserProvider = ({ children }) => {
    const [displayBoolean, setDisplayBoolean] = useState(false);
    const [displayLogin, setDisplayLogin] = useState(true);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    function displayLoginBox() {
        setDisplayBoolean(true);
    }
    function continueToPage(navPath) {
        navigate(navPath)
    }

    return (
        <UserContext.Provider value={{
            displayBoolean, setDisplayBoolean,
            displayLogin, setDisplayLogin,
            username, setUsername,
            password, setPassword,
            displayLoginBox,
            continueToPage
        }}>
            {children}
        </UserContext.Provider>
    )
}

export default UserContext;