import { useEffect, useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import { BiLeftArrow } from 'react-icons/bi';
import { IoCheckmark, IoClose } from 'react-icons/io5';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import EditableField from '../../Components/Shared/EditableField';

const EditItems = () => {
  const [selectedSku, setSelectedSku] = useState();
  const [searchVal, setSearchVal] = useState();
  const [searchResults, setSearchResults] = useState();
  const [selectedData, setSelectedData] = useState();
  const [notification, setNotification] = useState();
  const axiosJWT = useAxiosJWT();

  function handleSearch() {
    axiosJWT
      .post(`adminPortal/getSkus`, {
        searchVal: searchVal,
        limit: 15,
      })
      .then((response) => {
        setSearchResults(response.data);
      });
  }

  function getItemData(sku) {
    axiosJWT
      .post(`adminPortal/getItemData`, {
        sku: sku,
      })
      .then((response) => {
        setSelectedSku(response.data[0]);
      });
  }

  function saveData(dataKey, dataValue) {
    axiosJWT
      .post(`adminPortal/setItemData`, {
        sku: selectedSku?.modelNum,
        dataKey: dataKey,
        dataValue: dataValue,
      })
      .then(() => {});
  }

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [notification]);

  useEffect(() => {
    getItemData();
  }, []);

  return (
    <div
      className='inner-padding'
      style={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '5rem',
        border: '1px solid ',
        padding: '4rem 1rem 6rem 1rem',
        borderRadius: '3rem',
      }}
    >
      {!selectedSku ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: '2rem',
            }}
          >
            <label
              style={{
                marginRight: '2rem',
                fontSize: '24px',
                textTransform: 'uppercase',
              }}
            >
              Search Sku
            </label>
            <div
              className='search'
              style={{
                border: '1px solid ',
                borderRadius: '2rem',
                padding: '.5rem 1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <input
                className='searchBar'
                value={searchVal}
                onChange={(e) => {
                  setSearchVal(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSearch();
                  }
                }}
              ></input>
              <div className='search-icon'>
                <AiOutlineSearch
                  style={{ cursor: 'pointer', color: 'white' }}
                  onClick={handleSearch}
                />
              </div>
            </div>
          </div>
          {searchResults ? (
            <div style={{ width: '100%' }}>
              {searchResults?.map((i) => {
                return (
                  <div
                    className='hover-highlight'
                    style={{ cursor: 'pointer' }}
                    key={i.model_num}
                    onClick={() => {
                      getItemData(i.model_num);
                    }}
                  >
                    {i.model_num}
                  </div>
                );
              })}
            </div>
          ) : (
            ''
          )}
        </div>
      ) : (
        selectedSku && (
          <div className='usersContainer ' style={{ position: 'relative' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                left: '0',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (selectedData) {
                  setSelectedData();
                } else {
                  setSelectedSku();
                }
              }}
            >
              <BiLeftArrow style={{ marginRight: '.5rem' }} /> BACK
            </div>
            <h2>Model Number: {selectedSku?.modelNum}</h2>
            {selectedData ? (
              <div
                style={{
                  maxHeight: '40rem',
                  maxWidth: '82rem',
                  overflow: 'auto',
                }}
              >
                <table className='usersList inner-padding' style={{ width: '60rem' }}>
                  <thead>
                    <tr>
                      {typeof selectedData === 'object' && !Array.isArray(selectedData)
                        ? Object.keys(selectedData)?.map((i, index) => <th key={index}>{i}</th>)
                        : selectedData?.length > 0 &&
                          Object.keys(selectedData[0])?.map((i, index) => <th key={index}>{i}</th>)}
                    </tr>
                  </thead>
                  <tbody>
                    {typeof selectedData === 'object' && !Array.isArray(selectedData) ? (
                      <tr>
                        {Object.keys(selectedData)?.map((i, index) => (
                          <td key={index}>{selectedData[i]}</td>
                        ))}{' '}
                      </tr>
                    ) : (
                      Object.keys(selectedData)?.map((i) => (
                        <tr key={i}>
                          {Object.keys(selectedData[i]).map((j) => (
                            <td key={j}>{selectedData[i][j]}</td>
                          ))}
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            ) : (
              <>
                <table className='usersList inner-padding' style={{ width: '60rem' }}>
                  <thead>
                    <tr>
                      <th>Key</th>
                      <th>Value</th>
                      <th>Update</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Object.keys(selectedSku?.productInfo)?.map((i) => {
                      if (
                        typeof selectedSku.productInfo[i] === 'string' ||
                        selectedSku.productInfo[i] === null
                      ) {
                        return (
                          <EditableField
                            key={i}
                            data={{ [i]: selectedSku.productInfo[i] }}
                            saveData={saveData}
                          />
                        );
                      }
                    })}
                  </tbody>
                </table>
                <h1>More Information:</h1>
                <div style={{ display: 'flex', flexWrap: 'wrap', width: '60rem' }}>
                  {Object.keys(selectedSku)
                    ?.filter((i) => i !== 'productInfo')
                    ?.map((i, index) => {
                      if (typeof selectedSku[i] !== 'string') {
                        return (
                          <button
                            key={index}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-evenly',
                            }}
                            onClick={() =>
                              selectedSku[i]?.length > 0 ||
                              (typeof selectedSku[i] === 'object' &&
                                !Array.isArray(selectedSku[i]) &&
                                Object.values(selectedSku[i])?.some((i) => i !== null))
                                ? setSelectedData(selectedSku[i])
                                : setNotification(`No Data`)
                            }
                            className='editableFormButton'
                          >
                            {i}{' '}
                            <span style={{ alignSelf: 'baseline' }}>
                              {selectedSku[i]?.length > 0 ||
                              (typeof selectedSku[i] === 'object' &&
                                !Array.isArray(selectedSku[i]) &&
                                Object.values(selectedSku[i])?.some((i) => i !== null)) ? (
                                <IoCheckmark />
                              ) : (
                                <IoClose />
                              )}
                            </span>
                          </button>
                        );
                      }
                    })}
                </div>
              </>
            )}
            {notification ? (
              <div
                style={{
                  fontSize: '16px',
                  top: '47.7rem',
                  marginLeft: '4.8rem',
                  width: '520px',
                }}
                className='notice'
              >
                {notification}
              </div>
            ) : (
              ''
            )}
          </div>
        )
      )}
    </div>
  );
};

export default EditItems;
