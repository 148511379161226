import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import OrderTracking from '../../Components/FreightCart/OrderTracking';
import '../../CSS/OrderTracking.css';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { BsCheckLg } from 'react-icons/bs';
import useAuth from '../../hooks/useAuth';
import LoadingIcon from '../../Components/Shared/LoadingIcon';
import DatePicker from 'react-datepicker';
import Paginator from '../../Components/Media/Paginator';
import { VscChromeClose } from 'react-icons/vsc';
import dayjs from 'dayjs';
import { useCart } from '../../context/CartContext';
import { useContent } from '../../context/ContentProvider';
// import {CartButton} from '../assets/SVG/ReactSvg/CartButton'
// eslint-disable-next-line no-undef
const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;
const trackOrderBanner =
  'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688650799/Website%20Assets/Banners/OrderTrackingBanner_qlej0e.jpg';
const FreightCart = () => {
  const axiosJWT = useAxiosJWT();
  const { notificationData } = useCart();
  const navigate = useNavigate();
  const { auth } = useAuth();
  const { accountChosen, setAccountChosen, searchAccount, setSearchAccount } = useCart();
  const { customers } = useContent();
  const [orderStatus, setOrderStatus] = useState();
  const [myOrders, setMyOrders] = useState([]);
  const [OrderDateStart, setOrderDateStart] = useState();
  const [OrderDateEnd, setOrderDateEnd] = useState();
  const [ShipDateStart, setShipDateStart] = useState();
  const [ShipDateEnd, setShipDateEnd] = useState();
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState();
  const [show, setShow] = useState(30);
  const [openOrder, setOpenOrder] = useState();
  const [open, setOpen] = useState();
  const [loading, setLoading] = useState();
  const [searchingAdvanced, setSearchingAdvanced] = useState();
  const [searchFilters, setSearchFilters] = useState();
  const [sort, setSort] = useState();
  const [advancedSearch, setAdvancedSearch] = useState([]);
  const [openFilters, setOpenFilters] = useState('');
  // const [searchAccount, setSearchAccount] = useState(false);
  // const [accountChosen, setAccountChosen] = useState();
  const [customerList, setCustomerList] = useState();
  const [searchVal, setSearchVal] = useState('');
  const [showDrop, setShowDrop] = useState();
  const [trackingLink, setTrackingLink] = useState();
  const [filters, setFilters] = useState({
    ['in production']: false,
    shipped: false,
    ['ready to ship']: false,
    ['on hold']: false,
    ['packaging']: false,
  });
  const sortOptions = ['Order Numbers', 'PO Number', 'Ship Date', 'Order Date'];

  const advancedSearchOptions = [
    { 'PO #': 'vh.OACPO' },
    { 'Zip Code': 'vh.OAMZIP' },
    { 'Order Date': 'vh.OAORDT' },
    { 'Order #': 'vh.OAORD' },
    { 'Ship to Name': 'vh.OASNAM' },
    { 'Ship Date': 'vh.OASHDT' },
    { 'Tracking #': 'vh.OABOL' },
    { 'Item SKU': 'vd.OBITEM' },
  ];

  useEffect(() => {
    if (notificationData?.some((i) => i.Path === '/track-order')) {
      navigate('/unauthorized');
    }
  }, []);

  useEffect(() => {
    document.title = `Track Order - National Public Seating`;
  }, []);

  useEffect(() => {
    setCustomerList(customers);
  }, [customers]);

  useEffect(() => {
    if (
      myOrders
        ?.filter((i) => {
          if (searchFilters) {
            const includesFilter = Object.values(i)
              ?.filter((j) => typeof j === 'string')
              .some((j) => j?.toLowerCase()?.includes(searchFilters?.toLowerCase()));
            return includesFilter;
          } else {
            return true;
          }
        })
        ?.filter((i) => {
          return Object.values(filters).every((el) => el === false)
            ? true
            : filters[orderStatus?.find((j) => j.code === i?.invoiceNum)?.status.toLowerCase()];
        })?.length > 0
    ) {
      setLastPage(
        Math.ceil(
          myOrders
            ?.filter((i) => {
              if (searchFilters) {
                const includesFilter = Object.values(i)
                  ?.filter((j) => typeof j === 'string')
                  .some((j) => j?.toLowerCase().includes(searchFilters?.toLowerCase()));
                return includesFilter;
              } else {
                return true;
              }
            })
            ?.filter((i) => {
              return Object.values(filters).every((el) => el === false)
                ? true
                : filters[orderStatus?.find((j) => j.code === i?.invoiceNum)?.status.toLowerCase()];
            })?.length / show
        )
      );
    }
    setPage(0);
  }, [myOrders, searchFilters]);

  useEffect(() => {
    if (auth?.roles.includes(3500) && accountChosen && searchAccount === false) {
      getOrderHeaders();
    } else if (auth?.roles.includes(3500)) {
      setMyOrders([]);
      setSearchAccount(true);
    } else {
      getOrderHeaders();
    }
  }, []);

  useEffect(() => {
    getOrderStatus();
    getTrackingLinks();
  }, []);

  useEffect(() => {
    if (!auth?.roles.includes(3500)) {
      searchOrders();
    } else if (accountChosen) {
      searchOrders();
    }
  }, [searchingAdvanced]);

  const getOrderHeaders = async () => {
    if (
      auth?.roles.includes(3500) ||
      auth?.accounts?.find((i) => i.last_login === 1)?.AccountRoles?.includes(1400)
    ) {
      setLoading(true);
      setSearchAccount(false);
      await axiosJWT
        .post(`${apiAddress}/orders/orderHeaders`, {
          account: accountChosen ? accountChosen.customerId : false,
        })
        .then((response) => {
          setLoading(false);

          setMyOrders(response.data);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }
  };

  const searchOrders = async () => {
    if (
      auth?.roles.includes(3500) ||
      auth?.accounts?.find((i) => i.last_login === 1)?.AccountRoles?.includes(1400)
    ) {
      setLoading(true);
      await axiosJWT
        .post(`${apiAddress}/orders/searchOrders`, {
          searchObjects: searchingAdvanced
            ? advancedSearch.filter((i) => Object.values(i).value !== '')
            : null,
          account: accountChosen ? accountChosen.customerId : false,
        })
        .then((response) => {
          setLoading(false);
          setMyOrders(response.data);
        })
        .catch((err) => {
          setLoading(false);
          console.error(err);
        });
    }
  };

  async function getOrderStatus() {
    await axiosJWT
      .get(`${apiAddress}/orders/getOrderStatus`)
      .then((response) => {
        setOrderStatus(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function getTrackingLinks() {
    await axiosJWT
      .get(`${apiAddress}/content/getTrackingLinks`)
      .then((response) => {
        setTrackingLink(response.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  function handleFilterChange(i) {
    if (filters[i]) {
      setFilters({ ...filters, [i]: false });
    } else {
      setFilters({ ...filters, [i]: true });
    }
  }

  function setOpenItem(title) {
    if (open === title) {
      setOpen();
    } else {
      setOpen(title);
    }
  }

  function setOpenOrderItem(title) {
    if (open === title) {
      setOpenOrder();
    } else {
      setOpenOrder(title);
    }
  }

  function addAdvancedSearch(item, value, type) {
    setAdvancedSearch((prevState) => {
      const updatedArray = [...prevState];
      const key = Object.keys(item)[0];
      const name = Object.values(item)[0];
      const existingIndex = updatedArray.findIndex((obj) => obj.key === key);

      if (existingIndex !== -1) {
        const existingObject = updatedArray[existingIndex];
        if (key === 'Order Date' || key === 'Ship Date') {
          existingObject.value[type] = value;
        } else {
          existingObject.value = value.toString();
        }
      } else {
        const searchObject = {
          name: name,
          key: key,
        };

        if (key === 'Order Date' || key === 'Ship Date') {
          searchObject.value = { [type]: value };
        } else {
          searchObject.value = value;
        }

        updatedArray.push(searchObject);
      }

      return updatedArray;
    });
  }

  const changeAccounts = async () => {
    setMyOrders([]);
    setSearchAccount(true);
    setAccountChosen();
  };

  return (
    <>
      <div className='banner-header'>
        <img
          src={trackOrderBanner}
          className='banner'
        ></img>
      </div>
      <div
        className='track-order'
        style={{ width: '1230px', margin: 'auto' }}
      >
        <div>
          <div className='orders-search-bar'>
            <div className='top-bar-search'>
              <div
                style={{ width: '31rem' }}
                className='order-search-right'
              >
                <div className='search-orders'>
                  <input
                    onChange={(e) => setSearchFilters(e.target.value)}
                    placeholder='Search'
                  ></input>
                </div>
                <div
                  className={`orders-advanced-search ${
                    open === 'advancedSearch' ? 'open-search' : ' '
                  }`}
                  onClick={() => {
                    setOpenItem('advancedSearch');
                  }}
                >
                  Advanced Search{' '}
                </div>
              </div>
              <div
                style={{
                  width: '27rem',
                }}
                className='order-search-right'
              >
                <>
                  <div className='statusFilter'>
                    <div
                      className='statusTitle'
                      onClick={() => {
                        if (openFilters === 'status') {
                          setOpenFilters();
                        } else {
                          setOpenFilters('status');
                        }
                      }}
                    >
                      Filter By Status
                      {openFilters === 'status' ? (
                        <FiChevronUp
                          className='open'
                          onClick={() => {
                            setOpenFilters();
                          }}
                        />
                      ) : (
                        <FiChevronDown
                          className='open'
                          onClick={() => {
                            setOpenFilters('status');
                          }}
                        />
                      )}
                    </div>
                    {openFilters === 'status' ? (
                      <div className='order-status-filters'>
                        {Object.keys(filters).map((i, index) => {
                          return (
                            <div
                              key={index}
                              className='status-filter'
                              onClick={() => {
                                handleFilterChange(i);
                              }}
                            >
                              <div
                                className='selection-box'
                                onClick={() => {
                                  handleFilterChange(i);
                                }}
                              >
                                {filters[i] ? <BsCheckLg /> : ''}
                              </div>
                              {i}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                  <div className='orderHistory statusFilter'>
                    <div
                      className='statusTitle'
                      onClick={() => {
                        if (openFilters === 'history') {
                          setOpenFilters();
                        } else {
                          setOpenFilters('history');
                        }
                      }}
                    >
                      Sort Orders{' '}
                      {openFilters === 'history' ? (
                        <FiChevronUp
                          className='open'
                          onClick={() => {
                            setOpenFilters();
                          }}
                        />
                      ) : (
                        <FiChevronDown
                          className='open'
                          onClick={() => {
                            setOpenFilters('history');
                          }}
                        />
                      )}
                    </div>
                    {openFilters === 'history' ? (
                      <div className='order-status-filters'>
                        {sortOptions.map((i, index) => {
                          return (
                            <div
                              key={index}
                              className='status-filter'
                              style={{ width: 'initial', padding: '0.25rem' }}
                              onClick={() => {
                                if (sort === i) {
                                  setSort();
                                } else {
                                  setSort(i);
                                }
                              }}
                            >
                              <div
                                className='selection-box'
                                onClick={() => {
                                  if (sort === i) {
                                    setSort();
                                  } else {
                                    setSort(i);
                                  }
                                }}
                              >
                                {sort === i ? <BsCheckLg /> : ''}
                              </div>
                              {i}
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              </div>
            </div>

            {open === 'advancedSearch' ? <div className='white-box'></div> : ''}
            {open === 'advancedSearch' || open === 'searchTitles' ? (
              open === 'advancedSearch' ? (
                <div className='advanced-search-div'>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '1176px',
                    }}
                  >
                    <VscChromeClose
                      style={{
                        cursor: 'pointer',
                        width: '1.4rem',
                        height: '1.4rem',
                        color: 'var(--darkblue)',
                        strokeWidth: '0.5',
                        alignSelf: 'end',
                      }}
                      onClick={() => {
                        setSearchingAdvanced();
                        setAdvancedSearch([]);
                        setOrderDateStart();
                        setOrderDateEnd();
                        setShipDateStart();
                        setShipDateEnd();
                        setOpenItem();
                      }}
                    />

                    <div className='search-grid'>
                      {advancedSearchOptions.map((i, index) => {
                        return (
                          <div
                            key={index}
                            style={{
                              display: 'flex',
                              flexDirection: 'column',
                              marginBottom: '10px',
                            }}
                          >
                            {' '}
                            <div className='filter-headers'>{Object.keys(i)}:</div>
                            {Object.keys(i)[0] === 'Order Date' ||
                            Object.keys(i)[0] === 'Ship Date' ? (
                              <>
                                <div
                                  style={{
                                    display: 'flex',
                                    gap: '25px',
                                  }}
                                >
                                  <DatePicker
                                    style={{
                                      marginBottom: '5px',
                                    }}
                                    selected={
                                      Object.keys(i)[0] === 'Order Date'
                                        ? OrderDateStart
                                        : ShipDateStart
                                    }
                                    placeholderText='From:'
                                    onChange={(date) => {
                                      Object.keys(i)[0] === 'Order Date'
                                        ? setOrderDateStart(date)
                                        : setShipDateStart(date);
                                      addAdvancedSearch(i, date, 'from');
                                    }}
                                  ></DatePicker>
                                  <DatePicker
                                    style={{
                                      marginBottom: '5px',
                                    }}
                                    selected={
                                      Object.keys(i)[0] === 'Order Date'
                                        ? OrderDateEnd
                                        : ShipDateEnd
                                    }
                                    placeholderText='To:'
                                    onChange={(date) => {
                                      Object.keys(i)[0] === 'Order Date'
                                        ? setOrderDateEnd(date)
                                        : setShipDateEnd(date);
                                      addAdvancedSearch(i, date, 'to');
                                    }}
                                  ></DatePicker>
                                </div>
                              </>
                            ) : (
                              <>
                                <input
                                  className='advanced-search-input'
                                  value={
                                    advancedSearch.find(
                                      (j) => j?.name?.toString() === Object.values(i).toString()
                                    )?.value || ''
                                  }
                                  onChange={(e) => {
                                    addAdvancedSearch(i, e.target.value);
                                  }}
                                ></input>
                                <div></div>
                              </>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    <div style={{ display: 'flex', alignSelf: 'end' }}>
                      <button
                        onClick={() => {
                          setSearchingAdvanced(advancedSearch);
                        }}
                        className='search-button'
                        style={{ marginRight: '10px' }}
                      >
                        Search
                      </button>
                      <button
                        onClick={() => {
                          setAdvancedSearch([]);
                          setOrderDateStart();
                          setOrderDateEnd();
                          setShipDateStart();
                          setShipDateEnd();
                          setSort('');
                          setFilters({
                            ['in production']: false,
                            shipped: false,
                            ['ready to ship']: false,
                            ['on hold']: false,
                            ['packaging']: false,
                          });
                        }}
                        className='search-button'
                      >
                        Clear
                      </button>
                    </div>
                  </div>{' '}
                </div>
              ) : (
                <div style={{ display: 'flex', marginTop: '10px' }}>
                  {advancedSearch
                    .filter((i) =>
                      i.key === 'Order Date' || i.key === 'Ship Date'
                        ? i?.value?.from && i?.value?.to
                        : i?.value
                    )
                    .map((i, index) => (
                      <div
                        key={index}
                        className='search-titles'
                      >
                        <div style={{ marginRight: '4px', fontWeight: '600' }}>{i.key}</div>
                        {i.key === 'Order Date' || i.key === 'Ship Date' ? (
                          <div style={{ marginRight: '10px' }}>{`${dayjs(i?.value?.from).format(
                            'MM/DD/YYYY'
                          )}-${dayjs(i?.value?.to).format('MM/DD/YYYY')}`}</div>
                        ) : (
                          <div style={{ marginRight: '10px' }}>{i.value}</div>
                        )}
                      </div>
                    ))}
                </div>
              )
            ) : (
              ''
            )}
          </div>
          {auth?.roles?.includes(3500) ? (
            <div
              style={{
                borderRadius: '19px',
                display: 'flex',
                alignItems: 'center',
                marginBottom:
                  myOrders.length > 0 ||
                  loading ||
                  (myOrders.length <= 0 && !loading && accountChosen && !searchAccount)
                    ? '20px'
                    : '18rem',
                background: 'lightgrey',
                width: '100%',
                justifyContent: 'center',
                position: 'relative',
                minHeight: '75px',
              }}
            >
              {accountChosen && !searchAccount ? (
                <div style={{ minWidth: '443px', textAlign: 'center' }}>
                  Orders for {accountChosen.name} - Account #{accountChosen.customerId}
                </div>
              ) : (
                <div
                  style={{ width: '443px', height: '35px' }}
                  className='search-orders'
                >
                  <input
                    style={{ width: '100%' }}
                    type='text'
                    name='searchVal'
                    placeholder='Search Account'
                    id='searchVal'
                    value={searchVal}
                    onChange={(e) => {
                      setSearchVal(e.target.value);
                    }}
                    onFocus={() => {
                      if (accountChosen) {
                        setSearchVal('');
                      }
                      setShowDrop(true);
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        const findSearchVal = customerList?.filter((i) => {
                          return (
                            i.name?.toString()?.toUpperCase() ===
                            searchVal?.toString()?.toUpperCase()
                          );
                        });
                        if (findSearchVal) {
                          setAccountChosen(findSearchVal[0]);
                          setShowDrop(false);
                        }
                      }
                    }}
                    required
                  ></input>

                  {customerList && searchVal && showDrop ? (
                    <div
                      style={{ top: '4rem', left: '16rem' }}
                      className='modelNum-drop-div'
                    >
                      <div className='modelNum-dropdown'>
                        {customerList
                          ?.filter((i) => {
                            const searchUpperCase = searchVal?.toString()?.toUpperCase();
                            const nameUpperCase = i.name?.toString()?.toUpperCase();
                            const customerIdUpperCase = i.customerId?.toString()?.toUpperCase();

                            return (
                              (customerIdUpperCase && customerIdUpperCase === searchUpperCase) ||
                              (customerIdUpperCase &&
                                customerIdUpperCase.includes(searchUpperCase)) ||
                              (nameUpperCase && nameUpperCase.includes(searchUpperCase))
                            );
                          })
                          ?.sort((a, b) => {
                            const searchUpperCase = searchVal?.toString()?.toUpperCase();
                            const nameUpperCaseA = a.name?.toString()?.toUpperCase();
                            const customerIdUpperCaseA = a.customerId?.toString()?.toUpperCase();
                            const nameUpperCaseB = b.name?.toString()?.toUpperCase();
                            const customerIdUpperCaseB = b.customerId?.toString()?.toUpperCase();

                            const isExactMatchA = customerIdUpperCaseA === searchUpperCase;
                            const isExactMatchB = customerIdUpperCaseB === searchUpperCase;

                            if (isExactMatchA && !isExactMatchB) {
                              return -1;
                            } else if (!isExactMatchA && isExactMatchB) {
                              return 1;
                            }

                            // If neither or both are exact matches, sort by proximity
                            const proximityA =
                              (isExactMatchA
                                ? 0
                                : customerIdUpperCaseA?.indexOf(searchUpperCase)) ||
                              (nameUpperCaseA && nameUpperCaseA.indexOf(searchUpperCase)) ||
                              9999;

                            const proximityB =
                              (isExactMatchB
                                ? 0
                                : customerIdUpperCaseB?.indexOf(searchUpperCase)) ||
                              (nameUpperCaseB && nameUpperCaseB.indexOf(searchUpperCase)) ||
                              9999;

                            return proximityA - proximityB;
                          })
                          ?.map((i, index) => {
                            return (
                              <div
                                key={index}
                                className={`modelNum-list-item`}
                                onClick={() => {
                                  setAccountChosen(i);
                                  setSearchVal(i.customerId + ' - ' + i.name);
                                  setShowDrop(false);
                                }}
                              >
                                {i.customerId} - {i.name}
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              )}
              {auth?.roles.includes(3500) && searchAccount ? (
                <button
                  onClick={() => {
                    getOrderHeaders();
                    setSearchVal('');
                  }}
                  className='editableFormButton'
                  disabled={!accountChosen}
                >
                  Get Orders for Account
                </button>
              ) : auth?.roles.includes(3500) ? (
                <button
                  onClick={changeAccounts}
                  className='editableFormButton'
                >
                  Change Account
                </button>
              ) : (
                ''
              )}
            </div>
          ) : (
            ''
          )}

          <div>
            {loading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '20px',
                }}
              >
                <LoadingIcon />
              </div>
            ) : (myOrders.length <= 0 && !auth?.roles?.includes(3500)) ||
              (myOrders.length <= 0 && accountChosen && !searchAccount) ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '20px',
                }}
              >
                <p style={{ padding: '0px 26px' }}>
                  We're sorry, there are no results for your search.
                </p>
              </div>
            ) : myOrders.length <= 0 && auth?.roles.includes(3500) && !accountChosen ? (
              <></>
            ) : (
              myOrders
                ?.filter((i) => {
                  return Object.values(filters).every((el) => el === false)
                    ? true
                    : filters[
                        orderStatus?.find((j) => j.code === i?.invoiceNum)?.status.toLowerCase()
                      ];
                })
                .filter((i) => {
                  if (searchFilters) {
                    const includesFilter = Object.values(i)
                      ?.filter((j) => typeof j === 'string')
                      .some((j) => j.includes(searchFilters));
                    return includesFilter;
                  } else {
                    return true;
                  }
                })
                .slice(show * page, show * page + show)
                ?.sort((a, b) => {
                  switch (sort) {
                    case 'Order Numbers':
                      return parseInt(a.orderNum) <= parseInt(b.orderNum) ? -1 : 1;
                    case 'PO Numbers':
                      return parseInt(a.poNum) <= parseInt(b.poNum) ? -1 : 1;
                    case 'Ship Date':
                      return new Date(b.shipDate) - new Date(a.shipDate) <= 0 ? 1 : -1;
                    case 'Order Date':
                      return new Date(b.date) - new Date(a.date) <= 0 ? 1 : -1;
                    default:
                      return 0;
                  }
                })
                ?.map((i, index) => {
                  return (
                    <OrderTracking
                      key={index}
                      order={i}
                      open={openOrder}
                      setOpenItem={setOpenOrderItem}
                      orderStatusIcons={orderStatus}
                      account={accountChosen ? accountChosen?.customerId : null}
                      link={trackingLink.find(
                        (trackInfo) => i.carrierCode === trackInfo.carrier_code
                      )}
                    />
                  );
                })
            )}
            {myOrders.length > 0 ? (
              <Paginator
                currentPage={page}
                totalPages={lastPage}
                setCurrentPage={setPage}
                setShow={setShow}
                show={show}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FreightCart;
