import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import TemplateTilePage from '../Components/Shared/TemplateTilePage';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { createSearchParams } from 'react-router-dom';
import Paginator from '../Components/Media/Paginator';
import useAxiosJWT from '../hooks/useAxiosJWT';
import AdvancedSearch from '../Components/Shared/AdvancedSearch';
import ImageSlider from '../Components/Shared/ImageSlider';
import DiscoverProductsCard from '../Components/HomePage/DiscoverProductsCard';
import { GiSettingsKnobs } from 'react-icons/gi';

const searchBanner =
  'https://res.cloudinary.com/da3rom333/image/upload/v1689793742/Website%20Assets/Banners/BannerSearchUpdated_ipkxee.jpg';
// eslint-disable-next-line no-undef
const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;

const Search = () => {
  const location = useLocation();
  const axiosJWT = useAxiosJWT();
  const [results, setResults] = useState();
  const [searchParams] = useSearchParams();
  const searchParamsUse = Object.fromEntries([...searchParams]);
  const [dataAmount, setDataAmount] = useState();
  const [pageNumber, setPageNumber] = useState(0);
  const [perPage, setPerPage] = useState(16);
  const [isLoading, setIsLoading] = useState(true);
  const [randomCategories, setRandomCategories] = useState();
  const [categoryElements, setCategoryElements] = useState();
  const [searchFilters, setSearchFilters] = useState([
    { section: 'Certification', option: 'Exact Match' },
  ]);
  const [searchTextObjects, setSearchTextObjects] = useState([]);
  const [advancedSearchOpen, setAdvancedSearchOpen] = useState(false);
  const [statusIcons, setStatusIcons] = useState([]);

  const navigate = useNavigate();

  function getTotalDataAmount(searchText) {
    setIsLoading(true);
    axiosJWT
      .post(`products/search/length`, {
        searchText: searchText,
      })
      .then((response) => {
        setDataAmount(response.data);
      })
      .catch((error) => {
        console.error('Error fetching total data amount:', error);
      });
  }

  function handleClick(item) {
    navigate({
      pathname: `/products/${encodeURIComponent(item.series)}`,
      search: `?${createSearchParams(item.configOptions)}`,
    });
  }

  function getPageData(searchText) {
    setResults();
    setIsLoading(true);
    axiosJWT
      .post(`products/search`, {
        searchText: searchText.replace('-', '').replace(/'/g, "''"),
        page: pageNumber,
        perPage: perPage,
      })
      .then((response) => {
        setIsLoading(false);
        setResults(
          response.data.results?.map((i) => {
            return {
              image: i.imageLink,
              title: i.baseModelNum,
              series: i.series,
              configOptions: i.configOptions,
              status: i.status,
            };
          })
        );
      })
      .catch((error) => {
        console.error('Error fetching page data:', error);
        setIsLoading(false);
        return [];
      });
  }

  function getAdvancedSearchData() {
    setResults();
    setIsLoading(true);
    if (
      location?.state?.searchFilters.length > 0 &&
      location?.state?.searchTextObjects.length > 0
    ) {
      axiosJWT
        .post(`products/advancedSearch`, {
          searchFilters: location?.state?.searchFilters,
          searchTextObjects: location?.state?.searchTextObjects,
          page: pageNumber,
          perPage: perPage,
        })
        .then((response) => {
          setIsLoading(false);
          setResults(
            response.data.finalResults?.map((i) => {
              return {
                image: i.imageLink,
                title: i.baseModelNum,
                series: i.series,
                configOptions: i.configOptions,
                status: i.status,
              };
            })
          );
          setDataAmount(response.data.totalResults);
        })
        .catch((error) => {
          console.error('Error fetching page data:', error);
          setIsLoading(false);
          return [];
        });
    } else {
      setIsLoading(false);
      setResults([]);
    }
  }

  function getRandomCategories() {
    Axios.post(`${apiAddress}/products/randomCategories`, { limit: 4 }).then((response) => {
      setRandomCategories(response.data.randomCategories);
    });
  }

  async function getStatusIcons() {
    await Axios.get(`${apiAddress}/products/statusIcons`)
      .then((response) => {
        setStatusIcons(response.data);
      })
      .catch((error) => {
        console.error('Error fetching status icons:', error);
      });
  }

  useEffect(() => {
    if (searchParamsUse.search) {
      document.title = `Search Results for "${searchParamsUse.search}" - National Public Seating`;
    }
  }, [searchParamsUse.search]);

  useEffect(() => {
    if (location?.state) {
      document.title = `Advanced Search Results - National Public Seating`;
    }
  }, [location.state]);

  useEffect(() => {
    if (location.state) {
      setSearchFilters(location.state.searchFilters);
      setSearchTextObjects(location.state.searchTextObjects);
    }
  }, []);

  useEffect(() => {
    if (searchParamsUse.search) {
      getPageData(searchParamsUse.search);
    } else {
      getAdvancedSearchData();
    }
  }, [pageNumber, perPage]);

  useEffect(() => {
    if (pageNumber + 1 > Math.ceil(dataAmount / perPage)) {
      setPageNumber(Math.ceil(dataAmount / perPage) - 1);
    }
  }, [perPage, pageNumber]);

  useEffect(() => {
    if (searchParamsUse.search) {
      getPageData(searchParamsUse.search);
      getTotalDataAmount(searchParamsUse.search);
      setPageNumber(0);
      setSearchFilters([{ section: 'Certification', option: 'Exact Match' }]);
      setSearchTextObjects([]);
      setAdvancedSearchOpen(false);
    } else if (location.state) {
      getAdvancedSearchData();
      setPageNumber(0);
      setAdvancedSearchOpen(true);
    }
  }, [searchParams, location.state]);

  useEffect(() => {
    getRandomCategories();
    getStatusIcons();
  }, []);

  useEffect(() => {
    var holderArray = [];
    holderArray.push(
      <DiscoverProductsCard
        category={'All Products'}
        key={holderArray.length}
        imageUrl={'v1712780480/Website%20Assets/All%20Icons/All_Products_-_Card_2_bvdjk1.jpg'}
      />
    );
    randomCategories?.map((i, index) => {
      holderArray.push(
        <DiscoverProductsCard
          category={i.category}
          imageUrl={i.image}
          link={i.link}
          key={index + 1}
        />
      );
    });

    setCategoryElements(holderArray);
  }, [randomCategories]);
  return (
    <>
      <>
        <TemplateTilePage
          statusIcons={statusIcons}
          banner={
            /*`Search Results for ${searchParamsUse.search}`*/
            <img
              src={searchBanner}
              className='banner'
            ></img>
          }
          list={results}
          clickHandler={handleClick}
          middleElement={
            <div className='search-header'>
              <div
                className='inner-padding'
                style={{ display: 'flex', justifyContent: 'space-between' }}
              >
                {searchParamsUse?.search ? (
                  <h1>
                    Showing results for: <span>{searchParamsUse.search}</span>
                  </h1>
                ) : (
                  <h1>Advanced Search Results</h1>
                )}

                <button
                  style={{
                    alignSelf: 'center',
                  }}
                  className='button'
                  onClick={() => setAdvancedSearchOpen(!advancedSearchOpen)}
                >
                  <span>
                    <GiSettingsKnobs
                      style={{
                        transform: 'rotate(90deg)',
                        height: '1rem',
                        width: '1rem',
                        marginRight: '5px',
                      }}
                    />
                  </span>
                  Advanced Search
                </button>
              </div>
              {advancedSearchOpen && (
                <AdvancedSearch
                  searchFilters={searchFilters}
                  setSearchFilters={setSearchFilters}
                  searchTextObjects={searchTextObjects}
                  setSearchTextObjects={setSearchTextObjects}
                />
              )}
              {isLoading ? <div style={{ textAlign: 'center' }}>Loading results...</div> : ''}
              {results?.length <= 0 && !isLoading ? (
                <h1 style={{ textAlign: 'center' }}>NO ITEMS MATCH YOUR SEARCH</h1>
              ) : (
                ''
              )}
            </div>
          }
        />
      </>
      {!isLoading && Math.ceil(dataAmount / perPage) > 1 ? (
        <div className='pages inner-padding'>
          <Paginator
            currentPage={pageNumber}
            totalPages={Math.ceil(dataAmount / perPage)}
            setCurrentPage={setPageNumber}
            setShow={setPerPage}
            show={perPage}
          />
        </div>
      ) : (
        ''
      )}
      {dataAmount <= 4 && (
        <div className='related-products'>
          <h2 className='related-products-header'>
            Can't find what you're looking for? Use our product navigation to help you!
          </h2>
          <div className='related-products-div box'>
            <ImageSlider
              images={categoryElements}
              numOfDisplay={5}
            />
          </div>
        </div>
      )}
    </>
  );
};
export default Search;
