import '../src/CSS/App.css';
import '../src/CSS/MobileApp/M_App.css';
import { useEffect } from 'react';
import markerSDK from '@marker.io/browser';
import useAuth from './hooks/useAuth';
import { CartProvider } from './context/CartContext';
import { useSelector } from 'react-redux';
import { ContentProvider } from './context/ContentProvider';
import RouteDirectory from './RouteDirectory';
import { StageProvider } from './context/StageProvider';

function App() {
  const { auth } = useAuth();
  const mobileScreen = useSelector((state) => state.isMobile.value.isMobile);

  async function loadWidget() {
    if (auth?.roles?.includes(3500)) {
      // eslint-disable-next-line no-unused-vars
      const widget = await markerSDK.loadWidget({
        project: '64778327517aa570924847af',
      });
    }
  }

  function editMetaViewPort() {
    document.getElementsByTagName('meta')['viewport'].content = 'width=540';
    // eslint-disable-next-line no-console
    console.log('editedViewport', document.getElementsByTagName('meta'));
  }

  useEffect(() => {
    if (mobileScreen) {
      editMetaViewPort();
    }
  }, []);

  useEffect(() => {
    loadWidget();
  }, [auth?.roles]);

  return (
    <>
      <div className='App'>
        <link
          rel='stylesheet'
          href='https://fonts.googleapis.com/css2?family=Poppins'
        />
        <link
          href='http://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,800,900'
          rel='stylesheet'
          type='text/css'
        />
        <CartProvider>
          <ContentProvider>
            <StageProvider>
            <RouteDirectory />
            </StageProvider>
          </ContentProvider>
        </CartProvider>
      </div>
    </>
  );
}

export default App;
