import React, { useEffect } from 'react';
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';

import '../../CSS/DesignServices.css';
import Inspire from '../../Components/Dealer Portal/Inspire';
import Partners from '../../Components/Dealer Portal/Partners';

const step1 =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653561/Website%20Assets/SVGs/Space_Plan_Step1_of4aq4.svg';
const step2 =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653562/Website%20Assets/SVGs/Space_Plan_Step2_bjchxp.svg';
const step3 =
  'https://res.cloudinary.com/da3rom333/image/upload/v1688653562/Website%20Assets/SVGs/Space_Plan_Step2_bjchxp.svg';
const banner =
  'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688650796/Website%20Assets/Banners/DesignServices_px4s4p.jpg';
const DesignServices = () => {
  useEffect(() => {
    document.title = `Design Services - National Public Seating`;
    window.scrollTo(0, 0);
  });
  const processSteps = [
    { number: 1, title: 'Hand Sketch Concept', img: step1 },
    { number: 2, title: 'NPS 2D Drawing', img: step2 },
    { number: 3, title: 'NPS 3D Rendering', img: step3 },
  ];

  return (
    <div className='designServices'>
      <div className='banner'>
        <img src={banner}></img>
      </div>
      {/* <div style={{ height: "16rem" }}></div> */}

      <ReactCompareSlider
        className='servicesSlider'
        itemOne={
          <ReactCompareSliderImage
            src='https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1689553865/Website%20Assets/JPGs/design-partners-2_kb4gbm.jpg'
            // srcSet="..."
            alt='Image one'
          />
        }
        itemTwo={
          <ReactCompareSliderImage
            src='https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1689553865/Website%20Assets/JPGs/design-partners-1_uqp9m6.jpg'
            // srcSet="..."
            alt='Image two'
          />
        }
      />
      <div style={{ height: '911px' }}></div>
      <div className='ourService'>
        <h2>Our Service Process</h2>
        <div className='underline'></div>
        <div className='steps'>
          {processSteps.map((i, index) => {
            return (
              <div key={index} className='processStep'>
                <div className='img-div'>
                  <img src={i.img}></img>
                </div>
                <h1>{i.number}.</h1>
                <h3>{i.title}</h3>
              </div>
            );
          })}
        </div>
      </div>
      <Inspire />
      <Partners />
      <div className='spaceRefresh'>
        <h1>Looking for a full space refresh?</h1>
        <h3>
          Our Space Planning & Design Team can build your ideal space, taking into account the needs
          of the environment and the number of people that fill the place.
        </h3>
        <h4>CONTACT YOUR ACCOUNT MANAGER FOR MORE DETAILS</h4>
        <button
          onClick={() => {
            window.open('/dealer-locator');
          }}
        >
          Find a dealer
        </button>
      </div>
    </div>
  );
};

export default DesignServices;
