import React, { useEffect, useState } from "react";
import useAxiosJWT from "../../hooks/useAxiosJWT";
import useExportToExcel from "../../Components/Shared/useExportToExcel";
const ExportForDesign = () => {
  const axiosJWT = useAxiosJWT();
  const buttonStyle = {
    border: " 1px solid rgb(179, 179, 179)",
    borderRadius: "3rem",
    padding: "1rem 2rem",
    background: "none",
    fontSize: "18px",
    fontWeight: "500",
    textTransform: "uppercase",
    width: "350px",
    color: "rgb(0, 67, 91)",
    cursor: "pointer",
  };
  const [allData, setAllData] = useState();
  const [notification, setNotification] = useState();
  function getData() {
    axiosJWT.get(`adminPortal/getPricer`).then((response) => {
      setAllData(response.data);
    });
  }
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [notification]);

  const ExportToExcel = useExportToExcel();
  function exportForDesignHandler(tableName) {
    const data = allData[tableName];
    if (data) {
      ExportToExcel(
        {
          data: data,
        },
        tableName
      );
    } else {
      setNotification("No Data");
    }
  }
  return (
    <div className="adminDiv">
      <h2>Export for Design</h2>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          width: "75rem",
          margin: "auto",
          justifyContent: "space-between",
        }}
      >
        {allData && allData["pricer"] && (
          <button
            className="editorButton"
            style={buttonStyle}
            onClick={() => {
              exportForDesignHandler("pricer");
            }}
          >
            Pricer
          </button>
        )}
        {allData && allData["quickship"] && (
          <button
            className="editorButton"
            style={buttonStyle}
            onClick={() => {
              exportForDesignHandler("quickship");
            }}
          >
            Quick Ship
          </button>
        )}
        {allData && allData["custom"] && (
          <button
            className="editorButton"
            style={buttonStyle}
            onClick={() => {
              exportForDesignHandler("custom");
            }}
          >
            Custom
          </button>
        )}

        {allData && allData["newYearPricer"] && (
          <button
            className="editorButton"
            style={buttonStyle}
            onClick={() => {
              exportForDesignHandler("newYearPricer");
            }}
          >
            New Year Pricer
          </button>
        )}
        {allData && allData["newYearQuickship"] && (
          <button
            className="editorButton"
            style={buttonStyle}
            onClick={() => {
              exportForDesignHandler("newYearQuickship");
            }}
          >
            New Year Quick Ship Pricer
          </button>
        )}
        {allData && allData["newYearCustom"] && (
          <button
            className="editorButton"
            style={buttonStyle}
            onClick={() => {
              exportForDesignHandler("newYearCustom");
            }}
          >
            New Year Custom Pricer
          </button>
        )}
      </div>
      {notification ? <div className="notice">{notification}</div> : ""}
    </div>
  );
};

export default ExportForDesign;
