import { useNavigate } from 'react-router-dom';

const PurchasingPortal = () => {
  const navigate = useNavigate();

  return (
    <div className='usersContainer'>
      <>
        {' '}
        <h2>Purchasing Portal</h2>
        <button
          className='editableFormButton'
          onClick={() => {
            navigate('/employee-portal/Purchasing/Frames');
          }}
        >
          Edit Frame Status
        </button>
        <button
          className='editableFormButton'
          onClick={() => {
            navigate('/employee-portal/Purchasing/Ignore Inventory');
          }}
        >
          Edit Ignore Inventory
        </button>
        <button
          className='editableFormButton'
          onClick={() => {
            navigate('/employee-portal/Purchasing/On Hand Stock');
          }}
        >
          View On Hand Stock
        </button>
        <button
          className='editableFormButton'
          onClick={() => {
            navigate('/employee-portal/Purchasing/Open POs');
          }}
        >
          View Open POs
        </button>
      </>
    </div>
  );
};

export default PurchasingPortal;
