import React from "react";

const USAState = (props) => {
  return (
    <path
      //   className="usState"
      d={props.dimensions}
      fill={props.fill}
      data-name={props.state}
      className={`${props.state} state`}
      onClick={props.onClickState}
      onMouseOver={props.onEnterState}
      onMouseLeave={props.onLeaveState}
    >
      <div>{props.stateName}</div>
    </path>
  );
};
export default USAState;
