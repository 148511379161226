import React, { useEffect, useRef, useState } from 'react';
import { HiSortAscending, HiSortDescending } from 'react-icons/hi';
import BasicSearchBar from '../NPSportal/BasicSearchBar';

function FilterOptions({ filterBy, options, filter, setFilter, setOpenFilter, sort, setSort }) {
  const componentRef = useRef(null);
  const [selectAll, setSelectAll] = useState();
  const [filterOptions, setFilterOptions] = useState();
  const [search, setSearch] = useState();

  function handleSelect(option) {
    setSelectAll();
    if (Object.keys(filter).includes(filterBy)) {
      if (filter[filterBy]?.includes(option)) {
        if (filter[filterBy].length > 1) {
          setFilter({ ...filter, [filterBy]: filter[filterBy].filter((f) => f !== option) }); //remove option
        } else {
          const { [filterBy]: _, ...newState } = filter;
          setFilter(newState);
        }
      } else {
        setFilter({ ...filter, [filterBy]: [...filter[filterBy], option] });
      }
    } else {
      setFilter({ ...filter, [filterBy]: [option] });
    }
  }

  function handleSelectAll() {
    if (!selectAll) {
      setFilter({ ...filter, [filterBy]: filterOptions.map((option) => option.name || option) });
    } else {
      const { [filterBy]: _, ...newState } = filter;
      setFilter(newState);
    }
    setSelectAll(!selectAll);
  }

  function sortOptions() {
    if (sort.column.includes('Date')) {
      options.sort((a, b) => {
        const dateA = new Date(a);
        const dateB = new Date(b);
        if (sort.direction === 'Ascending') {
          return dateA - dateB;
        } else {
          return dateB - dateA;
        }
      });
    } else if (typeof options[0] === 'number') {
      sort.direction === 'Ascending'
        ? options.sort((a, b) => a - b)
        : options.sort((a, b) => b - a);
    } else if (typeof options[0] === 'object') {
      sort.direction === 'Ascending'
        ? options.sort((a, b) => a.name?.localeCompare(b.name))
        : options.sort((a, b) => b.name?.localeCompare(a.name));
    } else {
      sort.direction === 'Ascending'
        ? options.sort((a, b) => a.localeCompare(b))
        : options.sort((a, b) => b.localeCompare(a));
    }
    setFilterOptions(options);
  }

  function handleClearAll() {
    const { [filterBy]: _, ...newState } = filter;
    setFilter(newState);
    setSearch();
    if (sort?.column === filterBy) {
      setSort();
    }
  }

  function handleClickOutside(event) {
    if (componentRef.current && !componentRef.current.contains(event.target)) {
      setOpenFilter();
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);
  useEffect(() => {
    if (sort?.column === filterBy) {
      sortOptions();
    } else {
      setFilterOptions(options);
    }
  }, [sort, options]);
  useEffect(() => {
    if (options?.length && search) {
      if (options[0].name) {
        setFilterOptions(
          options.filter((option) =>
            option.name?.toString().toLowerCase().includes(search.toLowerCase())
          )
        );
      } else {
        setFilterOptions(
          options.filter((option) => option.toString().toLowerCase().includes(search.toLowerCase()))
        );
      }
    } else if (!search) {
      setFilterOptions(options);
    }
  }, [search, options]);

  useEffect(() => {
    if (filter[filterBy]?.length === options?.length) {
      setSelectAll(true);
    } else {
      setSelectAll(false);
    }
  }, [filter]);

  return (
    <div ref={componentRef} className={`filter-box ${options?.length ? '' : 'hide'}`}>
      <div className='sort-and-search-div'>
        <div className='search-bar-wrapper'>
          <BasicSearchBar filters={search} setFilters={setSearch} />
        </div>
        {sort?.column === filterBy && sort?.direction === 'Ascending' ? (
          <HiSortAscending
            className='sort-icon'
            onClick={() => {
              setSort({ column: filterBy, direction: 'Descending' });
              setSearch();
            }}
          />
        ) : (
          <HiSortDescending
            className='sort-icon'
            onClick={() => {
              setSort({ column: filterBy, direction: 'Ascending' });
              setSearch();
            }}
          />
        )}
      </div>
      <div className='filter-options-div'>
        <div
          className={`filter-option ${selectAll ? 'selected' : ''} select-all`}
          onClick={handleSelectAll}
        >
          <input
            type='checkbox'
            className='checkbox'
            checked={selectAll || false}
            onChange={handleSelectAll}
          />
          SELECT ALL
        </div>
        {filterOptions?.map((option, index) => (
          <div
            key={index}
            onClick={() => handleSelect(option.name || option)}
            className={`filter-option ${
              filter?.hasOwnProperty(filterBy) &&
              (filter[filterBy].includes(option) || filter[filterBy].includes(option.name))
                ? 'selected'
                : ''
            }`}
          >
            <input
              type='checkbox'
              className='checkbox'
              checked={
                filter?.[filterBy]?.includes(option) ||
                filter?.[filterBy]?.includes(option.name) ||
                false
              }
              onChange={() => handleSelect(option.name || option)}
            />
            {typeof option === 'object' && option.swatch ? (
              <span className='basic-flex'>
                <img
                  style={{ height: '20px', width: '20px', objectFit: 'cover', marginRight: '5px' }}
                  src={`${option?.swatch}`}
                ></img>
                <div>{option?.name}</div>
              </span>
            ) : (
              <div>{option === 0 || option ? option : '(blank)'}</div>
            )}
          </div>
        ))}
      </div>
      <div className='clear' onClick={handleClearAll}>
        Clear All
      </div>
    </div>
  );
}

export default FilterOptions;
