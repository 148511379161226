import React, { useEffect, useState } from 'react';
import DiscoverProductsCard from '../HomePage/DiscoverProductsCard';
import ImageSlider from '../Shared/ImageSlider';
import Axios from 'axios';
import decodeCloudinary from '../../hooks/decodeCloudinary';

// eslint-disable-next-line no-undef
const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;
const RelatedProducts = () => {
  const [productsList, setProductsList] = useState();
  const [productsElements, setProductElements] = useState();
  const decodeCloudinaryURL = decodeCloudinary();

  function getRandomProducts() {
    Axios.post(`${apiAddress}/content/getRandomSeries`, { limit: 5 }).then((response) => {
      // console.log(response.data);
      setProductsList(response.data.randomProducts);
    });
  }

  useEffect(() => {
    getRandomProducts();
  }, []);
  useEffect(() => {
    var holderArray = [];
    productsList?.map((i, index) => {
      holderArray.push(
        <DiscoverProductsCard
          category={i.category}
          itemName={i.itemName}
          imageUrl={decodeCloudinaryURL(i.image)}
          link={i.link}
          key={index}
        />
      );
    });
    setProductElements(holderArray);
  }, [productsList]);
  return (
    <div className='related-products'>
      <h2>Other Products</h2>
      <div className='underline'></div>
      <h3>You May Also Like</h3>
      <div className='related-products-div'>
        {productsElements ? <ImageSlider images={productsElements} numOfDisplay={5} /> : ''}
      </div>
    </div>
  );
};

export default RelatedProducts;
