import { useLocation, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import '../../CSS/Unauthorized.css';
import useUserInfo from '../../hooks/useUserInfo';
import { useState } from 'react';
import axios from 'axios';

const Unauthorized = ({}) => {
  const { auth } = useAuth();
  // eslint-disable-next-line no-undef
  const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;

  const { displayLoginBox } = useUserInfo();
  const [requested, setRequested] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const requestAccess = async () => {
    const response = await axios.post(
      `${apiAddress}/auth/accessRequest`,
      JSON.stringify({
        user: auth?.rep,
        pageRequest: location.state.from.pathname,
      }),
      {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      }
    );

    if (response.status === 200) {
      if (response.data.message) {
        setRequested(false);
        setErrorMessage(response.data.message);
      } else {
        setRequested(true);
      }
    }
  };

  return (
    <div className='unauthorized'>
      {auth?.roles?.includes(9999) ? (
        <div className='background'>
          <img
            className='unauth-icon'
            src='https://res.cloudinary.com/da3rom333/image/upload/v1712582687/Website%20Assets/Banners/401Option_1_REQUEST_SENT_3_wlj970.svg'
            alt='lock'
          ></img>
          <h2>RESTRICTED ACCESS</h2>
          <h4>Access to this area is allowed only to authorized users.</h4>
          <button
            onClick={() => {
              navigate('/');
            }}
          >
            Go Home
          </button>
        </div>
      ) : auth?.roles?.length === 0 ? (
        <div className='background'>
          <img
            className='unauth-icon'
            src='https://res.cloudinary.com/da3rom333/image/upload/v1712582687/Website%20Assets/Banners/401Option_1_REQUEST_SENT_3_wlj970.svg'
            alt='lock'
          ></img>
          <h2>RESTRICTED ACCESS</h2>
          <h4>
            Thank you for your patience while your NPS dealer account request is being reviewed. We
            will get back to you shortly!
          </h4>
          <button
            onClick={() => {
              navigate('/');
            }}
          >
            Go Home
          </button>
        </div>
      ) : errorMessage ? (
        <div className='background'>
          <img
            className='unauth-icon'
            src='https://res.cloudinary.com/da3rom333/image/upload/v1712582687/Website%20Assets/Banners/401Option_1_REQUEST_SENT_3_wlj970.svg'
            alt='lock'
          ></img>
          <h2>RESTRICTED ACCESS</h2>
          <h4>Access to this area is allowed only to authorized users.</h4>
          <h4 className='error-msg'>{errorMessage}</h4>
        </div>
      ) : !requested ? (
        <div className='background'>
          <img
            className='unauth-icon'
            src='https://res.cloudinary.com/da3rom333/image/upload/v1712582687/Website%20Assets/Banners/401Option_1_REQUEST_SENT_3_wlj970.svg'
            alt='lock'
          ></img>
          <h2>RESTRICTED ACCESS</h2>
          <h4>Access to this area is allowed only to authorized users.</h4>
          {!auth?.rep ? (
            <button onClick={displayLoginBox}>Login</button>
          ) : (
            <button onClick={requestAccess}>Request Access</button>
          )}
          <a href='/'>Go Home</a>
        </div>
      ) : (
        <div className='background'>
          <div className='border'>
            <img
              className='success-icon'
              src='https://res.cloudinary.com/da3rom333/image/upload/v1712582623/Website%20Assets/Banners/401Option_1_REQUEST_SENT_2_cedth7.svg'
              alt='success'
            ></img>
            <h2 className='success'>Request sent!</h2>
            <h4>Our team will review your request and contact back.</h4>
            <div className='underline'></div>
            <button
              onClick={() => {
                navigate('/');
              }}
            >
              Go Home
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Unauthorized;
