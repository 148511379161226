import React from 'react';
import { useStageInfo } from '../../context/StageProvider';
import { findExposedSides } from './StageFunctions';
import SelectStageItems from './SelectStageItems';
import AddSteps from './AddSteps';
import AddGuardrails from './AddGuardrails';
import AddSkirting from './AddSkirting';

function StageAddAccessories() {
  const { accessoryPage, stageBuild } = useStageInfo();
  return accessoryPage === 0 ? (
    <SelectStageItems
      selected={[]}
      setSelected={() => {}}
    />
  ) : accessoryPage === 1 ? (
    <AddSteps />
  ) : accessoryPage === 2 ? (
    <AddGuardrails />
  ) : accessoryPage === 3 ? (
    <AddSkirting />
  ) : (
    accessoryPage === 4 && (
      <SelectStageItems
        type='cart'
        selected={[]}
        setSelected={() => {}}
      />
    )
  );
}

export default StageAddAccessories;
