import React, { useState } from "react";

const Symbol = ({ data }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      className={`symbol ${hover ? "symbol-hover" : ""}`}
      onMouseEnter={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      {hover ? <p>{data.hoverData}</p> : <img src={data.image}></img>}
    </div>
  );
};

export default Symbol;
