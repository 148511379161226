import { Link } from "react-router-dom";
import "../../CSS/EnvironmentsDrop.css";
import { useNavigate } from "react-router-dom";
const Environmentsdrop = ({ data }) => {
  const navigate = useNavigate();

  return (
    <div className="env-div">
      <div className="media-title-div">
        <h2>Environments</h2>
        <Link to="/environments">VIEW ALL</Link>
      </div>
      <div className="grid-container">
        {data?.map((e) => (
          <div
            key={e.title}
            className="env-tile"
            onClick={() => {
              // console.log(e);
              navigate({
                pathname: `/environments`,
                search: `?filter=${e.title.toLowerCase()}`,
              });
            }}
          >
            <img
              src={`https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/${e.image}`}
              alt=""
            ></img>
            <h3>{e.title}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Environmentsdrop;
