import React, { useEffect, useState } from 'react';

const LaminateHover = ({ colorData, assignClass = false }) => {
  const [topUse, setTopUse] = useState();
  const [rightUse, setRightUse] = useState();
  useEffect(() => {
    if (colorData) {
      if (assignClass) {
        setTopUse(
          document
            ?.getElementById(`${colorData.selectionName}-${assignClass}`)
            ?.getBoundingClientRect().top - 190
        );
        setRightUse(
          document
            ?.getElementById(`${colorData.selectionName}-${assignClass}`)
            ?.getBoundingClientRect().right - 152
        );
      } else {
        setTopUse(
          document?.getElementById(colorData?.selectionName)?.getBoundingClientRect().top - 190
        );
        setRightUse(
          document?.getElementById(colorData?.selectionName)?.getBoundingClientRect().right - 152
        );
      }
    } else {
      setTopUse();
      setRightUse();
    }
  }, [colorData]);
  return (
    <>
      {topUse && rightUse ? (
        <div
          className='laminate-popup'
          style={{
            position: 'fixed',
            top: `${topUse}px`,
            left: `${rightUse}px`,
            cursor: 'pointer',
          }}
          onClick={() => window.open(colorData.imageLink)}
        >
          <div
            style={{
              backgroundImage: `url(${colorData?.Swatch})`,
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
            className='swatch-div'
          ></div>

          <div className='color-info-div'>
            <div> {colorData['Vendor Code']}</div>
            <div>
              <h1>
                {colorData?.selectionName}
                {` (${colorData?.value})`}
                <br></br>
                <span
                  style={{
                    fontSize: '10px',
                    color: 'red',
                  }}
                >
                  {colorData.Type === 'Premium' ? colorData.Type : ''}
                </span>
              </h1>
              <img
                src='https://images.wilsonart.com/media/logo/stores/1/Wilsonart_ES-01.png'
                className='vendor'
              ></img>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </>
  );
};

export default LaminateHover;
