/* eslint-disable no-alert */
import { useState } from 'react';
import useAxiosJWT from '../../hooks/useAxiosJWT';

function ScheduleCalendarEvent({ type, setShowAddEvent, setEditedCalendar, event, setEditPopup }) {
  const axiosJWT = useAxiosJWT();
  const today = new Date().toISOString().split('T')[0];
  const [title, setTitle] = useState();
  const [startDate, setStartDate] = useState();
  const [startTime, setStartTime] = useState('T08:00');
  const [endDate, setEndDate] = useState();
  const [endTime, setEndTime] = useState('T17:00');

  const generateTimeOptions = () => {
    const options = [
      // eslint-disable-next-line react/jsx-key
      <option
        value=''
        disabled
      >
        Select a time
      </option>,
    ];

    for (let hour = 8; hour <= 17; hour++) {
      const formattedHour = hour < 10 ? `0${hour}` : `${hour}`;
      options.push(
        <option
          key={formattedHour}
          value={`T${formattedHour}:00`}
        >
          {formattedHour > 12 ? formattedHour - 12 : formattedHour}:00{' '}
          {formattedHour > 11 ? ' pm' : ' am'}
        </option>
      );
    }
    return options;
  };

  async function handleAddEvent() {
    if (startDate && endDate) {
      if (type === 'add' && !title) {
        window.alert('Please enter a valid title for the event.');
        return;
      }
      const validateStart = new Date(`${startDate}${startTime}`);
      const validateEnd = new Date(`${endDate}${endTime}`);
      //end needs to be at least an hour after start
      if (validateEnd < validateStart + 3600000) {
        window.alert('Please make sure your event is at least one hour long.');
        return;
      }
      if (type === 'reschedule') {
        await handleDelete();
      }
      const newEvent = event
        ? { ...event, start: validateStart, end: validateEnd }
        : { title: title, start: validateStart, end: validateEnd };
      await axiosJWT
        .post(`employeePortal/addEvent`, { event: newEvent })
        .then((response) => {
          setEditedCalendar(true);
          setShowAddEvent(false);
        })
        .catch((err) => {
          console.error(err);
        });
    } else if (!startDate || !endDate) {
      window.alert('Please enter a valid start and end time for the event.');
      return;
    }
  }

  async function handleDelete() {
    await axiosJWT
      .post(`employeePortal/deleteEvent`, { event })
      .then((response) => {
        setEditPopup(false);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  return (
    <div className='calendar-popup'>
      <h4>{type === 'add' ? 'New Event' : 'Reschedule'}</h4>
      {type === 'add' && (
        <div>
          Title:
          <input
            className='new-event-title-input'
            required
            onChange={(e) => setTitle(e.target.value)}
          ></input>
        </div>
      )}
      <div>
        Start:
        <input
          type='date'
          min={today}
          required
          onChange={(e) => setStartDate(e.target.value)}
        />
        <select
          className='hour-selector'
          value={startTime}
          required
          onChange={(e) => setStartTime(e.target.value)}
        >
          {generateTimeOptions()}
        </select>
      </div>
      <div>
        End:
        <input
          type='date'
          min={today}
          required
          onChange={(e) => setEndDate(e.target.value)}
        />
        <select
          className='hour-selector'
          value={endTime}
          required
          onChange={(e) => setEndTime(e.target.value)}
        >
          {generateTimeOptions()}
        </select>
      </div>
      <div className='sub-cal-buttons'>
        <button
          className='edit-cal-button'
          onClick={() => setShowAddEvent(false)}
        >
          Cancel
        </button>
        <button
          className='edit-cal-button warning'
          type='submit'
          onClick={handleAddEvent}
        >
          {type === 'add' ? 'Add' : 'Set'}
        </button>
      </div>
    </div>
  );
}

export default ScheduleCalendarEvent;
