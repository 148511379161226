import '../../CSS/DealerCenter.css';
const DealerCube = ({ data }) => {
  // const [hover, setHover] = useState(false);
  return (
    <div
      className='dealerCenterCube'
      // onMouseEnter={() => {
      //   setHover(true);
      // }}
      // onMouseLeave={() => {
      //   setHover(false);
      // }}
      // onClick={() => {
      //   clickHandler(data);
      // }}
      //   style={{ backgroundImage: `url(${brochures})` }}
    >
      <img
        src={`https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/${data.image}`}
      ></img>
      <h2>{data.title}</h2>
      {data?.sublinks?.map((i, index) => {
        return (
          <button
            key={index}
            // style={
            //   {
            // background: hover ? "var(--lightgreen)" : "white",
            // fontWeight: hover ? "700" : "600",
            //   }
            // }
            onClick={() => {
              window.open(`/${i.link}`);
            }}
            className='dealer-portal-button'
          >
            {i.title}
          </button>
        );
      })}
    </div>
  );
};

export default DealerCube;
