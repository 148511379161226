/* eslint-disable camelcase */
import { useEffect, useState } from 'react';
import '../CSS/Whatsnew.css';
import { IoChevronBack, IoChevronForward, IoLocationSharp } from 'react-icons/io5';
// import { ImCalendar } from "react-icons/im";
// import DiscoverProducts from "../Components/HomePage/DiscoverProducts";
import NewLit from '../Components/WhatsNew/NewLit';
// import VideoPlayer from "../Components/Products/VideoPlayer";
import { BsChevronLeft, BsChevronRight, BsCircleFill } from 'react-icons/bs';

import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import HomeCarousel from '../Components/HomePage/Carousel';
import { useSelector } from 'react-redux';
const banner =
  'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688650804/Website%20Assets/Banners/WhatsNew_a0icl7.jpg';
const WhatsNew = () => {
  useEffect(() => {
    document.title = `What's New - National Public Seating`;
    window.scrollTo(0, 0);
  }, []);
  const mobileScreen = useSelector((state) => state.isMobile.value);
  const [literature, setLiterature] = useState();
  const [litIndex, setLitIndex] = useState(0);
  // const [events, setEvents] = useState();
  // const [playVideo, setPlayVideo] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [carouselIndexData, setCarouselIndexData] = useState();
  const [carouselData, setCarouselData] = useState();
  const [banners, setBanners] = useState();

  // eslint-disable-next-line no-undef
  const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;

  useEffect(() => {
    if (carouselData) {
      setCarouselIndexData(carouselData[carouselIndex]);
    }
  }, [carouselIndex, carouselData]);
  function getData() {
    Axios.get(`${apiAddress}/content/GetNewLit`).then((response) => {
      // console.log(response.data);
      setLiterature(response.data.literature);
    });
    Axios.get(`${apiAddress}/content/getProductCarousel`).then((response) => {
      setCarouselData(response.data.carouselList);
    });
    Axios.post(`${apiAddress}/content/getCarouselBanners`, {
      page: 'whatsnew',
    }).then((response) => {
      if (mobileScreen.isMobile) {
        response.data = response.data.filter((i) => i.screen_type?.toLowerCase() === 'mobile');
      } else {
        response.data = response.data.filter((i) => i.screen_type?.toLowerCase() === 'desktop');
      }
      setBanners(response.data.filter((i) => i.carousel_type.toLowerCase() === 'main'));
    });
  }
  function addIndex() {
    if (carouselIndex === carouselData.length - 1) {
      setCarouselIndex(0);
    } else {
      setCarouselIndex(carouselIndex + 1);
    }
  }
  function removeIndex() {
    if (carouselIndex === 0) {
      setCarouselIndex(carouselData.length - 1);
    } else {
      setCarouselIndex(carouselIndex - 1);
    }
  }
  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    // console.log(carouselData, 80);
  }, [carouselData]);

  const buttonStyle = {
    height: '30px',
    width: '30px',

    color: '#B3B3B3',
    cursor: 'pointer',
    background: 'white',
    boxShadow: '2px 2px 8px #0000004D',
    borderRadius: '30rem',
  };
  const navigate = useNavigate();

  return (
    <div className='whatsnew' style={{ position: 'relative' }}>
      <img src={banner} style={{ marginBottom: '0px' }} className='banner'></img>

      {/* <div
        className="video-div inner-padding"
        onClick={() => {
          setPlayVideo(true);
        }}
      >
        <div className="video-banner">
          <h3>New Video</h3>
          <h1>Titan Series</h1>
          <h2>Modern | Indestructible | Versatile</h2>
          <button>Watch the video</button>
        </div>
        <BsFillPlayCircleFill />
      </div>
      {playVideo ? (
        <VideoPlayer
          setShow={setPlayVideo}
          videoSrc={
            "https://res.cloudinary.com/da3rom333/video/upload/v1688060110/Product%20Assets/Videos/Patent_Pending_-_Titan_Series_by_National_Public_Seating_Video_pzkeez.mp4"
          }
        />
      ) : (
        ""
      )} */}
      <div className='inner-padding' style={{ margin: '4rem auto' }}>
        <HomeCarousel images={banners} />
      </div>
      <div className='newLit' style={{ position: 'relative' }}>
        <div className='inner-padding'>
          <h2>New Literature</h2>
          <div className='underline'></div>
          <h3>Have a look to our latest pieces of literature</h3>

          <div className='newLit-items' style={{ position: 'relative' }}>
            {literature?.length > 4 ? (
              <div
                className='navigateButtons'
                style={{ position: 'absolute', right: '2rem', top: '-4rem' }}
              >
                <IoChevronBack
                  style={{ ...buttonStyle, padding: '8px 10px 8px 6px' }}
                  onClick={() => {
                    if (litIndex - 2 < 0) {
                      setLitIndex(literature?.length - 2);
                    } else {
                      setLitIndex(litIndex - 2);
                    }
                  }}
                />
                <IoChevronForward
                  style={{
                    ...buttonStyle,
                    padding: '8px 6px 8px 10px',
                    marginLeft: '16px',
                  }}
                  onClick={() => {
                    if (litIndex + 2 >= literature?.length) {
                      setLitIndex(0);
                    } else {
                      setLitIndex(litIndex + 2);
                    }
                  }}
                />
              </div>
            ) : (
              ''
            )}
            {literature?.slice(litIndex, litIndex + 4)?.map((i, index) => {
              return <NewLit lit={i} key={index} />;
            })}
          </div>
        </div>
      </div>

      {/* <div className="newLit-container"></div> */}

      <div style={{ justifyContent: 'center' }} className='newWare'>
        <img
          style={{ width: '100%', height: '1010px' }}
          src='https://res.cloudinary.com/da3rom333/image/upload/v1697726132/Website%20Assets/WhatsNew%20Page/background_lines_vpfgyo.png'
        ></img>

        <div style={{ position: 'absolute', display: 'flex', width: '1470px' }}>
          <img
            style={{ height: '1010px' }}
            src='https://res.cloudinary.com/da3rom333/image/upload/v1699365646/Website%20Assets/WhatsNew%20Page/warehouse_no_bg_small_lorfo0.png'
          ></img>
          <div className='details inner-padding'>
            <h1>Say Hello to our NEW WAREHOUSE</h1>
            <div className='underline'></div>
            <div className='location'>
              <IoLocationSharp />
              <p>CL | Clifton | New Jersey | 07014</p>
            </div>

            {/* <button>Learn More</button> */}
          </div>
        </div>
      </div>

      <div className='newProducts'>
        <div
          className='inner-padding'
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <h1>New Products</h1>
          <div className='underline'></div>
          <div className='carousel'>
            <BsChevronLeft onClick={removeIndex} />
            <div className={`carousel-data${carouselIndex % 2 !== 0 ? '-reverse' : ''}`}>
              <div>
                <h1>{carouselIndexData?.title}</h1>
                <div className='underline'></div>
                <p>{carouselIndexData?.description}</p>
                <button
                  onClick={() => {
                    carouselIndexData?.link?.startsWith('http')
                      ? window.open(`${carouselIndexData?.link}`, '_blank')
                      : navigate('/products/' + carouselIndexData?.link);
                  }}
                >
                  Learn More
                </button>
              </div>
              <img
                style={{
                  borderRadius: '30rem',
                  boxSizing: 'border-box',
                  border: '15px solid #C0E66C',
                  objectFit: 'contain',
                  background: 'white',
                }}
                src={`https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/${carouselIndexData?.image}`}
                alt='product image'
              ></img>
            </div>
            <BsChevronRight onClick={addIndex} />
          </div>
          <div className='carousel-pointer'>
            {carouselData?.map((i, index) => {
              return (
                <BsCircleFill
                  key={index}
                  onClick={() => {
                    setCarouselIndex(index);
                  }}
                  className={index === carouselIndex ? 'selected-pointer pointer' : 'pointer'}
                />
              );
            })}
          </div>
          <button
            className='seeAll-button'
            onClick={() => {
              navigate('/new');
            }}
          >
            See all
          </button>
        </div>
      </div>
    </div>
  );
};

export default WhatsNew;
