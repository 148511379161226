import { Link, useNavigate } from 'react-router-dom';
import '../../CSS/MediaCenterDrop.css';
const MediaCenterDrop = ({ data, categories }) => {
  const navigate = useNavigate();

  return (
    <div className='media-div'>
      <div className='media-title-div'>
        <h2>Media Center</h2>
        <Link to='/media-center'>VIEW ALL</Link>
      </div>
      <div className='grid-container'>
        <div className='tile-div'>
          {data?.map((e) => (
            <a
              href={`/${e.link}`}
              style={{ textDecoration: 'none' }}
              key={e.link}
            >
              <div
                key={e.title}
                className='media-tile'
                // onClick={() => {
                //   navigate(`/${e.link}`);
                // }}
              >
                <img
                  src={`https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/${e.image}`}
                  alt=''
                ></img>
                <h3 style={{ cursor: 'pointer' }}>{e.title}</h3>
              </div>
            </a>
          ))}
        </div>
        <div className='view-by-product'>
          <h1>View By Product</h1>
          <div className='media-flex'>
            {categories?.map((i) => (
              <h2
                key={i.catName}
                onClick={() => {
                  navigate({
                    pathname: `/media`,
                    search: `?Category=${encodeURIComponent(i.catName)}`,
                  });
                  // window.location.reload(false);
                }}
              >
                {i.catName}
              </h2>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaCenterDrop;
