import '../../CSS/NPSportal/reusableComponents.css';
import { useState } from 'react';

const Tabs = ({ tabs }) => {
  const [clicked, setClicked] = useState(tabs[0].title);

  return (
    <div className='basic-flex'>
      {tabs?.map((tab, index) => (
        <div
          key={index}
          onClick={() => {
            setClicked(tab.title);
            tab.action();
          }}
          className={` ${clicked === tab.title ? `tab-clicked` : ''} tab `}
        >
          {tab.title}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
