import React, { useEffect, useState } from 'react';
import useCart from '../../hooks/useCart';

const CartQuantity = ({
  quantity,
  displaySku,
  uom,
  updateQty,
  //if its from freight cart - updateQty will use cart provider and pass in 3 params
  //if its from products page - updateQty will update Product State Redux Slice using only the first param
  //if its from instant freight quote - updateQty will set the local state using only the first param
  placeholder = 'Quantity',
  quantityState = false,
  setQuantityState = false,
}) => {
  // const { updateQty } = useCart();
  const [focus, setFocus] = useState(false);
  useEffect(() => {
    if (!quantity && updateQty && !quantityState) {
      updateQty(0, uom * quantity, displaySku);
    }
  }, [quantity]);

  useEffect(() => {
    if (!focus && quantity < 1 && !quantityState) {
      updateQty(1, uom * 1, displaySku);
    }
  }, [focus]);
  return (
    <div className='cart-quantity'>
      {' '}
      <div
        className='minus'
        onClick={() => {
          if (quantityState !== false && quantityState > 1) {
            // console.log(quantity);
            setQuantityState(quantityState - 1);
            updateQty(quantityState - 1, uom * (quantityState - 1), displaySku);
          } else if (quantity > 1) {
            updateQty(quantity - 1, uom * (quantity - 1), displaySku);
          }
        }}
      >
        -
      </div>
      <div className='quantity-input'>
        <input
          type='text'
          //   name="quantity"
          placeholder={placeholder}
          //   id="quantity"
          value={quantityState !== false ? quantityState : quantity}
          onChange={(e) => {
            // console.log(quantity);
            if (quantityState !== false) {
              setQuantityState(e.target.value === null ? 1 : e.target.value);
            } else {
              updateQty(parseInt(e.target.value), uom * parseInt(e.target.value || 0), displaySku);
            }
          }}
          required
          onFocus={() => {
            // console.log("focus");
            setFocus(true);
          }}
          onBlur={() => {
            // console.log("not focus");
            setFocus(false);
            if (
              quantityState !== null &&
              quantityState !== false &&
              quantityState !== '' &&
              quantityState != 0
            ) {
              updateQty(parseInt(quantityState), uom * parseInt(quantityState || 0), displaySku);
            } else {
              updateQty(parseInt(1), uom, displaySku);
              setQuantityState(1);
            }
          }}
        ></input>
      </div>
      <div
        className='plus'
        onClick={() => {
          if (quantityState !== false || quantity) {
            if (quantityState !== false) {
              setQuantityState(quantityState + 1);
            }
            updateQty(
              (quantityState || quantity) + 1,
              uom * ((quantityState || quantity) + 1),
              displaySku
            );
          } else {
            updateQty(1, uom * 1, displaySku);
          }
        }}
      >
        +
      </div>
    </div>
  );
};

export default CartQuantity;
