import React, { useEffect } from 'react';
import '../../CSS/Testimonials.css';
import { FaQuoteLeft } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
const banner =
  'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688650800/Website%20Assets/Banners/testimonials_eluytj.jpg';
const Testimonials = () => {
  useEffect(() => {
    document.title = `Testimonials - National Public Seating`;
    window.scrollTo(0, 0);
  });
  const testimonials = [
    {
      comment: 'We LOVE the chairs and rack that we did receive. Excellent product!',
      name: 'Carlton Doup Kung Fu Northwest',
    },
    {
      comment:
        'Steve James just gave me the good news that you are donating 3 stools for use in the kitchen at Belzer Scout Camp. Thank you so much! Belzer Day Camp is host to about 500 youth each week for 7 weeks in the summer and hosts other Scouts all year. Steve assists me in cooking for the staff of the summer camp – we serve about 40-50 youth and adult counselors each week. The use of your stools will be a wonderful thing for us. Thanks again for your generosity.  Sandi Hobbs BOY SCOUTS OF AMERICA Sr. Executive Assistant Crossroads of America Council',
      name: 'Sandi Hobbs BOY SCOUTS OF AMERICA Sr. Executive Assistant Crossroads of America Council',
    },
    {
      comment:
        'Thanks again for your response. It is rare to find a company who will respond in the way that your company has with such wonderful customer service. I will be sure to recommend your company to anyone who is looking to purchase classroom furniture in the future.',
      name: 'Nikki Galuska 7th Grade Language Arts Team 7-2 Team Leader Dublin, Ohio',
    },
    {
      comment:
        'When taken care of and properly assembled, the NPS Melody Music Stand definitely stands up against some of the most popular name brand music stands. In 14 years of teaching, I have fixed, repaired and tightened many stands before each concert. In my experience, Melody Music Stands require less maintenance and attention, leaving me with time to focus on other aspects of preparing for a concert or class. We have had our stands now for 7 years and they still have maintained their smooth black finish. They save time during class instruction because the tops are less likely to flop-down or pop-off, eliminating classroom disruptions.',
      name: 'Travis B. Foster York Preparatory Academy Director of Bands',
    },
    {
      comment:
        'I just wanted to thank you again for all you’ve done for Queen of Peace HS. We received the replacement seats and back for our café chairs on Friday evening. We just finished replacing them and they look great! You are truly a man of your word and a great example of a business that stands behind their products. Whenever there’s an opportunity for me to recommend National Public Seating and your products you can be sure that I’ll be singing you some high praises. Thank you again',
      name: 'Joe Sendra Facilities Manager Queen of Peace H.S. Burbank, Il. 60459',
    },
    {
      comment:
        "They say that when a product falls short of expectations (no matter how big or small), support is the lifeblood and the saving grace of an organization and the relationship with the customer. I've seen it many, many times over - where hard to follow processes, ticketing systems, poor communication, documentation, or hard to use services devastate and even cripple a company, its public relations, and growth potential. I've also seen scenarios where support SAVES the relationship between the customer and the distributor: This is one of those times. When I say I've spent months excitedly waiting on the arrival of my national public seating chem-resistant table, that's more of an understatement than a matter of fact, but as it arrived - I realized something was wrong. Part of the order arrived in a broken condition; Today, NPS, Yaffa Davis was the saving grace. After emailing, and providing the information, Yaffa INSTANTLY sent out replacement parts; no hassle, no time wasted, and what's more: When they arrived at the third party who failed to ship it to me, Yaffa was able to resend to me directly the parts that failed. I just want you to know that product satisfaction is NEVER as good as product, and process satisfaction, and when both were at risk, it felt like Yaffa would go to Antarctica and back just to get the phone I forgot or to Fiji just to bring me a cup of coffee (a.k.a would go to the ends of the earth just to keep the customer satisfied). At the heart of it, THAT'S what support should be. Taking care of the customers (within reason) and filling the gaps where the process fell short. And today, support took over and showed me what NPS was REALLY about - Thank you <3",
      name: 'Hyron H.',
    },
    {
      comment:
        'Gentlemen: Thank you very much for both the consideration and prompt response to my concern/complaint. As a minimum on my part, I would like to share with your customers and potential customers that I am thankful to have chosen National Public Seating as my supplier. All too often, customer service is non-existent or poor subsequent to the purchase. You have distinguished yourselves as being deserving of the highest marks for customer satisfaction. Very truly yours,',
      name: 'R. Heinton',
    },
    {
      comment:
        'I wanted to let you know how much I appreciated all of the effort that you put into making sure that our order process was accomplished without any glitches or hiccups; my boss is very happy with the chairs. They are, indeed, as comfortable as you touted to us. We are full steam ahead for hosting seminars, baby showers, wedding showers, etc., with our new chairs. Kudos, kiddo, kudos. Please feel free to pass this along to any and all of your superiors!!',
      name: 'Evelyn Presley, Horticulturist Defiance, MO',
    },

    {
      comment: 'I use your stools at my commercial kitchen and they hold up amazingly.',
      name: 'Joanna’s New World Biscotti',
    },
    {
      comment:
        'I just wanted to let you know how happy we are with your customer service. About 2 weeks ago I contacted your Service Department about a caster that broke off. All I wanted to know was if you could supply one and if not, what the specifications were so I could go out and get one to replace it. Somehow my email got to Jack Frankel and he handled it straight away. He sent us a replacement without charge and I was able to fix the problem. Thank-you again for the swift response and attentive customer service. Best regards,    ',
      name: 'Ray Baldonade Maria Montessori Charter Academy, CA',
    },
    {
      comment:
        'The Transport Risers arrived yesterday, so I only have just unpacked them. They look nice! They are going to work out great. I was very happy with the deal I got. Everyone I’ve talked to, at your company and at NPS have been courteous and helpful. I needed a copy of the assembly directions for the 4th tier and guardrail, and they were extremely quick to email me copies. Thanks again,',
      name: 'Music, Opstad Elementary School. Snoqualmie, WA',
    },
    {
      comment:
        "As head of the Building and Grounds Committee of our church (QPCC) I deal with many companies both for products and services. It's always refreshing when someone from a business treats you like you are a valued entity. Believe me it's not the norm, unfortunately. Jack came through without having to be reminded or anything else. He initiated getting the replacement parts for us and they worked. Can't beat that. My father had a saying for that  That's the best thing since sliced bread. As a result, as long as I am in charge, we will utilize National Public Seating products as needed and let others know of our experience. Much appreciated. Ciao.",
      name: 'Robert Romeri Quincy Point Congregational Church, MA',
    },
  ];
  const navigate = useNavigate();

  return (
    <div className='testimonialsPage'>
      <div className='banner'>
        <img src={banner}></img>
      </div>
      {/* <div style={{ height: "17rem" }}></div> */}

      <div className='testimonials-div inner-padding'>
        <div className=' left-testimonials'>
          <div className='title inner-padding'>
            <h1>
              What <span style={{ fontWeight: 'bold' }}>People</span> Say{' '}
              <span style={{ fontWeight: 'bold' }}>About</span> Us
            </h1>
            <div className='underline'></div>
            <h3>Your voice shapes our growth; we listen to evolve.</h3>
            <button
              onClick={() => {
                navigate('/contact?category=contact-us');
              }}
            >
              Submit Feedback
            </button>
          </div>
          <div className='testimonials'>
            {testimonials
              .filter((_, index) => index < Math.ceil(testimonials.length / 2))
              .map((i, index) => {
                return (
                  <div key={index} className='testimonial'>
                    <FaQuoteLeft />
                    <p>{i.comment}</p>
                    <h3>{i.name}</h3>
                  </div>
                );
              })}
          </div>
        </div>
        <div className='testimonials right-testimonials'>
          {testimonials
            .filter((_, index) => index >= Math.ceil(testimonials.length / 2))
            .map((i, index) => {
              return (
                <div key={index} className='testimonial'>
                  <FaQuoteLeft />
                  <p>{i.comment}</p>
                  <h3>{i.name}</h3>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
