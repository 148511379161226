import { useState } from 'react';
import { IoIosCloseCircleOutline } from 'react-icons/io';
import '../../CSS/AddFinishes.css';
import FinishesOptions from './FinishesOptions';
import Axios from 'axios';

const AddFinishes = ({ finishesNeeded, addToCart, product, quantity, resetValues }) => {
  // eslint-disable-next-line no-undef
  const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;
  const [selectedFinishes, setSelectedFinishes] = useState({});

  async function replaceString(start, len, laminateColor, skuIn, finishType) {
    const response = await Axios.post(`${apiAddress}/products/getLaminateCode`, {
      laminateName: laminateColor,
      // eslint-disable-next-line camelcase
      config_name: finishType,
    });
    const laminateCode = response.data[0].laminateCode;
    const holderString = skuIn;
    const part1 = holderString?.substring(0, start - 1);
    const part3 =
      holderString?.length > part1?.length + laminateCode.length
        ? holderString?.substring(start + len - 1)
        : '';
    return part1 + laminateCode + part3;
  }

  async function getSkuDisplayed() {
    let skuChange = product.BaseModelNum;
    for (const key of Object.keys(selectedFinishes)) {
      const holder = finishesNeeded?.filter((j) => j.name === key);
      skuChange = await replaceString(
        holder[0].pos,
        holder[0].charLen,
        selectedFinishes[key],
        skuChange,
        holder[0].listName
      );
    }
    return skuChange;
  }

  async function confirmSelection() {
    if (Object.keys(selectedFinishes).length === finishesNeeded.length) {
      var sku = await getSkuDisplayed();
      addToCart(
        product.FreightQuoteModelNum,
        quantity,
        sku,
        product.ShortDescription,
        product.imageLink,
        selectedFinishes,
        product.BaseModelNum,
        parseInt(product.uom) * parseInt(quantity),
        parseInt(product.uom),
        product.ShipsNonBoxed,
        false
      );
      resetValues();
    }
  }

  return (
    <div className='addfinishes'>
      <div className='modal'></div>
      <div className='finishesPopup'>
        <h1
          onClick={() => {}}
          className='mainTitle'
          style={{ margin: 'auto', textAlign: 'center' }}
        >
          ADD THE FOLLOWING TO COMPLETE THE SKU
        </h1>
        <div className='underline'></div>
        {finishesNeeded?.map((i) => {
          return (
            <FinishesOptions
              key={i.listName}
              data={i}
              selectedFinishes={selectedFinishes}
              setSelectedFinishes={setSelectedFinishes}
              assignClass={i.name}
              finishesNeeded={finishesNeeded}
            />
          );
        })}
        <button
          className='confirmSelection-valid'
          onClick={confirmSelection}
        >
          Confirm Selection
        </button>
        <IoIosCloseCircleOutline
          onClick={() => {
            resetValues();
          }}
          className='close-button'
        />
      </div>
    </div>
  );
};

export default AddFinishes;
