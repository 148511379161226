import { useState } from 'react';
import '../../CSS/HeaderNav.css';
import '../../CSS/MobileApp/M_HeaderNav.css';
import Search from './Search.js';
import HeaderNavLinks from './HeaderNavLinks';
import { useSelector } from 'react-redux';

const HeaderNav = () => {
  const [searchText, setSearchText] = useState('');
  const mobileScreen = useSelector((state) => state.isMobile.value);

  return (
    <nav className='header-nav padding'>
      <ul className='header-nav-list-items'>
        {!mobileScreen.isMobile ? <HeaderNavLinks></HeaderNavLinks> : ''}
        <Search
          searchText={searchText}
          setSearchText={setSearchText}
        />
      </ul>
    </nav>
  );
};

export default HeaderNav;
