import React from "react";
const logo =
  "https://res.cloudinary.com/da3rom333/image/upload/v1688653562/Website%20Assets/SVGs/Space_Plan_TheKits_j3b0c9.svg";
const logo1 =
  "https://res.cloudinary.com/da3rom333/image/upload/v1688653561/Website%20Assets/SVGs/Space_Plan_ProjectM_rsjzkq.svg";
const logo2 =
  "https://res.cloudinary.com/da3rom333/image/upload/v1688653560/Website%20Assets/SVGs/Space_Plan_MRL_blpoxc.svg";
const logo3 =
  "https://res.cloudinary.com/da3rom333/image/upload/v1688653559/Website%20Assets/SVGs/Space_Plan_2020D_z3cnws.svg";
const logo4 =
  "https://res.cloudinary.com/da3rom333/image/upload/v1688653560/Website%20Assets/SVGs/Space_Plan_CET_g3jxzb.svg";
const logo5 =
  "https://res.cloudinary.com/da3rom333/image/upload/v1688653560/Website%20Assets/SVGs/Space_Plan_AQ_opdhri.svg";
const Partners = () => {
  const partnerLogos = [
    {
      img: logo,
      link: "https://kisp.com/cad/",
      height: "100px",
      width: "217px",
      marginRight: "76px",
    },
    {
      img: logo1,
      link: "https://www.configura.com/projectmatrix",
      height: "100px",
      width: "118px",
      marginRight: "76px",
    },
    {
      img: logo2,
      link: "https://myresourcelibrary.com/View/0bb26a11-956c-4d94-9983-fbee47bd0981",
      height: "100px",
      width: "258px",
      marginRight: "0",
    },
    {
      img: logo3,
      link: "https://www.2020spaces.com/customers/national-public-seating/",
      height: "64px",
      width: "400px",
      marginRight: "72px",
    },
    {
      img: logo4,
      link: "https://my.configura.com/index.pl?page=marketplace&section=productinfo&pid=1086",
      height: "98px",
      width: "400px",
      marginRight: "72px",
    },
    {
      img: logo5,
      link: "https://aq-fes.com/blog/new-manufacturers-for-november-2021/",
      height: "100px",
      width: "100px",
      marginRight: "0",
    },
  ];
  return (
    <div className="partners">
      <h2>
        Our entire lines of cafeteria and science products can now be specified
        using:
      </h2>
      <div className="underline"></div>
      <div className="logos">
        {partnerLogos.map((i) => {
          return (
            <div
              className="logo-div"
              key={i}
              style={{
                height: i.height,
                width: i.width,
                marginRight: i.marginRight,
              }}
              onClick={() => {
                window.open(i.link);
              }}
            >
              <img src={i.img}></img>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Partners;
