import React from 'react';
//import useUserInfo from "../../hooks/useUserInfo";
import { AiOutlineCloseCircle } from 'react-icons/ai';
import useLogout from '../../hooks/useLogout';
import { Link } from 'react-router-dom';
import '../../CSS/MobileApp/M_MobileUserDrop.css';
import '../../CSS/MobileApp/M_HeaderNav.css';

function MobileUserDrop({ setDisplayUserProfile, auth }) {
  //const { username } = useUserInfo();
  const logout = useLogout();
  function close() {
    setDisplayUserProfile(false);
  }
  async function handleLogOut() {
    await logout();

    close();
  }
  return (
    <div className='dropdown-menu-container'>
      <div className='dropdown-overlay'></div>
      <div className='dropdown-menu user-profile'>
        <AiOutlineCloseCircle onClick={close} className='close-button' />
        <div className='profile-options'>
          <div>
            <h2>Hi {auth?.rep?.substring(0, auth?.rep?.indexOf(' ')) || auth?.rep}!</h2>
            <h3 onClick={handleLogOut}>Log Out</h3>
          </div>
          <div className='profile-button-div'>
            {auth?.roles?.includes(3500) && (
              <Link to='/employee-portal' onClick={close}>
                <button className='submit'>Employee Portal</button>
              </Link>
            )}
            {auth?.roles?.includes(1984) && (
              <Link to='/edit' onClick={close}>
                <button className='submit'>Editor</button>
              </Link>
            )}
            {auth?.roles?.includes(5150) && (
              <Link to='/admin' onClick={close}>
                <button className='submit'>Admin</button>
              </Link>
            )}
          </div>

          <Link to='/track-order' onClick={close}>
            <h4>TRACK YOUR ORDER</h4>
          </Link>
          <Link to='/saved-quotes' onClick={close}>
            <h4>MY QUOTES</h4>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MobileUserDrop;
