import React from "react";
import EditTemplate from "../EditorPortal/EditTemplate";

const NondealerRequests = () => {
  const columns = [
    {
      type: "text",
      name: "ID",
      searchAllowed: true,
      editable: false,
      display: false,
      table: "nondealer_requests",
      fieldName: "ID",
    },
    {
      type: "text",
      name: "Name",
      searchAllowed: true,
      editable: true,
      display: true,
      table: "nondealer_requests",
      fieldName: "name",
    },
    {
      type: "text",
      name: "Company",
      searchAllowed: true,
      editable: true,
      display: true,
      table: "nondealer_requests",
      fieldName: "company",
    },
    {
      type: "text",
      name: "Phone",
      searchAllowed: true,
      editable: true,
      display: true,
      table: "nondealer_requests",
      fieldName: "phone",
    },
    {
      type: "text",
      name: "Email",
      searchAllowed: true,
      editable: true,
      display: true,
      table: "nondealer_requests",
      fieldName: "email",
    },
    {
      type: "text",
      name: "Selection",
      searchAllowed: true,
      editable: false,
      display: false,
      table: "nondealer_requests",
      fieldName: "selection",
    },
    {
      type: "text",
      name: "Comments",
      searchAllowed: true,
      editable: true,
      display: true,
      table: "nondealer_requests",
      fieldName: "comments",
    },
    {
      type: "datetime",
      name: "Date Submitted",
      searchAllowed: true,
      editable: true,
      display: true,
      table: "nondealer_requests",
      fieldName: "date_submitted",
    },
    {
      type: "boolean",
      name: "Response Sent",
      searchAllowed: true,
      editable: true,
      display: true,
      table: "nondealer_requests",
      fieldName: "responded",
    },
  ];

  return (
    <EditTemplate
      columns={columns}
      getEndPoint={"getGenericData"}
      title={"Nondealer Requests"}
      table={"nondealer_requests"}
      exportAvailable={true}
      // importBatch={true}
      // exportTemplate={true}
      // importUpdate={true}
      addHandlerIncoming={null}
    />
  );
};

export default NondealerRequests;
