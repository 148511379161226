import React, { useEffect, useState } from 'react';
import '../../CSS/Returns.css';
import Axios from 'axios';
// eslint-disable-next-line no-undef
const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;

const banner =
  'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688650800/Website%20Assets/Banners/Returns_wjltiq.jpg';

const Returns = () => {
  const [terms, setTerms] = useState();

  useEffect(() => {
    document.title = `Returns - National Public Seating`;
    window.scrollTo(0, 0);
  });

  function getTerms() {
    Axios.get(`${apiAddress}/content/getTerms`)
      .then((response) => {
        setTerms(response.data.filter((i) => i.title === 'RETURNS' || i.title === 'CANCELLATIONS'));
      })
      .catch((err) => console.error(err));
  }

  useEffect(() => {
    getTerms();
  }, []);

  return (
    <div className='returns'>
      <div className='banner'>
        <img src={banner}></img>
      </div>
      <div className=' inner-padding'></div>
      {terms?.map((i) => (
        <>
          <h3>{i.title}</h3>
          <div className='underline'></div>
          <div className='returnDiv'>
            <img src={i.image_link}></img>
            <div className='returnText'>{i.details}</div>
          </div>
        </>
      ))}
    </div>
  );
};

export default Returns;
