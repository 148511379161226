import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useUserInfo from '../../hooks/useUserInfo';
import useAuth from '../../hooks/useAuth';

const Register = () => {

  const { setDisplayBoolean, setDisplayLogin } = useUserInfo();
  const navigate = useNavigate();
  const { auth } = useAuth();
  useEffect(() => {
    if (!auth?.rep) {
      setDisplayBoolean(true);
      setDisplayLogin(false);
    }

    navigate('/');
  });

  return null;
};

export default Register;
