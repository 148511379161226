import EnvImage from './EnvImage';
import { useSelector } from 'react-redux';

const EnvImages = ({ imageList, envFilter, clickHandler }) => {
  const mobileScreen = useSelector((state) => state.isMobile.value.isMobile);
  const midpoint = Math.round(imageList?.length / 2);
  const thirdLength = Math.round(imageList?.length / 3);
  const secondMidpoint = thirdLength * 2;

  return (
    <div className='envImages'>
      {!mobileScreen ? (
        <>
          <div>
            {imageList?.slice(0, thirdLength).map((i, index) => (
              <EnvImage
                key={i.image + index}
                i={i}
                clickHandler={clickHandler}
                filter={envFilter}
              />
            ))}
          </div>
          <div>
            {imageList?.slice(thirdLength, secondMidpoint).map((i, index) => (
              <EnvImage
                key={i.image + index}
                i={i}
                clickHandler={clickHandler}
                filter={envFilter}
              />
            ))}
          </div>
          <div>
            {imageList?.slice(secondMidpoint).map((i, index) => (
              <EnvImage
                key={i.image + index}
                i={i}
                clickHandler={clickHandler}
                filter={envFilter}
              />
            ))}
          </div>
        </>
      ) : (
        <>
          <div className='env-slice-div'>
            {imageList?.slice(0, midpoint).map((i, index) => (
              <EnvImage
                key={i.image + index}
                i={i}
                clickHandler={clickHandler}
                filter={envFilter}
              />
            ))}
          </div>
          <div className='env-slice-div'>
            {imageList?.slice(midpoint).map((i, index) => (
              <EnvImage
                key={i.image + index}
                i={i}
                clickHandler={clickHandler}
                filter={envFilter}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default EnvImages;
