import React, { useState } from 'react';

const ImageWithHover = ({ topUse, rightUse, src, swatch = false }) => {
  const [hover, setHover] = useState(false);
  return (
    <div style={{ position: 'relative' }}>
      <div>
        {hover ? (
          <div
            style={{
              position: 'fixed',
              top: `${topUse}px`,
              left: `${rightUse}px`,
              boxShadow: '1px 5px 5px grey',
              background: 'white',
              zIndex: 17,
              height: '250px',
            }}
          >
            <img
              src={
                src?.startsWith('v')
                  ? `https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/${src?.replace(
                      '.pdf',
                      '.jpg'
                    )}`
                  : src?.replace('.pdf', '.jpg')
              }
              alt={`(${swatch?.value})`}
              style={{
                height: '250px',
                width: '250px',
                objectFit: swatch ? 'cover' : 'contain',
              }}
            ></img>{' '}
            <div
              style={{
                zIndex: 17,
                width: '250px',
                textAlign: 'center',
                color: 'white',
                background: 'black',
                position: 'absolute',
                top: '7rem',
                font: 'normal normal normal 13px / 25px poppins',
              }}
            >
              {' '}
              {swatch ? `${swatch?.name} (${swatch?.value})` : ''}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
      {src && (
        <img
          onClick={() => {
            window.open(
              src?.startsWith('v')
                ? `https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/${src}`
                : src
            );
          }}
          src={
            src?.startsWith('v')
              ? `https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/${src?.replace(
                  '.pdf',
                  '.jpg'
                )}`
              : src?.replace('.pdf', '.jpg')
          }
          style={{
            height: '45px',
            width: '45px',
            objectFit: swatch ? 'cover' : 'contain',
            cursor: 'pointer',
          }}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
        ></img>
      )}
    </div>
  );
};

export default ImageWithHover;
