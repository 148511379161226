import { useState } from 'react';
import '../../CSS/DetailsDisplay.css';
const SellSheetCube = ({ clickHandler, text, img = false }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      className='media-cube'
      onClick={clickHandler}
      onMouseOver={() => {
        setHover(true);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
      style={{ justifyContent: 'space-between' }}
    >
      <img
        src={img}
        style={{ height: '5rem' }}
      ></img>
      <h3
        style={{
          width: '7rem',
          fontWeight: `${hover ? '600' : '500'}`,
          margin: '0',
        }}
      >
        {text}
      </h3>
    </div>
  );
};

export default SellSheetCube;
