import React, { useEffect, useState } from 'react';
import '../../CSS/DealerLogin.css';
import '../../CSS/MobileApp/M_DealerLogin.css';
import { AiOutlineCloseCircle } from 'react-icons/ai';
// import { BsTextIndentLeft } from 'react-icons/bs'
import LoginForm from './LoginForm';
import SignupForm from './SignupForm';
import useUserInfo from '../../hooks/useUserInfo';
import useAuth from '../../hooks/useAuth';
import useLogout from '../../hooks/useLogout';
import HelpForm from './HelpForm';
import Registered from './Registered';
import { AdvancedImage } from '@cloudinary/react';
import FormatImageLink from '../Shared/FormatImageLink';
import { useSearchParams } from 'react-router-dom';
import useAxiosJWT from '../../hooks/useAxiosJWT';
const DealerLogin = () => {
  const {
    displayBoolean,
    setDisplayBoolean,
    displayLogin,
    setDisplayLogin,

    username,
    setUsername,
    password,
    setPassword,
  } = useUserInfo();
  const { auth } = useAuth();
  const axiosJWT = useAxiosJWT();
  const logout = useLogout();
  const [showSignup, setShowSignup] = useState();
  const [showHelp, setShowHelp] = useState();
  const [showRegistered, setShowRegistered] = useState();

  const [form, setForm] = useState('');
  const [invite, setInvite] = useState();
  const [searchParams] = useSearchParams();
  const [linkFailed, setLinkFailed] = useState(false);
  const [logoutMessage, setLogoutMessage] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const token = searchParams.get('token');

  async function getTokenInfo(token) {
    await axiosJWT
      .post(`register/findToken`, {
        token: token,
      })
      .then((res) => {
        if (res.data) {
          if (auth?.rep) {
            setLogoutMessage(true);
            setDisplayLogin(true);
          } else {
            setShowSignup(true);
            setInvite(res.data);
          }
        } else {
          setLinkFailed(true);
        }
      });
  }
  useEffect(() => {
    if (token) {
      getTokenInfo(token);
    }
  }, []);

  function close() {
    setDisplayBoolean(false);
    setUsername('');
    setPassword('');
    setDisplayLogin(true);
    setLinkFailed(false);
    setLogoutMessage(false);
    setShowHelp(false);
    setShowRegistered(false);
    setRequestSent(false);
    setErrMsg('');
  }
  const signOut = async () => {
    await logout();
  };
  const decodeAndFormat = FormatImageLink();
  return (
    <div className={`dealer-login ${displayBoolean ? '' : 'close'}`}>
      <div className={`dealer-overlay ${displayBoolean ? 'show-overlay' : 'close'}`}>
        <div className={`login-popup ${displayBoolean ? 'open' : 'close'}`}>
          <input
            type='checkbox'
            id='show'
            checked={displayBoolean}
            onChange={(e) => {
              setDisplayBoolean(e.target.checked);
            }}
          ></input>
          <input
            type='checkbox'
            id='login'
            checked={displayLogin}
            onChange={(e) => {
              setDisplayLogin(e.target.checked);
            }}
          ></input>
          <AiOutlineCloseCircle onClick={close} className='close-button' />

          <div className={`inner-login-div${displayLogin ? '' : ' reverse'}`}>
            <div className={`login-image-div${displayLogin ? '' : ' reverse'}`}>
              {showRegistered || showHelp ? (
                <AdvancedImage
                  style={{
                    position: 'absolute',
                    left: '120px',
                    height: '13rem',
                  }}
                  cldImg={decodeAndFormat(
                    'v1688653587/Website Assets/SVGs/NPS_Logo_White_dhtqxv.svg'
                  )}
                />
              ) : (
                <div>
                  {' '}
                  <h1>{displayLogin ? 'Welcome to the Dealer Portal' : 'Join Us Today!'}</h1>
                  <h3>
                    {' '}
                    {displayLogin
                      ? 'Find everthing you need here!'
                      : 'Create an Account to be Part of our Dealer Team'}
                  </h3>
                  <div className='underline'></div>
                </div>
              )}
            </div>
            <div
              style={{
                alignSelf:
                  !showRegistered && !displayLogin && ((!showSignup && !showHelp) || showHelp)
                    ? 'start'
                    : ' ',
                marginTop:
                  !showRegistered && !displayLogin && ((!showSignup && !showHelp) || showHelp)
                    ? '20px'
                    : '',
                width: showRegistered ? '70%' : ' ',
              }}
              className='login-input-div'
            >
              <h2>
                {displayLogin
                  ? auth?.rep
                    ? `Welcome ${auth?.rep.substring(0, auth?.rep.indexOf(' '))}!`
                    : 'Login'
                  : showRegistered && form === 'showHelp'
                    ? 'Thank you for reaching out to NPS!'
                    : showRegistered && form === 'showSignup'
                      ? 'Thank you for creating an account and joining NPS!'
                      : showHelp
                        ? 'Let Us Help You!'
                        : // : linkFailed
                          // ? ""
                          'Register'}
              </h2>
              <div className='underline'></div>
              {displayLogin ? (
                auth?.rep ? (
                  <>
                    {logoutMessage ? (
                      <div style={{ width: '31rem', textWrap: 'wrap' }} className='text'>
                        Please logout before registering for a new account.
                      </div>
                    ) : (
                      ''
                    )}
                    <button onClick={signOut}>Sign Out</button>
                  </>
                ) : (
                  <LoginForm
                    username={username}
                    setUsername={setUsername}
                    password={password}
                    setPassword={setPassword}
                    setDisplayLogin={setDisplayLogin}
                    setDisplayBoolean={setDisplayBoolean}
                    setShowSignup={setShowSignup}
                    setShowHelp={setShowHelp}
                    requestSent={requestSent}
                    setRequestSent={setRequestSent}
                    errMsg={errMsg}
                    setErrMsg={setErrMsg}
                  />
                )
              ) : !showRegistered && !showSignup && !showHelp ? (
                <div className='register-div'>
                  {linkFailed ? (
                    <div style={{ width: '31rem', textWrap: 'wrap' }} className='text'>
                      This link has expired. Please contact your manager if a register link is still
                      needed.
                    </div>
                  ) : (
                    <>
                      <div className='text'>Are you an existing NPS Dealer?</div>
                      <div style={{ display: 'flex' }}>
                        <button
                          onClick={() => setShowSignup(true)}
                          className='submit dealer-button'
                        >
                          YES
                        </button>
                        <button onClick={() => setShowHelp(true)} className='submit dealer-button'>
                          NO
                        </button>
                      </div>
                    </>
                  )}
                </div>
              ) : showSignup ? (
                <SignupForm
                  username={username}
                  setUsername={setUsername}
                  password={password}
                  setPassword={setPassword}
                  setDisplayLogin={setDisplayLogin}
                  setDisplayBoolean={setDisplayBoolean}
                  setShowRegistered={setShowRegistered}
                  setShowSignup={setShowSignup}
                  setForm={setForm}
                  invite={invite}
                />
              ) : showHelp ? (
                <HelpForm
                  setDisplayBoolean={setDisplayBoolean}
                  setShowRegistered={setShowRegistered}
                  setShowHelp={setShowHelp}
                  setForm={setForm}
                />
              ) : showRegistered ? (
                <Registered
                  setDisplayBoolean={setDisplayBoolean}
                  setDisplayLogin={setDisplayLogin}
                  setShowRegistered={setShowRegistered}
                  form={form}
                />
              ) : (
                ' '
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DealerLogin;
