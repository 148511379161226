import React, { useEffect, useState } from 'react';
// import ImageWithHover from "../Components/EditorPortal/ImageWithHover";
import useAxiosJWT from '../hooks/useAxiosJWT';
import UploadFile from '../Components/Shared/UploadFile';
const TestCloudinary = () => {
  const [images, setImages] = useState();
  const [searchVal, setSearchVal] = useState('');
  const [searchUrl, setSearchUrl] = useState('');
  // const [replaceUrl, setReplaceUrl] = useState([]);
  // Define your metadata query parameters
  const [type, setType] = useState('sku');
  const [metadataImport, setMetadataImport] = useState();

  useEffect(() => {
    // console.log(metadataImport);
    updateMetadata();
  }, [metadataImport]);
  const axiosJWT = useAxiosJWT();
  function getData() {
    axiosJWT
      .post(`/cloudinary/getImagesBy${type}`, { [type]: searchVal })
      .then((response) => {
        // console.log(response.data);
        if (type === 'series') {
          setImages(response.data.map((resource) => resource.link));
          // const imageTypes = response.data.map((resource) => resource.type);
          // console.log("Image Types:", imageTypes);
        } else {
          setImages(response.data);
        }
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  // function replaceCloudinaryAsset() {
  //   const formData = new FormData();
  //   Object.values(replaceUrl).forEach((file) => {
  //     formData.append('arrayOfFiles', file);
  //   });
  //   formData.append('urlToBeReplaced', searchUrl);
  //   axiosJWT
  //     .post(`/cloudinary/replaceCloudinaryAsset`, formData, {
  //       headers: {
  //         'Content-Type': 'multipart/form-data',
  //       },
  //     })
  //     .then((response) => {
  //       console.log(response);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }

  function removeMetadata() {
    axiosJWT
      .post(`/cloudinary/removeMetadata`, { imageLinks: [searchUrl] })
      .then((response) => {
        console.error(response);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function getMetadata() {
    axiosJWT
      .post(`/cloudinary/getMetadata`, { link: searchUrl })
      .then((response) => {
        console.error(response);
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function updateMetadata() {
    // metadataImport?.slice(1)?.map(async ({ Image_link, ...rest }) => {
    // console.log({ ...rest });
    // await axiosJWT
    //   .post(`/cloudinary/updateMetadata`, {
    //     metadata: { ...rest, brand_wa3y7u: ["nps"] },
    //     imageLink: Image_link,
    //   })
    //   .then((response) => {
    //     console.log(response);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    axiosJWT
      .post(`/cloudinary/updateMetadata`, {
        metadataImport: metadataImport,
      })
      .then((response) => {
        console.error(response);
      })
      .catch((err) => {
        console.error(err);
      });
    // });
  }
  return (
    <div className='inner-padding'>
      <input
        value={searchVal}
        onChange={(e) => {
          setSearchVal(e.target.value);
        }}
        placeholder='Enter sku to search'
      ></input>
      <button onClick={getData}>test</button>
      <button
        onClick={() => {
          setType('sku');
        }}
        style={{ backgroundColor: type === 'sku' ? 'green' : 'lightgrey' }}
      >
        Sku
      </button>
      <button
        onClick={() => {
          setType('series');
        }}
        style={{ backgroundColor: type === 'series' ? 'green' : 'lightgrey' }}
      >
        Series
      </button>
      <div>
        {images?.map((i, index) => {
          return (
            <img
              key={index}
              src={`https://res.cloudinary.com/da3rom333/image/upload/${i}`}
              style={{ height: '4rem', width: '4rem' }}
            ></img>
          );
        })}
      </div>
      <div>
        <input
          type='text'
          value={searchUrl}
          onChange={(e) => {
            setSearchUrl(e.target.value);
          }}
          style={{ width: '25rem' }}
        ></input>

        <img src={searchUrl || ''} style={{ height: '2rem' }}></img>
        {/* {searchUrl && <ImageWithHover src={searchUrl} />}
        <input
          type="file"
          // value={`${replaceUrl}`}
          onChange={(e) => {
            console.log(e.target.files[0], 95, e.target.files[0].path);
            setReplaceUrl([e.target.files[0]]);
          }}
        ></input> */}
        <button onClick={removeMetadata}>Remove Metadata</button>
        <button onClick={updateMetadata}>Update Metadata</button>
        <button onClick={getMetadata}>Get Metadata</button>
        <UploadFile text={'Import Metadata'} setData={setMetadataImport} />
      </div>
    </div>
  );
};

export default TestCloudinary;
