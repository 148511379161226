import { AdvancedImage } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
// import { scale } from "@cloudinary/url-gen/actions/resize";

const OptionsCube = ({ option, optionType, optionsSelected, setOptionsSelected }) => {
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'da3rom333',
    },
  });

  function clicked() {
    setOptionsSelected({
      ...optionsSelected,
      [optionType.name]: option.selectionName,
    });
  }

  const myImage = cld.image(option.imageLink);
  return (
    <div
      className={`option-cube 
      
      ${optionsSelected[optionType.name] === option.selectionName ? 'option-cube-clicked' : ''}
       `}
      onClick={() => {
        clicked();
      }}
      style={{
        cursor: 'pointer',
      }}
    >
      {/* <div className="img-div"> */}
      <AdvancedImage
        cldImg={myImage}
        // plugins={[lazyload()]}
        style={{
          cursor: 'pointer',
          opacity: '1',
        }}
      />
      {/* </div> */}
      <div className='h4-div'>{/* <h4>{option.selectionName}</h4> */}</div>
    </div>
  );
};

export default OptionsCube;
