import React, { useEffect, useState } from 'react';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import FilterOptions from './FilterOptions';
import { PiArrowsDownUpFill } from 'react-icons/pi';
import { MdFilterListAlt } from 'react-icons/md';

function ItemTableHeader({
  columns,
  allRows,
  filter,
  setFilter,
  sort,
  setSort,
  setLoading,
  allowAdd,
}) {
  const [openFilter, setOpenFilter] = useState();
  const [filterOptions, setFilterOptions] = useState();

  useEffect(() => {
    setLoading(true);
    let options = {};
    allRows.forEach((row) => {
      columns.forEach((c) => {
        if (c.filterable) {
          if (c.type === 'boolean') {
            if (!options[c.value]) {
              options[c.value] = ['true', 'false'];
            }
          } else if (!options[c.value]) {
            options[c.value] = [row[c.value]];
          } else if (
            (typeof row[c.value] !== 'object' && !options[c.value].includes(row[c.value])) ||
            (typeof row[c.value] === 'object' &&
              row[c.value]?.name &&
              !options[c.value].find((o) => o?.name === row[c.value]?.name))
          ) {
            options[c.value] = [...options[c.value], row[c.value]];
          }
        }
      });
    });
    setFilterOptions(options);
  }, [allRows]);

  useEffect(() => {
    if (filterOptions) {
      setLoading(false);
    }
  }, [filterOptions]);

  return (
    <tr className='item-table-head'>
      {columns.map((c, i) => (
        <th key={i}>
          <span className='column-title'>
            {c.title}
            {c.filterable ? (
              openFilter === c.title ? (
                <>
                  <span
                    className='expand'
                    onClick={() => {
                      setOpenFilter();
                    }}
                  >
                    <FiChevronUp />
                    {sort?.column === c.value && <PiArrowsDownUpFill />}
                    {filter[c.value] && <MdFilterListAlt />}
                  </span>
                  <FilterOptions
                    filterBy={c.value}
                    filter={filter}
                    setFilter={setFilter}
                    setOpenFilter={setOpenFilter}
                    options={filterOptions[c.value]}
                    sort={sort}
                    setSort={setSort}
                  />
                </>
              ) : (
                <span
                  className='expand'
                  onClick={() => {
                    setOpenFilter(c.title);
                  }}
                >
                  <FiChevronDown />
                  {sort?.column === c.value && <PiArrowsDownUpFill />}
                  {filter[c.value] && <MdFilterListAlt />}
                </span>
              )
            ) : (
              ''
            )}
          </span>
        </th>
      ))}
      {allowAdd ? (
        <th
          style={{
            width: '4rem',
            position: 'sticky',
            zIndex: 1,
            right: '-1px',
          }}
        ></th>
      ) : (
        ''
      )}
    </tr>
  );
}

export default ItemTableHeader;
