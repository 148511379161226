import { useState, useEffect } from 'react';
import { AdvancedImage } from '@cloudinary/react';
import { scale } from '@cloudinary/url-gen/actions/resize';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import FormatImageLink from '../Shared/FormatImageLink';
import { useSelector } from 'react-redux';
import { LiaSearchSolid } from 'react-icons/lia';

const AddtoCart = ({
  modelNum,
  setModelNum,
  quantity,
  setQuantity,
  addToCart,
  skuList,
  getFinishes,
  searchVal,
  setSearchVal,
  resetValues,
  setAddClicked,
}) => {
  const [showDrop, setShowDrop] = useState(false);
  const mobileScreen = useSelector((state) => state.isMobile.value.isMobile);
  const [indexFocus, setIndexFocus] = useState(-1);
  const [isActive, setIsActive] = useState(false);
  const decodeAndFormat = FormatImageLink();

  function handleAdd() {
    if (modelNum.BaseModelNum.toString().includes('x')) {
      getFinishes();
    } else {
      addToCart(
        modelNum.FreightQuoteModelNum,
        quantity,
        modelNum.ModelNum,
        modelNum.ShortDescription,
        modelNum.imageLink,
        false,
        modelNum.productInfo ? modelNum.productInfo.ModelNum : modelNum.BaseModelNum,
        parseInt(modelNum.uom) * parseInt(quantity),
        parseInt(modelNum.uom),
        modelNum.ShipsNonBoxed,
        false
      );
      if (quantity) {
        resetValues();
      }
    }
  }
  useEffect(() => {
    if (mobileScreen && quantity === 1) {
      handleAdd();
    }
  }, [quantity]);

  return (
    <>
      {!mobileScreen ? (
        <div className='search-sku-div'>
          <div className='flex-left'>
            <h3>Search by SKU</h3>
            <div className='underline'></div>
            <div className='search'>
              <h4>Add SKU</h4>
              <div className='modelNum-input'>
                <input
                  type='text'
                  name='searchVal'
                  placeholder='Search SKU'
                  id='searchVal'
                  value={modelNum ? modelNum.BaseModelNum : searchVal}
                  onChange={(e) => {
                    if (modelNum?.ModelNum) {
                      setModelNum('');
                    }
                    setSearchVal(e.target.value);
                  }}
                  onFocus={() => {
                    setShowDrop(true);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      const findSearchVal = skuList?.filter((i) => {
                        return (
                          i.BaseModelNum?.toString()?.toUpperCase() ===
                          searchVal?.toString()?.toUpperCase()
                        );
                      });
                      if (findSearchVal) {
                        setModelNum(findSearchVal[0]);
                        console.log('findSearchVal[0]', findSearchVal[0]);
                        setShowDrop(false);
                      }
                    }
                  }}
                  required
                ></input>

                {skuList && searchVal && showDrop ? (
                  <div className='modelNum-drop-div'>
                    <div className='modelNum-dropdown'>
                      {skuList
                        ?.filter((i) => {
                          return i.BaseModelNum?.toString()
                            ?.toUpperCase()
                            ?.includes(searchVal?.toString()?.toUpperCase());
                        })
                        ?.map((i, index) => {
                          return (
                            <div
                              key={index}
                              className={`modelNum-list-item ${
                                index === indexFocus ? 'list-item-focus' : ''
                              }`}
                              onClick={() => {
                                setModelNum(i);
                                setShowDrop(false);
                              }}
                            >
                              {i.BaseModelNum}: {i.ShortDescription}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <div
                className='minus'
                onClick={() => {
                  if (quantity) {
                    setQuantity(quantity - 1);
                  }
                }}
              >
                -
              </div>
              <div className='quantity-input'>
                <input
                  type='integer'
                  name='quantity'
                  placeholder='Quantity'
                  id='quantity'
                  value={quantity}
                  onChange={(e) => {
                    console.log(e);
                    setQuantity(parseInt(e.target.value));
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleAdd();
                    }
                  }}
                  required
                ></input>
              </div>
              <div
                className='plus'
                onClick={() => {
                  if (quantity) {
                    setQuantity(quantity + 1);
                  } else {
                    setQuantity(1);
                  }
                }}
              >
                +
              </div>
              <button
                className='addToCart'
                onClick={handleAdd}
              >
                Add
              </button>
            </div>
            <div className='search-description'>{modelNum ? modelNum.ShortDescription : ''}</div>
          </div>
          <div className='flex-right'>
            {modelNum ? (
              <AdvancedImage
                cldImg={decodeAndFormat(
                  modelNum.imageLink
                    ? modelNum.imageLink
                    : 'https://res.cloudinary.com/da3rom333/image/upload/v1678460286/Misc%20Assets/image-coming-soon_lzhzdk.jpg'
                ).resize(scale().width(250))}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      ) : (
        //mobile design
        <div className='search-sku-div'>
          <div className='innerSkuSearch'>
            <h3>Search by SKU</h3>
            <div className='search'>
              <div
                className={`modelNum-input searchValInput ${isActive ? 'typingSearchBox' : ''} `}
              >
                <div className='searchIcon'>
                  <LiaSearchSolid />
                </div>
                <input
                  type='text'
                  name='searchVal'
                  placeholder='SKU'
                  id='searchVal'
                  value={modelNum ? modelNum.BaseModelNum : searchVal}
                  onChange={(e) => {
                    if (modelNum?.ModelNum) {
                      setModelNum('');
                    }
                    setSearchVal(e.target.value);
                  }}
                  onFocus={() => {
                    setShowDrop(true);
                    setIsActive(true);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      const findSearchVal = skuList?.filter((i) => {
                        return (
                          i.BaseModelNum?.toString()?.toUpperCase() ===
                          searchVal?.toString()?.toUpperCase()
                        );
                      });
                      if (findSearchVal) {
                        setModelNum(findSearchVal[0]);
                        setShowDrop(false);
                      }
                    }
                  }}
                  required
                ></input>
                {skuList && searchVal && showDrop ? (
                  <div className='modelNum-drop-div'>
                    <div className='modelNum-dropdown'>
                      {skuList
                        ?.filter((i) => {
                          return i.BaseModelNum?.toString()
                            ?.toUpperCase()
                            ?.includes(searchVal?.toString()?.toUpperCase());
                        })
                        ?.map((i, index) => {
                          return (
                            <div
                              key={index}
                              className={`modelNum-list-item ${
                                index === indexFocus ? 'list-item-focus' : ''
                              }`}
                              onClick={() => {
                                setModelNum(i);
                                setShowDrop(false);
                              }}
                            >
                              {i.BaseModelNum}: {i.ShortDescription}
                            </div>
                          );
                        })}
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
              <button
                className='addToCart'
                onClick={() => {
                  if (modelNum) {
                    setQuantity(1);
                  }
                  setAddClicked(true);
                }}
              >
                Add
              </button>
            </div>
            <div className={modelNum ? 'search-description' : ''}>
              {modelNum ? modelNum.ShortDescription : ''}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AddtoCart;
