import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { IconContext } from 'react-icons';
import { SlArrowRight, SlArrowLeft } from 'react-icons/sl';
import MobileDropMenu from './MobileDropMenu';

const MobileMenuItem = ({ menuItem, setShowDropdownMenu, globalMenuData, trail, special }) => {
  const [currentMenu, setCurrentMenu] = useState('true');
  const [displayType, setDisplayType] = useState('');
  const [categoryTitle, setCategoryTitle] = useState('');
  const [secondarySublinks, setSecSublinks] = useState([]);

  function getItemDisplayType(menuItem) {
    if (trail) {
      setDisplayType('closes');
    } else if (
      menuItem.sublinks &&
      !(menuItem.title === 'ENVIRONMENTS') &&
      !(menuItem.tab === 'Dealer' && menuItem.sublinks.length === 1)
    ) {
      setDisplayType('opens');
    } else {
      setDisplayType('link');
    }
  }

  function openNextMenu() {
    setCurrentMenu('next');
    if (displayType === 'opens') {
      setCategoryTitle(menuItem);
      setSecSublinks(menuItem.secondarySublinks);
    }
  }
  function closeCurrentMenu() {
    setCurrentMenu('prev');
  }

  function getLink(menuItem) {
    let link = '';
    if (menuItem.tab === 'About') {
      link = menuItem.tab + '/' + menuItem.link;
    } else if (menuItem.title === 'ENVIRONMENTS') {
      link = '/environments';
    } else if (menuItem.tab === 'Products') {
      link = `/products/${encodeURIComponent(menuItem.title)}`;
    } else {
      link = '/' + menuItem.link;
    }

    return link;
  }
  function closeDropDown() {
    setShowDropdownMenu(false);
  }

  useEffect(() => {
    getItemDisplayType(menuItem);
  }, [menuItem]);

  return (
    <div>
      {currentMenu === 'true' ? (
        <li
          className='nav-item'
          key={menuItem.title}
        >
          {displayType === 'opens' ? (
            <div
              className={`nav-item-a ${special}`}
              onClick={openNextMenu}
            >
              {menuItem.title}
              <IconContext.Provider
                value={{ color: 'white', size: '4rem', className: 'dropdown-menu-arrow-right' }}
              >
                <SlArrowRight />
              </IconContext.Provider>
            </div>
          ) : (
            <div>
              {displayType === 'closes' ? (
                <div
                  className={`nav-item-a dropdown-head ${menuItem.title === '' ? 'hide' : ''}`}
                  onClick={closeCurrentMenu}
                >
                  <IconContext.Provider
                    value={{ color: 'white', size: '4rem', className: 'dropdown-menu-arrow-left' }}
                  >
                    <SlArrowLeft></SlArrowLeft>
                  </IconContext.Provider>
                  {menuItem.title}
                </div>
              ) : special === 'NewProducts' ? (
                <Link
                  to='/new'
                  className='nav-item-a special'
                  onClick={closeDropDown}
                >
                  <div className='product-cat-div'>
                    <img
                      src='https://res.cloudinary.com/da3rom333/image/upload/v1693417171/Website%20Assets/SVGs/New_Products_wtppt8.svg'
                      alt=''
                      className='dropdown-specials-icon new-products-icon'
                    />
                    {menuItem.title}
                  </div>
                </Link>
              ) : special === 'QuickShip' ? (
                <Link
                  to='/quickship'
                  className='nav-item-a special'
                  onClick={closeDropDown}
                >
                  <div className='product-cat-div'>
                    <img
                      src='https://res.cloudinary.com/da3rom333/image/upload/v1693417171/Website%20Assets/SVGs/Quick_Ship_itheic.svg'
                      alt=''
                      className='dropdown-specials-icon quick-ship-icon'
                    />
                    {menuItem.title}
                  </div>
                </Link>
              ) : (
                <Link
                  to={getLink(menuItem)}
                  className={`nav-item-a ${special}`}
                  onClick={closeDropDown}
                >
                  {menuItem.title}
                </Link>
              )}
            </div>
          )}
        </li>
      ) : (
        <div>
          {currentMenu === 'next' ? (
            <>
              <MobileDropMenu
                menuData={menuItem.sublinks}
                globalMenuData={globalMenuData}
                categoryTitle={categoryTitle}
                setShowDropdownMenu={setShowDropdownMenu}
                secondarySublinks={secondarySublinks}
              ></MobileDropMenu>
            </>
          ) : (
            <MobileDropMenu
              menuData={trail[trail.length - 2].sublinks || {}}
              globalMenuData={globalMenuData}
              categoryTitle={trail[trail.length - 2] || {}}
              setShowDropdownMenu={setShowDropdownMenu}
              secondarySublinks={trail[trail.length - 2].secondarySublinks || ''}
            ></MobileDropMenu>
          )}
        </div>
      )}
    </div>
  );
};

export default MobileMenuItem;
