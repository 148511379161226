import { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { CgSoftwareDownload } from 'react-icons/cg';
import { AdvancedImage } from '@cloudinary/react';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { AiOutlineLink } from 'react-icons/ai';
import clipboardCopy from 'clipboard-copy';
import FormatImageLink from '../Shared/FormatImageLink';

const MediaTile = ({
  image,
  addToSelected,
  removeFromSelected,
  clearList,
  selectedList,
  listItems,
  applyEffects,
  imageEffects,
}) => {
  const [selected, setSelected] = useState(false);
  const [copied, setCopied] = useState(false);
  const decodeAndFormat = FormatImageLink();
  const imgData = image?.imageLink?.split('/');
  const seriesName = imgData[imgData.length - 2];

  function handleClick() {
    if (!selected) {
      addToSelected(image);
    } else {
      removeFromSelected(image);
    }
  }

  const createFilters = async () => {
    if (
      Object.keys(imageEffects)
        .filter((i) => i !== 'type')
        .filter((i) => imageEffects[i] !== '').length > 0
    ) {
      let string =
        '/' +
        Object.keys(imageEffects)
          .filter((i) => i !== 'type')
          .filter((i) => imageEffects[i] !== '')
          .map((key) => {
            return `${key}_${imageEffects[key]}`;
          })
          .join(',');
      if (!Object.keys(imageEffects).includes('q')) {
        string = string + ',q_auto';
      }
      return string;
    }
    return '';
  };

  async function handleDownloadClick(item, type) {
    let filterString = '';

    if (applyEffects) {
      filterString = await createFilters();
    }

    try {
      let newImage;
      if (Object.keys(imageEffects).includes('type') && imageEffects['type'] !== '') {
        newImage =
          item.imageLink.substring(0, item.imageLink.lastIndexOf('.') + 1) + imageEffects['type'];
      } else {
        newImage = item.imageLink;
      }

      if (type === 'download') {
        window.open(
          `https://res.cloudinary.com/da3rom333/image/upload${filterString}/fl_attachment/${newImage}`,
          '_blank'
        );
      } else if (type === 'copy') {
        clipboardCopy(
          `https://res.cloudinary.com/da3rom333/image/upload${filterString}/${newImage}`
        );
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 400);
  }, [copied]);

  useEffect(() => {
    setSelected(false);
  }, [clearList]);

  useEffect(() => {
    if (
      selectedList?.some(
        (item) => item.imageLink === image.imageLink && item.modelNum === image.modelNum
      )
    ) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectedList]);
  return (
    <>
      {listItems ? (
        <div
          className='mediaListItem'
          style={{
            border: selected ? '2px solid var(--lightgreen)' : '1px solid #BBBBBA',
            cursor: 'pointer',
          }}
          onClick={handleClick}
        >
          <div
            onClick={handleClick}
            className='selection-box'
            style={{
              borderColor: selected ? '#004D44' : '#939393',
              background: selected ? '#004D44' : 'white',
            }}
          >
            {selected ? (
              <FaCheck style={{ color: 'white' }} />
            ) : (
              <div style={{ height: '16px', width: '16px' }}></div>
            )}
          </div>
          <div className='tile-img' onClick={handleClick}>
            <AdvancedImage cldImg={decodeAndFormat(image.imageLink).resize(scale().width(25))} />
          </div>
          <div className='skuTitle'>
            {seriesName} - {image.modelNum}
          </div>
          <div className='mediaTile-buttons'>
            <div className='download-img' onClick={() => handleDownloadClick(image, 'download')}>
              <CgSoftwareDownload />
            </div>
            <div
              className='download-img'
              onClick={() => {
                setCopied(true);
                handleDownloadClick(image, 'copy');
              }}
            >
              <AiOutlineLink />
              {copied ? (
                <div className='notice' style={{ left: '50%' }}>
                  Link Copied
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className='mediaTile'
          style={{
            border: selected ? '2px solid var(--lightgreen)' : '1px solid #BBBBBA',
            cursor: 'pointer',
          }}
        >
          <div className='skuTitle'>
            {seriesName} - {image.modelNum}
          </div>
          <div className='tile-img' onClick={handleClick}>
            <AdvancedImage cldImg={decodeAndFormat(image.imageLink).resize(scale().width(250))} />
          </div>
          <div className='mediaTile-buttons'>
            <div
              onClick={handleClick}
              className='selection-box'
              style={{
                borderColor: selected ? '#004D44' : '#939393',
                background: selected ? '#004D44' : 'white',
              }}
            >
              {selected ? <FaCheck style={{ color: 'white' }} /> : ''}
            </div>
            <div className='download-img' onClick={() => handleDownloadClick(image, 'download')}>
              <CgSoftwareDownload />
            </div>
            <div
              className='download-img'
              onClick={() => {
                setCopied(true);
                handleDownloadClick(image, 'copy');
              }}
            >
              <AiOutlineLink />
              {copied ? (
                <div className='notice' style={{ left: '50%' }}>
                  Link Copied
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MediaTile;
