import React, { useState, useEffect } from "react";
import "../../CSS/InstantFreight.css";
import "../../CSS/MobileApp/M_InstantFreight.css";
import { IoCloseCircleOutline } from "react-icons/io5";
import InstantFreightInput from "./InstantFreightInput";
import InstantFreightLoading from "./InstantFreightLoading";
import InstantFreightQuote from "./InstantFreightQuote";
import useAxiosJWT from "../../hooks/useAxiosJWT";
import { useSelector } from "react-redux";

import useLocalStorage from "../../hooks/useLocalStorage";
const InstantFreight = ({
  setFreightShow,
  // cart,
  // setCart,
  // updateQty,
}) => {
  const { product, optionsSelected, skuDisplayed } = useSelector(
    (state) => state.productState.value
  );
  const [quote, setQuote] = useState(false);
  const [loading, setLoading] = useState(false);
  const [zipCode, setZipCode] = useState("");
  const [quoteError, setQuoteError] = useState(false);
  const [nonboxed, setNonboxed] = useState(false);
  const [insideDelivery, setInsideDelivery] = useLocalStorage(
    "insideDelivery",
    false
  );
  const [liftgate, setLiftgate] = useLocalStorage("liftgate", false);
  const [limitedAccess, setLimitedAccess] = useLocalStorage(
    "limitedAccess",
    false
  );
  const [residentialDelivery, setResidentialDelivery] = useLocalStorage(
    "residentialDelivery",
    false
  );
  const [accesorialString, setAccesorialString] = useState("NNNNN");
  const [allItems, setAllItems] = useState(false);
  const [qty, setQty] = useState(1);
  const [notification, setNotification] = useState("");
  const axiosJWT = useAxiosJWT();
  useEffect(() => {
    // console.log(accesorialString);
    //First Value is always 'N'
    let tempString = "N";
    tempString =
      tempString +
      (insideDelivery ? "Y" : "N") +
      (liftgate ? "Y" : "N") +
      (limitedAccess ? "Y" : "N") +
      (residentialDelivery ? "Y" : "N");

    setAccesorialString(tempString);
  }, [insideDelivery, liftgate, limitedAccess, residentialDelivery]);

  function getFreight() {
    // e.preventDefault();
    setQuote();
    setLoading(true);

    axiosJWT
      .post("NPS/freightCart", {
        zipCode: zipCode,
        options: accesorialString,
        itemQty: 1,
        items: [
          {
            item: product.productInfo.FreightQuoteModelNum,
            qty: qty,
            description: product.productInfo.ShortDescription,
            image: product.cdnLinks[0]?.Image,
            displaySku: skuDisplayed,
            optionsSelected: optionsSelected,
            baseSku: product.productInfo.ModelNum,
            totalQty: parseInt(product.productInfo.Quantity) * parseInt(qty),
            isNonBoxed: nonboxed,
          },
        ],
      })
      .then((response) => {
        // console.log(response.data);
        setQuote(response.data);
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err.response.data);
        setQuoteError(err.response.data);
        setLoading(false);
      });
  }

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [notification]);

  return (
    <div className="instantFreight">
      <div
        className="modal"
        onClick={() => {
          setFreightShow(false);
        }}
      ></div>
      <div className="media-popup" style={{ top: quote ? "30%" : "20%" }}>
        <IoCloseCircleOutline
          className="closeButton"
          onClick={() => {
            setFreightShow(false);
          }}
        />
        <h2>Instant Freight Quote</h2>
        <div className="underline"></div>
        {notification ? (
          <div className="overlay-notice">{notification}</div>
        ) : (
          ""
        )}
        {!quote && !loading && !quoteError ? (
          <InstantFreightInput
            setQuote={setQuote}
            insideDelivery={insideDelivery}
            setInsideDelivery={setInsideDelivery}
            liftgate={liftgate}
            setLiftgate={setLiftgate}
            limitedAccess={limitedAccess}
            setLimitedAccess={setLimitedAccess}
            residentialDelivery={residentialDelivery}
            setResidentialDelivery={setResidentialDelivery}
            allItems={allItems}
            setAllItems={setAllItems}
            qty={qty}
            setQty={setQty}
            zipCode={zipCode}
            setZipCode={setZipCode}
            clickHandler={getFreight}
            // cart={cart}
            // setCart={setCart}
            // updateQty={updateQty}
            setFreightShow={setFreightShow}
            nonboxed={nonboxed}
            setNonboxed={setNonboxed}
            setNotification={setNotification}
          />
        ) : loading ? (
          <InstantFreightLoading />
        ) : quote ? (
          <InstantFreightQuote
            quote={quote}
            qty={qty}
            setFreightShow={setFreightShow}
            nonboxed={nonboxed}
            setNotification={setNotification}
          />
        ) : quoteError ? (
          <div
            className=" form"
            style={{
              // margin: "auto",
              fontSize: "24px",
              // textTransform: "uppercase",
              color: "#00435B",
              // opacity: ".95",
              // width: "20rem",
              textAlign: "center",
              // width: "80%",
              padding: "0 10rem",
              boxSizing: "border-box",
            }}
          >
            Quote Error: {quoteError}
            <br></br>
            <br></br>
            Please try again or contact us
            <button
              className="submit"
              style={{ padding: "0 2rem", marginTop: "2rem" }}
              onClick={() => {
                window.open("/cart#contact");
              }}
            >
              Contact
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default InstantFreight;
