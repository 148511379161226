import '../../CSS/stockTable.css'
import React from 'react'
import { Link } from 'react-router-dom'

const StockTable = ({ stockData, series }) => {

    return (
        <div className='stock_data'>
            <table className='stock_table'>
                <caption>{stockData.series} Stock</caption>
                <thead>
                    <tr>
                        <th>Model Number</th>
                        <th>CA</th>
                        <th>NJ</th>
                        <th>TN</th>
                    </tr>
                </thead>
                <tbody>
                    {stockData.stock.map((sku) => (
                        <tr key={sku[0]}>
                            <td className="modelNum-Row" >
                                <Link to={`/products/${series}/${sku[0]}`} >
                                    {sku[0]}
                                </Link>

                            </td>
                            <td>{sku[1]}</td>
                            <td>{sku[2]}</td>
                            <td>{sku[3]}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default StockTable