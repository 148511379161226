import Quote from './Quote';

const QuoteImage = ({ quote, quoteLoading, quoteError, setNotice }) => {
  return (
    <div
      className='freight-quote'
      style={{
        background: quote || quoteLoading ? 'white' : '#E1E1E1',
        opacity: quote || quoteLoading ? '1' : '.75',
      }}
    >
      {quote ? (
        <Quote
          quote={quote}
          setNotice={setNotice}
        />
      ) : quoteLoading ? (
        <img
          src='https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1689625054/Website%20Assets/GIFs/truck-animation_miwg1h.gif'
          style={{ objectFit: 'contain' }}
        ></img>
      ) : quoteError ? (
        <div
          style={{
            margin: 'auto',
            fontSize: '24px',
            color: 'red',
            width: '20rem',
            textAlign: 'center',
          }}
        >
          Quote Error: {quoteError}
          <br></br>
          <br></br>
          Please try again or contact us below
        </div>
      ) : (
        <div
          style={{
            margin: 'auto',
            fontSize: '48px',
            textTransform: 'uppercase',
            color: '#808080',
            opacity: '.25',
            width: '20rem',
            textAlign: 'center',
          }}
        >
          No Quote Available
        </div>
      )}
    </div>
  );
};

export default QuoteImage;
