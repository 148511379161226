import '../../CSS/NPSportal/reusableComponents.css';
import { CgCheckR, CgCloseR } from 'react-icons/cg';
import { SlArrowDown } from 'react-icons/sl';
import DatePicker from 'react-datepicker';
import { useEffect, useState } from 'react';

const FormInput = ({
  asterisk = false,
  inputType = 'input',
  name,
  type = 'text',
  value = '',
  onChange,
  placeholder,
  maxLength,
  required = false,
  columns,
  onClick,
  setAddValues,
  addValues,
  options,
  i,
  ...rest
}) => {
  const [arrowClicked, setArrowClicked] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [selectOption, setSelectOption] = useState('');

  useEffect(() => {
    if (selectOption === '' && addValues && Object?.keys(addValues)?.includes(name)) {
      const { [name]: _, ...rest } = addValues;
      setAddValues(rest);
    }
  }, [selectOption]);

  useEffect(() => {
    if (addValues?.[name] === '') {
      const { [name]: _, ...rest } = addValues;
      setAddValues(rest);
    }
  }, [addValues]);

  var inputElement;
  switch (inputType) {
    case 'textarea':
      inputElement = (
        <textarea
          className='formTextarea'
          name={name}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          placeholder={placeholder}
          maxLength={maxLength || 5000}
          required={required}
          {...rest}
        ></textarea>
      );
      break;
    case 'boolean':
      inputElement = (
        <div className='yes-no-div'>
          <div
            className='yes-div'
            onClick={() => {
              onClick(1);
            }}
          >
            <CgCheckR className={`cg-check ${addValues?.[name] === 1 ? 'selected-choice' : ''}`} />
            <div className={`${addValues?.[name] === 0 ? 'non-select-boolean' : ''}`}>Yes</div>
          </div>
          <div
            className='no-div'
            onClick={() => {
              onClick(0);
            }}
          >
            <CgCloseR className={`cg-check ${addValues?.[name] === 0 ? 'selected-choice' : ''}`} />
            <div className={`${addValues?.[name] === 1 ? 'non-select-boolean' : ''}`}>No</div>
          </div>
        </div>
      );
      break;
    case 'dropdown':
      inputElement = (
        <div
          className={`formInput dropdown  ${isActive ? 'trigger-div' : ''}`}
          onClick={() => {
            setArrowClicked((prevArrow) => {
              setSelectOption('');
              setArrowClicked(!prevArrow);
            });
            setIsActive(true);
          }}
        >
          <div className='option-placeholder'>
            {selectOption ? selectOption : 'Select an Option'}
          </div>
          <div className='plus-arrow'>
            <SlArrowDown className={`arrow-icon ${arrowClicked ? 'rotated' : ''} }`} />
          </div>
        </div>
      );
      break;
    case 'datetime':
      inputElement = (
        <DatePicker
          className={`${isActive ? 'trigger-div' : ''}`}
          showIcon
          placeholderText='MM/DD/YYYY'
          onChange={(date) => {
            onClick(date);
          }}
          selected={addValues?.[name] ? addValues[name] : null}
          todayButton='TODAY'
        />
      );
      break;
    // default is a text input
    default:
      inputElement = (
        <input
          className={`formInput ${isActive ? 'trigger-div' : ''}`}
          name={name}
          type={type}
          value={value}
          onChange={(e) => {
            onChange(e.target.value);
          }}
          placeholder={placeholder}
          maxLength={maxLength || 300}
          required={required}
          {...rest}
        />
      );
      break;
  }
  return (
    <div>
      <fieldset
        className={`formFieldset ${
          inputType === 'boolean' || inputType === 'datetime' ? 'smaller-width' : ''
        }
        ${isActive ? 'trigger-div' : ''}`}
        onClick={() => {
          setIsActive(true);
        }}
        onMouseLeave={() => {
          if (addValues && !Object?.keys(addValues)?.includes(name) && !arrowClicked) {
            setIsActive(false);
          }
        }}
      >
        <legend className={`formLegend ${isActive ? 'trigger-div' : ''}`}>
          {name}
          {asterisk && <span style={{ color: 'red' }}>*</span>}
        </legend>
        <div
          className={`${
            inputType === 'dropdown' ? 'select-input' : inputType !== 'datetime' ? 'input-cont' : ''
          }`}
        >
          {inputElement}
        </div>
      </fieldset>
      {inputType === 'dropdown' && arrowClicked && (
        <>
          <div className='select-list-div'>
            <div className='select-list'>
              {columns
                ?.find((col) => col?.name.toLowerCase() === name?.toLowerCase())
                ?.values.map((i) => {
                  return (
                    <div
                      className='select-list-item'
                      key={i}
                      onClick={() => {
                        setSelectOption(i);
                        setArrowClicked(false);
                        onClick(i);
                      }}
                    >
                      {i}
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default FormInput;
