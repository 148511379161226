import React from 'react';
import { Cloudinary } from '@cloudinary/url-gen';
import { AdvancedImage } from '@cloudinary/react';
import { removeBackground } from '@cloudinary/url-gen/actions/effect';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { useSelector } from 'react-redux';
import '../../CSS/ProductBanner.css';
import '../../CSS/MobileApp/M_ProductBanner.css';

const ProductBanner = ({ text, image }) => {
  const mobileScreen = useSelector((state) => state.isMobile.value);
  const banner = mobileScreen.isMobile
    ? 'https://res.cloudinary.com/da3rom333/image/upload/v1701280847/product_category_banner.jpg'
    : 'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688650798/Website%20Assets/Banners/GeneralProducts_zhjzrl.png';

  const cld = new Cloudinary({
    cloud: {
      cloudName: 'da3rom333',
    },
  });
  return (
    <div>
      <img src={banner} className='banner-background'></img>
      <div className='banner-div'>
        <div className='banner-text'>{text}</div>
        <AdvancedImage
          cldImg={cld
            .image(image)
            .resize(scale().width(500))
            .effect(removeBackground('fineEdges'))
            .format('png')}
          className='banner-img'
        />
      </div>
    </div>
  );
};

export default ProductBanner;
