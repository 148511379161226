import React, { useEffect, useState } from 'react';
import { FaFileUpload, FaRegImage } from 'react-icons/fa';
import { IoCloseCircle } from 'react-icons/io5';
import '../../CSS/UploadFiles.css';
const UploadFiles = ({ files, setFiles }) => {
  useEffect(() => {
    // console.log(files);
  }, [files]);
  // const [files, setFiles] = useState([]);
  const [dragActive, setDragActive] = useState(false);
  const hiddenFileInput = React.useRef(null);

  function handleClick() {
    hiddenFileInput.current.click();
  }
  const handleChange = (event) => {
    // console.log(event.target.files);
    var holderArray = Array.from(event.target.files);
    setFiles([...files, ...holderArray]);
  };
  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };
  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      var holderArray = Array.from(e.dataTransfer.files);
      setFiles([...files, ...holderArray]);
    }
  };
  useEffect(() => {
    // console.log(files);
  }, [files]);
  return (
    <div className='file-upload'>
      <div
        className='selectorDiv'
        onClick={handleClick}
        onDragEnter={handleDrag}
        onDrop={handleDrop}
      >
        <FaFileUpload className='file-svg' />
        <div className='title'>
          Drop a file here to upload, or <span className='link'>click here to browse</span>
          <input
            type='file'
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{ display: 'none' }}
            multiple={true}
          />
        </div>
      </div>
      <div className='files'>
        {files?.length
          ? files?.map((i, index) => {
              return (
                <div className='file' key={index}>
                  <FaRegImage />
                  <h4>{i.name}</h4>
                  <IoCloseCircle
                    onClick={() => {
                      setFiles(
                        files?.filter((j) => {
                          return j.name !== i.name;
                        })
                      );
                    }}
                    className='image-remove'
                  />
                </div>
              );
            })
          : ''}
      </div>
      {dragActive && (
        <div
          id='drag-file-element'
          onDragEnter={handleDrag}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        ></div>
      )}
    </div>
  );
};

export default UploadFiles;
