import { AdvancedImage } from '@cloudinary/react';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { FaCartPlus, FaRuler } from 'react-icons/fa';
import CartQuantity from '../../Components/FreightCart/CartQuantity';
import FormatImageLink from '../../Components/Shared/FormatImageLink';
import { useDispatch, useSelector } from 'react-redux';
import { changeProduct, updateProductInfo } from '../../ReduxSlices/productState';
import useAuth from '../../hooks/useAuth';
import { useEffect, useState } from 'react';
import { useContent } from '../../context/ContentProvider';
import useUserInfo from '../../hooks/useUserInfo';
import Accessories from '../../Components/Products/Accessories';
import CartPricing from '../../Components/Products/CartPricing';
import { MdInfoOutline } from 'react-icons/md';
import { IoMdInformationCircle } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { MdEdit } from 'react-icons/md';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import { useCart } from '../../context/CartContext';

const ProductCart = ({ addToCart, page }) => {
	const decodeAndFormat = FormatImageLink();
	const { displayLoginBox, displayBoolean } = useUserInfo();

	const {
		optionsSelected,
		product,
		skuDisplayed,
    accessoriesSelected,
		openAddToCart,
		skuCompleted,
		quantity,
		nonBoxed,
		customerPrice,
		fullResponse,
		loadingPrice,
		stageBuilder,
	} = useSelector((state) => state.productState.value);

	const dispatch = useDispatch();
	const [requested, setRequested] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');
	const [addToCartTried, setAddToCartTried] = useState();
	const [searchVal, setSearchVal] = useState('');
	const [account, setAccount] = useState('');
	const [showDrop, setShowDrop] = useState();
	const [searchAccount, setSearchAccount] = useState(true);
  const [viewQuantity, setViewQuantity] = useState(false);
	const { auth } = useAuth();
	const axiosJWT = useAxiosJWT();
	const { notificationData } = useCart();
	const { customers, chosenAccount, setChosenAccount } = useContent();

  async function handleAddToCart() {
    if (
      auth?.roles.includes(3500) ||
      auth?.accounts
        ?.find((i) => i.last_login === 1)
        ?.AccountRoles?.some((role) => [1400, 1200, 1100].includes(role))
    ) {
      const response = await addToCart(
        product.productInfo.FreightQuoteModelNum,
        quantity,
        skuDisplayed,
        product.productInfo.ShortDescription,
        product?.cdnLinks[0]?.Image,
        optionsSelected,
        product.productInfo.BaseModelNum,
        parseInt(product.productInfo.Quantity) * parseInt(quantity),
        parseInt(product.productInfo.Quantity),
        product.productInfo.ShipsNonBoxed,
        nonBoxed,
        product.productInfo.Status,
        Object.keys(customerPrice).length > 0 ? customerPrice : false
      );
      if (response?.data?.error) {
        dispatch(
          updateProductInfo({
            notification: response.data.error,
          })
        );
      } else {
        if (accessoriesSelected.length > 0) {
          for (const accessory of accessoriesSelected) {
            let modelNum = accessory.accessorySkus?.find(
              (i) => i.accessoryFor === product.modelNum
            ).modelNum;
            let accessoryProduct = accessory.product.skuList.find(
              (sku) => sku.modelNum === modelNum
            );
            const options = {};
            Object.keys(
              accessory.product.skuList.find((sku) => sku.modelNum === modelNum).configOptions
            ).forEach(
              (key) =>
                (options[key] = accessory.product.skuList.find(
                  (sku) => sku.modelNum === modelNum
                ).configOptions[key].selectionName)
            );
            const response = await addToCart(
              accessoryProduct.productInfo.FreightQuoteModelNum,
              accessory.qty,
              modelNum, //skuDisplayed,
              accessoryProduct.productInfo.ShortDescription,
              accessoryProduct?.cdnLinks[0]?.Image,
              options,
              accessoryProduct.productInfo.BaseModelNum,
              parseInt(accessoryProduct.productInfo.Quantity) * parseInt(accessory.qty),
              parseInt(accessoryProduct.productInfo.Quantity),
              accessoryProduct.productInfo.ShipsNonBoxed,
              nonBoxed,
              accessoryProduct.productInfo.Status,
              Object.keys(
                accessory.accessorySkus?.find((i) => i.accessoryFor === product.modelNum).price
              ).length > 0
                ? accessory.accessorySkus?.find((i) => i.accessoryFor === product.modelNum).price
                : false
            );
            if (response?.data?.error) {
              dispatch(
                updateProductInfo({
                  notification: response.data.error,
                })
              );
            }
          }
        }
        window.open('/cart', '_blank');
        if (page === 'configurations') {
          dispatch(updateProductInfo({ openAddToCart: false }));
        }
        setAddToCartTried(false);
      }
    } else {
      dispatch(
        updateProductInfo({
          notification: 'Not authorized to add to freight cart',
        })
      );
      // displayLoginBox();
      setAddToCartTried(true);
    }
  }

  useEffect(() => {
    if (page === 'noConfig') {
      dispatch(updateProductInfo({ openAddToCart: true }));
    }
  }, []);

  useEffect(async () => {
    if (!displayBoolean && auth?.rep && addToCartTried) {
      handleAddToCart();
    } else if (!displayBoolean) {
      setAddToCartTried(false);
    }
  }, [displayBoolean]);

	useEffect(() => {
		if (chosenAccount) {
			setSearchAccount(false);
		}
	}, []);

	return (
		<div id='addToCart1' style={{ marginTop: '14px' }}>
			<div className='addToCart-outer-div'>
				{stageBuilder === 'newProduct' ? (
					<>
						<div
							className='addToCart-button'
							onClick={() => dispatch(updateProductInfo({ addStage: 'new' }))}
						>
							<FaRuler /> Add To Build
						</div>
						<Link
							to={{
								pathname: `/products/${fullResponse.seriesname}`,
								search: `?${Object.keys(optionsSelected)
									?.map(
										(key) =>
											`${encodeURIComponent(key)}=${encodeURIComponent(optionsSelected[key])}`
									)
									?.join('&')}`,
							}}
							target='_blank'
							rel='noopener noreferrer'
							className='stage-category-link'
						>
							View Full Product
						</Link>
					</>
				) : stageBuilder === 'existingProduct' ? (
					<>
						<div className='addToCart-buttons-wrapper'>
							<div
								className='addToCart-button'
								onClick={() => dispatch(updateProductInfo({ addStage: 'edit' }))}
							>
								<MdEdit /> Save Changes
							</div>
							<div
								className='addToCart-button'
								onClick={() => dispatch(updateProductInfo({ addStage: 'new' }))}
							>
								<FaRuler /> Add New
							</div>
						</div>
						<Link
							to={{
								pathname: `/products/${fullResponse.seriesname}`,
								search: `?${Object.keys(optionsSelected)
									?.map(
										(key) =>
											`${encodeURIComponent(key)}=${encodeURIComponent(optionsSelected[key])}`
									)
									?.join('&')}`,
							}}
							target='_blank'
							rel='noopener noreferrer'
							className='stage-category-link'
						>
							View Full Product
						</Link>
					</>
				) : (
					<div
						className={`addToCart-button ${openAddToCart ? 'open' : ''}`}
						onClick={(event) => {
							if (page === 'noConfig') {
							} else if (skuCompleted) {
								dispatch(updateProductInfo({ openAddToCart: !openAddToCart }));
								if (!openAddToCart) {
									event.preventDefault();
									event.stopPropagation();
									setTimeout(() => {
										document.getElementById('addToCart1').scrollIntoView({
											block: 'center',
											inline: 'nearest',
											behavior: 'smooth',
										});
									}, 100);
								}
							} else {
								dispatch(
									updateProductInfo({
										notification: 'Complete Sku before adding to cart',
									})
								);
							}
						}}
					>
						<div
							style={{
								display: 'flex',
								alignItems: 'center',
							}}
						>
							<FaCartPlus />
							<div>Add To Cart</div>
							{openAddToCart ? (
								<BsChevronUp className='addToCart-arrow' />
							) : (
								<BsChevronDown className='addToCart-arrow' />
							)}
						</div>
					</div>
				)}
			</div>
			{openAddToCart ? (
				<div className={openAddToCart ? 'addToCart-open' : ''}>
					<div className='product-details product-details-addToCart'>
						{auth?.roles?.includes(3500) && (
							<div className='employee-cart-price'>
								<div
									className='price-flex-div'
									style={{
										marginBottom: '5px',
									}}
								>
									<h3
										style={{
											margin: '0px',
											fontSize: '17px',
											lineHeight: '29px',
										}}
									>
										Account Pricing:{' '}
									</h3>
									{chosenAccount && !searchAccount && (
										<button
											onClick={() => {
												setChosenAccount();
												setSearchAccount(true);
											}}
											style={{ width: '124px' }}
										>
											Default
										</button>
									)}
								</div>
								<div
									className='price-flex-div'
									style={{
										position: 'relative',
									}}
								>
									{chosenAccount && !searchAccount ? (
										<div className='pricing-for-text'>
											Pricing for #{chosenAccount.customerId} - {chosenAccount.name}
										</div>
									) : (
										<>
											<input
												type='text'
												name='searchVal'
												placeholder='Search Account'
												id='searchVal'
												value={searchVal}
												onChange={(e) => {
													setSearchVal(e.target.value);
												}}
												onFocus={() => {
													setShowDrop(true);
												}}
												onKeyDown={(e) => {
													// console.log(e);
													if (e.key === 'Enter') {
														// e.preventDefault();

                            const findSearchVal = customers?.filter((i) => {
                              return (
                                i.name?.toString()?.toUpperCase() ===
                                searchVal?.toString()?.toUpperCase()
                              );
                            });
                            // console.log(findSearchVal);
                            if (findSearchVal) {
                              setAccount(findSearchVal[0]);
                              setShowDrop(false);
                            }
                          }
                          // if (e.key === "ArrowDown" || e.key === "ArrowUp") {
                          //   if (showDrop) {
                          //     setIndexFocus(indexFocus + 1);
                          //     console.log(indexFocus);
                          //   }
                          // }
                        }}
                        required
                      ></input>
                      {customers && searchVal && showDrop ? (
                        <div className='customers-drop-div'>
                          <div className='customers-dropdown'>
                            {customers
                              ?.filter((i) => {
                                const searchUpperCase = searchVal?.toString()?.toUpperCase();
                                const nameUpperCase = i.name?.toString()?.toUpperCase();
                                const customerIdUpperCase = i.customerId?.toString()?.toUpperCase();

                                return (
                                  (customerIdUpperCase &&
                                    customerIdUpperCase === searchUpperCase) ||
                                  (customerIdUpperCase &&
                                    customerIdUpperCase.includes(searchUpperCase)) ||
                                  (nameUpperCase && nameUpperCase.includes(searchUpperCase))
                                );
                              })
                              ?.sort((a, b) => {
                                const searchUpperCase = searchVal?.toString()?.toUpperCase();
                                const nameUpperCaseA = a.name?.toString()?.toUpperCase();
                                const customerIdUpperCaseA = a.customerId
                                  ?.toString()
                                  ?.toUpperCase();
                                const nameUpperCaseB = b.name?.toString()?.toUpperCase();
                                const customerIdUpperCaseB = b.customerId
                                  ?.toString()
                                  ?.toUpperCase();

                                const isExactMatchA = customerIdUpperCaseA === searchUpperCase;
                                const isExactMatchB = customerIdUpperCaseB === searchUpperCase;

																if (isExactMatchA && !isExactMatchB) {
																	return -1;
																} else if (!isExactMatchA && isExactMatchB) {
																	return 1;
																}

                                // If neither or both are exact matches, sort by proximity
                                const proximityA =
                                  (isExactMatchA
                                    ? 0
                                    : customerIdUpperCaseA?.indexOf(searchUpperCase)) ||
                                  (nameUpperCaseA && nameUpperCaseA.indexOf(searchUpperCase)) ||
                                  9999;

                                const proximityB =
                                  (isExactMatchB
                                    ? 0
                                    : customerIdUpperCaseB?.indexOf(searchUpperCase)) ||
                                  (nameUpperCaseB && nameUpperCaseB.indexOf(searchUpperCase)) ||
                                  9999;

                                return proximityA - proximityB;
                              })
                              ?.map((i, index) => {
                                return (
                                  <div
                                    key={index}
                                    className={`modelNum-list-item`}
                                    onClick={() => {
                                      setAccount(i);
                                      setShowDrop(false);
                                      setSearchVal(i.customerId + ' - ' + i.name);
                                    }}
                                  >
                                    {i.customerId} - {i.name}
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  )}
                  {auth?.roles.includes(3500) && chosenAccount && !searchAccount ? (
                    <button
                      onClick={() => {
                        setSearchAccount(true);
                        setAccount('');
                      }}
                    >
                      Change Account
                    </button>
                  ) : auth?.roles.includes(3500) && searchAccount ? (
                    <button
                      onClick={() => {
                        setSearchVal('');
                        setSearchAccount(false);
                        setChosenAccount(account);
                      }}
                      disabled={!account}
                    >
                      Get Price
                    </button>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            )}
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'start' }}>
                <div className='img-div'>
                  {' '}
                  <AdvancedImage
                    cldImg={decodeAndFormat(product?.cdnLinks[0]?.Image).resize(scale().width(100))}
                  />
                </div>
                <div className='flex-right'>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      width: '25rem',
                      alignItems: 'center',
                    }}
                  >
                    <div className='basic-flex'>
                      <div className='skuTitle'>SKU:</div>
                      <div className='modelNum'>{skuDisplayed}</div>
                    </div>
                    {skuDisplayed?.length > 16 ? (
                      <div style={{ color: 'var(--darkblue)' }}>
                        <IoMdInformationCircle
                          onMouseEnter={() => setViewQuantity(true)}
                          onMouseLeave={() => setViewQuantity(false)}
                        />
                        {viewQuantity ? (
                          <div
                            style={{
                              position: 'absolute',
                              marginTop: '-3.5rem',
                              marginLeft: '-5rem',
                              background: 'var(--lightgrey-50)',
                              padding: '5px 10px',
                              borderRadius: '6px',
                              fontSize: '12px',
                              boxShadow: '1px 1px 5px #acacac',
                              zIndex: 11,
                            }}
                            className='info-pop basic-flex'
                          >
                            <div>Sales Quantity:</div>
                            <div style={{ marginLeft: '3px' }}>
                              {product?.productInfo?.Quantity || 1}
                            </div>
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      <div className='basic-flex'>
                        <div>Sales Quantity:</div>
                        <div className='modelNum'>{product?.productInfo?.Quantity || 1}</div>
                      </div>
                    )}
                  </div>
                  <CartPricing
                    customerPrice={customerPrice}
                    loadingPrice={loadingPrice}
                  />
                  <div className='product-addToCart'>
                    <CartQuantity
                      style={{ marginTop: '8px' }}
                      quantity={quantity}
                      updateQty={(newQty) => {
                        dispatch(updateProductInfo({ quantity: newQty }));
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className='addToCart-open line'></div>
              {accessoriesSelected.length > 0 && <Accessories />}
              <div className='button-div'>
                <button
                  onClick={() => handleAddToCart()}
                  className={`addToCart ${page === 'noConfig' ? 'no-config-button' : ''}`}
                >
                  Add to cart
                </button>
                <button
                  onClick={() => {
                    if (
                      auth?.roles.includes(3500) ||
                      auth?.accounts
                        ?.find((i) => i.last_login === 1)
                        ?.AccountRoles?.some((role) => [1400, 1200, 1100].includes(role))
                    ) {
                      window.open('/cart', '_blank');
                    } else {
                      dispatch(
                        updateProductInfo({
                          notification: 'Not authorized to view freight cart',
                        })
                      );
                    }
                  }}
                  className={`viewCart ${page === 'noConfig' ? 'no-config-button' : ''}`}
                >
                  View Cart
                </button>
                {page === 'configurations' && (
                  <button
                    onClick={() => {
                      dispatch(changeProduct());
                      dispatch(updateProductInfo({ openAddToCart: false }));
                    }}
                    className='clear-selections'
                  >
                    Clear Selections
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ProductCart;
