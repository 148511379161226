import React, { useState } from 'react';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import '../../CSS/AdminPortal.css';
import '../../CSS/UploadFiles.css';
import '../../CSS/CloudinaryPdf.css';

const EditCloudinaryPdf = () => {
  const [searchUrl, setSearchUrl] = useState('');
  const [replaceUrl, setReplaceUrl] = useState([]);
  // Define your metadata query parameters
  const [responseMessage, setReponseMessage] = useState();
  const axiosJWT = useAxiosJWT();

  function replaceCloudinaryAsset() {
    const formData = new FormData();
    Object.values(replaceUrl).forEach((file) => {
      formData.append('arrayOfFiles', file);
    });
    formData.append('urlToBeReplaced', searchUrl);
    axiosJWT
      .post(`/cloudinary/replaceCloudinaryAsset`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
      .then((response) => {
        // console.log(response);
        setReponseMessage(
          `Success- overwritten:${response.data.overwritten}, secure_url:${response.data.secure_url}`
        );
      })
      .catch((err) => {
        // console.log(err);
        setReponseMessage(err);
      });
  }
  return (
    <div
      className='inner-padding'
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '3rem',
      }}
    >
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <label>Cloudinary Link to Replace:</label>
          <input
            className='cloudinary-input'
            type='text'
            // placeholder="Insert URL to be removed"
            value={searchUrl}
            onChange={(e) => {
              setSearchUrl(e.target.value);
            }}
            style={{ width: '25rem', marginLeft: '1rem' }}
          ></input>
        </div>
        {/* <img src={searchUrl || ""} style={{ height: "2rem" }}></img> */}
        <div
          style={{
            height: '40rem',
            width: '39.5rem',
            border: '1px solid black',
            borderRadius: '15px',
            marginTop: '1.5rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {searchUrl ? (
            <img
              src={searchUrl.replace('pdf', 'jpg')}
              style={{ maxWidth: '100%', maxHeight: ' 100%' }}
            ></img>
          ) : (
            'No Image Available'
          )}
        </div>
        <div>
          <input
            className=' filter-input'
            type='file'
            // value={`${replaceUrl}`}
            onChange={(e) => {
              // console.log(e.target.files[0], 95, e.target.files[0].path);
              setReplaceUrl([e.target.files[0]]);
            }}
          ></input>
          {/* <div>
            <UploadFiles files={replaceUrl} setFiles={setReplaceUrl} />
          </div> */}
          <button className='editableFormButton' onClick={replaceCloudinaryAsset}>
            Replace
          </button>
        </div>

        {responseMessage ? (
          <div style={{ width: '30rem', textAlign: 'center' }}>{responseMessage}</div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default EditCloudinaryPdf;
