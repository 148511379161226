import { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { CgSoftwareDownload } from 'react-icons/cg';
import { AdvancedImage } from '@cloudinary/react';
import { scale } from '@cloudinary/url-gen/actions/resize';
import { lazyload } from '@cloudinary/react';
import { AiOutlineLink } from 'react-icons/ai';
import { getPage } from '@cloudinary/url-gen/actions/extract';
import { BsFillPlayCircleFill } from 'react-icons/bs';
import FormatImageLink from '../Shared/FormatImageLink';
import clipboardCopy from 'clipboard-copy';
const MediaTile = ({
  image,
  mainImage,
  setMainImage,
  addToSelected = false,
  removeFromSelected = false,
  clearList = false,
  selectedList,
  auth,
  pdf = false,
  setMultiplePages,
  pageCount = false,
  video = false,
  setVideoPlayer,
}) => {
  const decodeAndFormat = FormatImageLink();
  const [selected, setSelected] = useState(false);
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCopied(false);
    }, 400);
  }, [copied]);

  useEffect(() => {
    setSelected(false);
  }, [clearList]);

  useEffect(() => {
    if (selectedList?.includes(image)) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectedList]);
  function handleClick() {
    // setSelected(!selected);
    // console.log(addToSelected);
    // console.log(image);
    if (!selected && addToSelected) {
      addToSelected(image);
    } else if (removeFromSelected) {
      removeFromSelected(image);
    }
  }
  return (
    <>
      <div
        className='mediaTile'
        style={{
          // borderColor: selected ? "var(--lightgreen)" : "#BBBBBA",
          border: mainImage === image ? '1px solid #00435B' : '1px solid #BBBBBA',
          cursor: 'pointer',
        }}
        onClick={() => {
          setMainImage(image);
          // console.log(pdf, video, image, pageCount);
          if (pdf) {
            // eslint-disable-next-line no-console
            console.log('pdf');
            setMultiplePages(pageCount);
            setVideoPlayer(false);
          } else if (video) {
            // eslint-disable-next-line no-console
            console.log('video');
            setVideoPlayer(true);
            setMultiplePages(false);
          } else {
            // eslint-disable-next-line no-console
            console.log('image');
            setMultiplePages(false);
            setVideoPlayer(false);
          }
        }}
      >
        {auth?.roles?.includes(3500) || auth?.roles?.includes(3001) ? (
          <div
            onClick={handleClick}
            className={'selection-box'}
            style={{
              borderColor: selected ? '#004D44' : '#939393',
              background: selected ? '#004D44' : 'white',
            }}
          >
            {selected ? (
              <FaCheck style={{ color: 'white' }} />
            ) : (
              <div style={{ height: '16px', width: '16px' }}></div>
            )}
          </div>
        ) : (
          ''
        )}
        <div className='tile-img'>
          {pdf ? (
            <AdvancedImage
              cldImg={decodeAndFormat(image)
                .resize(scale().width(300))
                .format('png')
                .extract(getPage().byNumber(1))}
              plugins={[lazyload()]}
            />
          ) : video ? (
            <div>
              <img src={image.replace('.mp4', '.jpg')}></img>{' '}
              <BsFillPlayCircleFill
                style={{
                  position: 'absolute',
                  color: 'white',
                  top: '35%',
                  left: '35%',
                  height: '2rem',
                  width: '2rem',
                  boxShadow: '0px 0px 0px #BBBBBA',
                  borderRadius: '3rem',
                  background: '#BBBBBA',
                }}
                onClick={() => {
                  // console.log(videoData.videoLink,  "74");
                  // setCurrentVideo(videoData.videoLink);
                  // setOpen(true);
                }}
              />
            </div>
          ) : (
            <AdvancedImage
              cldImg={decodeAndFormat(image).resize(scale().width(250))}
              plugins={[lazyload()]}
            />
          )}
        </div>
        {/* {index} */}
        {auth?.roles?.includes(3500) || auth?.roles?.includes(3001) ? (
          <div className='mediaTile-buttons'>
            <div
              className='download-img'
              onClick={() => {
                {
                  video
                    ? window.open(`${image}`)
                    : window.open(
                        `https://res.cloudinary.com/da3rom333/image/upload/fl_attachment/${image}`,
                        '_blank'
                      );
                }
              }}
            >
              <CgSoftwareDownload />
            </div>
            <div
              className='download-img'
              onClick={() => {
                setCopied(true);

                {
                  video
                    ? clipboardCopy(`${image}`)
                    : clipboardCopy(`https://res.cloudinary.com/da3rom333/image/upload/${image}`);
                }
              }}
            >
              <AiOutlineLink />
              {copied ? (
                <div className='notice' style={{ left: '50%' }}>
                  Link Copied
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};

export default MediaTile;
