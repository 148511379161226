import EditTemplate from '../EditorPortal/EditTemplate';
const EditCalendarTasks = () => {
  const columns = [
    {
      type: 'text',
      name: 'ID',
      searchAllowed: true,
      editable: false,
      table: 'cycle_calendar_tasks',
      fieldName: 'ID',
      display: false,
    },
    {
      type: 'text',
      name: 'Title',
      searchAllowed: true,
      editable: true,
      table: 'cycle_calendar_tasks',
      fieldName: 'title',
      display: true,
    },
    {
      type: 'text',
      name: 'Hours',
      searchAllowed: true,
      editable: true,
      table: 'cycle_calendar_tasks',
      fieldName: 'hours_count',
      display: true,
    },
    {
      type: 'text',
      name: 'Locked Date',
      searchAllowed: true,
      editable: true,
      table: 'cycle_calendar_tasks',
      fieldName: 'locked_start_date',
      display: true,
    },
    {
      type: 'text',
      name: 'Locked Month',
      searchAllowed: true,
      editable: true,
      table: 'cycle_calendar_tasks',
      fieldName: 'locked_months',
      display: true,
    },
    {
      type: 'text',
      name: 'Sort Order',
      searchAllowed: true,
      editable: true,
      table: 'cycle_calendar_tasks',
      fieldName: 'sort',
      display: true,
    },
  ];

  return (
    <div>
      <EditTemplate
        title={'Cycle Calendar Tasks'}
        //data={data}
        columns={columns}
        //searchValue={"model_num"}
        getEndPoint={'getGenericData'}
        table={'cycle_calendar_tasks'}
        //exportAvailable={true}
        //importBatch={true}
        //exportTemplate={true}
        //importUpdate={true}
      />
    </div>
  );
};

export default EditCalendarTasks;
