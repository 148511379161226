import { useEffect, useState } from 'react';
import { Cloudinary } from '@cloudinary/url-gen';
import { AdvancedImage } from '@cloudinary/react';
import useAuth from '../../hooks/useAuth';
import useUserInfo from '../../hooks/useUserInfo';
import { fill } from '@cloudinary/url-gen/actions/resize';
import { useSelector } from 'react-redux';
import ImageSlider from '../Shared/ImageSlider';
import { IoClose } from 'react-icons/io5';
import ShowcaseItem from './ShowcaseItem';
import { GoDownload } from 'react-icons/go';

const EnvironmentPopUp = ({ data, filter, setShow }) => {
  const { auth } = useAuth();
  const { displayLoginBox, displayBoolean } = useUserInfo();
  const [downloadClicked, setDownloadClicked] = useState(false);
  const mobileScreen = useSelector((state) => state.isMobile.value.isMobile);
  const download =
    'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1689475801/Website%20Assets/PNGs/download_pgzd5n.png';
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'da3rom333',
    },
  });

  var mobileEnvirArray = [];

  data?.items?.map((i) => {
    return mobileEnvirArray.push(
      <ShowcaseItem
        item={i}
        key={i.sku}
      />
    );
  });

  useEffect(() => {
    if (!displayBoolean && auth?.rep && downloadClicked) {
      window.open(`https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/${data.image}`);
    }
  }, [displayBoolean]);

  return (
    <div className={`environ-pop ${mobileScreen ? 'mobile' : ''}`}>
      <div className='popup-header'>
        <h2>
          {filter !== 'all'
            ? data.category.find((i) => i.toLowerCase() === filter.toLowerCase())
            : data.category[0]}
        </h2>
        <div className='underline'></div>
      </div>
      <div className='flexbox'>
        <div className='env-main-img'>
          <AdvancedImage
            className='big-image'
            cldImg={cld.image(data.image).resize(fill().width(772).height(660))}
          />
          {mobileScreen && (
            <IoClose
              className='close-button'
              onClick={() => {
                setShow('');
              }}
            />
          )}
          <GoDownload
            className='download-env'
            onClick={() => {
              setDownloadClicked(true);
              if (auth?.rep) {
                window.open(
                  `https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/${data.image}`
                );
              } else {
                displayLoginBox();
              }
            }}
            src={download}
          />
        </div>
        <div className='right-div'>
          <div className='showcase-title'>
            <h3>Showcase |</h3>
            <h4>Click on products to learn more</h4>
          </div>
          <div className='item-div'>
            {!mobileScreen ? (
              mobileEnvirArray
            ) : (
              <ImageSlider
                images={mobileEnvirArray}
                numOfDisplay={4}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnvironmentPopUp;
