import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TemplateTilePage from '../../Components/Shared/TemplateTilePage';
import '../../CSS/MediaCenter.css';
import '../../CSS/MobileApp/M_MediaCenter.css';
import MediaCenterCube from '../../Components/Media/MediaCenterCube';
import { useSelector } from 'react-redux';

const MediaCenter = () => {
  const mobileScreen = useSelector((state) => state.isMobile.value);

  const mediaBanner = mobileScreen.isMobile
    ? 'https://res.cloudinary.com/da3rom333/image/upload/v1701097120/media_center_banner.jpg'
    : 'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688650794/Website%20Assets/Banners/Banner_Media_Center_bxofyt.jpg';

  useEffect(() => {
    document.title = 'Media - National Public Seating';
  }, []);
  const navigate = useNavigate();
  function clickHandler(i) {
    navigate(`/${i.link}`);
  }
  const list = [
    {
      title: 'Media Downloads',
      link: 'media',
    },
    {
      title: 'Digital Catalog',
      link: 'catalog',
    },
    {
      title: 'Brochures',
      link: 'brochures',
    },
    {
      title: 'Promo Videos',
      link: 'videos',
    },
  ];
  return (
    <div className='mediaCenter'>
      <TemplateTilePage
        banner={<img src={mediaBanner}></img>}
        across={4}
        elements={list.map((i, index) => {
          return <MediaCenterCube key={index} data={i} clickHandler={clickHandler} />;
        })}
      />
    </div>
  );
};

export default MediaCenter;
