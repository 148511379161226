import { useLocation } from 'react-router-dom';
import useUserInfo from '../../hooks/useUserInfo';
import useAuth from '../../hooks/useAuth';
import { useEffect } from 'react';
import Unauthorized from './Unauthorized';

const RequestLogin = () => {
  const { displayLoginBox, continueToPage, displayBoolean } = useUserInfo();
  const { auth } = useAuth();
  const location = useLocation();
  const from = location.state?.from?.pathname || '/';

  useEffect(() => {
    displayLoginBox();
  }, []);

  useEffect(() => {
    if (!displayBoolean && auth?.rep) {
      continueToPage(from);
    }
  }, [displayBoolean]);

  return <Unauthorized />;
};

export default RequestLogin;
