import Axios from 'axios';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import GetColorInfo from '../Products/GetColorInfo';
import { useSelector } from 'react-redux';
import useAuth from '../../hooks/useAuth';
// eslint-disable-next-line no-undef, camelcase
const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;

const CompleteItemPrice = () => {
  const { finishTypes } = useSelector((state) => state.productState.value);
  const axiosJWT = useAxiosJWT();
  const { auth } = useAuth();

  const logPricing = async (skuDisplayed, customerPrice) => {
    await axiosJWT
      .post('NPS/productPricingLogs', {
        skuDisplayed,
        basePrice:
          customerPrice?.basePrice?.unitPrice < 1 || !customerPrice?.basePrice?.unitPrice
            ? 'Custom Pricing'
            : customerPrice.basePrice.unitPrice,
        lamUpcharge:
          customerPrice?.laminatesUpcharge?.unitPrice || customerPrice?.laminatesUpcharge,
        edgeUpcharge: customerPrice?.premEdgeUpcharge?.unitPrice || customerPrice?.premEdgeUpcharge,
      })
      .then(() => {})
      .catch((err) => {
        console.error(err);
      });
  };

  async function getItemPrice(product, seriesData, optionsSelected, account = false) {
    let basePrice = 0;
    let premLamPrice = 0;
    let premEdgePrice = [];
    let laminatesUpcharge = 0;
    let premEdgeUpcharge = 0;
    let series = seriesData;
    if (!seriesData) {
      const response = await Axios.post(`${apiAddress}/products/setSeriesDataBySku`, {
        sku: product?.modelNum,
      });
      series = response.data[0];
    }

    await axiosJWT

      .post(`${apiAddress}/products/getCustomerPricing`, {
        sku: product?.modelNum,
        uomId: product?.quantity,
        account: account,
      })
      .then((response) => {
        if (response.data?.error || response.data?.listPrice < 1) {
          basePrice = null;
          console.error(response.data?.error);
        } else {
          basePrice = response.data;
        }
      })
      .catch((error) => {
        console.error('Error fetching customer price:', error);
      });

    if (series?.laminate_upcharge_sku) {
      const response = await axiosJWT.post(`${apiAddress}/products/getCustomerPricing`, {
        sku: series?.laminate_upcharge_sku,
        uomId: 1,
        upcharge: true,
        account: account,
      });

      premLamPrice = response.data;
    }

    if (series?.edge_upcharge_sku) {
      const skuArray = series.edge_upcharge_sku.split(',');
      const promises = skuArray.map(async (sku) => {
        try {
          const response = await axiosJWT.post(`${apiAddress}/products/getCustomerPricing`, {
            sku: sku,
            uomId: 1,
            upcharge: true,
            account: account,
          });

          return response.data;
        } catch (error) {
          console.error('Error fetching customer price:', error);
          return null;
        }
      });

      const responseData = await Promise.all(promises);
      premEdgePrice = responseData;
    }

    const options = ['Bench Color', 'TableTop Color'];

    for (const option of options) {
      if (Object.keys(optionsSelected).includes(option)) {
        const color = await GetColorInfo(optionsSelected[option], finishTypes, option, product);

        if (color?.Type === 'Premium' || color?.type === 'Premium') {
          laminatesUpcharge = premLamPrice;
        } else {
          if (laminatesUpcharge !== 0) {
            laminatesUpcharge = premLamPrice;
          }
        }
      } else {
        laminatesUpcharge = 0;
      }
    }

    const edgeTranslations = [
      { edgeModelNum: 'PREMTM', edgeType: 'T-Mold' },
      { edgeModelNum: 'PREMEB', edgeType: 'PVC Edge Banding' },
      { edgeModelNum: 'PREMPE', edgeType: 'Protect Edge' },
    ];

    if (premEdgePrice.length > 0) {
      const edgeType = edgeTranslations.find((i) => i.edgeType === optionsSelected.Edge);

      const edge = premEdgePrice.find((i) => i.itemSku === edgeType?.edgeModelNum);

      if (Object.keys(optionsSelected).includes('Premium Edge Color')) {
        if (optionsSelected['Premium Edge Color'] !== 'Black') {
          premEdgeUpcharge = edge;
        } else {
          premEdgeUpcharge = 0;
        }
      } else {
        premEdgeUpcharge = 0;
      }
    } else {
      premEdgeUpcharge = 0;
    }
    // console.log(basePrice, premEdgeUpcharge, laminatesUpcharge, 19);
    if (
      auth?.accounts
        ?.find((account) => account.last_login === 1)
        ?.AccountRoles?.some((role) => [1100, 1200, 1300].includes(role))
    ) {
      logPricing(product.fullSku, {
        basePrice: basePrice,
        premEdgeUpcharge: premEdgeUpcharge?.error ? 0 : premEdgeUpcharge,
        laminatesUpcharge: laminatesUpcharge?.error ? 0 : laminatesUpcharge,
      });
    }
    return {
      basePrice: basePrice,
      premEdgeUpcharge: premEdgeUpcharge?.error ? 0 : premEdgeUpcharge,
      laminatesUpcharge: laminatesUpcharge?.error ? 0 : laminatesUpcharge,
    };
  }

  return getItemPrice;
};

export default CompleteItemPrice;
