import { useNavigate } from "react-router-dom";
import "../../CSS/EnvironmentsSlider.css";
import { Cloudinary } from "@cloudinary/url-gen";
import { AdvancedImage } from "@cloudinary/react";
import { fill } from "@cloudinary/url-gen/actions/resize";

const EnvironmentCard = ({ src, title }) => {
  const navigate = useNavigate();
  const cld = new Cloudinary({
    cloud: {
      cloudName: "da3rom333",
    },
  });

  return (
    <div
      className="env-card"
      onClick={() => {
        navigate(`/environments?filter=${title[0]}`);
      }}
    >
      <AdvancedImage
        cldImg={cld.image(src).resize(fill().width(386).height(386))}
      />
      {/* <img src={src} alt={title}></img> */}

      <h3>{title[0]}</h3>
    </div>
  );
};

export default EnvironmentCard;
