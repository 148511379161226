import { AdvancedImage, lazyload } from '@cloudinary/react';
import { Cloudinary } from '@cloudinary/url-gen';
// import { scale } from "@cloudinary/url-gen/actions/resize";
import { useSelector, useDispatch } from 'react-redux';
import { updateProductInfo } from '../../ReduxSlices/productState';

const OptionsCube = ({ option, optionType, availableOptions }) => {
  const { optionsSelected } = useSelector((state) => state.productState.value);
  const dispatch = useDispatch();
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'da3rom333',
    },
  });

  function clicked() {
    dispatch(
      updateProductInfo({
        optionsSelected: {
          ...optionsSelected,
          [optionType.name]: option.selectionName,
        },
      })
    );
  }

  const myImage = cld.image(option.imageLink);
  // myImage.resize(scale().width(25).height(25))
  return (
    <div
      className={`option-cube  
      
      ${
        optionsSelected[optionType.name] === option.selectionName
          ? 'option-cube-clicked'
          : !availableOptions.has(option.selectionName)
            ? 'unavailable'
            : ''
      }
       `}
      onClick={() => {
        if (availableOptions.has(option.selectionName)) {
          clicked();
        }
        // console.log(option);
      }}
      style={{
        cursor: availableOptions.has(option.selectionName) ? 'pointer' : 'not-allowed',
      }}
    >
      {/* <div className="img-div"> */}
      <AdvancedImage
        cldImg={myImage}
        plugins={[lazyload()]}
        style={{
          borderRadius: '6px',
          cursor: availableOptions.has(option.selectionName) ? 'pointer' : 'not-allowed',
          opacity: availableOptions.has(option.selectionName) ? '1' : '.45',
        }}
      />
      {/* </div> */}
      <div className='h4-div'>{/* <h4>{option.selectionName}</h4> */}</div>
    </div>
  );
};

export default OptionsCube;
