import { useEffect, useState } from 'react';
import '../../CSS/StockCheck.css';
import '../../CSS/MobileApp/M_StockCheck.css';
import { IoCloseCircleOutline } from 'react-icons/io5';
import useAxiosJWT from '../../hooks/useAxiosJWT';

import Axios from 'axios';
import LoadingIcon from '../Shared/LoadingIcon';
import { useSelector, useDispatch } from 'react-redux';
import { updateProductInfo } from '../../ReduxSlices/productState';

const StockCheck = ({ setStockShow }) => {
  const { series } = useSelector((state) => state.productState.value);
  const dispatch = useDispatch();
  // eslint-disable-next-line no-undef, camelcase
  const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;
  const axiosJWT = useAxiosJWT();
  const [stock, setStock] = useState();
  const [stockError, setStockError] = useState();
  function getStock() {
    axiosJWT
      .post('NPS/get_stock', { series: series })
      .then((response) => {
        setStock(response.data);
        // console.log(response.data);
      })
      .catch((error) => {
        if (error.response) {
          setStockError(error.response.data);
          console.error('Server responded with an error:', error.response.data);
        } else if (error.request) {
          // The request was made but no response was received
          console.error('No response received:', error.request);
        } else {
          // Something happened in setting up the request that triggered an error
          console.error('Request setup error:', error.message);
        }
      });
  }

  useEffect(() => {
    getStock();
  }, []);
  return (
    <div className='stockCheck'>
      <div
        className='modal'
        onClick={() => {
          setStockShow(false);
        }}
      ></div>
      <div className='media-popup'>
        <IoCloseCircleOutline
          className='closeButton'
          onClick={() => {
            setStockShow(false);
          }}
        />
        <h2>Real-Time Stock Check</h2>
        <div className='underline'></div>
        <div className='stock'>
          {stockError ? ( // Check if stockError has a value
            <div className='error-popup'>
              <p>{stockError}</p>
            </div>
          ) : (
            <>
              {stock?.length ? (
                <>
                  <div className={`header ${stock?.length > 8 ? 'six36' : 'six53'}`}>
                    <div>{series}</div>
                    <div>NJ</div>
                    <div>TN</div>
                    <div>CA</div>
                    <div>CL</div>
                  </div>
                  <div style={{ paddingTop: '50px' }}>
                    {stock?.map((i, index) => {
                      return (
                        <div
                          key={index}
                          className='row'
                          onClick={() => {
                            Axios.post(`${apiAddress}/products/getOptionsbySKU`, {
                              sku: i['sku'],
                            }).then((response) => {
                              // console.log(response.data);
                              dispatch(
                                updateProductInfo({
                                  optionsSelected: response.data.results.configOptions,
                                })
                              );
                              setStockShow(false);
                            });
                          }}
                        >
                          {' '}
                          <div className='left'>{i['sku']}</div>
                          <div>{i['NJ'] || '-'}</div>
                          <div>{i['TN'] || '-'}</div>
                          <div>{i['CA'] || '-'}</div>
                          <div>{i['CL'] || '-'}</div>
                        </div>
                      );
                    })}
                  </div>
                </>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '400px',
                    width: '100%',
                    minHeight: '310px',
                  }}
                >
                  <LoadingIcon />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default StockCheck;
