import { useState, useEffect, useRef } from 'react';
import { AiOutlineCheck, AiOutlineClose, AiOutlineCloseCircle } from 'react-icons/ai';
import { BsArrowReturnRight } from 'react-icons/bs';
import { BiChevronDown, BiChevronUp, BiLeftArrow } from 'react-icons/bi';
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlineSort } from 'react-icons/md';
import { CgClose, CgSearch } from 'react-icons/cg';
import { RiCheckboxBlankCircleLine, RiCheckboxCircleFill } from 'react-icons/ri';
import { FaPen, FaSave, FaTrash } from 'react-icons/fa';
import { PiLinkSimpleBold } from 'react-icons/pi';
import clipboardCopy from 'clipboard-copy';
import useAxiosJWT from '../../hooks/useAxiosJWT';
import '../../CSS/AdminPortal.css';
import ImageWithHover from './ImageWithHover';
import UploadFile from '../Shared/UploadFile';
import ManageAccount from '../AdminPortal/ManageAccount';
import useAuth from '../../hooks/useAuth';
import '../../CSS/EditableFormDesign.css';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import EditableFormAddNew from './EditableFormAddNew';

// eslint-disable-next-line no-undef
const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;

const EditableFormDesign = ({
  title,
  columns,
  data,
  saveHandler,
  getData,
  addHandler = false,
  removeHandler = false,
  clickHandler = false,
  backHandler = false,
  exportAvailable = false,
  exportHandler = false,
  exportTemplateHanlder = false,
  batchImportHandler = false,
  uploadImportHandler = false,
  notice = false,
  importBatch = false,
  accountRoles = false,
  setShowAddAccount = false,
  searchInput = false,
  textDesc = false,
}) => {
  const axiosJWT = useAxiosJWT();
  const { auth, setAuth } = useAuth();
  const [editableRows, setEditableRows] = useState([]);
  const [filters, setFilters] = useState(searchInput ? searchInput : '');
  const [newData, setNewData] = useState(data);
  const [openAdd, setOpenAdd] = useState(false);
  const [addValues, setAddValues] = useState({});
  const [batchImportData, setBatchImportData] = useState();
  const [uploadImportData, setUploadImportData] = useState();
  const [showSelect, setShowSelect] = useState();
  const [roles, setRoles] = useState();
  const [searchRoles, setSearchRoles] = useState([]);
  const [searchPermissions, setSearchPermissions] = useState([]);
  const [sort, setSort] = useState('ASC');
  const [sortColumn, setSortColumn] = useState();
  const [openAccount, setOpenAccount] = useState(false);
  const [user, setUser] = useState('');
  const [userAccounts, setUserAccounts] = useState([]);
  const [customerList, setCustomerList] = useState();
  const [errMsg, setErrMsg] = useState('');
  const [selectedFilter, setSelectedFilter] = useState();
  const [notification, setNotification] = useState();
  const [adminApprovals, setAdminApprovals] = useState();
  const [sortOpen, setSortOpen] = useState(false);
  const [imageHover, setImageHover] = useState();
  const [topUse, setTopUse] = useState();
  const [rightUse, setRightUse] = useState();
  const [dropdownType, setDropdownType] = useState();

  useEffect(() => {
    if (searchInput && searchInput === filters) {
      // Clear search params
      const url = new URL(window.location.href);
      url.searchParams.delete('searchInput');
      window.history.replaceState(null, '', url.toString());
    }
  }, [filters]);

  useEffect(() => {
    if (imageHover) {
      setTopUse(document?.getElementById(imageHover)?.getBoundingClientRect().top - 260);
      setRightUse(document?.getElementById(imageHover)?.getBoundingClientRect().left - 95);
    } else {
      setTopUse();
      setRightUse();
    }
  }, [imageHover]);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [notification]);

  useEffect(() => {
    if (batchImportData) {
      batchImportHandler(batchImportData);
      setBatchImportData();
    }
  }, [batchImportData]);

  useEffect(() => {
    if (uploadImportData) {
      uploadImportHandler(uploadImportData);
      setUploadImportData();
    }
  }, [uploadImportData]);

  const handleFilterChange = (key, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [key]: value }));
  };

  const handleEdit = (dataLine) => {
    setEditableRows([...editableRows, dataLine.ID]);
  };

  const handleDateChange = (date, data, field) => {
    if (date) {
      setNewData((prevData) => {
        return prevData.map((obj) => {
          if (obj.ID === data.ID) {
            return {
              ...obj,
              [field]: date,
            };
          }
          return obj;
        });
      });
    }
  };

  const handleInputChange = (event, data, field, booleanType = false) => {
    setNewData((prevData) => {
      return prevData.map((obj) => {
        if (obj.ID === data.ID) {
          return {
            ...obj,
            [field]: booleanType ? (event.target.checked ? 1 : 0) : event.target.value,
          };
        }
        return obj;
      });
    });
  };

  const handleSortChange = (fieldName) => {
    if (fieldName === sortColumn) {
      setSort((prevSortDirection) => (prevSortDirection === 'ASC' ? 'DESC' : 'ASC'));
    } else {
      setSortColumn(fieldName);
      setSort('ASC');
    }
  };

  function sortAllRows() {
    if (newData) {
      var rows = [...newData];

      rows.sort((a, b) => {
        const valueA = a[sortColumn];
        const valueB = b[sortColumn];
        function isValidDate(date) {
          return !isNaN(new Date(date).getTime());
        }
        if (isValidDate(valueA) && isValidDate(valueB)) {
          const dateA = new Date(valueA);
          const dateB = new Date(valueB);

          if (sort === 'ASC') {
            return dateA - dateB;
          } else if (sort === 'DESC') {
            return dateB - dateA;
          } else {
            return 0;
          }
        } else if (!isNaN(valueA) && !isNaN(valueB)) {
          if (sort === 'ASC') {
            return valueA - valueB;
          } else if (sort === 'DESC') {
            return valueB - valueA;
          }
        } else {
          if (sort === 'ASC') {
            return String(valueA).toLowerCase().localeCompare(String(valueB).toLowerCase());
          } else if (sort === 'DESC') {
            return String(valueB).toLowerCase().localeCompare(String(valueA).toLowerCase());
          } else {
            return 0;
          }
        }
      });
      setNewData(rows);
    }
  }

  function saveRole(ID, role, checked) {
    axiosJWT
      .post(`adminPortal/saveRole`, {
        ID: ID,
        role: role,
        checked: checked,
      })
      .then((response) => {
        if (role === 'Not_Approved') {
          getData();
        }
      })
      .catch((err) => {
        console.error(err.sqlMessage);
      });
  }

  const handleRoleChange = (data, name, ID, role, checked, type) => {
    if (
      !auth?.accounts.find((account) => account.last_login === 1)?.AccountRoles?.includes(1100) &&
      (role === 'Admin' || role === 'Master Admin') &&
      name === 'AccountRoles'
    ) {
      setNotification('Cannot control admin role due to restriction on your permissions.');
    } else {
      const newAccounts = newData.map((obj) => {
        if (obj.ID === ID) {
          if (name === 'AccountRoles') {
            if (type === 'AccountRoles') {
              return {
                ...obj,
                Accounts: obj.Accounts.map((account, index) => {
                  if (index === 0) {
                    return {
                      ...account,
                      AccountRoles: {
                        ...account.AccountRoles,
                        [role]: checked ? 1 : 0,
                      },
                    };
                  }
                  return account;
                }),
              };
            } else {
              return {
                ...obj,
                Accounts: obj.Accounts.map((account, index) => {
                  if (index === 0) {
                    return {
                      ...account,
                      Permissions: {
                        ...account.Permissions,
                        [role]: checked ? 1 : 0,
                      },
                    };
                  }
                  return account;
                }),
              };
            }
          } else {
            if (role === 'Not_Approved') {
              return {
                ...obj,
                active: 0,
                [name]: {
                  ...obj[name],
                  [role]: checked ? 1 : 0,
                },
              };
            }
            return {
              ...obj,
              [name]: {
                ...obj[name],
                [role]: checked ? 1 : 0,
              },
            };
          }
        }
        return obj;
      });
      setNewData(newAccounts);

      if (name === 'AccountRoles') {
        axiosJWT
          .post(`adminPortal/manageRoleForUserAccount`, {
            userID: data.Accounts[0].user_ID,
            accountNum: data.Accounts[0].account_num.toString(),
            accountName: customerList?.find((i) => i.customerId === data.Accounts[0].account_num)
              ?.name,
            role: role,
            checked: checked,
          })
          .then(() => {})
          .catch((err) => {
            console.error(err);
          });
      } else {
        saveRole(ID, role, checked);
      }
    }
  };
  function save(dataLine) {
    setEditableRows(
      editableRows.filter((i) => {
        return i !== dataLine.ID;
      })
    );
    saveHandler(dataLine);
  }

  function remove(dataLine) {
    setEditableRows(editableRows.filter((i) => i !== dataLine.ID));
    setNewData(newData.filter((i) => i.ID !== dataLine.ID));
    removeHandler(dataLine);
  }

  const formatDate = (dateString) => {
    const dateObject = new Date(dateString);
    const year = dateObject.getUTCFullYear();
    const month = dateObject.getUTCMonth() + 1;
    const day = dateObject.getUTCDate();
    const paddedDay = day < 10 ? `0${day}` : day;
    const paddedMonth = month < 10 ? `0${month}` : month;
    const formattedDate = `${paddedMonth}-${paddedDay}-${year}`;
    return formattedDate;
  };

  function handleSearchRoleChange(role, checked, type) {
    if (checked) {
      if (type === 'role') {
        setSearchRoles([...searchRoles, role]);
      } else {
        setSearchPermissions([...searchPermissions, role]);
      }
    } else {
      if (type === 'role') {
        setSearchRoles(searchRoles.filter((i) => i !== role));
      } else {
        setSearchPermissions(searchPermissions.filter((i) => i !== role));
      }
    }
  }

  useEffect(() => {
    // console.log(data, columns, 76);
    setNewData(data);
  }, [data]);

  async function loginAsUser(user) {
    // console.log(user);
    if (auth?.roles?.includes(5150)) {
      try {
        const response = await axiosJWT.post(
          `${apiAddress}/auth/loginForUser`,
          JSON.stringify({
            username: user.Username,
            developerRights: auth?.roles?.includes(5150),
          }),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          }
        );
        // console.log(JSON.stringify(response));
        // cookies.set('jwt', response.cookies.jwt)
        const accessToken = response?.data?.accessToken;
        const rep = response?.data?.rep;
        const dealer = response?.data?.dealer;
        const phone = response?.data?.phone;
        const roles = response?.data?.roles;
        setAuth({ rep, dealer, phone, roles, accessToken });
        console.log(accessToken);
      } catch (err) {
        console.log(err);
        if (!err?.response) {
          setErrMsg('No Server Response');
        } else if (err.response?.status === 400) {
          setErrMsg('Missing Username or Password');
        } else if (err.response?.status === 401) {
          setErrMsg('Incorrect Username or Password');
        } else {
          setErrMsg('Login Failed');
        }
      }
    }
  }

  // useEffect(() => {
  //   getData();
  // }, [userAccounts]);

  function manageAccount(userClick = '') {
    // console.log(user);
    if (openAccount) {
      setOpenAccount(false);
      setUser('');
      setUserAccounts([]);
    } else if (!openAccount && userClick !== user) {
      setOpenAccount(true);
      setUser(userClick);
      setUserAccounts(userClick.Accounts);
    }
  }

  useEffect(() => {
    axiosJWT
      .post(`adminPortal/getCustomers`, {
        search: '',
        active: 'A',
        limit: 99999,
      })
      .then((response) => {
        // console.log(response.data[0], 26);
        setCustomerList(response.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const approveAccount = async (data) => {
    await axiosJWT
      .post(`adminPortal/verifyAccount`, {
        accountRequestID: data.ID,
      })
      .then((response) => {
        getData();
        setNotification(response.data.message);
      })
      .catch((err) => {
        setNotification(err.response.data.error);
        console.error(err);
      });
  };

  const getAdminApprovals = async () => {
    await axiosJWT
      .get(`adminPortal/adminApprovals`)
      .then((response) => {
        setAdminApprovals(response.data);
      })
      .catch((err) => {
        setNotification(err.response.data.error);
        console.error(err);
      });
  };

  const removeRouteRequest = async (dataLine) => {
    await axiosJWT
      .post(`adminPortal/removeRouteRequest`, {
        ID: dataLine.ID,
        request: dataLine.request,
      })
      .then(() => {
        getData();
        setNotification('Success!');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const managePermissions = async (dataLine, checked) => {
    await axiosJWT
      .post(`adminPortal/manageRoleForUserAccount`, {
        userID: dataLine.ID,
        accountNum: dataLine?.account_num,
        accountName: customerList?.find(
          (i) => i?.customerId?.toString() === dataLine?.account_num?.toString()
        )?.name,
        role: dataLine.permission,
        checked: checked,
      })
      .then(() => {
        getData();
        setNotification('Success!');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  function manageAccount(userClick = '') {
    if (openAccount) {
      setOpenAccount(false);
      setUser('');
      setUserAccounts([]);
    } else if (!openAccount && userClick !== user) {
      setOpenAccount(true);
      setUser(userClick);
      setUserAccounts(userClick.Accounts);
    }
  }

  async function loginAsUser(user) {
    if (auth?.roles?.includes(5150)) {
      try {
        const response = await axiosJWT.post(
          `${apiAddress}/auth/loginForUser`,
          JSON.stringify({
            username: user.Username,
            developerRights: auth?.roles?.includes(5150),
          }),
          {
            headers: { 'Content-Type': 'application/json' },
            withCredentials: true,
          }
        );
        const accessToken = response?.data?.accessToken;
        const rep = response?.data?.rep;
        const dealer = response?.data?.dealer;
        const phone = response?.data?.phone;
        const roles = response?.data?.roles;
        setAuth({ rep, dealer, phone, roles, accessToken });
        console.error(accessToken);
      } catch (err) {
        console.error(err);
        if (!err?.response) {
          setNotification('No Server Response');
        } else if (err.response?.status === 400) {
          setNotification('Missing Username or Password');
        } else if (err.response?.status === 401) {
          setNotification('Incorrect Username or Password');
        } else {
          setNotification('Login Failed');
        }
      }
    }
  }

  useEffect(() => {
    if (searchInput && searchInput === filters) {
      // Clear search params
      const url = new URL(window.location.href);
      url.searchParams.delete('searchInput');
      window.history.replaceState(null, '', url.toString());
    }
  }, [filters]);

  useEffect(() => {
    if (imageHover) {
      setTopUse(document?.getElementById(imageHover)?.getBoundingClientRect().top - 260);
      setRightUse(document?.getElementById(imageHover)?.getBoundingClientRect().left - 95);
    } else {
      setTopUse();
      setRightUse();
    }
  }, [imageHover]);

  useEffect(() => {
    if (notification) {
      const timer = setTimeout(() => {
        setNotification();
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [notification]);

  useEffect(() => {
    if (batchImportData) {
      batchImportHandler(batchImportData);
      setBatchImportData();
    }
  }, [batchImportData]);

  useEffect(() => {
    if (uploadImportData) {
      uploadImportHandler(uploadImportData);
      setUploadImportData();
    }
  }, [uploadImportData]);

  useEffect(() => {
    sortAllRows();
  }, [sortColumn, sort]);

  useEffect(() => {
    axiosJWT
      .post(`adminPortal/getRoles`)
      .then((response) => {
        setRoles(Object.values(response.data));
      })
      .catch((err) => {
        console.error(err.sqlMessage);
      });
  }, []);

  useEffect(() => {
    setNewData(data);
  }, [data]);

  useEffect(() => {
    axiosJWT
      .post(`adminPortal/getCustomers`, {
        search: '',
        active: 'A',
        limit: 99999,
      })
      .then((response) => {
        setCustomerList(response.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    getAdminApprovals(data);
  }, [data]);

  return (
    <>
      {notice ? (
        <div
          className='notice'
          style={{
            fontWeight: '600',
          }}
        >
          {notice}
        </div>
      ) : (
        ''
      )}
      {backHandler ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            left: '20rem',
            top: '15rem',
            cursor: 'pointer',
          }}
          onClick={() => {
            backHandler();
          }}
        >
          <BiLeftArrow style={{ marginRight: '.5rem' }} /> BACK
        </div>
      ) : (
        ''
      )}

      {openAdd ? (
        <EditableFormAddNew
          setOpenAdd={setOpenAdd}
          setAddValues={setAddValues}
          columns={columns}
          addValues={addValues}
          addHandler={addHandler}
          getData={getData}
        />
      ) : (
        ''
      )}
      {openAccount && (
        <ManageAccount
          customerList={customerList}
          user={user}
          setUser={setUser}
          userAccounts={userAccounts}
          setUserAccounts={setUserAccounts}
          manageAccount={manageAccount}
          setOpenAccount={setOpenAccount}
          openAccount={openAccount}
          getData={getData}
        />
      )}
      <div className='users-container'>
        <h2>{title}</h2>
        <div className='underline'></div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {exportAvailable && importBatch ? (
            <button
              className='editableFormButton'
              onClick={exportHandler}
            >
              Export for Batch Update
            </button>
          ) : !importBatch && exportAvailable ? (
            <button
              className='editableFormButton'
              onClick={exportHandler}
            >
              Export
            </button>
          ) : (
            ''
          )}
          {importBatch && auth?.roles?.includes(1117) ? (
            <UploadFile
              text={'Import Batch Update'}
              setData={setBatchImportData}
            />
          ) : (
            ''
          )}

          {exportTemplateHanlder ? (
            <button
              onClick={exportTemplateHanlder}
              className='editableFormButton'
            >
              Export Upload Template
            </button>
          ) : (
            ''
          )}

          {exportTemplateHanlder ? (
            <UploadFile
              text={'Import Upload Template'}
              setData={setUploadImportData}
            />
          ) : (
            ''
          )}
        </div>
      </div>
      {textDesc && auth?.roles?.includes(5150) ? (
        <h3 style={{ textAlign: 'center', textTransform: 'none' }}>{textDesc}</h3>
      ) : (
        ''
      )}
      <div
        onClick={() => {
          if (sortOpen) {
            setSortOpen(false);
          }
        }}
      >
        <div className='search-sort-bar'>
          <div className='search-all-fields'>
            <CgSearch style={{ transform: 'scaleX(-1)' }} />
            <input
              onChange={(e) => setFilters(e.target.value)}
              placeholder='Search'
              value={filters || ''}
            ></input>
          </div>
          <div className='sort-div flex-div'>
            <div
              onClick={() => setSortOpen(!sortOpen)}
              className='flex-div sort-orders'
              style={{
                marginRight: !addHandler && !setShowAddAccount ? '24px' : '40px',
              }}
            >
              <MdOutlineSort />
              <h4>Sort Items</h4>
            </div>
            {sortOpen && (
              <div
                style={{
                  margin: !addHandler && !setShowAddAccount ? '19rem 0rem 0rem -1rem' : '',
                }}
                className='sort-dropdown'
              >
                {columns
                  ?.filter((i) => (i.display || i.name === 'ID') && i.type !== 'roles')
                  ?.map((i) => (
                    <div
                      onClick={() => handleSortChange(i.name)}
                      key={i.name}
                      style={{
                        color: sortColumn === i.name ? 'var(--darkblue)' : '#707070',
                      }}
                      className='sort-line-item'
                    >
                      <div className='sort-line-item'>
                        {sortColumn === i.name ? (
                          <RiCheckboxCircleFill />
                        ) : (
                          <RiCheckboxBlankCircleLine />
                        )}
                        <div>{i?.name?.replaceAll('_', ' ')}</div>
                      </div>
                      {sort === 'ASC' && sortColumn === i.name ? (
                        <MdKeyboardArrowUp
                          style={{
                            cursor: 'pointer',
                            alignSelf: 'center',
                            marginLeft: '5px',
                          }}
                        />
                      ) : (
                        <MdKeyboardArrowDown
                          style={{
                            color: sortColumn === i.name ? '#00435B' : 'grey',
                            cursor: 'pointer',
                            alignSelf: 'center',
                            marginLeft: '5px',
                          }}
                        />
                      )}
                    </div>
                  ))}
              </div>
            )}
            {addHandler || setShowAddAccount ? (
              <button
                onClick={() => (setShowAddAccount ? setShowAddAccount(true) : setOpenAdd(true))}
                className='editable-form-search'
              >
                Add New
              </button>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className='white-div-editable-form'></div>
        <div
          style={{
            overflow: 'auto',
            maxHeight: '825px',
            maxWidth: '1465px',
            minHeight: '500px',
            marginLeft: '14.3rem',
          }}
        >
          <table className='content-table'>
            <thead>
              <tr>
                {columns
                  .filter((i) => {
                    return i.display;
                  })
                  .map((i) => {
                    switch (i.type.toLowerCase) {
                      case 'boolean':
                        return <th>{i.name}</th>;
                      default:
                        return (
                          <th>
                            <div style={{ display: 'flex' }}>
                              {i.name === 'internalRoles' || i.name === 'AccountRoles'
                                ? i.name
                                    .split(/(?=[A-Z])/)
                                    .join(' ')
                                    .replace(/_/g, ' ')
                                    .replace(/\b\w/g, (match) => match.toUpperCase())
                                : i.name
                                    .replace(/_/g, ' ')
                                    .replace(/\b\w/g, (match) => match.toUpperCase())}
                              {/* <br></br> */}
                              {i.name === 'internalRoles' ||
                              i.name === 'AccountRoles' ||
                              i.name === 'Permissions' ||
                              i.name === 'Accounts Roles' ? (
                                <>
                                  <div>
                                    {showSelect === i.name ? (
                                      <BiChevronUp
                                        onClick={() => {
                                          setShowSelect();
                                        }}
                                        className='laminate-select-down laminates-svg'
                                      />
                                    ) : (
                                      <BiChevronDown
                                        onClick={() => {
                                          setShowSelect(i.name);
                                        }}
                                        className='laminate-select-down laminates-svg'
                                      />
                                    )}{' '}
                                  </div>
                                  {showSelect === i.name && (
                                    <div
                                      style={{
                                        marginTop: '30px',
                                        marginLeft: '-10px',
                                        width: '135px',
                                      }}
                                      className='roles-dropdown'
                                    >
                                      {(i.name === 'internalRoles'
                                        ? roles
                                        : accountRoles?.filter((j) =>
                                            i.name === 'Accounts Roles'
                                              ? true
                                              : i.name === 'Permissions'
                                              ? j.permission === 1
                                              : j.permission === 0
                                          )
                                      )?.map((role, index) => (
                                        <div
                                          key={index}
                                          style={{
                                            display: 'flex',
                                            cursor: 'default',
                                            color: '#707070',
                                          }}
                                        >
                                          <input
                                            type='checkbox'
                                            checked={
                                              i.name === 'Permissions' ||
                                              i.name === 'Accounts Roles'
                                                ? searchPermissions.includes(role.role)
                                                : searchRoles.includes(
                                                    i.name === 'internalRoles' ? role : role.role
                                                  )
                                            }
                                            onChange={(event) =>
                                              handleSearchRoleChange(
                                                i.name === 'internalRoles' ? role : role.role,
                                                event.target.checked,
                                                i.name === 'Permissions' ||
                                                  i.name === 'Accounts Roles'
                                                  ? 'permission'
                                                  : 'role'
                                              )
                                            }
                                            style={{ cursor: 'pointer' }}
                                          />

                                          <div>{i.name === 'internalRoles' ? role : role.role}</div>
                                        </div>
                                      ))}
                                      <div
                                        style={{
                                          display: 'flex',
                                          cursor: 'default',
                                        }}
                                      >
                                        <input
                                          type='checkbox'
                                          checked={
                                            i.name === 'Permissions' || i.name === 'Accounts Roles'
                                              ? searchPermissions.includes('No Selections')
                                              : searchRoles.includes('No Selections')
                                          }
                                          onChange={(event) =>
                                            handleSearchRoleChange(
                                              'No Selections',
                                              event.target.checked,
                                              i.name === 'Permissions' ||
                                                i.name === 'Accounts Roles'
                                                ? 'permission'
                                                : 'role'
                                            )
                                          }
                                          style={{ cursor: 'pointer' }}
                                        />
                                        <div>No Selections</div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              ) : i.type === 'boolean' ? (
                                // <div
                                //   style={{
                                //     display: "flex",
                                //     justifyContent: "center",
                                //     alignItems: "center",
                                //   }}
                                // >
                                //   <AiOutlineCheck
                                //     style={{ cursor: "pointer" }}
                                //     onClick={() =>
                                //       selectedFilter && selectedFilter[i.name] === 1
                                //         ? setSelectedFilter("")
                                //         : setSelectedFilter({
                                //             [i.name]: 1,
                                //           })
                                //     }
                                //     className={`boolean-filter ${
                                //       selectedFilter && selectedFilter[i.name] === 1
                                //         ? "selected-filter"
                                //         : ""
                                //     }`}
                                //   />
                                //   <AiOutlineClose
                                //     style={{ cursor: "pointer" }}
                                //     onClick={() =>
                                //       selectedFilter && selectedFilter[i.name] === 0
                                //         ? setSelectedFilter("")
                                //         : setSelectedFilter({
                                //             [i.name]: 0,
                                //           })
                                //     }
                                //     className={`boolean-filter ${
                                //       selectedFilter && selectedFilter[i.name] === 0
                                //         ? "selected-filter"
                                //         : ""
                                //     }`}
                                //   />
                                // </div>
                                ''
                              ) : (
                                ''
                              )}
                            </div>
                          </th>
                        );
                    }
                  })}
                {auth?.roles?.includes(5150) && title === 'Users' ? <th>Login As User</th> : ''}
                {title === 'Pending Approvals' ? <th>Approve Account</th> : ''}
                {title === 'Pending Approvals' ? <th>Remove</th> : ''}
                {title === 'Dealer Account Requests' ? <th>Manage Account</th> : ''}
                {title === 'Route Requests' ? <th>Approve</th> : ''}
                {title === 'Route Requests' ? <th>Deny</th> : ''}
                {clickHandler ? <th>Edit Subitem</th> : ''}
                {columns.every((i) => i.editable === false) ? (
                  ''
                ) : (
                  <th
                    style={{
                      width: '4rem',
                      position: 'sticky',
                      top: '10px',
                      zIndex: 1,
                      right: '-1px',
                    }}
                  ></th>
                )}
              </tr>
            </thead>
            <tbody>
              {newData
                ?.filter((dataIn) => {
                  if (searchPermissions.length >= 1) {
                    if (Object.keys(dataIn).includes('Accounts')) {
                      if (dataIn?.Accounts?.length > 0) {
                        if (
                          Object.values(dataIn['Accounts'][0]?.Permissions).every((i) => i === 0) &&
                          Object.values(dataIn['Accounts'][0]?.AccountRoles).every(
                            (i) => i === 0
                          ) &&
                          searchPermissions.includes('No Selections')
                        ) {
                          return true;
                        }
                        return (
                          Object.keys(dataIn['Accounts'][0].Permissions).some((i) => {
                            const targetValue = dataIn['Accounts'][0].Permissions[i];
                            // console.log(userRole, value, targetValue);
                            // console.log(searchRoles, i, 564);
                            return searchPermissions.includes(i) && targetValue === 1;
                          }) ||
                          Object.keys(dataIn['Accounts'][0].AccountRoles).some((i) => {
                            const targetValue = dataIn['Accounts'][0].AccountRoles[i];
                            // console.log(userRole, value, targetValue);
                            // console.log(searchRoles, i, 564);
                            return searchPermissions.includes(i) && targetValue === 1;
                          })
                        );
                      } else {
                        return false;
                      }
                    } else {
                      if (
                        Object.values(dataIn['Accounts'][0]?.Permissions).every((i) => i === 0) &&
                        searchPermissions.includes('No Selections')
                      ) {
                        return true;
                      }
                      return Object.keys(dataIn['Accounts'][0]?.Permissions).some((i) => {
                        const targetValue = dataIn['Accounts'][0].Permissions[i];
                        // console.log(userRole, value, targetValue);
                        // console.log(searchRoles, i, 564);
                        return searchPermissions.includes(i) && targetValue === 1;
                      });
                    }
                  }
                  if (searchRoles.length >= 1) {
                    if (
                      Object.values(
                        dataIn['internalRoles']
                          ? dataIn['internalRoles']
                          : dataIn['Accounts'][0].AccountRoles
                      ).every((i) => i === 0) &&
                      searchRoles.includes('No Selections')
                    ) {
                      return true;
                    }
                    return Object.keys(
                      dataIn['internalRoles']
                        ? dataIn['internalRoles']
                        : dataIn['Accounts'][0].AccountRoles
                    ).some((i) => {
                      const targetValue = (
                        dataIn['internalRoles']
                          ? dataIn['internalRoles']
                          : dataIn['Accounts'][0].AccountRoles
                      )[i];
                      return searchRoles.includes(i) && targetValue === 1;
                    });
                  } else if (selectedFilter) {
                    const key = Object.keys(selectedFilter);
                    const value = Object.values(selectedFilter);

                    return dataIn[key] === value[0];
                  } else {
                    return true;
                  }
                })
                ?.filter((dataIn) => {
                  if (filters) {
                    return (
                      Object.values(dataIn)
                        ?.toString()
                        .toLowerCase()
                        ?.includes(filters.toString().toLowerCase()) ||
                      (dataIn?.Accounts?.length > 0
                        ? dataIn?.Accounts?.some(
                            (i) =>
                              customerList
                                ?.find((j) => j.customerId === i.account_num)
                                ?.name?.toString()
                                .toLowerCase()
                                .includes(filters.toString().toLowerCase()) ||
                              i.account_num
                                .toString()
                                .toLowerCase()
                                .includes(filters.toString().toLowerCase())
                          )
                        : false)
                    );
                  } else {
                    return true;
                  }
                })
                .map((dataLine, index) => {
                  // console.log(dataLine);
                  let accountType = !Object.keys(dataLine)?.includes('internalRoles')
                    ? Object.keys(dataLine).includes('Accounts')
                      ? dataLine?.Accounts[0]
                      : null
                    : null;
                  return (
                    <tr key={index}>
                      {columns
                        ?.filter((i) => {
                          return i.display;
                        })
                        ?.map((i, index) => {
                          if (!i.editable) {
                            if (i.type === 'boolean') {
                              return (
                                <td className={` editable-row`}>
                                  {dataLine[i.name] === 1 ? <AiOutlineCheck /> : <AiOutlineClose />}
                                </td>
                              );
                            } else if (i.type === 'account_roles') {
                              return (
                                <td
                                  style={{ cursor: 'default' }}
                                  className={` editable-row 
                                    `}
                                >
                                  {dataLine[i.fieldName]?.map((element, index, array) => (
                                    <>
                                      {' '}
                                      {array.length > 1 ? (
                                        <></>
                                      ) : Object.keys(element.AccountRoles).filter(
                                          (i) => element.AccountRoles[i] === 1
                                        )?.length > 0 ? (
                                        Object.keys(element.AccountRoles)
                                          .filter((i) => element.AccountRoles[i] === 1)
                                          .map((i) => (
                                            <>
                                              <span>{i}</span>
                                              <br></br>
                                            </>
                                          ))
                                      ) : (
                                        ''
                                      )}
                                      {array.length > 1 ? (
                                        <></>
                                      ) : Object.keys(element.Permissions).filter(
                                          (i) => element.Permissions[i] === 1
                                        )?.length > 0 ? (
                                        Object.keys(element.Permissions)
                                          .filter((i) => element.Permissions[i] === 1)
                                          .map((i) => (
                                            <>
                                              <span>{i}</span>
                                              <br></br>
                                            </>
                                          ))
                                      ) : (
                                        ''
                                      )}
                                    </>
                                  ))}
                                </td>
                              );
                            } else if (i.type === 'accounts') {
                              return (
                                <td
                                  style={{ cursor: 'default' }}
                                  className={` editable-row  
                                  `}
                                >
                                  {dataLine[i.name]?.map((element, index, array) => (
                                    <span key={index}>
                                      {array.length > 1 && index + 1 + '. '}
                                      {element.account_num} {' - '}
                                      {
                                        customerList?.find(
                                          (i) => i.customerId === element.account_num
                                        )?.name
                                      }{' '}
                                      <br></br>
                                    </span>
                                  ))}
                                </td>
                              );
                            } else if (i.type === 'manage') {
                              return (
                                <td
                                  key={index}
                                  className={` editable-row`}
                                  //
                                >
                                  <div
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'column',
                                    }}
                                  >
                                    <button
                                      style={{
                                        margin: '0px 0px 5px 0px',
                                      }}
                                      onClick={() => manageAccount(dataLine)}
                                      className='manage-button'
                                    >
                                      Manage
                                    </button>
                                    {dataLine['active'] ? (
                                      <button
                                        style={{
                                          margin: '0px',
                                        }}
                                        className={`manage-button`}
                                        onClick={() =>
                                          handleRoleChange(
                                            dataLine,
                                            i.fieldName,
                                            dataLine.ID,
                                            'Not_Approved',
                                            1,
                                            i.name
                                          )
                                        }
                                      >
                                        Decline
                                      </button>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </td>
                              );
                            } else {
                              return <td key={index}>{dataLine[i.name]}</td>;
                            }
                          } else {
                            switch (i.type.toLowerCase()) {
                              case 'text':
                                return (
                                  <td
                                    className={` editable-row ${
                                      editableRows?.includes(dataLine.ID) ? 'edit-mode-design' : ''
                                    }`}
                                  >
                                    {editableRows?.includes(dataLine.ID) ? (
                                      <input
                                        type='text'
                                        value={dataLine[i.name]}
                                        onChange={(event) =>
                                          handleInputChange(event, dataLine, i.name)
                                        }
                                      />
                                    ) : (
                                      dataLine[i.name]
                                    )}
                                  </td>
                                );
                                break;
                              case 'textarea':
                                return (
                                  <td
                                    className={` editable-row ${
                                      editableRows?.includes(dataLine.ID) ? 'edit-mode-design' : ''
                                    }`}
                                  >
                                    {editableRows?.includes(dataLine.ID) ? (
                                      <input
                                        type='text'
                                        value={dataLine[i.name]}
                                        onChange={(event) =>
                                          handleInputChange(event, dataLine, i.name)
                                        }
                                      />
                                    ) : (
                                      dataLine[i.name]
                                    )}
                                  </td>
                                );
                                break;
                              case 'boolean':
                                return (
                                  <td
                                    className={` editable-row ${
                                      editableRows?.includes(dataLine.ID) ? 'edit-mode' : ''
                                    }`}
                                  >
                                    {editableRows?.includes(dataLine.ID) ? (
                                      <input
                                        type='checkbox'
                                        checked={dataLine[i.name] === 1}
                                        onChange={(event) =>
                                          handleInputChange(event, dataLine, i.name, true)
                                        }
                                      />
                                    ) : dataLine[i.name] === 1 ? (
                                      <AiOutlineCheck />
                                    ) : dataLine[i.name] === 0 ? (
                                      <AiOutlineClose />
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                );
                                break;
                              // case "roleboolean":
                              //   return (
                              //     <h4 className={`editable-row`}>
                              //       <input
                              //         type="checkbox"
                              //         checked={dataLine[i.name] === 1}
                              //         onChange={(event) =>
                              //           handleRoleChange(
                              //             dataLine.ID,
                              //             i.name,
                              //             event.target.checked
                              //           )
                              //         }
                              //       />
                              //     </h4>
                              //   );
                              //   break;
                              case 'roles':
                                return (
                                  <td
                                    style={{ position: 'relative' }}
                                    className={` editable-row`}
                                  >
                                    <>
                                      <div
                                        onClick={() => {
                                          if (
                                            showSelect === dataLine.ID &&
                                            dropdownType ===
                                              (accountType ? accountType[i.name] : dataLine[i.name])
                                          ) {
                                            setShowSelect(null);
                                            setDropdownType(null);
                                          } else {
                                            setShowSelect(dataLine.ID);
                                            setDropdownType(
                                              accountType ? accountType[i.name] : dataLine[i.name]
                                            );
                                          }
                                        }}
                                        className={`login-input-holder selection `}
                                        style={{
                                          display: 'flex',
                                          width: '140px',
                                          justifyContent: 'space-between',
                                          padding: '6px 10px',
                                          height: '35px',
                                          borderRadius: '9px',
                                        }}
                                      >
                                        <div className='select-down'>
                                          {/* {console.log(dataLine[i], 1027)} */}
                                          {/* {console.log(accountType)} */}
                                          {Object.values(
                                            accountType ? accountType[i.name] : dataLine[i.name]
                                          ).filter((i) => i === 1).length === 1
                                            ? Object.keys(
                                                accountType ? accountType[i.name] : dataLine[i.name]
                                              ).filter(
                                                (key) =>
                                                  (accountType
                                                    ? accountType[i.name]
                                                    : dataLine[i.name])[key] === 1
                                              )
                                            : `${
                                                Object.values(
                                                  accountType
                                                    ? accountType[i.name]
                                                    : dataLine[i.name]
                                                ).filter((i) => i === 1).length
                                              } selected`}
                                        </div>
                                        {showSelect === dataLine.ID &&
                                        dropdownType ===
                                          (accountType ? accountType[i.name] : dataLine[i.name]) ? (
                                          <BiChevronUp
                                            onClick={() => {
                                              setShowSelect();
                                              setDropdownType();
                                            }}
                                            className='laminate-select-down laminates-svg'
                                          />
                                        ) : (
                                          <BiChevronDown
                                            onClick={() => {
                                              setShowSelect(dataLine.ID);
                                              setDropdownType(
                                                accountType ? accountType[i.name] : dataLine[i.name]
                                              );
                                            }}
                                            className='laminate-select-down laminates-svg'
                                          />
                                        )}
                                      </div>
                                      {showSelect === dataLine.ID &&
                                      dropdownType ===
                                        (accountType ? accountType[i.name] : dataLine[i.name]) ? (
                                        <div className='roles-dropdown'>
                                          {/* {console.log(dataLine, i, 8888)} */}

                                          {Object.keys(
                                            accountType ? accountType[i.name] : dataLine[i.name]
                                          ).map((role) => (
                                            <div
                                              key={index}
                                              style={{
                                                display: 'flex',
                                                cursor: 'default',
                                              }}
                                            >
                                              <input
                                                type='checkbox'
                                                checked={
                                                  (accountType
                                                    ? accountType[i.name]
                                                    : dataLine[i.name])[role] === 1
                                                }
                                                style={{ cursor: 'pointer' }}
                                                onChange={(event) =>
                                                  handleRoleChange(
                                                    dataLine,
                                                    i.fieldName,
                                                    dataLine.ID,
                                                    role,
                                                    event.target.checked,
                                                    i.name
                                                  )
                                                }
                                              />

                                              <div style={{ marginLeft: '7px' }}>{role}</div>
                                            </div>
                                          ))}
                                        </div>
                                      ) : (
                                        ''
                                      )}{' '}
                                    </>
                                  </td>
                                );
                                break;

                              case 'dropdown':
                                return (
                                  <td
                                    className={`  editable-row ${
                                      editableRows?.includes(dataLine.ID) ? 'edit-mode-design' : ''
                                    }`}
                                  >
                                    {editableRows?.includes(dataLine.ID) ? (
                                      <select
                                        value={dataLine[i.name]}
                                        onChange={(event) =>
                                          handleInputChange(event, dataLine, i.name)
                                        }
                                      >
                                        {i?.values?.map((j) => {
                                          return (
                                            <option
                                              value={j}
                                              key={j}
                                            >
                                              {j}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    ) : (
                                      dataLine[i.name]
                                    )}
                                  </td>
                                );
                                break;

                              case 'cloudinaryimage':
                                return (
                                  <td
                                    className={` editable-row ${
                                      editableRows?.includes(dataLine.ID) ? 'edit-mode-design' : ''
                                    }`}
                                  >
                                    <div
                                      onMouseEnter={() => setImageHover(dataLine.ID)}
                                      onMouseLeave={() => setImageHover()}
                                      id={dataLine.ID}
                                      style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        height: '100%',
                                      }}
                                    >
                                      {editableRows?.includes(dataLine.ID) ? (
                                        <input
                                          style={{ minWidth: '100px' }}
                                          type='text'
                                          value={dataLine[i.name]}
                                          onChange={(event) =>
                                            handleInputChange(event, dataLine, i.name)
                                          }
                                        />
                                      ) : (
                                        dataLine[i]
                                      )}
                                      <ImageWithHover
                                        topUse={topUse}
                                        rightUse={rightUse}
                                        src={dataLine[i.name]}
                                      />
                                    </div>
                                  </td>
                                );
                                break;
                              case 'link':
                                return (
                                  <td
                                    className={` editable-row ${
                                      editableRows?.includes(dataLine.ID) ? 'edit-mode-design' : ''
                                    }`}
                                    //
                                  >
                                    <div
                                      onClick={() => {
                                        if (!editableRows?.includes(dataLine.ID)) {
                                          clipboardCopy(dataLine[i.name]);
                                          setNotification('Copied!');
                                        }
                                      }}
                                      className={
                                        !editableRows?.includes(dataLine.ID) && dataLine[i.name]
                                          ? 'link-column'
                                          : ''
                                      }
                                    >
                                      {editableRows?.includes(dataLine.ID) ? (
                                        <input
                                          style={{ width: '100%' }}
                                          type='text'
                                          value={dataLine[i.name]}
                                          onChange={(event) =>
                                            handleInputChange(event, dataLine, i.name)
                                          }
                                        />
                                      ) : (
                                        dataLine[i.name] && (
                                          <>
                                            <PiLinkSimpleBold
                                              style={{
                                                height: '1.1rem',
                                                width: '1.1rem',
                                                marginRight: '2px',
                                              }}
                                            />
                                            <div>{dataLine[i.name].substring(0, 17)}</div>
                                          </>
                                        )
                                      )}
                                    </div>
                                  </td>
                                );

                              case 'datetime':
                                return (
                                  <td
                                    className={` editable-row ${
                                      editableRows?.includes(dataLine.ID) ? 'edit-mode-design' : ''
                                    }`}
                                    //
                                  >
                                    {editableRows?.includes(dataLine.ID) ? (
                                      <DatePicker
                                        key={dataLine.ID}
                                        onChange={(date) => {
                                          // console.log(date, 587);
                                          handleDateChange(date, dataLine, i.name);
                                        }}
                                        selected={
                                          dataLine[i.name] ? new Date(dataLine[i.name]) : ''
                                        }
                                      />
                                    ) : dataLine[i.name] ? (
                                      formatDate(dataLine[i.name])
                                    ) : (
                                      ''
                                    )}
                                  </td>
                                );
                            }
                          }
                        })}
                      {auth?.roles?.includes(5150) && title === 'Users' ? (
                        <td>
                          <button
                            onClick={() => loginAsUser(dataLine)}
                            style={{
                              fontSize: '10px',
                              height: '50px',
                              minWidth: '150px',
                            }}
                            className='editableFormButton'
                          >
                            Login As {dataLine.Name}
                          </button>
                        </td>
                      ) : (
                        ''
                      )}
                      {title === 'Pending Approvals' && (
                        <td>
                          <button
                            style={{
                              fontSize: '14px',
                              minWidth: '120px',
                              margin: '0px',
                            }}
                            className={`editableFormButton ${
                              dataLine?.Completed === 1 ||
                              adminApprovals?.some((i) => i.add_request_ID === dataLine.ID)
                                ? 'disabled-button'
                                : ''
                            }`}
                            onClick={() =>
                              dataLine?.Completed === 1 ||
                              adminApprovals?.some((i) => i.add_request_ID === dataLine.ID)
                                ? ''
                                : approveAccount(dataLine)
                            }
                          >
                            Approve
                          </button>
                        </td>
                      )}
                      {title === 'Pending Approvals' && (
                        <td>
                          <button
                            style={{
                              fontSize: '14px',
                              minWidth: '120px',
                              margin: '0px',
                            }}
                            className={`editableFormButton `}
                            onClick={() => remove(dataLine)}
                          >
                            Remove
                          </button>
                        </td>
                      )}
                      {title === 'Dealer Account Requests' && (
                        <td>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {' '}
                            <button
                              style={{
                                margin: '0px 0px 5px 0px',
                              }}
                              className={`manage-button `}
                              onClick={() => manageAccount(dataLine)}
                            >
                              Manage
                            </button>
                            <button
                              style={{
                                margin: '0px',
                              }}
                              className={`manage-button`}
                              onClick={() =>
                                handleRoleChange(
                                  dataLine,
                                  'Dealer Account Requests',
                                  dataLine.ID,
                                  'Not_Approved',
                                  1,
                                  'roles'
                                )
                              }
                            >
                              Decline
                            </button>
                          </div>
                        </td>
                      )}
                      {title === 'Route Requests' && (
                        <td>
                          <button
                            style={{
                              fontSize: '14px',
                              minWidth: '120px',
                              margin: '0px',
                            }}
                            className={`editableFormButton `}
                            onClick={() => managePermissions(dataLine, true)}
                          >
                            Approve
                          </button>
                        </td>
                      )}
                      {title === 'Route Requests' && (
                        <td>
                          <button
                            style={{
                              fontSize: '14px',
                              minWidth: '120px',
                              margin: '0px',
                            }}
                            className={`editableFormButton `}
                            onClick={() =>
                              title === 'Dealer Account Requests'
                                ? removeRouteRequest(dataLine)
                                : managePermissions(dataLine, false)
                            }
                          >
                            Deny
                          </button>
                        </td>
                      )}
                      {clickHandler ? (
                        <td
                          onClick={() => {
                            if (clickHandler) {
                              clickHandler(dataLine);
                            }
                          }}
                        >
                          <BsArrowReturnRight
                            style={{
                              height: '1.5rem',
                              width: '1.2rem',
                              color: 'var(--darkblue)',
                            }}
                          />
                        </td>
                      ) : (
                        ''
                      )}

                      {columns.every((i) => i.editable === false) ? null : (
                        <td
                          style={{
                            position: 'sticky',
                            right: '-1px',
                            background: 'white',
                            zIndex: 1,
                          }}
                        >
                          {editableRows?.includes(dataLine.ID) ? (
                            <div className='edit-icons'>
                              {removeHandler ? (
                                <FaTrash
                                  className='garbage'
                                  onClick={() => {
                                    remove(dataLine);
                                  }}
                                />
                              ) : (
                                ''
                              )}
                              <FaSave
                                className=' pencil save'
                                onClick={() => save(dataLine)}
                              />
                              <CgClose
                                className='back'
                                onClick={() => {
                                  setEditableRows(
                                    editableRows.filter((i) => {
                                      return i !== dataLine.ID;
                                    })
                                  );
                                }}
                              />
                            </div>
                          ) : (
                            <FaPen
                              className='pencil'
                              onClick={() => handleEdit(dataLine)}
                            />
                          )}
                        </td>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
        {notification ? <div className='notice'>{notification}</div> : ''}
      </div>
    </>
  );
};

export default EditableFormDesign;
