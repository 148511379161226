import React, { useEffect, useState } from 'react';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { FaCheck } from 'react-icons/fa';
import { SlArrowDown } from 'react-icons/sl';
import axios from '../../api/axios';
import { HiInformationCircle } from 'react-icons/hi';
// eslint-disable-next-line no-undef
const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;
const CategoryBar = ({
  productList,
  skuList,
  addRemoveSku,
  selectedSkus,
  catLevels,
  setCatLevels,
  selectedMediaTypes,
  setSelectedMediaTypes,
  selectedType,
  setSelectedType,
  navigateOnChange,
  setImageEffects,
  imageEffects,
  setApplyEffects,
}) => {
  // const [openCats, setOpenCats] = useState(false);
  // const [openSkus, setOpenSkus] = useState(false);
  // const [openType, setOpenType] = useState(false);
  // const [openImageType, setOpenImageType] = useState(false);
  const optionDrops = ['cats', 'skus', 'mediaType', 'imageType'];
  const [openOptions, setOpenOptions] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [transformations, setTransformations] = useState([]);
  const [openSuboptions, setOpenSuboptions] = useState(false);
  const [errorNotification, setErrorNotification] = useState(false);
  const imageTypes = ['Product', 'Details', 'Environment'];
  const litTypes = ['Sell Sheet', 'Assembly Instructions'];
  const types = ['Images', 'Literature'];
  const [infoHover, setInfoHover] = useState(false);
  // const navigate = useNavigate();
  function handleTitleClick(type) {
    if (openOptions === type) {
      setOpenOptions(false);
    } else {
      setOpenOptions(type);
    }
  }

  async function getTransformations() {
    axios.get(`${apiAddress}/content/getTransformations`).then((response) => {
      setTransformations(response.data);
    });
  }

  useEffect(() => {
    getTransformations();
  }, []);

  function handleApply() {
    setApplyEffects(true);
    setOpenOptions(false);
  }

  const handleInputChangeAdd = (event) => {
    const { name, value } = event.target;
    const transformName = transformations.find((i) => i.text === name);
    const isNum = !isNaN(parseFloat(value)) && isFinite(value);
    if (!isNum && transformName.options === 'px' && value !== '') {
      // setErrorNotification(true);
    } else if (imageEffects) {
      setImageEffects((values) => ({
        ...values,
        [transformName.code]: value,
      }));
    } else {
      setImageEffects({
        [transformName.code]: value,
      });
    }
  };

  async function getTransformations() {
    axios.get(`${apiAddress}/content/getTransformations`).then((response) => {
      setTransformations(response.data);
    });
  }

  useEffect(() => {
    getTransformations();
  }, []);

  return (
    <div className='category-bar-container'>
      <h2 className='category-title'>Search & Filter Images</h2>
      <h3
        className={`select-cat ${openOptions === 'mediaType' ? 'active' : 'non-active-div'}`}
        onClick={() => {
          handleTitleClick('mediaType');
        }}
      >
        Select Media Type
        <div
          onClick={() => {
            handleTitleClick('mediaType');
          }}
        >
          <SlArrowDown className={`arrow-icon ${openOptions === 'mediaType' ? 'rotated' : ''}`} />
        </div>
      </h3>
      {openOptions === 'mediaType' ? (
        <div className='category-list-div rem5'>
          <div className='category-list rem5'>
            {types.map((i, index) => {
              return (
                <div key={index}>
                  <h3
                    onClick={() => {
                      setSelectedType(i);
                      setSelectedMediaTypes();
                    }}
                  >
                    <div
                      onClick={() => {
                        setSelectedType(i);
                        setSelectedMediaTypes();
                      }}
                      className={`selection-box-sku ${
                        selectedType === i ? 'selected-box' : 'non-selected-box'
                      }`}
                    >
                      {selectedType === i ? <FaCheck /> : ''}
                    </div>
                    {i}
                  </h3>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        ''
      )}
      <h3
        className={`select-cat ${openOptions === 'cats' ? 'selected-cat' : 'non-selected-cat'}`}
        onClick={() => {
          handleTitleClick('cats');
        }}
      >
        Select a Category
        <div
          onClick={() => {
            handleTitleClick('cats');
          }}
        >
          <SlArrowDown className={`arrow-icon ${openOptions === 'cats' ? 'rotated' : ''}`} />
        </div>
      </h3>
      {openOptions === 'cats' ? (
        <div className='category-list-div'>
          <div className='category-list'>
            {productList?.map((i) => {
              return (
                <div key={i.catName}>
                  <h3
                    onClick={() => {
                      navigateOnChange(i.catName);
                    }}
                    className={`${catLevels[0] === i.catName ? 'selected' : ''}`}
                  >
                    {i.catName}
                    {catLevels[0] === i.catName ? (
                      <AiOutlineCloseCircle className='close-icon' />
                    ) : (
                      ''
                    )}
                  </h3>
                  {catLevels[0] === i.catName ? (
                    <div className='subcat-list'>
                      {i.subCats.map((j) => {
                        return (
                          <div key={j.catName}>
                            <h4
                              key={j.catName}
                              onClick={() => {
                                setCatLevels([catLevels[0], j.catName]);
                              }}
                              className={`${catLevels[1] === j.catName ? 'selected' : ''}`}
                            >
                              {j.catName}
                            </h4>
                            {catLevels[1] === j.catName ? (
                              <div className='subcat-list'>
                                {j?.subCats?.map((k) => {
                                  return (
                                    <div key={k.catName}>
                                      <h4
                                        key={k.catName}
                                        onClick={() => {
                                          setCatLevels([catLevels[0], catLevels[1], k.catName]);
                                        }}
                                        className={`${
                                          catLevels[2] === k.catName ? 'selected' : ''
                                        }`}
                                      >
                                        {k.catName}
                                      </h4>
                                    </div>
                                  );
                                })}
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        ''
      )}
      <h3
        className={`select-cat ${openOptions === 'skus' ? 'active-div' : 'non-active-div'}`}
        onClick={() => {
          handleTitleClick('skus');
        }}
      >
        Select SKUS{' '}
        <div
          onClick={() => {
            handleTitleClick('skus');
          }}
        >
          <SlArrowDown className={`arrow-icon ${openOptions === 'skus' ? 'rotated' : ''}`} />
        </div>
      </h3>

      {openOptions === 'skus' ? (
        <div>
          <div className='search-sku'>
            <label className='search-sku-label'>
              <h3>Search SKU</h3>
              <input
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              ></input>
            </label>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <h3
                className='select-all'
                onClick={() => {
                  const list = skuList.filter((i) => {
                    if (!searchValue) {
                      return i;
                    } else {
                      return i?.toUpperCase()?.includes(searchValue?.toUpperCase());
                    }
                  });
                  addRemoveSku(list);
                }}
              >
                Select All
              </h3>
              <h3
                className='clear-selection'
                onClick={() => {
                  addRemoveSku(skuList, false, true);
                }}
              >
                Clear Selection
              </h3>
            </div>
          </div>
          <div className='category-list-div'>
            <div className='category-list'>
              {skuList
                ? skuList
                    .filter((i) => {
                      if (!searchValue) {
                        return i;
                      } else {
                        return i?.toUpperCase()?.includes(searchValue?.toUpperCase());
                      }
                    })
                    .map((i) => {
                      return (
                        <div key={i}>
                          <h3
                            onClick={() => {
                              addRemoveSku(i);
                            }}
                          >
                            <div
                              onClick={() => {
                                addRemoveSku(i);
                              }}
                              className={`selection-box-sku ${
                                selectedSkus?.includes(i) ? 'selected-box' : 'non-selected-box'
                              }`}
                            >
                              {selectedSkus?.includes(i) ? <FaCheck /> : ''}
                            </div>
                            {i}
                          </h3>
                        </div>
                      );
                    })
                : ''}
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      {selectedType === 'Images' ? (
        <h3
          className={`select-cat ${openOptions === 'imageTypes' ? 'active-div' : 'non-active-div'}`}
          onClick={() => {
            handleTitleClick('imageTypes');
          }}
        >
          Select Image Types{' '}
          <div
            onClick={() => {
              handleTitleClick('imageTypes');
            }}
          >
            <SlArrowDown
              className={`arrow-icon ${openOptions === 'imageTypes' ? 'rotated' : ''}`}
            />
          </div>
        </h3>
      ) : selectedType === 'Literature' ? (
        <h3
          className={`select-cat ${openOptions === 'litTypes' ? 'active-div' : 'non-active-div'}`}
          onClick={() => {
            handleTitleClick('litTypes');
          }}
        >
          Select Literature Types{' '}
          <div
            onClick={() => {
              handleTitleClick('litTypes');
            }}
            p
          >
            <SlArrowDown className={`arrow-icon ${openOptions === 'litTypes' ? 'rotated' : ''}`} />
          </div>
        </h3>
      ) : (
        ''
      )}
      {openOptions === 'imageTypes' ? (
        <div className='category-list-div rem7'>
          <div className='category-list rem7'>
            {imageTypes.map((i) => {
              return (
                <div key={i}>
                  <h3
                    onClick={() => {
                      if (selectedMediaTypes?.includes(i)) {
                        setSelectedMediaTypes(selectedMediaTypes.filter((j) => j !== i));
                      } else {
                        setSelectedMediaTypes([...selectedMediaTypes, i]);
                      }
                    }}
                  >
                    <div
                      onClick={() => {
                        if (selectedMediaTypes?.includes(i)) {
                          setSelectedMediaTypes(selectedMediaTypes.filter((j) => j !== i));
                        } else if (selectedMediaTypes) {
                          setSelectedMediaTypes([...selectedMediaTypes, i]);
                        } else {
                          setSelectedMediaTypes([i]);
                        }
                      }}
                      className={`selection-box-sku ${
                        selectedMediaTypes?.includes(i) ? 'selected-box' : 'non-selected-box'
                      }`}
                    >
                      {selectedMediaTypes?.includes(i) ? <FaCheck /> : ''}
                    </div>
                    {i}
                  </h3>
                </div>
              );
            })}
          </div>
        </div>
      ) : openOptions === 'litTypes' ? (
        <div className='category-list-div rem7'>
          <div className='category-list rem7'>
            {litTypes.map((i) => {
              return (
                <div key={i}>
                  <h3
                    onClick={() => {
                      if (selectedMediaTypes?.includes(i)) {
                        setSelectedMediaTypes(selectedMediaTypes.filter((j) => j !== i));
                      } else {
                        setSelectedMediaTypes([...selectedMediaTypes, i]);
                      }
                    }}
                  >
                    <div
                      onClick={() => {
                        if (selectedMediaTypes?.includes(i)) {
                          setSelectedMediaTypes(selectedMediaTypes.filter((j) => j !== i));
                        } else if (selectedMediaTypes) {
                          setSelectedMediaTypes([...selectedMediaTypes, i]);
                        } else {
                          setSelectedMediaTypes([i]);
                        }
                      }}
                      className={`selection-box-sku ${
                        selectedMediaTypes?.includes(i) ? 'active-div' : 'non-active-div'
                      }`}
                    >
                      {selectedMediaTypes?.includes(i) ? <FaCheck /> : ''}
                    </div>
                    {i}
                  </h3>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        ''
      )}
      <h2 className='image-transformations'>Image Transformations</h2>
      <h3
        className={`select-cat  select-transformations ${
          openOptions === 'transformations' ? 'active-div' : 'non-active-div'
        }`}
      >
        Select Transformations
        <HiInformationCircle
          className='select-transformations-circle'
          onClick={() => {
            setInfoHover(!infoHover);
          }}
        />
        {infoHover ? (
          <div
            className='password-info-popup password-info'
            onMouseLeave={() => setInfoHover(false)}
          >
            <p>Click here to learn more about how to transform your images!</p>
            <div
              style={{ marginTop: '0px' }}
              className='media-download-buttons'
            >
              <button
                onClick={() =>
                  window.open(
                    'https://res.cloudinary.com/da3rom333/image/upload/v1702656806/Misc%20Assets/IMAGE_TRANSFORMATIONS_ry7weo.pdf',
                    '_blank'
                  )
                }
              >
                Learn More
              </button>
            </div>
          </div>
        ) : (
          ''
        )}
        <div
          onClick={() => {
            handleTitleClick('transformations');
          }}
        >
          <SlArrowDown
            className={`arrow-icon curser ${openOptions === 'transformations' ? 'rotated' : ''}`}
          />
        </div>
      </h3>
      {openOptions === 'transformations' ? (
        <div
          style={{ padding: '0px', height: '18rem' }}
          className='category-list-div'
        >
          <div
            style={{ padding: '8px', height: '17rem' }}
            className='category-list'
          >
            {transformations.map((i, index) => {
              return (
                <div
                  className='transformation-item'
                  key={index}
                >
                  <h3 className='cursor'>{i.text.replace(/_/g, ' ')}</h3>
                  {i.options.includes(';') ? (
                    <h3
                      onClick={() => {
                        if (openSuboptions === i.text) {
                          setOpenSuboptions(false);
                        } else {
                          setOpenSuboptions(i.text);
                        }
                      }}
                      className={`select-cat  cat-select ${
                        openSuboptions === i.text ? 'active-div' : 'non-active-div'
                      }`}
                    >
                      {imageEffects[i.code] ? imageEffects[i.code] : 'Select'}
                      <div
                        onClick={() => {
                          if (openSuboptions === i.text) {
                            setOpenSuboptions(false);
                          } else {
                            setOpenSuboptions(i.text);
                          }
                        }}
                      >
                        <SlArrowDown
                          className={`arrow-drop ${openSuboptions === i.text ? 'rotated' : ''}`}
                        />
                      </div>
                    </h3>
                  ) : (
                    <input
                      style={{
                        fontSize: '11px',
                        width: '10.6rem',
                        textTransform: 'uppercase',
                        border: '1px solid #BBBBBA',
                        borderRadius: '15px',
                        padding: '2px 10px',
                      }}
                      onChange={handleInputChangeAdd}
                      value={imageEffects[i.code] || ''}
                      name={i.text}
                      placeholder={i.options}
                    ></input>
                  )}
                  {openSuboptions === i.text && (
                    <div className='category-list-div cat-dropdown'>
                      <div
                        className='category-list'
                        style={{ height: '100% ' }}
                      >
                        {i.options.split(';').map((k, index) => {
                          return (
                            <div key={index}>
                              <h3
                                style={{ fontSize: '12px' }}
                                onClick={(e) => {
                                  if (imageEffects) {
                                    if (imageEffects[i.code] === k) {
                                      setImageEffects((values) => ({
                                        ...values,
                                        [i.code]: '',
                                      }));
                                    } else {
                                      setImageEffects((values) => ({
                                        ...values,
                                        [i.code]: k,
                                      }));
                                    }
                                  } else {
                                    setImageEffects({
                                      [i.code]: k,
                                    });
                                  }
                                  setOpenSuboptions('');
                                }}
                              >
                                <div
                                  onClick={() => {
                                    if (imageEffects) {
                                      if (imageEffects[i.code] === k) {
                                        setImageEffects((values) => ({
                                          ...values,
                                          [i.code]: '',
                                        }));
                                      } else {
                                        setImageEffects((values) => ({
                                          ...values,
                                          [i.code]: k,
                                        }));
                                      }
                                    } else {
                                      setImageEffects({
                                        [i.code]: k,
                                      });
                                    }
                                    setOpenSuboptions('');
                                  }}
                                  className={`selection-box-sku ${
                                    imageEffects[i.code] === k ? 'selected-box' : 'non-selected-box'
                                  }`}
                                  style={{
                                    marginRight: '.5rem',
                                    border: '1px solid',
                                  }}
                                >
                                  {imageEffects[i.code] === k ? <FaCheck /> : ''}
                                </div>
                                {k}
                              </h3>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}

            <div
              style={{ justifyContent: 'space-evenly' }}
              className='media-download-buttons'
            >
              <button
                style={{ minWidth: '115px' }}
                onClick={handleApply}
              >
                Apply
              </button>

              <button
                style={{ minWidth: '115px' }}
                onClick={() => {
                  setApplyEffects(false);
                  setImageEffects({});
                }}
              >
                Clear
              </button>
            </div>
            {/* {errorNotification && <p>Input must be a number</p>} */}
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default CategoryBar;
