import { useEffect, useState } from 'react';
import { useCart } from '../../context/CartContext';
import { useSelector } from 'react-redux';

import Axios from 'axios';
const InstantFreightQuote = ({ quote, qty, setFreightShow, nonboxed, setNotification }) => {
  const {
    product: modelNum,
    optionsSelected,
    skuDisplayed,
    customerPrice,
  } = useSelector((state) => state.productState.value);
  const { addToCart } = useCart();
  const [email, setEmail] = useState('');
  const date = new Date();
  const [sendMessage, setSendMessage] = useState(false);
  // eslint-disable-next-line no-undef
  const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;
  const dd = String(date.getDate()).padStart(2, '0');
  const mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
  const yyyy = date.getFullYear();
  const validDate = mm + '/' + dd + '/' + yyyy;
  date.setDate(date.getDate() + 30);

  function validateEmail() {
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  }

  function sendQuote() {
    if (validateEmail()) {
      setSendMessage('Sending email...');

      Axios.post(`${apiAddress}/products/emailQuote`, {
        email: email,
        quoteNum: quote[0],
      }).then((response) => {
        setSendMessage(response.data.message);
        setEmail('');
      });
    } else {
      setSendMessage('Email required');
    }
  }

  useEffect(() => {
    // const delay = 2000; // Delay in milliseconds (2 seconds)
    const timeoutId = setTimeout(() => {
      if (sendMessage !== 'Sending email...') {
        setSendMessage(false);
      }
    }, 5000);
    return () => {
      // Clear the timeout if the component unmounts before 2 seconds
      clearTimeout(timeoutId);
    };
  }, [sendMessage]);

  return (
    <div className='form instantQuote' style={{ height: '395px' }}>
      <div className='row1'>
        <div className='quoteNum'>
          <div>Quote #</div>
          <div className='num'>{quote[0]}</div>
        </div>
        <div className='quoteTotal'>${quote[1]}</div>
      </div>
      <div className='row2'>{quote[2]}</div>
      <div className='row3'>
        This quote is valid until <span style={{ fontWeight: '700' }}>{validDate}</span>
      </div>
      <div className='row4'>
        <div style={{ flexDirection: 'column', marginTop: '14px' }}>
          <div className='sendCopy'>Send a copy of the quote:</div>
          <div
            style={{
              marginTop: '10px',
              marginBottom: '33px',
              position: 'relative',
            }}
          >
            <input
              placeholder='Enter email address'
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              name={email}
            ></input>
            <div className='error-msg-text' style={{ position: 'absolute', top: '2.5rem' }}>
              {sendMessage ? `${sendMessage}` : ''}
            </div>
            <button
              className='sendCopyButton'
              style={{ marginLeft: '1rem', marginRight: '12px' }}
              onClick={sendQuote}
            >
              Send
            </button>
            <button
              className='downloadPDF'
              onClick={() => {
                window.open(quote[3]);
              }}
            >
              PDF
            </button>
          </div>
        </div>
        <div style={{ justifyContent: 'space-between' }}>
          <button
            className='cartButton'
            onClick={() => {
              const response = addToCart(
                modelNum.productInfo.FreightQuoteModelNum,
                qty,
                skuDisplayed,
                modelNum.productInfo.ShortDescription,
                modelNum.cdnLinks[0]?.Image,
                optionsSelected,
                modelNum.productInfo.BaseModelNum,
                parseInt(modelNum.productInfo.Quantity) * parseInt(qty),
                parseInt(modelNum.productInfo.Quantity),
                modelNum.productInfo.ShipsNonBoxed,
                nonboxed,
                modelNum.productInfo.Status,
                Object.keys(customerPrice).length > 0 ? customerPrice : false
              );
              if (response?.data?.error) {
                setNotification(response.data.error);
              } else {
                setFreightShow(false);
                window.open('/cart', '_blank');
              }
            }}
          >
            Add to Cart
          </button>
          <button
            className='cartButton'
            onClick={() => {
              setFreightShow(false);
              window.open('/cart', '_blank');
            }}
          >
            View Cart
          </button>
        </div>
      </div>
    </div>
  );
};

export default InstantFreightQuote;
