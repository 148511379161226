import '../CSS/AdminPortal.css';
import GenericTextButton from '../assets/SVG/ReactSvg/genericTextButton';

const Admin = () => {
  return (
    <>
      <div className='adminDiv'>
        <h2>Admin Portal</h2>
        <div className='admin-buttons-div'>
          <GenericTextButton buttonText='Users' buttonLink='admin/users' />
          <GenericTextButton buttonText='New Users' buttonLink='admin/new-users' />
          <GenericTextButton buttonText='Nondealers' buttonLink='admin/nondealers' />
          <GenericTextButton buttonText='Pending Approvals' buttonLink='admin/account-requests' />
          <GenericTextButton
            buttonText='Dealer Requests'
            buttonLink='admin/dealer-account-requests'
          />
          <GenericTextButton buttonText='Route Requests' buttonLink='admin/route-requests' />
          <GenericTextButton buttonText='API Tests' buttonLink='admin/apiTests' />
        </div>
      </div>
    </>
  );
};

export default Admin;
