import { useState, useEffect } from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
import FullPageLoading from '../../Components/Shared/FullPageLoading';
import TemplateTile from '../../Components/Shared/TemplateTile';
import '../../CSS/InStockLaminateItems.css';
import ItemsTable from '../../Components/Quickship/ItemsTable';
import { HiOutlineDocumentDownload } from 'react-icons/hi';
import useExportToExcel from '../../Components/Shared/useExportToExcel';
import { BiSolidColor } from 'react-icons/bi';
import useAuth from '../../hooks/useAuth';

const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;

const InStockLaminateItems = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchParamsUse = Object.fromEntries([...searchParams]);
  const [categories, setCategories] = useState();
  const [fullList, setFullList] = useState();
  const [laminates, setLaminates] = useState();
  const [found, setFound] = useState(false);
  const [categoryChosen, setCategoryChosen] = useState();
  const [viewAll, setViewAll] = useState();
  const { auth } = useAuth();

  const ExportToExcel = useExportToExcel();

  const columns = [
    {
      type: 'text',
      title: 'Model #',
      filterable: true,
      table_name: 'in-stock-laminates',
      value: 'completeSku',
      display: 1,
      link: 'productLink',
    },
    {
      type: 'text',
      title: 'Stock',
      filterable: true,
      table_name: 'in-stock-laminates',
      value: 'stock',
      display: 1,
    },
    {
      type: 'text',
      title: 'Subcategory',
      filterable: true,
      table_name: 'in-stock-laminates',
      value: 'subcategory',
      display: 1,
    },
    {
      type: 'image',
      title: 'Image',
      filterable: false,
      table_name: 'in-stock-laminates',
      value: 'image',
      display: 1,
    },
    {
      type: 'text',
      title: 'Description',
      filterable: true,
      table_name: 'in-stock-laminates',
      value: 'description',
      display: 1,
    },

    {
      type: 'image',
      title: 'Tabletop Color',
      filterable: true,
      table_name: 'in-stock-laminates',
      value: 'tableTopColor',
      display: 1,
    },
    {
      type: 'image',
      title: 'Bench Color',
      filterable: true,
      table_name: 'in-stock-laminates',
      value: 'benchColor',
      display: 1,
    },
    {
      type: 'image',
      title: 'Back Color',
      filterable: true,
      table_name: 'in-stock-laminates',
      value: 'backColor',
      display: 1,
    },
  ];

  function getProductData() {
    Axios.get(`${apiAddress}/stockItems/getTabletopItemsInStock`)
      .then((response) => {
        setFullList(response.data);

        const finalCategories = [];

        response.data.forEach((item) => {
          const category = item?.skuData?.group_name;
          if (!finalCategories?.some((i) => i.category === category)) {
            if (item?.skuData?.cover_item) {
              finalCategories?.push({
                category: category,
                image: `https://res.cloudinary.com/da3rom333/image/upload/${item?.skuData?.imageLink}`,
              });
            }
          }
        });
        setCategories(finalCategories);

        if (Object?.keys(searchParamsUse)?.length > 0) {
          setCategoryChosen(
            finalCategories?.find((i) => i.category === searchParamsUse['category'])
          );
        }
        setFound(true);
      })
      .catch((err) => {
        console.error(err);
        setFound(true);
      });
  }

  function createConfigOptions(item) {
    const finalConfigOptions = Object.fromEntries(
      Object.entries(item?.configOptions).map(([key, value]) => {
        if (key === 'Bench Color' || key === 'TableTop Color' || key === 'Back Color') {
          const foundItem = laminates.find((i) => i.config_value === value);

          return [key, foundItem ? foundItem.selection_name : value];
        } else {
          return [key, value];
        }
      })
    );

    return finalConfigOptions;
  }

  function handleExport() {
    ExportToExcel(
      {
        data: mapFullItemsList(viewAll ? fullList : fullList?.filter((i) => !i?.tabletopNonMatch)),
      },
      'Overstock Products'
    );
  }

  function getLaminates() {
    Axios.get(`${apiAddress}/stockItems/getLaminateNames`).then((response) => {
      setLaminates(
        response.data.map((lam) => {
          return { ...lam, name: lam.selection_name };
        })
      );
    });
  }

  function clearCache() {
    Axios.get(`${apiAddress}/stockItems/clearLamCache`).then((response) => {
      window.location.reload();
    });
  }

  function logEvent() {
    Axios.post(`${apiAddress}/content/logEvent`, {
      user: auth?.rep || 'N/A',
      page: '/overstock-products',
    }).catch((error) => {
      console.error('Error adding to overstock log:', error);
    });
  }

  function mapItems(list) {
    return list?.map((item, index) => {
      return {
        ...item,
        ID: index,
        stock: item.stock?.NJ || item.stock?.CL,
        subcategory: item.skuData?.subcategory || '',
        image: item.image?.imageLink,
        description: item.image?.description,
        tableTopColor: laminates?.find(
          (lam) => lam.config_value === item.configOptions['TableTop Color']
        )
          ? {
              ...laminates?.find(
                (lam) => lam.config_value === item.configOptions['TableTop Color']
              ),
              value: item.configOptions['TableTop Color'],
            }
          : '',
        benchColor: laminates?.find((lam) => lam.config_value === item.configOptions['Bench Color'])
          ? {
              ...laminates?.find((lam) => lam.config_value === item.configOptions['Bench Color']),
              value: item.configOptions['Bench Color'],
            }
          : '',
        backColor: laminates?.find((lam) => lam.config_value === item.configOptions['Back Color'])
          ? {
              ...laminates?.find((lam) => lam.config_value === item.configOptions['Back Color']),
              value: item.configOptions['Back Color'],
            }
          : '',
        productLink: `https://www.nationalpublicseating.com/products/${encodeURIComponent(
          item?.skuData?.config_group || item?.skuData?.series
        )}?${createSearchParams(createConfigOptions(item))}`,
      };
    });
  }

  function mapFullItemsList(list) {
    return list?.map((item) => {
      return {
        Sku: item.completeSku,
        Stock: item.stock?.NJ || item.stock?.CL,
        Subcategory: item.skuData?.subcategory || '',
        Image: item.image?.imageLink,
        Description: item.image?.description,
        ['TableTop Color']: laminates?.find(
          (lam) => lam.config_value === item.configOptions['TableTop Color']
        )?.selection_name,
        ['TableTop Color Image']: laminates?.find(
          (lam) => lam.config_value === item.configOptions['TableTop Color']
        )?.swatch,
        ['Bench Color']: laminates?.find(
          (lam) => lam.config_value === item.configOptions['Bench Color']
        )?.selection_name,
        ['Bench Color Image']: laminates?.find(
          (lam) => lam.config_value === item.configOptions['Bench Color']
        )?.swatch,
        ['Back Color']: laminates?.find(
          (lam) => lam.config_value === item.configOptions['Back Color']
        )?.selection_name,
        ['Back Color Image']: laminates?.find(
          (lam) => lam.config_value === item.configOptions['Back Color']
        )?.swatch,
      };
    });
  }

  useEffect(() => {
    document.title = 'Overstock Products - National Public Seating';
    window.scrollTo(0, 0);
  }, [categoryChosen]);

  useEffect(() => {
    getProductData();
    getLaminates();
  }, []);

  useEffect(() => {
    logEvent();
  }, [auth?.rep]);

  useEffect(() => {
    if (categoryChosen) {
      navigate(
        {
          pathname: `/overstock-products`,
          search: `?category=${categoryChosen?.category}`,
        },
        { replace: true }
      );
    }
  }, [categoryChosen]);

  return found ? (
    <>
      {auth?.roles?.includes(1117) && (
        <button
          style={{ position: 'absolute', left: '94rem', top: '11.2rem' }}
          onClick={clearCache}
          className='discover-button clear-cache'
        >
          Clear Cache
        </button>
      )}
      <div
        className={`${categoryChosen ? 'in-stock-table-header' : 'in-stock-header'} inner-padding`}
      >
        {categoryChosen ? (
          <div
            onClick={() => {
              setCategoryChosen();
              navigate('/overstock-products');
            }}
            className='laminates-back-button'
          >
            {'< Back'}
          </div>
        ) : (
          ''
        )}
        {categoryChosen ? (
          <>
            <div
              style={{ alignItems: 'end' }}
              className='basic-flex'
            >
              <h2>Overstock {categoryChosen?.category}</h2>

              <div className='laminates-disclaimer'>
                *Disclaimer: Stock is available on a first come basis. Review your order
                confirmations to confirm actual lead time.
              </div>
            </div>
            <div className='basic-flex'>
              <div className='hover-item-outer-div'>
                <BiSolidColor
                  style={{
                    color: viewAll ? 'var(--lightgreen)' : 'var(--darkblue)',
                  }}
                  onClick={() => {
                    setViewAll(!viewAll);
                  }}
                />
                <div className={`${!viewAll ? 'color-swatch-selection-hover bigger' : ''}`}>
                  Click to view all possible color combinations between bench and tabletop
                  laminates. By default, only matching colors are shown.
                </div>
                <div className={`${viewAll ? 'color-swatch-selection-hover smaller' : ''}`}>
                  Click to view only color combinations where bench and tabletop laminates match.
                </div>
              </div>
              <HiOutlineDocumentDownload
                style={{
                  cursor: 'pointer',
                }}
                onClick={() => handleExport()}
              />
            </div>
          </>
        ) : (
          <h2>Overstock Products</h2>
        )}
      </div>
      {!categoryChosen ? (
        <div className='categories-grid inner-padding'>
          {categories?.map((category, index) => (
            <div key={index}>
              {' '}
              <TemplateTile
                item={{
                  image: category?.image,
                  catName: category.category,
                }}
                clickHandler={() => setCategoryChosen(category)}
              />
            </div>
          ))}
        </div>
      ) : (
        laminates && (
          <ItemsTable
            tableName={'Overstock Products'}
            tableColumns={columns}
            tableRows={
              viewAll
                ? mapItems(
                    fullList?.filter((i) => i?.skuData?.group_name === categoryChosen?.category)
                  )
                : mapItems(
                    fullList
                      ?.filter((i) => i?.skuData?.group_name === categoryChosen?.category)
                      ?.filter((i) => !i?.tabletopNonMatch)
                  )
            }
          />
        )
      )}
    </>
  ) : (
    <FullPageLoading />
  );
};

export default InStockLaminateItems;
