import { AdvancedImage } from '@cloudinary/react';
import decodeCloudinary from '../../hooks/decodeCloudinary';
import { Cloudinary } from '@cloudinary/url-gen';
import { createSearchParams, useNavigate } from 'react-router-dom';
import '../../CSS/ShowcaseItem.css';

function ShowcaseItem({ item }) {
  const navigate = useNavigate();
  const decodeCloudinaryURL = decodeCloudinary();
  const cld = new Cloudinary({
    cloud: {
      cloudName: 'da3rom333',
    },
  });

  function clickHandler(item) {
    navigate({
      pathname: `/products/${item.series}`,
      search: `?${createSearchParams(item.configOptions)}`,
    });
  }

  return (
    <div
      className='env-item'
      onClick={() => clickHandler(item)}
    >
      <div className='img-div'>
        <AdvancedImage cldImg={cld.image(decodeCloudinaryURL(item.img))} />
      </div>
      <div className='item-details'>
        <div className='series'>{item.series}</div>
        <div className='skuName'>{item.sku}</div>
      </div>
    </div>
  );
}

export default ShowcaseItem;
