/* eslint-disable camelcase */
import React from 'react';
import TemplateTilePage from '../Components/Shared/TemplateTilePage';
import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import { useNavigate } from 'react-router-dom';
// import NavigateBar from "../Components/Products/navigateBar";
import ProductsBannerSvg from '../assets/SVG/ReactSvg/ProductsBannerSvg';
import ProductBanner from '../Components/Shared/ProductBanner';
import Loading from '../Components/Shared/Loading';
import { useSelector } from 'react-redux';
import NotFound from '../Components/Products/NotFound';

// eslint-disable-next-line no-undef
const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;

const Category2 = () => {
  const navigate = useNavigate();
  const mobileScreen = useSelector((state) => state.isMobile.value);
  const { categoryID } = useParams();
  useEffect(() => {
    document.title = `${categoryID || 'Products'} - National Public Seating`;
    window.scrollTo(0, 0);
  });
  // const location = useLocation();

  const [catData, setCatData] = useState();
  const [found, setFound] = useState('');
  function getCategories(catID) {
    Axios.post(`${apiAddress}/products/getCategory`, {
      cat: catID,
    }).then((response) => {
      if (response.data.length > 0) {
        setCatData(response.data);
        setFound('found');
      } else {
        setFound('not found');
      }
    });
  }
  function getProductData() {
    Axios.post(`${apiAddress}/products/getCategory`, {}).then((response) => {
      // console.log(response.data, "35", categoryID);
      setCatData(response.data);
      setFound('found');
    });
  }

  // useEffect(() => {
  //   if (!catData && !location?.state?.selectedCat) {
  //     getCategories(categoryID);
  //   } else {
  //     setCatData(location?.state?.selectedCat);
  //   }
  // }, []);

  useEffect(() => {
    if (categoryID) {
      getCategories(categoryID);
    } else {
      getProductData();
    }
  }, []);

  useEffect(() => {
    if (categoryID) {
      getCategories(categoryID);
    } else {
      getProductData();
    }
  }, [categoryID]);

  function clickHandler(s) {
    if (s.hasSubCats === 'TRUE') {
      // console.log(s);
      return navigate(`/categories/${encodeURIComponent(s.catName)}`);
    } else {
      return navigate(
        `/products/${encodeURIComponent(s.catName)}${s.linkParams ? `?${s.linkParams}` : ''}`
      );
    }
  }

  return (
    <div>
      {/* {categoryID ? (
        <NavigateBar series={categoryID} />
      ) : (
        <div className="navigate-bar"></div>
      )} */}

      {/* {console.log(catData)} */}
      {catData ? (
        <TemplateTilePage
          list={catData}
          bannerClass={'banner-header'}
          clickHandler={clickHandler}
          banner={
            categoryID ? (
              <ProductBanner text={categoryID} image={catData[0].parentCoverImage} />
            ) : mobileScreen.isMobile ? (
              <img
                className='mobile-new-products-banner'
                src='https://res.cloudinary.com/da3rom333/image/upload/v1700166068/Website%20Assets/Banners/d730d5ae-b329-4d2e-9f46-dd6568ce58a0_ypxjlx.jpg'
                alt='NEW PRODUCTS'
                onClick={() => {
                  return navigate(`/new`);
                }}
              ></img>
            ) : (
              <ProductsBannerSvg />
            )
          }
          navigateBarOriginal={categoryID ? categoryID : false}
        />
      ) : found === 'not found' ? (
        <NotFound title={'category'} />
      ) : (
        <Loading />
      )}
    </div>
    // <button onClick={()=>{console.log(subCat)}}>Here</button>
  );
};

export default Category2;
