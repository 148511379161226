import * as React from "react";

const SvgComponent = (props) => (
  <svg
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 18 18"
    {...props}
  >
    <path
      d="M18 7.74c-.04.29-.07.58-.14.86-.67 2.97-2.46 4.9-5.41 5.66-2.16.56-4.17.11-5.98-1.18-.04-.03-.09-.06-.13-.09 0 0-.02 0-.08-.02-.03.04-.06.1-.11.15-1.52 1.53-3.05 3.05-4.57 4.57-.25.26-.55.37-.91.28a.89.89 0 0 1-.49-1.41c.06-.07.13-.14.19-.21l4.54-4.54c.04-.04.08-.08.12-.13-.38-.5-.7-1.03-.94-1.61-1.8-4.2.79-9.08 5.28-9.93.3-.06.6-.09.9-.14h.98c.3.05.6.08.9.14 2.78.5 5.11 2.78 5.68 5.54.08.37.12.74.18 1.11v.95Zm-7.25 4.95c3 0 5.45-2.44 5.45-5.44 0-3-2.44-5.45-5.44-5.45-2.99 0-5.44 2.44-5.45 5.44 0 3 2.44 5.45 5.44 5.45Z"
      style={{
        fill: "white",
      }}
      data-name="Capa 1"
    />
  </svg>
);

export default SvgComponent;
