import { useState, useEffect } from 'react';
import MediaTile from './MediaTile';
import Paginator from './Paginator';
import PdfTile from './PdfTile';
import Loading from '../Shared/Loading';

const MediaTiles = ({
  list,
  selectAll,
  addToSelected,
  removeFromSelected,
  clearList,
  selectedList,
  listItems,
  selectedMediaTypes,
  selectedType,
  imageEffects,
  applyEffects,
}) => {
  const [page, setPage] = useState(0);
  const [lastPage, setLastPage] = useState();
  const [show, setShow] = useState(16);
  const [flatList, setFlatList] = useState();

  function flattenList() {
    if (list) {
      let flatArray = [];
      if (selectedType === 'Images') {
        Object.keys(list)?.map((i) => {
          flatArray.push(...list[i]?.imageLinks);
        });
      } else if (selectedType === 'Literature') {
        Object.keys(list)?.map((i) => {
          if (list[i].pdfLinks) {
            flatArray.push(...list[i]?.pdfLinks);
          }
        });
      }

      flatArray = flatArray.filter((i) => {
        if (!selectedMediaTypes || selectedMediaTypes.length === 0) {
          return true;
        }
        return selectedMediaTypes?.includes(i.type);
      });
      setFlatList(flatArray);
    }
  }

  useEffect(() => {
    setFlatList();
    flattenList();
  }, [list, selectedMediaTypes, selectedType]);

  useEffect(() => {
    if (flatList) {
      setLastPage(Math.ceil(flatList.length / show));
    }
    setPage(0);
  }, [flatList]);

  return (
    <div
      className='mediaTiles'
      style={{ display: 'flex', marginTop: listItems ? '25px' : '' }}
    >
      {flatList ? (
        flatList.slice(show * page, show * page + show).map((i, index) => {
          if (selectedType === 'Images') {
            return (
              <MediaTile
                key={i.modelNum + i.imageLink + index}
                image={i}
                selectAll={selectAll}
                addToSelected={addToSelected}
                removeFromSelected={removeFromSelected}
                clearList={clearList}
                selectedList={selectedList}
                listItems={listItems}
                selectedType={selectedType}
                imageEffects={imageEffects}
                applyEffects={applyEffects}
              />
            );
          } else {
            return (
              <PdfTile
                key={i.modelNum + i.imageLink + index}
                image={i}
                selectAll={selectAll}
                addToSelected={addToSelected}
                removeFromSelected={removeFromSelected}
                clearList={clearList}
                selectedList={selectedList}
                listItems={listItems}
                selectedType={selectedType}
              />
            );
          }
        })
      ) : (
        <Loading />
      )}
      <div
        className='pages'
        style={{ display: 'flex' }}
      >
        {flatList ? (
          <Paginator
            currentPage={page}
            totalPages={lastPage}
            setCurrentPage={setPage}
            setShow={setShow}
            show={show}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default MediaTiles;
