import React, { useEffect } from "react";
import "../../CSS/Environmental.css";

const mas =
  "https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1689475804/Website%20Assets/PNGs/MasBanner_q4agq9.png";
const image =
  "https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1689475802/Website%20Assets/PNGs/EnvBannerImage_fxrgi1.png";
const banner =
  "https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688650796/Website%20Assets/Banners/Environmental_u1iqkx.jpg";
const Environmental = () => {
  useEffect(() => {
    document.title = `Environmental - National Public Seating`;
    window.scrollTo(0, 0);
  }, []);

  const statements = [
    {
      type: "STEEL",
      comment:
        "All of the steel for our products contain 30-40% of post-consumer waste (recycled).",
    },
    {
      type: "CORRUGATED CONTAINERS",
      comment:
        "Are made of recycled fiber. 100% total recycled content and 90% post consumer content minimum.",
    },
    {
      type: "plastic",
      comment: "Our plastic products contain up to 35% of pre-consumer waste.",
    },
    {
      type: "wood",
      comment:
        "100% of wood used for our products comes from non-boreal forests.",
    },
    {
      type: "POWDER COATING VS. LIQUID FINISHES",
      comment:
        "Our products are powder coated and are highly protective to our environment. While liquid finishes contain solvents which have pollutants known as volatile organic compounds (VOCs), powder coating contains no solvents and releases negligible amounts, if any, of VOCs into the atmosphere. In addition, most powder coating overspray that does not adhere to the part is retrieved and reused, virtually eliminating the waste commonly found in liquid finishing processes.",
    },
    {
      type: "energy",
      comment:
        "During November 2008, National Public Seating®, completed a retrofit of all its lighting (over 800 fixtures), to eco-friendly units, saving 135,586 kWh annually. This reduction in energy consumption has an environmental impact equivalent to removing 20 cars from the road, 84 tons of greenhouse gas (C02) and saving 198 barrels of oil annually! Click here to view National Public Seating's Award of Distinction from UR Energy.",
    },
    {
      type: "AIR EMISSIONS",
      comment:
        "Our Folding Chairs are manufactured within ANSI BIFMA X7.1 2007 VOC Air Emission guidelines; Criteria #1 – TVOC, Formaldehyde, All Aldehydes & 4–Phenylcyclohexene.",
    },
    {
      type: "SOLAR ENERGY",
      comment:
        "As the industry leader in institutional-grade furniture, including portable folding furniture for lunch rooms, training rooms, etc., & furniture for worship halls, science labs, music programs, performing arts, cafeterias & others, NPS® continues to trailblaze in setting the standard for environmental stewardship. In addition to minimizing its eco footprint in the areas of raw material sourcing, energy consumption & air emissions, NPS® is proud to announce a major solar panel installation on the roof of its 200,000 square foot factory facility in its corporate headquarters in Clifton, NJ, completed Summer 2011. Via the installation of a 240KW solar PV system on its roof, we will offset most of the local utility’s need to burn oil and gas for electrical production in the public grid and therefore reduce greenhouse gas emissions into the atmosphere. Since we will be generating 80% of the electricity that we use with this system, we have cut our carbon footprint significantly.",
    },
    {
      type: "MAS CERTIFIED GREEN",
      comment:
        "The entire NPS® Product Line is now MAS Certified Green™, a nationally recognized registered trademark delineating low VOC emitting products in the marketplace so that purchasers and specifiers of those products know that they can earn credits in sustainability programs like LEED, BIFMA’s Level, and California CHP program.",
    },
  ];

  return (
    <div className="environmental-page ">
      <div className="banner">
        <img src={banner}></img>
      </div>
      <div className="inner-padding"></div>
      <div>
        <h2>
          At National Public Seating® we are committed to providing quality
          products while preserving the quality of our environment.
        </h2>
        <div className="underline"></div>
        <div className="textDiv">
          <div>
            Protecting the environment is one of the most important factors
            affecting the future of the planet. Most people are now aware of the
            necessity of preserving and protecting nature in order to ensure our
            well-being and that of our children. Pollution, especially that
            related to the over-consumption of raw materials, has reached
            alarming levels due to our current lifestyle. That is why at
            National Public Seating® we are constantly striving to minimize the
            consequences of our activities.
            <br></br>
            <br></br>At National Public Seating®, the quality of our products
            and the protection of the environment go hand in hand. As a consumer
            of raw material, we are committed to the use of pre and post
            consumer waste (recycled material), while preserving the high
            quality standards of our products.
          </div>
          <img src={image}></img>
        </div>
        <div className="env-statements inner-padding">
          <h3>Examples of this include:</h3>
          <div className="underline"></div>
          <div>
            {statements.map((i) => {
              return (
                <div className="statement" key={i}>
                  <h2>{i.type}</h2>
                  <h4>{i.comment}</h4>
                </div>
              );
            })}
          </div>
        </div>
        <div className="env-img-banner inner-padding">
          <img src={mas}></img>
        </div>
      </div>
    </div>
  );
};

export default Environmental;
