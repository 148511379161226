import React, { useEffect, useState } from 'react';
// import "../CSS/EditorTools.css";
import '../CSS/EditorPortal.css';
// import EditTemplate from "../Components/EditorTools/EditTemplate";
import { useNavigate } from 'react-router-dom';
import useAxiosJWT from '../hooks/useAxiosJWT';
import useExportToExcel from '../Components/Shared/useExportToExcel';
import UpdateImageTable from '../Components/UpdateImageTable';

const EditorPortalNew = () => {
  const navigate = useNavigate();
  const axiosJWT = useAxiosJWT();
  const ExportToExcel = useExportToExcel();
  const [categories, setCategories] = useState();
  const [hoverCategory, setHoverCategory] = useState();
  // eslint-disable-next-line no-unused-vars
  const [hoverItem, setHoverItem] = useState();
  const [warning, setWarning] = useState();

  async function getEditCategories() {
    await axiosJWT
      .post(`adminPortal/getEditCategories`, { page: 'edit' })
      .then((response) => {
        const groupedData = {};
        response.data.forEach((item) => {
          // eslint-disable-next-line camelcase
          const { parent_ID, parent_title, ...rest } = item;

          // eslint-disable-next-line camelcase
          const key = `${parent_ID}_${parent_title}`;
          if (!groupedData[key]) {
            groupedData[key] = {
              // eslint-disable-next-line camelcase
              ID: parent_ID,

              // eslint-disable-next-line camelcase
              title: parent_title,
              tabs: [],
            };
          }

          groupedData[key].tabs.push(rest);
        });

        const result = Object.values(groupedData);

        setCategories(result);
        setHoverCategory(result[0]);
      })
      .catch((err) => console.error(err));
  }
  useEffect(() => {
    getEditCategories();
  }, []);

  function getSkuList() {
    axiosJWT
      .get('adminPortal/getSeriesList')
      .then((res) => {
        ExportToExcel({ data: res.data }, 'skulist');
      })
      .catch((err) => [console.log(err)]);
  }

  function getQuickshipSkus() {
    axiosJWT
      .get('adminPortal/getQuickshipSkus')
      .then((res) => {
        ExportToExcel({ data: res.data }, 'quickship skus');
      })
      .catch((err) => [console.error(err)]);
  }

  function getSortedPricer() {
    axiosJWT
      .get('adminPortal/getSortedPricer')
      .then((res) => {
        ExportToExcel({ data: res.data }, 'pricer');
      })
      .catch((err) => [console.error(err)]);
  }

  function handleClick(table) {
    if (table?.route_name) {
      if (table.route_name === 'getSkuList') {
        getSkuList();
      } else if (table.route_name === 'getQuickshipSkus') {
        getQuickshipSkus();
      } else if (table.route_name === 'imageImport') {
        setWarning(table.table_name);
      } else if (table.route_name === 'getSortedPricer') {
        getSortedPricer();
      } else {
        navigate(`/${table.route_name}`);
      }
    } else {
      navigate(`/editTable/${table.title}`);
    }
  }

  return (
    <div
      style={{ minHeight: '40rem' }}
      className='adminDiv'
    >
      <h2>Editor Portal</h2>
      <div
        className='inner-padding '
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {categories
            ?.sort((a, b) => a.ID - b.ID)
            ?.map((i, index) => {
              return (
                <div
                  key={index}
                  style={{ display: 'flex' }}
                >
                  <button
                    style={{ marginTop: categories[0] === i ? '0px' : '7px' }}
                    onClick={() => {
                      setHoverCategory(i);
                    }}
                    className={`editor-portal-button ${hoverCategory === i ? 'clicked' : ''}`}
                    key={index}
                  >{`${i.title}`}</button>
                </div>
              );
            })}
        </div>
        <div
          className='hover-items-div'
          style={{
            borderTopLeftRadius:
              hoverCategory && categories
                ? hoverCategory === categories[0]
                  ? '0px'
                  : '1.5rem'
                : '',
            borderBottomLeftRadius:
              hoverCategory && categories
                ? hoverCategory === categories[categories.length - 1]
                  ? '0px'
                  : '1.5rem'
                : '',
          }}
        >
          {hoverCategory?.tabs
            ?.sort((a, b) => a.title.localeCompare(b.title))
            ?.map((j, index) => (
              <div
                key={index}
                onMouseEnter={() => setHoverItem(j)}
                onMouseLeave={() => setHoverItem(null)}
                onClick={() => handleClick(j)}
                className='hover-item'
              >
                {j.title}
              </div>
            ))}
        </div>
      </div>
      {warning && (
        <UpdateImageTable
          setWarning={setWarning}
          table={warning}
        />
      )}
    </div>
  );
};

export default EditorPortalNew;
