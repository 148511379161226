import axios from "../api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    const response = await axios.get("/refresh", {
      withCredentials: true,
    });
    // console.log(response.data?.accounts, 11);
    setAuth((prev) => {
      return {
        ...prev,
        roles: response.data.roles,
        accessToken: response.data.accessToken,
        rep: response.data.rep,
        dealer: response.data.dealer,
        phone: response.data.phone,
        accounts: response.data?.accounts,
      };
    });
    return response.data.accessToken;
  };
  return refresh;
};

export default useRefreshToken;
