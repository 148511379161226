import { Link } from "react-router-dom";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
const CalculateShipping = () => {
  const [hover, setHover] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="calculate-shipping">
      <div
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        onClick={() => {
          navigate(`/cart`);
        }}
        className="calculate-button-div"
      >
        <Link
          className={`${
            hover
              ? " calculate-now-button calculate-now-button-hover"
              : "calculate-now-button"
          }`}
          to={`/cart`}
        >
          CALCULATE NOW!
        </Link>
      </div>
    </div>
  );
};

export default CalculateShipping;
