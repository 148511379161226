import React from 'react';
import { useStageInfo } from '../../context/StageProvider';

function ZoomBar() {
  const { zoom, setZoom } = useStageInfo();
  const zoomArray = [0.6, 0.7, 0.8, 0.9, 1.0, 1.1, 1.2, 1.3, 1.4];

  return (
    <div className='zoom-bar-wrapper'>
      <div className='zoom-bar-flex'>
        <div
          className='zoom-minus'
          onClick={() => {
            if (zoom > 0.6) {
              setZoom(parseFloat((zoom - 0.1).toFixed(1)));
            }
          }}
        >
          -
        </div>
        <div className='zoom-bar'>
          {zoomArray.map((i) => (
            <div
              key={i}
              style={{
                backgroundColor: zoom === i ? 'white' : 'var(--darkblue)',
                border: zoom === i ? '1px solid var(--darkblue)' : 'none',
                height: zoom === i ? '11px' : '3px',
                borderRadius: '10px',
                marginTop: zoom === i ? '-5px' : '0',
                width: '11px',
                justifySelf: 'center',
              }}
              onMouseUp={() => setZoom(i)}
            ></div>
          ))}
        </div>
        <div
          className='zoom-plus'
          onClick={() => {
            if (zoom < 1.4) {
              setZoom(parseFloat((zoom + 0.1).toFixed(1)));
            }
          }}
        >
          +
        </div>
      </div>
    </div>
  );
}

export default ZoomBar;
