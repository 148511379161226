import React, { useState } from 'react';
import useAuth from '../../hooks/useAuth';
import { useCart } from '../../context/CartContext';
import useAxiosJWT from '../../hooks/useAxiosJWT';

function CartPricing({ customerPrice, loadingPrice }) {
	const axiosJWT = useAxiosJWT();
	const { auth } = useAuth();
	const { notificationData } = useCart();
	const [requested, setRequested] = useState(false);
	const [errorMessage, setErrorMessage] = useState('');

	async function requestAccess() {
		const response = await axiosJWT.post(
			`auth/accessRequest`,
			JSON.stringify({
				user: auth?.rep,
				pageRequest: 'product pricing',
			}),
			{
				headers: { 'Content-Type': 'application/json' },
				withCredentials: true,
			}
		);

		if (response.status === 200) {
			if (response.data.message) {
				setRequested(false);
				setErrorMessage(response.data.message);
			} else {
				setRequested(true);
			}
		}
	}

	return (
		<div
			style={{
				minHeight: '52px',
				display: 'flex',
				alignItems: 'flex-start',
				flexDirection: 'column',
				justifyContent: 'center',
			}}
		>
			{customerPrice &&
			(auth?.accounts
				?.find((account) => account.last_login === 1)
				?.AccountRoles?.some((role) => [1100, 1200, 1300].includes(role)) ||
				auth?.roles?.includes(3500)) &&
			!notificationData?.some((i) => i.Path === 'pricing') ? (
				<>
					{' '}
					<div
						style={{
							display: 'flex',
						}}
					>
						<div className='skuTitle'>LIST PRICE:</div>
						<div
							style={{
								height: '22px',
								borderRadius: '15px',
								width: '112px',
							}}
							className={
								!customerPrice?.basePrice?.listPrice || loadingPrice
									? 'loading-price'
									: 'modelNum'
							}
						>
							{(
								+customerPrice?.basePrice?.listPrice +
								(+customerPrice?.laminatesUpcharge?.listPrice ||
									+customerPrice?.laminatesUpcharge) +
								(+customerPrice?.premEdgeUpcharge?.listPrice ||
									+customerPrice?.premEdgeUpcharge)
							)?.toLocaleString(undefined, {
								style: 'currency',
								currency: 'USD',
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</div>
					</div>
					<div
						style={{
							display: 'flex',
						}}
					>
						<div className='skuTitle'>DEALER PRICE:</div>
						<div
							style={{
								height: '22px',
								borderRadius: '15px',
								width: '112px',
							}}
							className={
								!customerPrice?.basePrice?.unitPrice || loadingPrice
									? 'loading-price'
									: 'modelNum'
							}
						>
							{(
								+customerPrice?.basePrice?.unitPrice +
								(+customerPrice?.laminatesUpcharge?.unitPrice ||
									+customerPrice?.laminatesUpcharge) +
								(+customerPrice?.premEdgeUpcharge?.unitPrice ||
									+customerPrice?.premEdgeUpcharge)
							)?.toLocaleString(undefined, {
								style: 'currency',
								currency: 'USD',
								minimumFractionDigits: 2,
								maximumFractionDigits: 2,
							})}
						</div>
					</div>
				</>
			) : !customerPrice &&
			  (auth?.accounts
					?.find((account) => account.last_login === 1)
					?.AccountRoles?.some((role) => [1100, 1200, 1300].includes(role)) ||
					auth?.roles?.includes(3500)) ? (
				<h3 style={{ margin: '0px', width: '24rem' }}>
					Custom Pricing - please reach out to a sales rep for your price.
				</h3>
			) : auth?.accounts?.length > 0 &&
			  !notificationData?.some((i) => i.Path === 'pricing') ? (
				requested ? (
					<h3>Access Requested</h3>
				) : errorMessage ? (
					<h3>{errorMessage}</h3>
				) : (
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						{' '}
						<h3 style={{ margin: '0px', width: '4rem' }}>Pricing:</h3>{' '}
						<div onClick={requestAccess} className='request-access-cart'>
							request access
						</div>
					</div>
				)
			) : (
				''
			)}
		</div>
	);
}

export default CartPricing;
