import React from 'react';
import { useNavigate } from 'react-router-dom';

const FilterButtons = ({ list, envFilter }) => {
  const navigate = useNavigate();
  return (
    <div className='envFilters'>
      <div
        className={`envFilter-button ${envFilter === 'all' ? 'selected-env-button' : ''}`}
        onClick={() => {
          navigate(
            {
              pathname: `/environments`,
              // search: `?filter=all`,
            },
            { replace: true }
          );
        }}
      >
        All
      </div>

      {list &&
        [...new Set(Object.values(list)?.flat())]?.map((i) => {
          return (
            <div
              key={i}
              className={`envFilter-button ${
                i.toLowerCase() === envFilter.toLowerCase() ? 'selected-env-button' : ''
              }`}
              onClick={() => {
                navigate(
                  {
                    pathname: `/environments`,
                    search: `?filter=${i}`,
                  },
                  { replace: true }
                );
              }}
            >
              {i}
            </div>
          );
        })}
    </div>
  );
};

export default FilterButtons;
