import { useState, useEffect, React } from 'react';

import axios from 'axios';
import '../../CSS/DealerLogin.css';
import { FaEnvelope } from 'react-icons/fa';
// import { useNavigate } from "react-router-dom";
import { FaRegCheckCircle } from 'react-icons/fa';
import { FaCheckCircle } from 'react-icons/fa';
import { RiErrorWarningFill } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
const USER_REGEX = /\S+@\S+\.\S+/;
// eslint-disable-next-line no-undef
const apiAddress = process.env.REACT_APP_SERVER_API_ADDRESS;
const RESET_PASSWORD_URL = '/auth/requestResetPass';

const RequestReset = () => {
  // const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [validUser, setValidUser] = useState(false);
  // const [errMsg, setErrMsg] = useState("");
  const [submit, setSubmit] = useState(false);
  const [userFocus, setUserFocus] = useState(false);
  const [errMsg, setErrMsg] = useState(false);
  useEffect(() => {
    setValidUser(USER_REGEX.test(username));
    // setErrMsg("");
  }, [username]);

  const navigate = useNavigate();
  const handleSubmit = async (e) => {
    e.preventDefault();
    //if button enabled with JS hack
    // const v1 = USER_REGEX.test(username);
    // if (!v1) {
    //   setErrMsg("Invalid Entry");
    //   return;
    // }
    try {
      // const response =
      const response = await axios.post(
        `${apiAddress}${RESET_PASSWORD_URL}`,
        JSON.stringify({ email: username }),
        {
          headers: { 'Content-Type': 'application/json' },
          withCredentials: true,
        }
      );
      if (response) {
        setSubmit(true);
      }
      //clear state and controlled inputs
      //need value attrib on inputs for this
      //   setUsername("");
    } catch (err) {
      setSubmit(true);

      setErrMsg(err.response.data.message);
      //   if (!err?.response) {
      //     setErrMsg("No Server Response");
      //   } else {
      //     setErrMsg("Reset Failed");
      //   }
    }
  };

  return (
    <div className='request-pass-div'>
      {!submit ? (
        <div>
          {/* <p className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">
          {errMsg}
        </p> */}
          <h1>Forgot your password?</h1>
          <div className='underline'></div>
          <p>
            To reset your password, please enter the email address associated with your account
            below. We will send you an email with instructions on how to reset your password.
          </p>
          <form className='login-form'>
            <div
              className={`login-input-holder ${
                userFocus && !validUser ? 'error-input' : validUser ? 'valid-input' : ''
              }`}
            >
              <div className={`dealer-icon ${validUser ? 'valid-icon' : ''}`}>
                <FaEnvelope />
              </div>
              <input
                style={{ width: '324px' }}
                type='email'
                name='email'
                placeholder='Email'
                id='email'
                value={username}
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
                onFocus={() => {
                  setUserFocus(true);
                }}
                required
              ></input>
              {validUser ? (
                <div className='dealer-msg valid'>
                  <FaCheckCircle />
                </div>
              ) : userFocus ? (
                <div className='dealer-msg error'>
                  <RiErrorWarningFill />
                </div>
              ) : (
                <></>
              )}
            </div>
            {/* <label htmlFor="username">
                        <FontAwesomeIcon icon={faCheck} className={validName ? "valid" : "hide"} />
                        <FontAwesomeIcon icon={faTimes} className={validName || !username ? "hide" : "invalid"} />
                    </label>
                    <p id="uidnote" className={username && !validName ? "instructions" : "offscreen"}>
                        <FontAwesomeIcon icon={faInfoCircle} />
                        Must enter a valid email address
                    </p> */}

            <button className='submit' onClick={handleSubmit}>
              Request Reset Password Link
            </button>
          </form>
        </div>
      ) : errMsg ? (
        <div className='confirmation-div'>
          {' '}
          <div className='confirmation-msg' style={{ textAlign: 'center', fontSize: '20px' }}>
            {errMsg}
          </div>
          <button
            className='submit'
            onClick={() => {
              navigate('/');
            }}
          >
            Go Home
          </button>
        </div>
      ) : (
        <div className='confirmation-div'>
          <FaRegCheckCircle />
          <div className='confirmation-msg' style={{ textAlign: 'center', fontSize: '28px' }}>
            An email will be sent to {username} if an account exists. Please check spam and trash if
            you do not see the email within 5 minutes.
          </div>
          <button
            className='submit'
            onClick={() => {
              navigate('/');
            }}
          >
            Go Home
          </button>
        </div>
      )}
    </div>
  );
};

export default RequestReset;
