import React, { useEffect, useRef, useState } from 'react';
import '../../CSS/ServicePortal.css';
import ClaimButton from '../../Components/Service Portal/ClaimButton';
import { AiOutlineRight } from 'react-icons/ai';
import ClaimForm from '../../Components/Service Portal/ClaimForm';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import GeneralFeedback from '../../Components/Service Portal/GeneralFeedback';

const submitaclaim =
  'https://res.cloudinary.com/da3rom333/image/upload/v1705262551/Website%20Assets/SVGs/ServicePortal/submit-a-claim_c2wrox.svg';
const orderyinquiry =
  'https://res.cloudinary.com/da3rom333/image/upload/v1705262502/Website%20Assets/SVGs/ServicePortal/order-inquiry_yrchjt.svg';
const product =
  'https://res.cloudinary.com/da3rom333/image/upload/v1705262531/Website%20Assets/SVGs/ServicePortal/product-information_qwcbog.svg';
const general =
  'https://res.cloudinary.com/da3rom333/image/upload/v1705262487/Website%20Assets/SVGs/ServicePortal/general-feedback_tjrtr1.svg';
const submitaclaim1 =
  'https://res.cloudinary.com/da3rom333/image/upload/v1705262555/Website%20Assets/SVGs/ServicePortal/submit-select_yng6lf.svg';
const orderyinquiry1 =
  'https://res.cloudinary.com/da3rom333/image/upload/v1705262505/Website%20Assets/SVGs/ServicePortal/order-select_i24trl.svg';
const product1 =
  'https://res.cloudinary.com/da3rom333/image/upload/v1705262535/Website%20Assets/SVGs/ServicePortal/product-select_idvzvz.svg';
const general1 =
  'https://res.cloudinary.com/da3rom333/image/upload/v1705262491/Website%20Assets/SVGs/ServicePortal/general-select_pj1fj5.svg';
const sellsheets =
  'https://res.cloudinary.com/da3rom333/image/upload/v1705262524/Website%20Assets/SVGs/ServicePortal/PI_Sell_Sheets_raeyiu.svg';
const assembly =
  'https://res.cloudinary.com/da3rom333/image/upload/v1705262520/Website%20Assets/SVGs/ServicePortal/PI_Assembly_pswd33.svg';
const videos =
  'https://res.cloudinary.com/da3rom333/image/upload/v1705262520/Website%20Assets/SVGs/ServicePortal/PI_Assembly_pswd33.svg';
const images =
  'https://res.cloudinary.com/da3rom333/image/upload/v1705262509/Website%20Assets/SVGs/ServicePortal/PI_Images_jj68kh.svg';
const somethingelse =
  'https://res.cloudinary.com/da3rom333/image/upload/v1705262513/Website%20Assets/SVGs/ServicePortal/CS_Something_else_n8xb3a.svg';
const request =
  'https://res.cloudinary.com/da3rom333/image/upload/v1705262516/Website%20Assets/SVGs/ServicePortal/PI_Request_isq7yo.svg';
const returns =
  'https://res.cloudinary.com/da3rom333/image/upload/v1705262494/Website%20Assets/SVGs/ServicePortal/OI_Returns_Cancellations_clex1l.svg';
const missing =
  'https://res.cloudinary.com/da3rom333/image/upload/v1705262543/Website%20Assets/SVGs/ServicePortal/SC_Missing_Parts_dowjln.svg';
const warranty =
  'https://res.cloudinary.com/da3rom333/image/upload/v1705262546/Website%20Assets/SVGs/ServicePortal/SC_Warranty_aq2ifp.svg';
const damage =
  'https://res.cloudinary.com/da3rom333/image/upload/v1705262539/Website%20Assets/SVGs/ServicePortal/SC_Freight_Damage_araoj9.svg';
const track =
  'https://res.cloudinary.com/da3rom333/image/upload/v1705262498/Website%20Assets/SVGs/ServicePortal/OI_Track_Order_qc15jf.svg';
const banner =
  'https://res.cloudinary.com/da3rom333/image/upload/q_auto,f_auto/v1688650795/Website%20Assets/Banners/customerPortal_bm9dtc.jpg';

const ServicePortal = () => {
  useEffect(() => {
    document.title = `Contact - National Public Seating`;
  }, []);
  const [selectedButton, setSelectedButton] = useState();
  const buttonTitles = [
    { title: 'Product Information', image: product, selectImage: product1 },
    {
      title: 'Order Inquiry',
      image: orderyinquiry,
      selectImage: orderyinquiry1,
    },
    {
      title: 'Submit a Claim',
      image: submitaclaim,
      selectImage: submitaclaim1,
    },
    { title: 'Contact Us', image: general, selectImage: general1 },
  ];
  const [optionButtons, setOptionButtons] = useState();
  const [showForm, setShowForm] = useState();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchParamsUse = Object.fromEntries([...searchParams]);
  useEffect(() => {
    // console.log(searchParamsUse, "57");
    if (searchParamsUse.category) {
      scrollOnClick();
      // console.log(searchParamsUse.category, "59");
      setSelectedButton(searchParamsUse.category);
    } else if (!searchParamsUse.category) {
      setSelectedButton();
    }
    if (searchParamsUse.form) {
      setShowForm(searchParamsUse.form);
    }
  }, []);

  function navigateButton(buttonTitle) {
    // console.log(buttonTitle, "72");
    navigate(`/contact?category=${buttonTitle}`, { replace: true });
  }

  function navigateForm(formTitle) {
    // console.log(formTitle, "73");
    if (formTitle) {
      navigate({
        pathname: `/contact`,
        search: `?${createSearchParams(
          {
            category: searchParamsUse.category,
            form: formTitle,
          },
          { replace: true }
        )}`,
      });
    } else {
      navigate({
        pathname: `/contact`,
        search: `?${createSearchParams(
          {
            category: searchParamsUse.category,
            // form: formTitle,
          },
          { replace: true }
        )}`,
      });
    }
  }
  useEffect(() => {
    // console.log(searchParamsUse, "97");
    if (searchParamsUse.category) {
      setSelectedButton(searchParamsUse.category);
    }
    if (searchParamsUse.form) {
      setShowForm(searchParamsUse.form);
    } else if (!searchParamsUse.form) {
      setShowForm();
    }
  }, [searchParamsUse]);

  function switchForm() {
    switch (selectedButton) {
      case 'product-information':
        setOptionButtons([
          {
            title: 'View Sell Sheets',

            clickHandler: function () {
              navigate(`/media?type=Literature&types=%5B"Sell+Sheets"%5D`);
            },
            image: sellsheets,
          },
          {
            title: 'View Assembly Instructions',
            clickHandler: function () {
              navigate(`/media?type=Literature&types=%5B"Assembly+Instructions"%5D`);
            },
            image: assembly,
          },
          {
            title: 'View Product Videos',
            clickHandler: function () {
              navigate('/videos');
            },
            image: videos,
          },
          {
            title: 'View Product Images',
            clickHandler: function () {
              navigate('/media?type=Images&types=%5B%5D');
            },
            image: images,
          },
          {
            title: 'Request Catalog/Swatch Box',
            clickHandler: function () {
              navigateForm('request-catalog/swatch');
            },
            image: request,
          },
          {
            title: 'Need Something Else?',
            clickHandler: function () {
              navigateForm('something-else');
            },
            image: somethingelse,
          },
        ]);
        break;
      case 'order-inquiry':
        setOptionButtons([
          {
            title: 'Track My Order',
            clickHandler: function () {
              navigate('/track-order');
            },
            image: track,
          },

          {
            title: 'Need Something Else?',
            clickHandler: function () {
              navigateForm('something-else');
            },
            image: somethingelse,
          },
        ]);
        break;
      case 'submit-a-claim':
        setOptionButtons([
          {
            title: 'Missing Parts',
            clickHandler: function () {
              navigateForm('missing-parts');
            },
            image: missing,
          },
          {
            title: 'Freight Damage',
            clickHandler: function () {
              navigateForm('freight-damage');
            },
            image: damage,
          },
          {
            title: 'Warranty',
            clickHandler: function () {
              navigateForm('warranty');
            },
            image: warranty,
          },
          {
            title: 'Returns & Cancellations',
            clickHandler: function () {
              navigateForm('returns-&-cancellations');
            },
            image: returns,
          },
          {
            title: 'Need Something Else?',
            clickHandler: function () {
              navigateForm('something-else');
            },
            image: somethingelse,
          },
        ]);
        break;
      case 'contact-us':
        setOptionButtons();
        // navigateForm("general-feedback");
        break;
    }
  }
  useEffect(() => {
    switchForm();
    // console.log(selectedButton, "219");
  }, [selectedButton]);
  const ref = useRef(null);
  function scrollOnClick() {
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  }
  return (
    <div className='servicePortal'>
      <div className='banner'>
        <img src={banner}></img>
      </div>
      <div style={{ marginTop: '-30rem' }} className='inner-padding'>
        <div className='buttons'>
          {buttonTitles?.map((i) => {
            return (
              <ClaimButton
                key={i.title}
                i={i}
                selectedButton={selectedButton}
                setSelectedButton={navigateButton}
                setShowForm={navigateForm}
                scrollOnClick={scrollOnClick}
              />
            );
          })}
        </div>
        <div ref={ref} style={{ position: 'absolute', top: '52rem' }} id='formSection'></div>
        {selectedButton && !showForm && selectedButton !== 'contact-us' ? (
          <div className='form'>
            <h2>{selectedButton.replace('-', ' ').replace('-', ' ')} Options</h2>
            {optionButtons?.map((i, index) => {
              return (
                <div key={index} className='option' onClick={i.clickHandler}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <div className='vertical-line'></div>
                    <img src={i.image}></img>
                    <div className='title'>{i.title}</div>
                  </div>
                  <AiOutlineRight />
                </div>
              );
            })}
          </div>
        ) : showForm ? (
          <ClaimForm
            setShowForm={navigateForm}
            showForm={showForm}
            selectedButton={selectedButton}
          />
        ) : selectedButton === 'contact-us' ? (
          <GeneralFeedback />
        ) : showForm === 'something-else' ? (
          ''
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default ServicePortal;
