/* eslint-disable camelcase */
import React from 'react';
import { useParams } from 'react-router-dom';

const Catalogs_Pricer = () => {

  return (
    <div className='inner-padding'>
      <div
        style={{
          position: 'relative',
          paddingTop: 'max(60%,326px)',
          height: '0',
          width: '100%',
        }}
      >
        <iframe
          allow='clipboard-write'
          sandbox='allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-scripts allow-same-origin allow-popups allow-modals allow-popups-to-escape-sandbox allow-forms'
          allowFullScreen={true}
          style={{
            position: 'absolute',
            border: 'none',
            width: '100%',
            height: '100%',
            left: '0',
            right: '0',
            top: '0',
            bottom: '0',
          }}
          src={'https://e.issuu.com/embed.html?d=nps_catalog_2022-2023&u=npscorp'}
        ></iframe>
      </div>
      <div
        onClick={() => {
          window.open(
            'https://res.cloudinary.com/da3rom333/image/upload/v1691080625/NPS_2023_Price_List_DI_vebaql.pdf'
          );
        }}
        style={{
          textAlign: 'center',
          fontSize: '24px',
          textTransform: 'uppercase',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
      >
        Click here for pricer
      </div>
    </div>
  );
};

export default Catalogs_Pricer;
