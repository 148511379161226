import '../../CSS/Dropdown.css';
import Categorydrop from './Categorydrop';
import ProductCategoryImage from './ProductCategoryImage';

const ProductsDrop = ({ categories, productCategories }) => {

  return (
    <div className='products-dropdown'>
      {productCategories ? (
        <div>
          {productCategories.map((category) => (
            <div key={category.title}>
              <ProductCategoryImage category={category} />
            </div>
          ))}
        </div>
      ) : (
        ''
      )}
      <div className='line'></div>
      {categories?.productList ? (
        <div>
          {categories.productList?.map((c) => (
            <Categorydrop key={c.catName} c={c} />
          ))}
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ProductsDrop;
