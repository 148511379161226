import React, { useState } from "react";

const SwatchSample = ({ swatch, name, code, option }) => {
  const [hover, setHover] = useState(false);
  return (
    <>
      <div
        className="color-swatch"
        style={{
          backgroundImage: `url("${swatch}")`,
          backgroundSize: "cover",
        }}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        {hover ? (
          <div className="swatch-hover">
            {`${option}:`}
            <br></br>
            {`${name} (${code})`}
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
};

export default SwatchSample;
