import '../../CSS/NPSportal/reusableComponents.css';
import { useState } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';

const BasicDropdown = ({ list, title }) => {
  const [openList, setOpenList] = useState(false);
  const [chosenItem, setChosenItem] = useState('');

  return (
    <div>
      <div
        onClick={() => setOpenList(!openList)}
        className={`basic-dropdown-tab ${openList || chosenItem ? 'dropdown-clicked' : ''}`}
      >
        <div className='basic-flex'>
          <div className='basic-dropdown-title'>{chosenItem ? chosenItem.text : title}</div>
        </div>

        <MdKeyboardArrowDown
          className={`basic-dropdown-arrow ${openList ? 'rotate-up' : 'rotate-down'}`}
        />
      </div>
      {openList && (
        <div className='basic-dropdown-list'>
          {list?.map((i, index) => (
            <div
              key={index}
              onClick={() => {
                setChosenItem(i);
                setOpenList(false);
              }}
              className='dropdown-entire-item'
            >
              {i.icon && <img className='dropdown-icon' src={`${i.icon}`}></img>}
              <div className='dropdown-list-item'>{i.text}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default BasicDropdown;
