import CartItem from './CartItem';
import { useSelector } from 'react-redux';

const Cart = ({ cart, updateQty, removeFromCart, allowedAccess }) => {
  const mobileScreen = useSelector((state) => state.isMobile.value.isMobile);

  return (
    <div
      className={`cart ${cart?.length > 0 ? '' : 'empty'} ${mobileScreen ? 'mobile-cart' : ''} ${
        allowedAccess ? 'allowed' : ''
      }`}
    >
      {!mobileScreen && (
        <div className='cart-headers'>
          <div className='model-num-header'>Model #</div>
          <div className='description-header'>Product Description</div>
          <div style={{ width: '115px' }}>Nonboxed</div>
          <div style={{ width: '145px' }}>Quantity</div>
          {allowedAccess && (
            <>
              <div style={{ width: '65px' }}>Price</div>
              <div style={{ width: '85px' }}>Total</div>
            </>
          )}

          <div className='remove-column'></div>
        </div>
      )}
      {cart?.length ? (
        cart?.map((i, index) => {
          return (
            <CartItem
              cart={cart}
              item={i}
              key={index}
              updateQty={updateQty}
              index={index}
              removeFromCart={removeFromCart}
              allowedAccess={allowedAccess}
            />
          );
        })
      ) : (
        <div className='empty-cart'>
          <img src='https://res.cloudinary.com/da3rom333/image/upload/v1715714537/Icon_Empty_Cart_jtonnv.svg'></img>
          Your cart is empty
        </div>
      )}
    </div>
  );
};

export default Cart;
