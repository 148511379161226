import React from "react";
import { useState } from "react";

const CallUsButton = ({ buttonText, buttonLink }) => {
  const [hover, setHover] = useState(false);
  return (
    <div
      className="svg-div"
      onMouseEnter={() => {
        setHover(true);
        // console.log(hover);
      }}
      onMouseLeave={() => {
        setHover(false);
      }}
    >
      <a href={buttonLink}>
        <svg
          id="Capa_2"
          data-name="Capa 2"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 161.75 49.75"
        >
          <defs>
            <style>{".cls-1-call{fill:#00435b}.cls-2-call{fill:gray}"}</style>
          </defs>
          <g id="Capa_1-2" data-name="Capa 1">
            <rect
              x={0.38}
              y={0.37}
              width={161}
              height={49}
              rx={24.5}
              ry={24.5}
              style={{
                fill: "#fff",
                stroke: `${hover ? "var(--darkblue)" : "#b3b3b3"}`,
                strokeMiterlimit: 10,
                strokeWidth: `${hover ? "1px" : ".75px"}`,
                cursor: `${hover ? "pointer" : "default"}`,
              }}
            />
            <text
              x="50%"
              y="50%"
              dominantBaseline="middle"
              textAnchor="middle"
              style={{
                fontFamily: "Poppins-SemiBold,Poppins",
                fontSize: "13.98px",
                fontWeight: 600,
                fill: "#00435b",
              }}
            >
              {buttonText}
            </text>
          </g>
        </svg>
      </a>
    </div>
  );
};

export default CallUsButton;
